import auth0 from 'auth0-js';
import {baseUrl} from '../utils';

export default class Auth {
    auth0 = new auth0.WebAuth({
        domain: 'twcentral.auth0.com',
        clientID: 'AqEKq7opiXnf2N3MrHnNNIRfZL11W1Sw',
        redirectUri: baseUrl + 'callback',
        responseType: 'token id_token',
        audience: 'https://api.tw-portal.com',
        scope: 'openid'
    });

    constructor() {
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
    }

    login(callbackUrl) {
        if (typeof(Storage) === "undefined") {
            alert('Your browser or browser configuration is not supported. If the issue persist, please contact support.');
            return;
        }
        // if(callbackUrl){
        //     this.auth0.baseOptions.redirectUri = callbackUrl;
        // }
        this.auth0.authorize();
    }

    handleAuthentication(successCallback) {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
                successCallback();
            } else if (err) {
                // history.replace('/home');
                console.log(err);
            }
        });
    }

    setSession(authResult) {
        // Set the time that the access token will expire at
        let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
    }

    refreshSession(successCallback,failureCallback) {
        this.auth0.checkSession({
            // audience: 'https://api.tw-portal.com'
        }, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
                successCallback();
            } else if (err) {
                console.log(err);
                failureCallback();
            }
        });
    }

    checkSessionExp(){
        let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        let d = new Date();
        d.setMinutes(d.getMinutes() + 15);
        const expiresSoon = d.getTime() > expiresAt;
        return { expiresSoon, expiresAt };
    }

    logout() {
        // Clear access token and ID token from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        this.auth0.logout({
            returnTo: baseUrl,
            clientID: 'AqEKq7opiXnf2N3MrHnNNIRfZL11W1Sw'
        });
    }

    isAuthenticated() {
        // Check whether the current time is past the
        // access token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        const isAuthed = new Date().getTime() < expiresAt;
        if(!isAuthed){
            const location = window.location.pathname;
            if(location !== '/'){
                sessionStorage.lastPathAttempted = location;
            }
        }
        return isAuthed;
    }
}