import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});

class Community extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            city: '',
            zip: '',
            county: '',
            isLoading: false,
            validations: []
        };
    }

    componentDidMount() {
        const communityId = this.props.communityId || null;
        if(communityId !== null){
            this.setState({isLoading: true}, () => this.loadCommunity(communityId))
        } else {
            const community = this.props.community || null;
            if(community !== null){
                this.setState({
                    name: community.name || '',
                    city: community.city || '',
                    zip: community.zip || '',
                    county: community.county || ''
                })
            }
        }
    }

    loadCommunity(communityId) {
        request(
            'GET',
            '/Manage/getCommunity?communityId=' + communityId,
            null,
            (data) => {
                this.loadData(data.community);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            name: data.name,
            city: data.city || '',
            zip: data.zip || '',
            county: data.county || ''
        })
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }

    validateAll(){
        const fieldsToValidate = [
            'name',
            'city',
            'zip',
            'county'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(
                fieldsToValidate[i],
                this.state[fieldsToValidate[i]] || null
            );
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value){
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'name':
                isValid = validateField(value);
                invalidationReason = 'The name of the Community is required';
                break;
            case 'city':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the city name';
                }
                break;
            case 'zip':
                if(value !== null && value.length > 0){
                    isValid = validateField(value,5,5);
                    invalidationReason = 'Please enter the 5 digit zip code';
                }
                break;
            case 'county':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the county name';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        if (field === 'zip') {
            value = value.replace(/\D+/g, '');
            if(value.length > 5){
                value = value.substring(0,5);
            }
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE';
        const communityId = this.props.communityId || null;

        const {
            name,
            city,
            zip,
            county,
            isLoading
        } = this.state;
        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                        value={name}
                        name="name"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Community Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('name')}
                        helperText={this.fieldErrorMessage('name')}
                        required
                    />
                    <TextField autoComplete="new"
                       value={city}
                       name="city"
                       onChange={(e) => this.handleFieldChange(e)}
                       label="City"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('city')}
                       helperText={this.fieldErrorMessage('city')}
                    />
                    <TextField autoComplete="new"
                       value={zip}
                       name="zip"
                       onChange={(e) => this.handleFieldChange(e)}
                       label="Zip"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('zip')}
                       helperText={this.fieldErrorMessage('zip')}
                    />
                    <TextField autoComplete="new"
                       value={county}
                       name="county"
                       onChange={(e) => this.handleFieldChange(e)}
                       label="County"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('county')}
                       helperText={this.fieldErrorMessage('county')}
                    />
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.props.save({name,communityId,city,zip,county})} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(Community);