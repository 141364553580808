import React, { Component } from 'react';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue} from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import {request,downloadFile} from "../utils";
import Dialog from "./Dialog";
import UpdateConstruction from "./Forms/UpdateConstruction";
import CustomToolbarSelect from "./CustomToolbarSelect";
// import CustomToolbar from "./CustomToolbar";
import CloseConstruction from "./Forms/CloseConstruction";
import DeleteConstruction from "./Forms/DeleteConstruction";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DownloadConstructions from "./Forms/DownloadConstructions";

const styles = theme => ({
    root: {
        width: '100%',
        textAlign: 'left !important',
        marginTop: theme.spacing(1),

        // maxWidth: '100%'
    },
    paper: {
        width: '100%',
        // maxWidth: '100%'
    },
    tableWrapper: {
        // maxHeight: 440,
        // overflow: 'auto',
        maxWidth: '100%'
    },
    fabProgress: {
        color: blue[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    fabProgressWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    margin: {
        margin: theme.spacing(1),
    },
    controls: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    grow: {
        flexGrow: 1,
    },
    activeSwitch: {
        marginLeft: theme.spacing(2)
    }
});

class ConstructionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            builderId: 'All',
            builders: [],
            columns: [],
            data: [],
            currentBuilderId: 'All',
            loadingBuilders: true,
            showUpdateConstruction: false,
            constructionId: '',
            active: true,
            showDeleteDialog: false,
            deleteDialog: {},
            showCloseDialog: false,
            closeDialog: {},
            showDownloadDialog: false,
            downloadDialog: {},
            isActive: true,
            expDownload: true
        };
    }

    componentDidMount() {
        this.getBuilders();
    }

    loadConstructions() {

        let action = '/Manage/getConstructions';
        const builderId = this.state.builderId || null;

        action += '?isActive=' + this.state.isActive;

        if(builderId.length !== null && builderId !== 'All'){
            action += '&builderId=' + builderId;
        }

        request(
            'GET',
            action,
            null,
            (data) => {
                this.setState({columns: data.columns, data: data.constructions, isLoading: false, currentBuilderId: (builderId || 'All')})
            },
            (error) => {
                this.handleError(error);
            })
    }

    getBuilders() {
        if(this.state.builders.length > 0) {
            this.setState({loadingBuilders: false})
        } else {
            request(
                'GET',
                '/Manage/getBuilders',
                null,
                (data) => {
                    this.setState({builders: data.builders, loadingBuilders: false}, () => this.loadConstructions())
                },
                (error) => {
                    this.setState({loadingBuilders: false, isLoading: false}, () => this.props.showSnack('error',error))
                })
        }
    }

    handleError(error){
        this.setState({isLoading: false,loadingBuilders: false, data: [], columns: []}, () => this.props.showSnack('error',error))
    }

    handleChange(e){
        const builderId = e.target.value;
        this.setState({builderId})
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        if(field === 'isActive'){
            this.setState({[field]: !value, isLoading: true}, () => this.reload());
        } else {
            this.setState({[field]: !value});
        }
    }

    confirmClose(o){
        let summary = o.lot.length !== 0 ? 'Lot ' + o.lot  + ' ' : '';
        summary += o.address;

        const body = (
            <CloseConstruction summary={summary} constructionId={o.constructionId} close={(c) => this.close(c)} showSnack={(t,m) => this.props.showSnack(t,m)}/>
        );

        this.setState({
            closeDialog: {title: 'Close Property Details', body},
            showCloseDialog: true,
        })
    }
    
    close(id){
        const action = '/Manage/closeConstruction';
        const data = {constructionId: id};
        const successMessage = 'Successfully Closed Property Details!';
        
        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({saving: false, closeDialog: {}, showCloseDialog: false}, () => this.successAndReload(successMessage))
            },
            (error) => {
                this.setState({saving: false, closeDialog: {}, showCloseDialog: false}, () => this.props.showSnack('error',error))
            })
    }

    confirmDelete(o){
        let summary = o.lot.length !== 0 ? 'Lot ' + o.lot  + ' ' : '';
        summary += o.address;

        const body = (
            <DeleteConstruction summary={summary} constructionId={o.constructionId} delete={(i,c) => this.delete(i,c)} showSnack={(t,m) => this.props.showSnack(t,m)}/>
        );

        this.setState({
            deleteDialog: {title: 'Delete Property Details', body},
            showDeleteDialog: true,
        })
    }

    delete(id, confirmationCode){
        const action = '/Manage/deleteConstruction';
        const data = {constructionId: id, confirmationCode: confirmationCode};
        const successMessage = 'Successfully Deleted Property Details!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({saving: false, deleteDialog: {}, showDeleteDialog: false}, () => this.successAndReload(successMessage))
            },
            (error) => {
                this.setState({saving: false, deleteDialog: {}, showDeleteDialog: false}, () => this.props.showSnack('error',error))
            })
    }

    confirmDownload(){
        const builders = this.state.builders;
        const builderId = this.state.currentBuilderId;

        const body = (
            <DownloadConstructions builders={builders} builderId={builderId} download={(b,f,t,d) => this.download(b,f,t,d)} showSnack={(t,m) => this.props.showSnack(t,m)}/>
        );

        this.setState({
            downloadDialog: {title: 'Download Property Details', body},
            showDownloadDialog: true,
        })
    }

    download(builderId,fileName,tabName,date){
        const action = '/Manage/downloadConstructions?builderId=' + builderId + '&fileName=' + fileName + '&tabName=' + tabName + '&date=' + date;
        // const data = {builderId,fileName,tabName,date};
        const successMessage = 'Download Successfully Initiated!';

        downloadFile(
            fileName,
            'GET',
            action,
            null,
            (data) => {
                this.setState({saving: false, downloadDialog: {}, showDownloadDialog: false}, () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.setState({saving: false, downloadDialog: {}, showDownloadDialog: false}, () => this.props.showSnack('error',error))
            })
    }

    successAndReload(successMessage) {
        this.props.showSnack('success',successMessage);
        this.reload();
    }

    editConstruction(o) {
        const constructionId = o.constructionId || null;

        if(constructionId !== null){
            this.setState({constructionId: constructionId, showUpdateConstruction: true})
        }
    }

    reload(){
        this.setState({
            isLoading: true,
            saving: false,
            columns: [],
            data: []
        }, () => this.loadConstructions())
    }

    errorLoadingNewConstruction(error){
        this.setState({constructionId: '', showUpdateConstruction: false}, () => this.props.showSnack('error',error))
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    whiteSpace: 'pre'
                }
            },
            MUIDataTableBody: {
                emptyTitle: {
                    textAlign: 'left',
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    width: 250,
                    paddingLeft: 15
                }
            }
        }
    });

    render() {
        const { classes } = this.props;
        const fileName = "tw_property_details.csv";

        const {
            isLoading,
            builderId,
            builders,
            columns,
            data,
            currentBuilderId,
            loadingBuilders,
            showUpdateConstruction,
            constructionId,
            active,
            saving,
            showDeleteDialog,
            deleteDialog,
            showCloseDialog,
            closeDialog,
            showDownloadDialog,
            downloadDialog,
            isActive,
            expDownload
        } = this.state;

        const options = {
            print: false,
            selectableRowsOnClick: true,
            selectableRowsHeader: true,
            selectableRows: 'single',
            responsive: 'standard',
            downloadOptions: {filename: fileName, separator: ','},
            onDownload: (buildHead, buildBody, columns, data) => {
                if(expDownload){
                    // createCSVDownload(columns,data, {downloadOptions: {filename: fileName, separator: ','}})
                    this.confirmDownload();
                    return false;
                }
                return "\uFEFF" + buildHead(columns) + buildBody(data);
            },
            // jumpToPage: true,
            textLabels: {
                body: {
                    noMatch: isLoading ? "Loading.." : "No Results found",
                },
            },
            rowsPerPage: 100,
            rowsPerPageOptions: [10,25,50,100],
            tableBodyHeight: '440px',
            tableBodyMaxHeight: '440px',
            // customToolbar: () => (
            //     <CustomToolbar
            //         download={() => {
            //             createCSVDownload(columns,data, {downloadOptions: {filename: fileName, separator: ','}})
            //         }}
            //     />
            // ),
            customToolbarSelect: (selectedRows) => (
                <CustomToolbarSelect
                    showEdit={true}
                    edit={(o) => this.editConstruction(o)}
                    showConstructionClose={isActive}
                    showConstructionDelete={isActive}
                    delete={(o) => this.confirmDelete(o)}
                    close={(o) => this.confirmClose(o)}
                    selectedRows={selectedRows}
                    data={data}
                />
            ),
        };

        const closeDialogButtons = [
            {
                disable: saving,
                text: 'Cancel',
                callback: () => {
                    this.setState({
                        closeDialog: {},
                        showCloseDialog: false,
                    })
                }
            }
        ];

        const deleteDialogButtons = [
            {
                disable: saving,
                text: 'Cancel',
                callback: () => {
                    this.setState({
                        deleteDialog: {},
                        showDeleteDialog: false,
                    })
                }
            }
        ];

        const downloadDialogButtons = [
            {
                disable: saving,
                text: 'Cancel',
                callback: () => {
                    this.setState({
                        downloadDialog: {},
                        showDownloadDialog: false,
                    })
                }
            }
        ];
        
        const dialogBody = (
            <div>
                {(constructionId||null) !== null &&
                <UpdateConstruction readOnlyMode={active} constructionId={constructionId} showSnack={(t,m) => this.props.showSnack(t,m)} error={(e) => this.errorLoadingNewConstruction(e)} />
                }
            </div>
        );

        return (
            <div>
                <div className={classes.controls}>
                    <div className={classes.grow}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="builder-select-label">Builder</InputLabel>
                            <Select
                                id="builder-select"
                                value={builderId}
                                onChange={(e) => this.handleChange(e)}
                                disabled={isLoading || loadingBuilders}
                            >
                                <MenuItem value="All">All</MenuItem>
                                {builders.map((item, index) => (
                                    <MenuItem key={'builder-select-' + item.builderId} value={item.builderId}>{item.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Filter by Builder</FormHelperText>
                        </FormControl>
                        <div style={{display: 'inline-flex'}}>
                            <div className={classes.fabProgressWrapper}>
                                <Fab
                                    color="primary"
                                    aria-label="load"
                                    disabled={builderId === currentBuilderId || isLoading}
                                    onClick={() => this.setState({isLoading: true, columns: [], data: []}, () => this.loadConstructions())
                                    }>
                                    <SendIcon/>
                                </Fab>
                                {loadingBuilders &&
                                <CircularProgress size={68} className={classes.fabProgress} />
                                }
                            </div>
                        </div>
                        <FormControlLabel
                            className={classes.activeSwitch}
                            control={
                                <Switch
                                    name="isActive"
                                    checked={isActive}
                                    onChange={(e) => this.handleSwitchChange(e)}
                                    color="primary"
                                    disabled={isLoading}
                                />
                            }
                            label="Active Property Details"
                        />
                        <FormControlLabel
                            className={classes.activeSwitch}
                            control={
                                <Switch
                                    name="expDownload"
                                    checked={expDownload}
                                    onChange={(e) => this.handleSwitchChange(e)}
                                    color="primary"
                                    disabled={isLoading}
                                />
                            }
                            label="Excel Download?"
                        />
                    </div>
                    <div className={classes.fabProgressWrapper}>
                        <Fab
                            aria-label="save"
                            onClick={() => this.reload()}
                            disabled={isLoading}
                        >
                            <RefreshIcon/>
                        </Fab>
                        {isLoading && <CircularProgress size={68} className={classes.fabProgress} />}
                    </div>
                </div>
                <div className={classes.root}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                            // title={title}
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
                <Dialog fullScreen={true} closeCallback={() => this.setState({constructionId: '', showUpdateConstruction: false})} showDialog={showUpdateConstruction} title='Edit Property Details' bodyHtml={dialogBody}/>
                <Dialog closeCallback={() => this.setState({deleteDialog: {}, showDeleteDialog: false})} showDialog={showDeleteDialog} title={deleteDialog.title||''} bodyHtml={deleteDialog.body||''} buttons={deleteDialogButtons} />
                <Dialog closeCallback={() => this.setState({closeDialog: {}, showCloseDialog: false})} showDialog={showCloseDialog} title={closeDialog.title||''} bodyHtml={closeDialog.body||''} buttons={closeDialogButtons} />
                <Dialog closeCallback={() => this.setState({downloadDialog: {}, showDownloadDialog: false})} showDialog={showDownloadDialog} title={downloadDialog.title||''} bodyHtml={downloadDialog.body||''} buttons={downloadDialogButtons} />
            </div>
        );
    }
}

export default withStyles(styles)(ConstructionTable);