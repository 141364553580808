import React, { Component } from 'react';
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import moment from 'moment'

const styles = theme => ({
    card: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    cardContent: {
    },
    author: {

    },
    date: {

    },
    summary: {

    },
    detail: {

    },
    title: {
        textAlign: 'left',
        fontSize: '.75rem',
        fontWeight: 600
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    archive: {

    }
});

class Log extends Component {
    handleSwitchChange(index,currentState){
        const log = this.props.log || null;
        // const logObject = log !== null && (log.object || null) !== null ? log.object : null;
        const updates = log !== null ? (log.updates || []) : [];
        updates[index].isArchived = !currentState;

        this.props.save(updates);
    }

    render() {
        const { classes } = this.props;

        const title = this.props.title || 'Log';
        const type = this.props.type || 'log';
        const log = this.props.log || null;
        // const logObject = log !== null && (log.object || null) !== null ? log.object : null;
        const updates = log !== null ? (log.updates || []) : [];
        const noUpdatesText = this.props.noUpdatesText || 'No updates found..';

        return (
            <div>
                <Typography color="primary" className={classes.title} variant="h6">{title}</Typography>
                {updates.length > 0 ? (
                    <Grid container>
                        {updates.map((item, index) =>
                            <Card className={classes.card} key={type + '-update-' + index}>
                                <CardContent className={classes.cardContent}>
                                    <div className={classes.author}>{item.author}</div>
                                    <div className={classes.date}>{item.dateAdded}</div>
                                    <div className={classes.summary}>{item.summary}</div>
                                    <div className={classes.detail}>{item.detail}</div>
                                    {type !== 'price' &&
                                    <div className={classes.archive}>
                                        <FormControlLabel
                                            className={classes.switch}
                                            control={
                                                <Switch
                                                    name="isArchived"
                                                    checked={item.isArchived}
                                                    onChange={() => this.handleSwitchChange(index,item.isArchived)}
                                                    color="primary"
                                                    size="small"
                                                    disabled={this.props.readOnlyMode}
                                                />
                                            }
                                            label="Archived?"
                                        />
                                    </div>
                                    }
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                ) : (
                    <div className={classes.noUpdates}>{noUpdatesText}</div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(Log);
