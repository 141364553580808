import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {validateField} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        flex: '0 0 98%'
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // flexGrow: 1,
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    inlineButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    fullWidth: {
        flex: '0 0 100%'
    }
});

class DownloadConstructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            builderId: '',
            fileName: '',
            tabName: '',
            date: '',
            isLoading: false,
            validations: []
        };
    }

    componentDidMount() {
        const builderId = this.props.builderId || null;
        if(builderId !== null && builderId !== 'All'){
            const fileName = this.getBuilderName(builderId);
            this.setState({builderId, fileName})
        }
    }

    validateAll() {
        const fieldsToValidate = [
            'builderId',
            'fileName',
            'tabName',
            'date',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'builderId':
                isValid = value !== null && value.length > 0;
                invalidationReason = 'Please select a builder.';
                break;
            case 'fileName':
                isValid = validateField(value);
                invalidationReason = 'Please enter a File Name';
                break;
            case 'tabName':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = '';
                }
                break;
            case 'date':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = '';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        if(field === 'fileName' || field === 'tabName'){
            value = value.replace(/[^A-Za-z0-9-_\s]/g, '').trim();
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    handleChange(e){
        const builderId = e.target.value;
        let fileName = this.state.fileName || null;
        if(fileName !== null){
            this.setState({builderId})
        } else {
            fileName = this.getBuilderName(builderId);
            this.setState({builderId, fileName})
        }
    }

    getBuilderName(builderId) {
        const builders = this.props.builders;
        const vIndex = _.findIndex(builders, {builderId: builderId});
        if(vIndex > -1){
            return builders[vIndex].name;
        }
        return '';
    }

    download() {
        const {
            builderId,
            fileName,
            tabName,
            date,
        } = this.state;

        this.props.download(builderId,fileName,tabName,date);
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'DOWNLOAD';
        const builders = this.props.builders || [];

        const {
            builderId,
            fileName,
            tabName,
            date,
            isLoading
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    {/*<strong>Please </strong>*/}
                    {/*<br/>*/}
                    <FormControl className={classes.formControl}>
                        <InputLabel id="builder-select-label">Builder</InputLabel>
                        <Select
                            id="builder-select"
                            value={builderId}
                            onChange={(e) => this.handleChange(e)}
                            disabled={isLoading}
                        >
                            {/*<MenuItem value="All">All</MenuItem>*/}
                            {builders.map((item, index) => (
                                <MenuItem key={'builder-select-' + item.builderId} value={item.builderId}>{item.name}</MenuItem>
                            ))}
                        </Select>
                        {/*<FormHelperText>Select the Builder</FormHelperText>*/}
                    </FormControl>
                    <br/>
                    <TextField autoComplete="new"
                        name="fileName"
                        value={fileName}
                        onChange={(e) => this.handleFieldChange(e)}
                        label="File Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('fileName')}
                        helperText={this.fieldErrorMessage('fileName')}
                        required
                        fullWidth
                    />
                    <TextField autoComplete="new"
                       name="tabName"
                       value={tabName}
                       onChange={(e) => this.handleFieldChange(e)}
                       label="Tab Name"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('tabName')}
                       helperText={this.fieldErrorMessage('tabName')}
                    />
                    <TextField autoComplete="new"
                       name="date"
                       value={date}
                       onChange={(e) => this.handleFieldChange(e)}
                       label="Date"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('date')}
                       helperText={this.fieldErrorMessage('date')}
                    />
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid() || isLoading} onClick={() => this.setState({isLoading: true},() => this.download())} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(DownloadConstructions);