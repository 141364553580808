import React from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from '@material-ui/icons/Home';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ViewListIcon from '@material-ui/icons/ViewList';
import Hidden from '@material-ui/core/Hidden';
import Zoom from '@material-ui/core/Zoom';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/es/Typography/Typography";
import {request} from "../utils";
import _ from 'lodash';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
        minHeight: '90vh',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    appBar: {
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: 6,
        marginRight: 6,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        overflowX: 'auto'
    },
    avatar: {
        margin: '10px auto',
        backgroundColor: green["500"],
        width: 85,
        height: 85,
        userSelect: 'none',
        outline: 'none !important'
    },
    user: {
        color: green["500"],
        userSelect: 'none',
        outline: 'none !important',
        padding: '0 12px'
    },
    icon: {
        color: 'inherit'
    },
    activeLink: {
        textDecoration: 'none',
        color: green["500"] + ' !important',
    },
    link: {
        textDecoration: 'none',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    linkList: {
        padding: 0
    },
    active: {
        backgroundColor: green["100"],
        color: '#fff'
    },
    areaHeader: {
        textAlign: 'left',
        wordBreak: 'break-word',
        margin: '0 0 15px 2px',
        fontWeight: 400,
        textTransform: 'uppercase',
        fontSize: '1.5rem'
    },
    menuItem: {

    }
});

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawerOpen: false,
            anchorEl: null,
            accountOpen: false,
            userName: 'User',
            permissions: [],
            intervalId: null,
            // showSessionWarning: false,
            // sessionExpiresAt: null
        };
    }

    componentDidMount() {
        const intervalId = setInterval(() => this.timer(), 60000);

        request(
            'GET',
            '/user/getUser',
            null,
            (data) => {
                // window.sessionStorage.clear();
                // window.sessionStorage.setItem("user", data.user);
                // window.sessionStorage.setItem("permissions", data.permissions);
                this.setState({userName: data.user, permissions: data.permissions, intervalId});
            },
            (error) => {
                this.setState({intervalId});
            })
    }

    componentWillUnmount() {
        const intervalId = this.state.intervalId;
        if(intervalId !== null){
            clearInterval(intervalId);
        }
    }

    timer() {
        const exp = this.props.auth.checkSessionExp(); //expiresSoon, expiresAt

        if(exp.expiresSoon) { // && !this.state.showSessionWarning
            console.log('Session Expires at: ' + exp.expiresAt);
            // this.setState({sessionExpiresAt: exp.expiresAt, showSessionWarning: true})
            this.refreshSession();
        }
    }

    refreshSession() {
        this.props.auth.refreshSession(
            () => {
                // this.setState({sessionExpiresAt: null, showSessionWarning: false})
            },
            () => {
                alert("There was an error refreshing your session. Please save your work immediately!")
                // this.setState({sessionExpiresAt: null, showSessionWarning: false}, () => alert("There was an error refreshing your session. Please save your work immediately!"))
            })
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleDrawerOpen = () => {
        this.setState({ drawerOpen: true });
    };

    handleDrawerClose = () => {
        this.setState({ drawerOpen: false });
    };

    hasPermission(permissions,permission){
        return _.indexOf(permissions,permission) !== -1;
    }

    render() {
        const userName = this.state.userName || 'User';
        const { classes, auth, area } = this.props;
        const permissions = this.state.permissions;

        // const showSessionWarning = this.state.showSessionWarning;

        // const sessionWarningButtons = [
        //     {
        //         text: 'Stay Logged In',
        //         callback: () => this.refreshSession()
        //     },
        //     {
        //         text: 'Logout Now',
        //         callback: () => this.props.auth.logout()
        //     }
        // ];

        // const sessionExpiresAt = this.state.sessionExpiresAt || null;

        // const renderer = ({ hours, minutes, seconds, completed }) => {
        //     return <span>Your session will expire in {minutes} minutes and {seconds} seconds. Please click "Stay Logged In" if you don't want to lose your work.</span>;
        // };

        // const sessionWarningBody = (
        //     <div>
        //         {sessionExpiresAt !== null &&
        //             <Countdown renderer={renderer} date={sessionExpiresAt} onComplete={() => this.props.auth.logout()} />
        //         }
        //     </div>
        // );

        // const sessionWarningTitle = "Session Expiring Soon!";

        const menuList = (
            <List className={classes.linkList}>
                <Link to={'/home'} className={classes.link}>
                    <ListItem button>
                        <ListItemIcon>
                            <Tooltip TransitionComponent={Zoom} title="Home" placement="right">
                                <HomeIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={'Home'} />
                    </ListItem>
                </Link>
                {this.hasPermission(permissions,'read:dashboard') &&
                <Link to={'/admin/dashboard'} className={classes.link}>
                    <ListItem button>
                        <ListItemIcon>
                            <Tooltip TransitionComponent={Zoom} title="Dashboard" placement="right">
                                <DashboardIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={'Dashboard'} />
                    </ListItem>
                </Link>
                }
                {(this.hasPermission(permissions,'read:propertydetails') || this.hasPermission(permissions,'read:salesdetails') || this.hasPermission(permissions,'read:other')) &&
                <Link to={'/admin/manage'} className={classes.link}>
                    <ListItem button>
                        <ListItemIcon>
                            <Tooltip TransitionComponent={Zoom} title="Properties and Sales" placement="right">
                                <ViewListIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={'Properties and Sales'} />
                    </ListItem>
                </Link>
                }
                {this.hasPermission(permissions,'read:user') &&
                <Link to={'/admin/users'} className={classes.link}>
                    <ListItem button>
                        <ListItemIcon>
                            <Tooltip TransitionComponent={Zoom} title="Users" placement="right">
                                <SupervisedUserCircleIcon />
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={'Users'} />
                    </ListItem>
                </Link>
                }
            </List>
        );

        const accountOpen = Boolean(this.state.anchorEl);
        const accountMenu = (
            <div style={{margin: '0 6px'}}>
                <IconButton
                    aria-owns={accountOpen ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={accountOpen}
                    onClose={this.handleClose}
                >
                    <div style={{outline: 'none'}}>
                        <Avatar className={classes.avatar}>{userName.charAt(0)}</Avatar>
                        <h3 className={classes.user}>{userName}</h3>
                        <Divider/>
                        <MenuItem onClick={() => auth.logout()} className={classes.menuItem}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText inset primary="Logout" />
                        </MenuItem>
                    </div>
                </Menu>
            </div>
        );
        const appBarHeader = (
            <div className={classes.grow}>
                {area ? (
                    <Typography variant="h6" color="inherit">
                        {area}
                    </Typography>
                ) : (
                    <div className="logo">
                        {/*<img src={logo} alt="logo" />*/}
                    </div>
                )}
            </div>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                <Hidden only={['lg', 'xl']}>
                    <AppBar className={classes.appBar}>
                        <Toolbar disableGutters={true}>
                            <IconButton
                                color="inherit"
                                aria-label="Open Menu"
                                onClick={this.handleDrawerOpen}
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: this.state.drawerOpen,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            {appBarHeader}
                            {accountMenu}
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        anchor="left"
                        open={this.state.drawerOpen}
                        onClose={this.handleDrawerClose}
                    >
                        <div
                            tabIndex={0}
                            role="button"
                            onClick={this.handleDrawerClose}
                            onKeyDown={this.handleDrawerClose}
                        >
                            <div className={classes.toolbar}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ArrowBack />
                                </IconButton>
                            </div>
                            <Divider />
                            {menuList}
                        </div>
                    </Drawer>
                </Hidden>
                <Hidden only={['xs','sm', 'md']}>
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: this.state.drawerOpen,
                        })}
                    >
                        <Toolbar disableGutters={true}>
                            <IconButton
                                color="inherit"
                                aria-label="Open Menu"
                                onClick={this.handleDrawerOpen}
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: this.state.drawerOpen,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            {appBarHeader}
                            {accountMenu}
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: this.state.drawerOpen,
                            [classes.drawerClose]: !this.state.drawerOpen,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: this.state.drawerOpen,
                                [classes.drawerClose]: !this.state.drawerOpen,
                            }),
                        }}
                        open={this.state.drawerOpen}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ArrowBack />
                            </IconButton>
                        </div>
                        <Divider />
                        {menuList}
                    </Drawer>
                </Hidden>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {this.props.children}
                </main>
                {/*<Dialog closeCallback={() => this.setState({showSessionWarning: false})} showDialog={showSessionWarning} title={sessionWarningTitle} bodyHtml={sessionWarningBody} buttons={sessionWarningButtons} />*/}
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Layout);