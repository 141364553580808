import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import { amber, green, red, blue} from '@material-ui/core/colors';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FaceIcon from '@material-ui/icons/Face';
import CategoryIcon from '@material-ui/icons/Category';
import Paper from '@material-ui/core/Paper';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Users from "./Users";
import Templates from "./Templates";

const styles = theme => ({
    header: {
        textAlign: 'center',
        color: '#555',
        fontSize: 30,
        textTransform: 'uppercase',
        fontWeight: 300,
        margin: theme.spacing(2) + 'px 0'
    },
    grid: {
        padding: theme.spacing(2) + 'px 0',
        display: 'flex'
        // minHeight: 200
    },
    grow: {
        flex: 1
    },
    flex: {
        display: 'flex'
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: red[600],
    },
    info: {
        backgroundColor: blue[600],
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },

    appBar: {
        backgroundColor: '#fefefe'
    },
    tab: {
        minHeight: 400
        // padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        borderRadius: 0
    },
});

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            snackMessage: '',
            snackType: 'test',
            showSnack: false
        };
    }

    // componentDidMount() {
    //
    // }

    handleChange = (event, value) => {
        this.setState({ tab: value });
    };

    handleSnackbarClose() {
        this.setState({snackMessage: '', snackType: '', showSnack: false});
    }

    showSnack(type,message){
        this.setState({snackMessage: message, snackType: type, showSnack: true});
    }

    render() {
        const { classes } = this.props;
        const { tab, showSnack, snackType, snackMessage } = this.state;

        return (
            <div>
                <div className={classes.header}>
                    TW Central Admin
                </div>
                <AppBar elevation={2} className={classes.appBar} position="static" color="default">
                    <Tabs
                        value={this.state.tab}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="Users" icon={<FaceIcon/>}/>
                        <Tab label="Templates" icon={<CategoryIcon/>}/>
                    </Tabs>
                </AppBar>
                <Paper className={classes.paper} elevation={2}>
                    {tab === 0 &&
                    <div className={classes.tab}>
                        <Users showSnack={(t,m) => this.showSnack(t,m)}/>
                    </div>
                    }
                    {tab === 1 &&
                    <div className={classes.tab}>
                        <Templates showSnack={(t,m) => this.showSnack(t,m)}/>
                    </div>
                    }
                </Paper>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={showSnack}
                    autoHideDuration={4000}
                    onClose={() => this.handleSnackbarClose()}
                >
                    <SnackbarContent
                        className={classes[snackType]}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                {snackType === 'error' &&
                                <ErrorIcon className={classes.iconVariant}/>
                                }
                                {snackType === 'warning' &&
                                <WarningIcon className={classes.iconVariant}/>
                                }
                                {snackType === 'info' &&
                                <InfoIcon className={classes.iconVariant}/>
                                }
                                {snackType === 'success' &&
                                <SuccessIcon className={classes.iconVariant}/>
                                }
                                {snackMessage}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={() => this.handleSnackbarClose()}>
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>
        );
    }
}

export default withStyles(styles)(Admin);