import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import TextField from "@material-ui/core/TextField";
import {request} from "../../utils";
import _ from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Loading from "../Loading";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Dialog from "../Dialog";
import UpdateLenderUpdate from "./UpdateLenderUpdate";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(0.5),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    card: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    cardContent: {
    },
    update: {

    },
    updateValue: {

    },
    updateDate: {
        fontWeight: 500,
    }
});

class LenderUpdates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lenderUpdates: [],
            applicationDate: null,
            qualified: false,
            approvalDate: null,
            status: '',
            notes: '',
            // copyPreviousNotes: false,
            ctc: false,
            clearToCloseDate: null,
            clearToCloseNotes: '',
            showLenderUpdateForm: false,
            validations: [],
            isLoading: false,
            showDialog: false,
            dialog: {}
        };
    }

    componentDidMount() {
        const lenderUpdates = this.props.lenderUpdates || null;
        if(lenderUpdates !== null){
            this.setState({lenderUpdates})
        }
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'applicationDate',
            'qualified',
            'approvalDate',
            'status',
            'notes',
            'ctc',
            'clearToCloseDate'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';
        const {ctc} = this.state;

        switch(fieldName){
            case 'applicationDate':
                // isValid = value !== null;
                // invalidationReason = 'Please enter the Application Date';
                break;
            case 'qualified':
                break;
            case 'approvalDate':
                break;
            case 'status':
                break;
            case 'notes':
                break;
            case 'ctc':
                break;
            case 'clearToCloseDate':
                if(ctc){
                    isValid = value !== null;
                    invalidationReason = 'Please enter the Clear To Close Date';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        // if(field === 'notes' && value !== ''){
        //     this.setState({[field]: value, validations, copyPreviousNotes: false});
        // } else{
            this.setState({[field]: value, validations});
        // }
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        if(field === 'ctc'){
            this.setState({[field]: !value, clearToCloseDate: null});
        } else {
            this.setState({[field]: !value});
        }
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        if(i > -1){
            return false;
        }
        return true;
    }

    reset() {
        this.setState({
            applicationDate: null,
            qualified: false,
            approvalDate: null,
            status: '',
            notes: '',
            ctc: false,
            clearToCloseDate: null,
            clearToCloseNotes: '',
            showLenderUpdateForm: false,
            // copyPreviousNotes: false
        })
    }

    saveLenderUpdate() {
        const {
            applicationDate,
            qualified,
            approvalDate,
            status,
            notes,
            clearToCloseDate,
            clearToCloseNotes,
            // copyPreviousNotes
        } = this.state;

        const data = {
            applicationDate,
            qualified,
            approvalDate,
            status,
            notes,
            clearToCloseDate,
            clearToCloseNotes,
            // copyPreviousNotes,
            contractId: this.props.contractId
        };

        request(
            'POST',
            '/Manage/addLenderUpdate',
            data,
            (data) => {
                this.loadData(data.lenderUpdate);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        let lu = this.state.lenderUpdates;
        lu.unshift(data);

        // console.log(lu);
        // console.log(data);

        this.setState({
            lenderUpdates: lu,
            isLoading: false,
            applicationDate: null,
            qualified: false,
            approvalDate: null,
            status: '',
            notes: '',
            ctc: false,
            // copyPreviousNotes: false,
            clearToCloseDate: null,
            clearToCloseNotes: '',
            showLenderUpdateForm: false
        }, () => this.props.showSnack('success','Lender Update Added Successfully!'))
    }

    editLenderUpdate(lenderUpdateId){
        const lenderUpdates = this.state.lenderUpdates;
        const index = _.findIndex(lenderUpdates, {lenderUpdateId: lenderUpdateId});
        const lu = lenderUpdates[index];

        const body = (
            <UpdateLenderUpdate lenderUpdate={lu} success={(d) => this.lenderUpdateSuccess(d)} showSnack={this.props.showSnack} />
        );

        this.setState({
            dialog: {title: 'Edit Lender Update', body},
            showDialog: true,
        })
    }

    lenderUpdateSuccess(data)
    {
        const lenderUpdates = this.state.lenderUpdates;
        const index = _.findIndex(lenderUpdates, {lenderUpdateId: data.lenderUpdateId});
        const lu = lenderUpdates[index];

        lu.applicationDate = data.applicationDate;
        lu.qualified = data.qualified;
        lu.approvalDate = data.approvalDate;
        lu.status = data.status;
        lu.notes = data.notes;
        lu.clearToCloseDate = data.clearToCloseDate;
        lu.clearToCloseNotes = data.clearToCloseNotes;

        this.setState({
            lenderUpdates: lenderUpdates,
            dialog: {},
            showDialog: false,
        }, () => this.props.showSnack('success','Lender Update Edited Successfully!'))
    }

    render() {
        const { classes,readOnlyMode,payment } = this.props;

        const {
            isLoading,
            showLenderUpdateForm,
            lenderUpdates,
            showDialog,
            dialog,

            applicationDate,
            qualified,
            approvalDate,
            status,
            notes,
            ctc,
            // copyPreviousNotes,
            clearToCloseDate,
            clearToCloseNotes
        } = this.state;

        return (
            <div>
                {showLenderUpdateForm ? (
                <div>
                    {payment !== 'Cash' &&
                    <div>
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                margin="normal"
                                className={classes.dateInput}
                                label="Application Date"
                                value={applicationDate}
                                onChange={(d) => this.handleDateChange('applicationDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                                // required
                            />
                        </MuiPickersUtilsProvider>
                        <br/>
                        <FormControlLabel
                            className={classes.switch}
                            control={
                                <Switch
                                    name="qualified"
                                    checked={qualified}
                                    onChange={(e) => this.handleSwitchChange(e)}
                                    color="primary"
                                />
                            }
                            label="Qualifies?"
                        />
                        <br/>
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                margin="normal"
                                className={classes.dateInput}
                                label="Approval Date"
                                value={approvalDate}
                                onChange={(d) => this.handleDateChange('approvalDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                            />
                        </MuiPickersUtilsProvider>
                        <br/>
                        <FormControl className={classes.dropdown}>
                            <InputLabel htmlFor="construction-status-select">Status</InputLabel>
                            <Select
                                id="construction-status-select"
                                name="status"
                                value={status}
                                onChange={(e) => this.handleSelectChange(e)}
                            >
                                <MenuItem value="Good">Good</MenuItem>
                                <MenuItem value="Attention Required">Attention Required</MenuItem>
                                <MenuItem value="In Progress">In Progress</MenuItem>
                            </Select>
                        </FormControl>
                        <br/>
                    </div>
                    }
                    <TextField autoComplete="new"
                        value={notes}
                        name="notes"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Notes"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('notes')}
                        helperText={this.fieldErrorMessage('notes')}
                        fullWidth
                    />
                    {/*{notes.length > 0 &&*/}
                    {/*<div>*/}
                    {/*    <br/>*/}
                    {/*    <FormControlLabel*/}
                    {/*        className={classes.switch}*/}
                    {/*        control={*/}
                    {/*            <Switch*/}
                    {/*                name="copyPreviousNotes"*/}
                    {/*                checked={copyPreviousNotes}*/}
                    {/*                onChange={(e) => this.handleSwitchChange(e)}*/}
                    {/*                color="primary"*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        label="Copy Notes from previous update?"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*}*/}
                    <div>
                        <br/>
                        <FormControlLabel
                            className={classes.switch}
                            control={
                                <Switch
                                    name="ctc"
                                    checked={ctc}
                                    onChange={(e) => this.handleSwitchChange(e)}
                                    color="primary"
                                />
                            }
                            label="Clear to Close?"
                        />
                        <br/>
                        {ctc &&
                        <div>
                            <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/DD/YYYY"
                                    margin="normal"
                                    className={classes.dateInput}
                                    label="Clear to Close Date"
                                    value={clearToCloseDate}
                                    onChange={(d) => this.handleDateChange('clearToCloseDate', d)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    autoOk={true}
                                    required
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        }
                        <br/>
                        <TextField autoComplete="new"
                                   value={clearToCloseNotes}
                                   name="clearToCloseNotes"
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="CTC Notes"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('clearToCloseNotes')}
                                   helperText={this.fieldErrorMessage('clearToCloseNotes')}
                                   fullWidth
                        />
                    </div>
                    <br/>
                    <br/>
                    <Button variant="contained" color="primary" onClick={() => this.setState({isLoading: true}, () => this.saveLenderUpdate())} className={classes.button}>SAVE</Button>
                    <Button variant="contained" color="secondary" onClick={() => this.reset()} className={classes.button}>CANCEL</Button>
                </div>
                ):(
                    <div>
                        <Button disabled={readOnlyMode} variant="contained" color="primary" onClick={() => this.setState({showLenderUpdateForm: true})} className={classes.button}>ADD LENDER UPDATE</Button>
                    </div>
                )}
                {isLoading ? (
                    <Loading/>
                ) : (
                    <div>
                        <h5>Lender Updates</h5>
                        {lenderUpdates.length > 0 ? (
                            <Grid container>
                                {lenderUpdates.map((item, index) =>
                                    <Card className={classes.card} key={'lender-update-' + index}>
                                        <CardContent className={classes.cardContent}>
                                            <div className={classes.updateDate}>Date Note Added: <span className={classes.updateValue}>{item.dateCreated || ''}</span></div>
                                            <div className={classes.update}>Application Date: <span className={classes.updateValue}>{item.applicationDate || ''}</span></div>
                                            <div className={classes.update}>Qualifies?: <span className={classes.updateValue}>{item.qualified ? 'Yes' : 'No'}</span></div>
                                            <div className={classes.update}>Approval Date: <span className={classes.updateValue}>{item.approvalDate || ''}</span></div>
                                            <div className={classes.update}>Status: <span className={classes.updateValue}>{item.status || ''}</span></div>
                                            <div className={classes.update}>Notes: <span className={classes.updateValue}>{item.notes || ''}</span></div>
                                            <div className={classes.update}>Clear to Close: <span className={classes.updateValue}>{(item.clearToCloseDate || null) === null ? 'No' : 'Yes - ' + item.clearToCloseDate}</span></div>
                                            <div className={classes.update}>CTC Notes: <span className={classes.updateValue}>{(item.clearToCloseNotes || null) === null ? 'No' : 'Yes - ' + item.clearToCloseNotes}</span></div>
                                            {!readOnlyMode &&
                                            <Button size="small" variant="contained" color="primary" onClick={() => this.editLenderUpdate(item.lenderUpdateId)} className={classes.button}>Edit</Button>
                                            }
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        ) : (
                            <div className={classes.noUpdates}>No Lender Updates..</div>
                        )}
                    </div>
                )}
                <Dialog closeCallback={() => this.setState({dialog: {}, showDialog: false})} showDialog={showDialog} title={dialog.title||''} bodyHtml={dialog.body||''} />
            </div>
        );
    }
}

export default withStyles(styles)(LenderUpdates);
