import 'whatwg-fetch'
import { saveAs } from 'file-saver';

export const baseUrl = process.env.NODE_ENV === 'production' ? 'https://tw-portal.com/' : 'https://localhost:3001/';
export const apiUrl = process.env.NODE_ENV === 'production' ? 'https://api.tw-portal.com' : 'https://localhost:44362';
// export const apiUrl = 'https://api.tw-portal.com';

export function request(method, action, requestData, successCallback, failureCallback){
    let errorMessage = "";

    function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            let error = new Error(response.statusText);
            error.response = response;
            throw error
        }
    }

    function parseJSON(response) {
        return response.json()
    }

    const accessToken = localStorage.getItem('access_token');
    if(accessToken.length === 0){
        failureCallback('Error verifying auth. Please logout and log back in. If the issue persist, please contact support.');
    } else {
        fetch(apiUrl + action, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: requestData != null ? JSON.stringify(requestData) : undefined
        })
            .then(checkStatus)
            .then(parseJSON)
            .then(function(data) {
                if(data.success){
                    successCallback(data);
                } else {
                    errorMessage = data.message || 'An error occurred. Please try again. If the issue persist, please contact support.';
                }
            })
            .catch(function(error) { //error.message ||
                errorMessage = 'Error communicating with the Server. Please try again. If the issue persist, please contact support.';
            })
            .then(function(e) {
                if(errorMessage.length > 0){
                    failureCallback(errorMessage);
                }
            });
    }
}

export function downloadFile(fileName, method, action, requestData, successCallback, failureCallback){
    let errorMessage = "";

    function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            let error = new Error(response.statusText);
            error.response = response;
            throw error
        }
    }

    // function parseJSON(response) {
    //     return response.json()
    // }

    const accessToken = localStorage.getItem('access_token');
    if(accessToken.length === 0){
        failureCallback('Error verifying auth. Please logout and log back in. If the issue persist, please contact support.');
    } else {
        fetch(apiUrl + action, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: requestData != null ? JSON.stringify(requestData) : undefined
        })
            .then(checkStatus)
            // .then(parseJSON)
            .then(response => response.blob())
            .then(blob => {
                let file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(file, fileName);
                successCallback();
            })
            .catch((error) => { //error.message ||
                errorMessage = 'Error communicating with the Server. Please try again. If the issue persist, please contact support.';
            })
            .then((e) => {
                if(errorMessage.length > 0){
                    failureCallback(errorMessage);
                }
            });
    }
}

export function upload(method, action, requestData, successCallback, failureCallback){
    let errorMessage = "";

    function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            let error = new Error(response.statusText);
            error.response = response;
            throw error
        }
    }

    function parseJSON(response) {
        return response.json()
    }

    const accessToken = localStorage.getItem('access_token');
    if(accessToken.length === 0){
        failureCallback('Error verifying auth. Please logout and log back in. If the issue persist, please contact support.');
    } else {
        fetch(apiUrl + action, {
            method: method,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`
            },
            body: requestData
        })
            .then(checkStatus)
            .then(parseJSON)
            .then(function(data) {
                if(data.success){
                    successCallback(data);
                } else {
                    errorMessage = data.message || 'An error occurred. Please try again. If the issue persist, please contact support.';
                }
            })
            .catch(function(error) { //error.message ||
                errorMessage = 'Error communicating with the Server. Please try again. If the issue persist, please contact support.';
            })
            .then(function(e) {
                if(errorMessage.length > 0){
                    failureCallback(errorMessage);
                }
            });
    }
}

export function validateField(field, min, max) {
    let value = field || '';
    const maxLength = max || null;
    const minLength = min || null;
    if (maxLength !== null && minLength !== null) {
        if (value.length >= minLength && value.length <= maxLength) {
            return true
        }
    }
    if (maxLength === null && minLength === null && value.length > 0) {
        return true
    }
    return false
}

export function validateName(name) {
    const n = (name||null) !== null ? name.trim() : '';
    if(n.length === 0){
        return false;
    }
    const re = /^[a-zA-Z.'-]+(?: +[a-zA-Z.'-]+)+$/;
    const regexResult = re.exec(name) || null;
    if (regexResult === null) {
        return false
    }
    return true
}

export function validateLenientName(name) {
    const n = (name||null) !== null ? name.trim() : '';
    if(n.length === 0){
        return false;
    }
    const re = /^[a-zA-Z ,.&`'-]+$/;
    const regexResult = re.exec(name) || null;
    if (regexResult === null) {
        return false
    }
    return true
}

export function validateEmail(email) {
    let value = email || '';

    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexResult = re.exec(value) || null;
    if (regexResult === null) {
        return false
    }
    return true
}

export function validatePassword(password) {
    let value = password || '';

    const re = /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$%&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z]).{8,}$/;
    const regexResult = re.exec(value) || null;
    if (regexResult === null) {
        return false
    }
    return true
}


export function validateStage(stage) {
    let value = stage || '';

    const re = /^[0-9]+([,.][0-9]+)?$/;
    const regexResult = re.exec(value) || null;
    if (regexResult === null) {
        return false
    }
    return true
}

export function validatePrice(price) {
    let value = price || '';

    const re = /^\d+(\.\d{2})?$/;
    const regexResult = re.exec(value) || null;
    if (regexResult === null) {
        return false
    }
    return true
}

export function validateNumber(number) {
    const re = /^\d+$/;
    const regexResult = re.exec(number) || null;
    if (regexResult === null) {
        return false
    }
    return true
}

export function validatePercentage(number) {
    const re = /^\d+(\.\d{1,2})?$/;
    const regexResult = re.exec(number) || null;
    if (regexResult === null) {
        return false
    }
    return true
}

function buildCSV(columns, data, options) {
    const replaceDoubleQuoteInString = columnData =>
        // eslint-disable-next-line no-useless-escape
        typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData;

    const buildHead = columns => {
        return (
            columns
                .reduce(
                    (soFar, column) =>
                        column.download
                            ? soFar +
                            '"' +
                            escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label || column.name)) +
                            '"' +
                            options.downloadOptions.separator
                            : soFar,
                    '',
                )
                .slice(0, -1) + '\r\n'
        );
    };
    const CSVHead = buildHead(columns);

    const buildBody = data => {
        if (!data.length) return '';
        return data
            .reduce(
                (soFar, row) =>
                    soFar +
                    '"=""' +
                    row.data
                        .filter((_, index) => columns[index].download)
                        .map(columnData => escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
                        .join('"""' + options.downloadOptions.separator + '"=""') +
                    '"""\r\n',
                '',
            )
            .trim();
    };
    const CSVBody = buildBody(data);

    // const csv = options.onDownload
    //     ? options.onDownload(buildHead, buildBody, columns, data)
    //     : `${CSVHead}${CSVBody}`.trim();
    const csv = `${CSVHead}${CSVBody}`.trim();
    return csv;
}

function downloadCSV(csv, filename) {
    const blob = new Blob([csv], { type: 'text/csv' });

    /* taken from react-csv */
    if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const dataURI = `data:text/csv;charset=utf-8,${csv}`;

        const URL = window.URL || window.webkitURL;
        const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.setAttribute('href', downloadURI);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export function createCSVDownload(columns, data, options) {
    const csv = buildCSV(columns, data, options);

    if (options.onDownload && csv === false) {
        return;
    }

    downloadCSV(csv, options.downloadOptions.filename);
}

function escapeDangerousCSVCharacters(data) {
    if (typeof data === 'string') {
        // Places single quote before the appearance of dangerous characters if they
        // are the first in the data string.
        // eslint-disable-next-line no-useless-escape
        return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
    }

    return data;
}