import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateEmail, validateField, validateLenientName} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});

class Agent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseeName: '',
            georgiaRealEstateLicenseNumber: '',
            licenseePhone: '',
            licenseeEmail: '',
            alternateEmail: '',
            realtorMembership: '',
            validations: [],
            isLoading: false
        };
    }

    componentDidMount() {
        const agentId = this.props.agentId || null;
        if(agentId !== null){
            this.setState({isLoading:true},() => this.loadAgent(agentId))
        } else {
            const agent = this.props.agent || null;
            if(agent !== null){
                this.setState({
                    licenseeName: agent.licenseeName || '',
                    georgiaRealEstateLicenseNumber: agent.georgiaRealEstateLicenseNumber || '',
                    licenseePhone: agent.licenseePhone || '',
                    licenseeEmail: agent.licenseeEmail || '',
                    alternateEmail: agent.alternateEmail || '',
                    realtorMembership: agent.realtorMembership || '',
                })
            }
        }
    }

    loadAgent(agentId) {
        request(
            'GET',
            '/Manage/getAgent?agentId=' + agentId,
            null,
            (data) => {
                this.loadData(data.agent);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            licenseeName: data.licenseeName || '',
            georgiaRealEstateLicenseNumber: data.georgiaRealEstateLicenseNumber || '',
            licenseePhone: data.licenseePhone || '',
            licenseeEmail: data.licenseeEmail || '',
            alternateEmail: data.alternateEmail || '',
            realtorMembership: data.realtorMembership || '',
        })
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'licenseeName',
            'georgiaRealEstateLicenseNumber',
            'licenseePhone',
            'licenseeEmail',
            'alternateEmail',
            'realtorMembership',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    };

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'licenseeName':
                isValid = validateLenientName(value);
                invalidationReason = 'Please enter the Licensee\'s Name';
                break;
            case 'georgiaRealEstateLicenseNumber':
                isValid = validateField(value);
                invalidationReason = 'Please enter the GA Real Estate License #';
                break;
            case 'licenseePhone':
                isValid = validateField(value,10,10);
                invalidationReason = 'Please enter the Licensee\'s phone';
                break;
            case 'licenseeEmail':
                isValid = validateEmail(value);
                invalidationReason = 'Please enter the Licensee\'s email';
                break;
            case 'alternateEmail':
                // isValid = validateEmail(value);
                // invalidationReason = 'Please enter an alternate email';
                break;
            case 'realtorMembership':
                // isValid = validateField(value);
                // invalidationReason = 'Please enter the Realtor Membership';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    };

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;
        if (field === 'licenseePhone') {
            value = value.replace(/\D+/g, '');
            if(value.length > 10){
                value = value.substring(0,10);
            }
        }
        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    };

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const readOnlyMode = this.props.readOnlyMode || false;
        if(readOnlyMode){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;

    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE';
        let agentId = this.props.agentId || null;
        if(agentId === null){
            const agent = this.props.agent || null;
            if(agent !== null) {
                agentId = agent.agentId || null;
            }
        }

        const readOnlyMode = this.props.readOnlyMode || false;

        const {
            licenseeName,
            georgiaRealEstateLicenseNumber,
            licenseePhone,
            licenseeEmail,
            alternateEmail,
            realtorMembership,
            isLoading
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                        value={licenseeName}
                        name="licenseeName"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Licensee's Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('licenseeName')}
                        helperText={this.fieldErrorMessage('licenseeName')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={georgiaRealEstateLicenseNumber}
                        name="georgiaRealEstateLicenseNumber"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="GA Real Estate License #"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('georgiaRealEstateLicenseNumber')}
                        helperText={this.fieldErrorMessage('georgiaRealEstateLicenseNumber')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={licenseePhone}
                        name="licenseePhone"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Phone # (10 Digits)"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('licenseePhone')}
                        helperText={this.fieldErrorMessage('licenseePhone')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={licenseeEmail}
                        name="licenseeEmail"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Email"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('licenseeEmail')}
                        helperText={this.fieldErrorMessage('licenseeEmail')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={alternateEmail}
                        name="alternateEmail"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Alternate Email"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('alternateEmail')}
                        helperText={this.fieldErrorMessage('alternateEmail')}
                        disabled={readOnlyMode}
                    />
                    {/*<TextField autoComplete="new" */}
                    {/*    value={realtorMembership}*/}
                    {/*    name="realtorMembership"*/}
                    {/*    onChange={(e) => this.handleFieldChange(e)}*/}
                    {/*    label="Realtor Membership"*/}
                    {/*    className={classes.textField}*/}
                    {/*    margin="normal"*/}
                    {/*    error={this.fieldHasError('realtorMembership')}*/}
                    {/*    helperText={this.fieldErrorMessage('realtorMembership')}*/}
                    {/*    required*/}
                    {/*    disabled={readOnlyMode}*/}
                    {/*/>*/}
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.props.save({licenseeName,georgiaRealEstateLicenseNumber,licenseePhone,licenseeEmail,alternateEmail,realtorMembership,agentId})} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(Agent);