import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
    // request,
    validateField
} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import LinkIcon from '@material-ui/icons/Link';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});

class AddLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            summary: '',
            url: '',
            imageUrl: '',
            color: '',
            backgroundColor: '',
            linkMode: 'new',
            landingPageLinkId: '',
            isLoading: false,
            validations: []
        };
    }

    componentDidMount() {
        const linkMode = this.props.linkMode || 'new';
        this.setState({linkMode})
    }

    save(){
        let {
            title,
            summary,
            url
        } = this.state;

        const {
            landingPageLinkId,
            linkMode
        } = this.state;

        const links = this.props.links || [];

        if(linkMode === 'existing'){
            const i = _.findIndex(links,{landingPageLinkId:landingPageLinkId})
            if(i !== -1){
                const link = links[i];
                title = link.title;
                summary = link.summary;
                url = link.url;
            }
        }

        this.setState({title: '',summary: '',url: '',landingPageLinkId: ''},() => this.props.save({title,summary,url,landingPageLinkId}))
    }

    validateAll(){
        const fieldsToValidate = [
            'title',
            'summary',
            'url',
            'imageUrl',
            'color',
            'backgroundColor'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(
                fieldsToValidate[i],
                this.state[fieldsToValidate[i]] || null
            );
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value){
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'title':
                isValid = validateField(value);
                invalidationReason = 'The Title is required';
                break;
            case 'summary':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter a Summary';
                }
                break;
            case 'url':
                isValid = validateField(value);
                invalidationReason = 'The URL is required';
                break;
            case 'imageUrl':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please provide an Image URL';
                }
                break;
            case 'color':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please select a Text Color';
                }
                break;
            case 'backgroundColor':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please select a Background Color';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    };

    isValid() {
        const {linkMode,landingPageLinkId,title} = this.state;
        const categoryLinks = this.props.categoryLinks || [];
        if(linkMode === 'existing'){
            const links = this.props.links || [];
            if((landingPageLinkId||null) !== null){
                const linkIndex = _.findIndex(links,{landingPageLinkId: landingPageLinkId});
                if(linkIndex !== -1){
                    const link = links[linkIndex];
                    const duplicate = _.findIndex(categoryLinks,{title: link.title}) !== -1;
                    if(!duplicate){
                        return true;
                    }
                }
            }
            return false;
        } else {
            const duplicate = _.findIndex(categoryLinks,{title: title}) !== -1;
            if(duplicate){
                return false;
            }
            const validations = this.validateAll();
            const i = _.findIndex(validations, {isValid: false});
            return i <= -1;
        }
    }

    render() {
        const { classes } = this.props;
        const links = this.props.links || [];

        const {
            title,
            summary,
            url,
            linkMode,
            landingPageLinkId,
            // imageUrl,
            // color,
            // backgroundColor,
            isLoading
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                {linkMode === 'new'&&
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                               value={title}
                               name="title"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Link Title"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('title')}
                               helperText={this.fieldErrorMessage('title')}
                               required
                    />
                    <TextField autoComplete="new"
                               value={summary}
                               name="summary"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Link Summary"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('summary')}
                               helperText={this.fieldErrorMessage('summary')}
                    />
                    <TextField autoComplete="new"
                               value={url}
                               name="url"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Link URL"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('url')}
                               helperText={this.fieldErrorMessage('url')}
                               required
                    />
                    {/*<TextField autoComplete="new"*/}
                    {/*           value={imageUrl}*/}
                    {/*           name="imageUrl"*/}
                    {/*           onChange={(e) => this.handleFieldChange(e)}*/}
                    {/*           label="Link Image URL"*/}
                    {/*           className={classes.textField}*/}
                    {/*           margin="normal"*/}
                    {/*           error={this.fieldHasError('imageUrl')}*/}
                    {/*           helperText={this.fieldErrorMessage('imageUrl')}*/}
                    {/*/>*/}
                </div>
                }
                {linkMode === 'existing' &&
                <div>
                    <FormControl className={classes.dropdown}>
                        <InputLabel htmlFor="link-select">Link</InputLabel>
                        <Select
                            id="link-select"
                            name="landingPageLinkId"
                            value={landingPageLinkId}
                            onChange={(e) => this.handleSelectChange(e)}
                            disabled={(links||[]).length === 0}
                        >
                            {(links||[]).length !== 0 &&
                                links.map((item, index) => (
                                <MenuItem key={'link-'+item.landingPageLinkId} value={item.landingPageLinkId}>{item.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                }
                <br/>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!this.isValid()} onClick={() => this.save()} className={classes.button} startIcon={<LinkIcon />}
                >
                    Add Link
                </Button>
            </div>
        );
    }
}

export default withStyles(styles)(AddLink);