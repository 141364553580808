import React, { Component } from 'react';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import { blue} from '@material-ui/core/colors';
import {request} from "../utils";

const styles = theme => ({
    root: {
        width: '100%',
        textAlign: 'left !important',
        marginTop: theme.spacing(1),

        // maxWidth: '100%'
    },
    paper: {
        width: '100%',
        // maxWidth: '100%'
    },
    tableWrapper: {
        // maxHeight: 440,
        // overflow: 'auto',
        maxWidth: '100%'
    },
    fabProgress: {
        color: blue[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    fabProgressWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    margin: {
        margin: theme.spacing(1),
    },
    controls: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    grow: {
        flexGrow: 1,
    },
    activeSwitch: {
        marginLeft: theme.spacing(2)
    }
});

class FilteredConstructionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            columns: [],
            data: [],
        };
    }

    componentDidMount() {
        this.loadConstructions();
    }

    loadConstructions() {
        let action = '/Dashboard/getConstructions';
        const queryString = this.props.queryString||null;
        if(queryString !== null){
            action += queryString;
        }

        request(
            'GET',
            action,
            null,
            (data) => {
                this.setState({columns: data.columns, data: data.constructions, isLoading: false})
            },
            (error) => {
                this.setState({isLoading: false, data: [], columns: []}, () => this.props.showSnack('error',error))
            })
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        this.setState({[field]: !value});
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    whiteSpace: 'pre'
                }
            },
            MUIDataTableBody: {
                emptyTitle: {
                    textAlign: 'left',
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    width: 250,
                    paddingLeft: 15
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    '&:nth-child(even)': {
                        backgroundColor: '#f7f7f7'
                    }
                }
            },
        }
    });

    render() {
        const { classes } = this.props;

        const {
            isLoading,
            columns,
            data,
        } = this.state;

        const options = {
            print: false,
            download: false,
            // selectableRowsOnClick: false,
            // selectableRowsHeader: false,
            selectableRows: 'none',
            responsive: 'standard',
            textLabels: {
                body: {
                    noMatch: isLoading ? "Loading.." : "No Results found",
                },
            },
            rowHover: false,
            rowsPerPage: 100,
            rowsPerPageOptions: [10,25,50,100],
            tableBodyHeight: '440px',
            tableBodyMaxHeight: '440px',
        };

        return (
            <div>
                <div className={classes.root}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                            // title={title}
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(FilteredConstructionTable);