import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField, validateLenientName} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});

class Attorney extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            validations: [],
            isLoading: false
        };
    }

    componentDidMount() {
        const attorneyId = this.props.attorneyId || null;
        if(attorneyId !== null){
            this.setState({isLoading:true},() => this.loadAttorney(attorneyId))
        } else {
            const attorney = this.props.attorney || null;
            if(attorney !== null){
                this.setState({
                    name: attorney.name || '',
                    phone: attorney.phone || '',
                })
            }
        }
    }

    loadAttorney(attorneyId) {
        request(
            'GET',
            '/Manage/getAttorney?attorneyId=' + attorneyId,
            null,
            (data) => {
                this.loadData(data.attorney);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            name: data.name || '',
            phone: data.phone || ''
        })
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'name',
            'phone',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    };

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'name':
                isValid = validateLenientName(value);
                invalidationReason = 'Please enter the Attorney\'s Name';
                break;
            case 'phone':
                isValid = validateField(value,10,10);
                invalidationReason = 'Please enter the Attorney\'s phone';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    };

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;
        if (field === 'phone') {
            value = value.replace(/\D+/g, '');
            if(value.length > 10){
                value = value.substring(0,10);
            }
        }
        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    };

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const readOnlyMode = this.props.readOnlyMode || false;
        if(readOnlyMode){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;

    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE';
        const readOnlyMode = this.props.readOnlyMode || false;
        let attorneyId = this.props.attorneyId || null;
        if(attorneyId === null){
            const attorney = this.props.attorney || null;
            if(attorney !== null) {
                attorneyId = attorney.attorneyId || null;
            }
        }
        
        const {
            isLoading,
            name,
            phone,
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                        value={name}
                        name="name"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('name')}
                        helperText={this.fieldErrorMessage('name')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={phone}
                        name="phone"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Phone # (10 Digits)"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('phone')}
                        helperText={this.fieldErrorMessage('phone')}
                        required
                        disabled={readOnlyMode}
                    />
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.props.save({attorneyId,name,phone})} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(Attorney);