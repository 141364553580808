import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles} from '@material-ui/core/styles';
import DashboardCard from './DashboardCard';
import DashboardGraph from './DashboardGraph';
import Dialog from './Dialog';
import DashboardDialog from './DashboardDialog';
import Divider from '@material-ui/core/es/Divider/Divider';
import {request} from '../utils'
import CircularProgress from '@material-ui/core/CircularProgress';
import FilteredConstructionTable from "./FilteredConstructionTable";
import FilteredListingTable from "./FilteredListingTable";
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { amber, green, red, blue} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import NotesIcon from '@material-ui/icons/Notes';
import HomeIcon from '@material-ui/icons/Home';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventIcon from '@material-ui/icons/Event';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import HistoryIcon from '@material-ui/icons/History';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaletteIcon from '@material-ui/icons/Palette';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

const styles = theme => ({
    grid: {
        padding: theme.spacing(2) + 'px 0',
        display: 'flex',
        textAlign: 'center',
        minHeight: 200
    },
    progress: {
        margin: theme.spacing(2),
    },
    grow: {
        flexGrow: 1
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: red[600],
    },
    info: {
        backgroundColor: blue[600],
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    titleIconWrapper: {
        // backgroundColor: '#fff',
        color: '#fff',
        width: 36,
        height: 36,
        padding: 0,
        minWidth: 36,
        boxSizing: 'border-box',
        minHeight: 36,
        // boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        fontWeight: 500,
        lineHeight: 1.75,
        // borderRadius: 4,
        // letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10
    },
    titleIconBg: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    titleIcon: {
        fontSize: '1.75rem'
    },
});

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: '',
            counters: [],
            graphs: [],
            countersLoading: true,
            graphsLoading: true,
            table: {},
            showTable: false,
            snackMessage: '',
            snackType: 'test',
            showSnack: false
        };
    }

    componentDidMount() {
        this.getCounters();
    }

    handleSnackbarClose() {
        this.setState({snackMessage: '', snackType: '', showSnack: false});
    }

    showSnack(type,message){
        this.setState({snackMessage: message, snackType: type, showSnack: true});
    }

    getCounters() {
        request('GET','/dashboard/getCounters',null,this.getCountersSuccess.bind(this),this.requestFailed.bind(this))
    }

    getCountersSuccess(data) {
        this.setState({counters: data.counters, countersLoading: false}, () => this.getCharts())
    }

    getCharts() {
        request('GET','/dashboard/getCharts',null,this.getChartsSuccess.bind(this),this.requestFailed.bind(this))
    }

    getChartsSuccess(data){
        this.setState({graphs: data.charts, graphsLoading: false})
    }

    requestFailed(error) {
        this.setState({countersLoading: false, graphsLoading: false,hasError: true, errorMessage: error + '. Please try again or support if you need assistance.'})
    }

    cardClicked(card){
        // console.log(card);
        if(card.clickType === 'Property Details'){
            const { classes } = this.props;

            const body = (
                <FilteredConstructionTable queryString={card.queryString} showSnack={(t,m) => this.showSnack(t,m)}/>
            );

            const icon = card.icon;

            let style = {};

            const title = (
                <div>
                    <div className={classes.titleIconWrapper} style={style}>
                        <div className={classes.titleIconBg}>
                            {icon === 'receipt' &&
                            <ReceiptIcon className={classes.titleIcon} />
                            }
                            {icon === 'home' &&
                            <HomeIcon className={classes.titleIcon} />
                            }
                            {icon === 'schedule' &&
                            <ScheduleIcon className={classes.titleIcon} />
                            }
                            {icon === 'daterange' &&
                            <DateRangeIcon className={classes.titleIcon} />
                            }
                            {icon === 'eventbusy' &&
                            <EventBusyIcon className={classes.titleIcon} />
                            }
                            {icon === 'note' &&
                            <NotesIcon className={classes.titleIcon} />
                            }
                            {icon === 'history' &&
                            <HistoryIcon className={classes.titleIcon} />
                            }
                            {icon === 'eventavailable' &&
                            <EventAvailableIcon className={classes.titleIcon} />
                            }
                            {icon === 'palette' &&
                            <PaletteIcon className={classes.titleIcon} />
                            }
                            {icon === 'localShipping' &&
                            <LocalShippingIcon className={classes.titleIcon} />
                            }
                            {icon === 'event' &&
                            <EventIcon className={classes.titleIcon} />
                            }
                        </div>
                    </div>
                    <span>{card.title}</span>
                </div>
            )

            this.setState({
                table: {title, body},
                showTable: true,
            })
        }
        else if(card.clickType === 'Sales Details'){
            const body = (
                <FilteredListingTable  queryString={card.queryString} showSnack={(t,m) => this.showSnack(t,m)}/>
            );

            this.setState({
                table: {title: card.title, body},
                showTable: true,
            })
        }
    }

    render() {
        const { classes } = this.props;
        const {
            hasError,
            errorMessage,
            counters,
            graphs,
            countersLoading,
            graphsLoading,
            table,
            showTable,
            snackMessage,
            snackType,
            showSnack,
        } = this.state;

        return (
            <div>
                <Grid>
                    {countersLoading ? (
                        <CircularProgress className={classes.progress} />
                    ) : (
                        <Grid container spacing={2} className={classes.grid} justify="flex-start" alignItems="flex-start">
                            {(counters || null) !== null &&
                            counters.map((item,index) =>
                                <Grid item xs={12} sm={6} md={4} lg={3} key={'cnt-' + index}>
                                    <DashboardCard color={item.color} icon={item.icon} label={item.label} count={item.count} period={item.period} onClick={() => this.cardClicked(item)}/>
                                </Grid>
                            )
                            }
                        </Grid>
                    )}
                </Grid>

                <Divider/>
                <Grid>
                    {graphsLoading ? (
                        <CircularProgress className={classes.progress} color="secondary" />
                    ) : (
                        <Grid  container spacing={2} className={classes.grid} justify="flex-start" alignItems="flex-start">
                            {(graphs || null) !== null &&
                            graphs.map((item,index) =>
                                <Grid item xs={12} sm={12} md={12} key={'gph-' + index}>
                                    <DashboardGraph color={item.color} label={item.label} summary={item.summary} period={item.period} data={item.data} options={item.options} type={item.type}/>
                                </Grid>
                            )
                            }
                        </Grid>
                    )}
                </Grid>
                <Dialog showDialog={hasError} title={'An Error Occurred'} body={errorMessage} closeCallback={() => this.setState({hasError: false,errorMessage: ''})} />
                <DashboardDialog fullScreen={true} closeCallback={() => this.setState({table: {}, showTable: false})} showDialog={showTable} title={table.title||''} bodyHtml={table.body||''} />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={showSnack}
                    autoHideDuration={4000}
                    onClose={() => this.handleSnackbarClose()}
                >
                    <SnackbarContent
                        className={classes[snackType]}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                {snackType === 'error' &&
                                <ErrorIcon className={classes.iconVariant}/>
                                }
                                {snackType === 'warning' &&
                                <WarningIcon className={classes.iconVariant}/>
                                }
                                {snackType === 'info' &&
                                <InfoIcon className={classes.iconVariant}/>
                                }
                                {snackType === 'success' &&
                                <SuccessIcon className={classes.iconVariant}/>
                                }
                                {snackMessage}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={() => this.handleSnackbarClose()}>
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>
        );
    }
}

export default withStyles(styles)(Dashboard);