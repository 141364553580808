import React, { Component } from 'react';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue} from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import {request} from "../utils";
import Dialog from "./Dialog";
import Agent from "./Forms/Agent";
import Community from "./Forms/Community";
import CoopAgent from "./Forms/CoopAgent";
import Attorney from "./Forms/Attorney";
import Broker from "./Forms/Broker";
import Contact from "./Forms/Contact";
import LeadSource from "./Forms/LeadSource";
import Lender from "./Forms/Lender";
import LoanOfficer from "./Forms/LoanOfficer";
import Property from "./Forms/Property";
import CustomToolbarSelect from "./CustomToolbarSelect";
// import Import from "./Forms/Import";

const styles = theme => ({
    root: {
        width: '100%',
        textAlign: 'left !important',
        marginTop: theme.spacing(1),

        // maxWidth: '100%'
    },
    paper: {
        width: '100%',
        // maxWidth: '100%'
    },
    tableWrapper: {
        // maxHeight: 440,
        // overflow: 'auto',
        maxWidth: '100%'
    },
    fabProgress: {
        color: blue[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    fabProgressWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    margin: {
        margin: theme.spacing(1),
    },
    controls: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    grow: {
        flexGrow: 1,
    },
    import: {
        margin: theme.spacing(1),
        display: 'inline-block'
    },
});

class OtherTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            saving: false,
            columns: [],
            data: [],
            option: 'agent',
            currentOption: 'agent',
            showUpdateOther: false,
            dialogTitle: '',
            dialogBody: null,
            deleteDialog: {},
            showDeleteDialog: false,
            deleteId: '',
            // showImportCount: 0
        };
    }

    componentDidMount() {
        this.loadOthers();
    }

    loadOthers() {

        // const p = page || 1;
        // let action = '/Manage/getTable?type=' + this.state.currentOption + '&page=' + p;
        let action = '/Manage/getTable?type=' + this.state.currentOption;

        request(
            'GET',
            action,
            null,
            (data) => {
                this.setState({columns: data.columns, data: data.list, isLoading: false})
            },
            (error) => {
                this.handleError(error);
            })
    }

    update(action,data,successMessage){
        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({saving: false, showUpdateOther: false, dialogTitle: '', dialogBody: null}, () => this.successAndReload(successMessage))
            },
            (error) => {
                this.setState({saving: false}, () => this.props.showSnack('error',error))
            })
    }

    updateAgent = (data) => {
        const successMessage = 'Successfully Updated TRU Agent!';
        const action = '/Manage/updateAgent';
        this.update(action,data,successMessage);
    };

    updateCoopAgent = (data) => {
        const successMessage = 'Successfully Updated Co-op Agent!';
        const action = '/Manage/updateCoopAgent';
        this.update(action,data,successMessage);
    };

    updateAttorney = (data) => {
        const successMessage = 'Successfully Updated Attorney!';
        const action = '/Manage/updateAttorney';
        this.update(action,data,successMessage);
    };

    updateBroker = (data) => {
        const successMessage = 'Successfully Updated Broker!';
        const action = '/Manage/updateBroker';
        this.update(action,data,successMessage);
    };

    // updateBuilder = (data) => {
    //     const successMessage = 'Successfully Updated Builder!';
    //     const action = '/Manage/updateBuilder';
    //     this.update(action,data,successMessage);
    // };
    //

    updateCommunity = (data) => {
        const successMessage = 'Successfully Updated Community!';
        const action = '/Manage/updateCommunity';
        this.update(action,data,successMessage);
    };

    updateContact = (data) => {
        const successMessage = 'Successfully Updated Contact!';
        const action = '/Manage/updateContact';
        this.update(action,data,successMessage);
    };

    updateLeadSource = (data) => {
        const successMessage = 'Successfully Updated Lead Source!';
        const action = '/Manage/updateLeadSource';
        this.update(action,data,successMessage);
    };

    updateLender = (data) => {
        const successMessage = 'Successfully Updated Lender!';
        const action = '/Manage/updateLender';
        this.update(action,data,successMessage);
    };

    updateLoanOfficer = (data) => {
        const successMessage = 'Successfully Updated LoanOfficer!';
        const action = '/Manage/updateLoanOfficer';
        this.update(action,data,successMessage);
    };

    updateProperty = (data) => {
        const successMessage = 'Successfully Updated Property!';
        const action = '/Manage/updateProperty';
        this.update(action,data,successMessage);
    };

    handleError(error){
        this.setState({isLoading: false, saving: false, data: [], columns: []}, () => this.props.showSnack('error',error))
    }

    handleChange(e){
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]:value})
    }

    edit(o) {
        const otherId = o.id;
        const {currentOption, saving} = this.state;

        if(otherId !== null){
            let title = '';
            let body = null;

            switch (currentOption) {
                case 'agent':
                    title = 'Edit TRU Agent';
                    body = (
                        <Agent readOnlyMode={saving} agentId={otherId} save={(a) => this.setState({saving:true}, () => this.updateAgent(a))} showSnack={this.props.showSnack}/>
                    );
                    break;
                case 'coopAgent':
                    title = 'Edit Co-op Agent';
                    body = (
                        <CoopAgent readOnlyMode={saving} coopAgentId={otherId} save={(a) => this.setState({saving:true}, () => this.updateCoopAgent(a))} showSnack={this.props.showSnack}/>
                    );
                    break;
                case 'attorney':
                    title = 'Edit Attorney';
                    body = (
                        <Attorney readOnlyMode={saving} attorneyId={otherId} save={(a) => this.setState({saving:true}, () => this.updateAttorney(a))} showSnack={this.props.showSnack}/>
                    );
                    break;
                case 'broker':
                    title = 'Edit Broker';
                    body = (
                        <Broker readOnlyMode={saving} brokerId={otherId} save={(b) => this.setState({saving:true}, () => this.updateBroker(b))} showSnack={this.props.showSnack}/>
                    );
                    break;
                case 'builder':
                    // title = 'Edit Builder';
                    // body = (
                    //     <Builder readOnlyMode={saving} builderId={otherId} save={(b) => this.setState({saving:true}, () => this.updateBuilder(b))} showSnack={this.props.showSnack}/>
                    // );
                    return;
                // break;
                case 'community':
                    title = 'Edit Community';
                    body = (
                        <Community communityId={otherId} save={(c) => this.setState({saving:true}, () => this.updateCommunity(c))} showSnack={this.props.showSnack}/>
                    );
                    break;
                case 'contact':
                    title = 'Edit Contact';
                    body = (
                        <Contact hideContactType={true} readOnlyMode={saving} contactId={otherId} save={(c) => this.setState({saving:true}, () => this.updateContact(c))} showSnack={this.props.showSnack}/>
                    );
                    break;
                case 'leadSource':
                    title = 'Edit Lead Source';
                    body = (
                        <LeadSource readOnlyMode={saving} leadSourceId={otherId} save={(l) => this.setState({saving:true}, () => this.updateLeadSource(l))} showSnack={this.props.showSnack}/>
                    );
                    break;
                case 'lender':
                    title = 'Edit Lender';
                    body = (
                        <Lender readOnlyMode={saving} lenderId={otherId} save={(l) => this.setState({saving:true}, () => this.updateLender(l))} showSnack={this.props.showSnack}/>
                    );
                    break;
                case 'loanOfficer':
                    title = 'Edit Loan Officer';
                    body = (
                        <LoanOfficer readOnlyMode={saving} loanOfficerId={otherId} showLender={true} save={(l) => this.setState({saving:true}, () => this.updateLoanOfficer(l))} showSnack={this.props.showSnack}/>
                    );
                    break;
                case 'property':
                    title = 'Edit Property';
                    body = (
                        <Property readOnlyMode={saving} propertyId={otherId} save={(p) => this.setState({saving:true}, () => this.updateProperty(p))} showSnack={this.props.showSnack}/>
                    );
                    break;
                default:
                    break;
            }

            this.setState({showUpdateOther: true, dialogTitle: title, dialogBody: body});
        }
    }

    // handleRowClick(rowData,rowMeta) {
    //     const otherId = rowData[0] || null;
    //
    // }

    // handleRowsDeleted(r){
    //     const data = this.state.data;
    //     const row = r.data[0].dataIndex;
    //     const objToDelete = data[row];
    //     this.confirmDelete(objToDelete);
    //     return false;
    // }
    
    confirmDelete(o){
        const currentOption = this.state.currentOption;
        const title = 'DELETE ' + currentOption.toUpperCase();
        const body = (
            <span>
            Are you sure you want to delete {o.name}?
            </span>
        );

        this.setState({
            deleteDialog: {title,body},
            showDeleteDialog: true,
            deleteId: o.id
        })
    }
    
    delete(id){
        const currentOption = this.state.currentOption;

        let action,data,successMessage;

        switch (currentOption) {
            case 'agent':
                data = {agentId:id};
                successMessage = 'Successfully Deleted TRU Agent!';
                action = '/Manage/deleteAgent';
                break;
            case 'coopAgent':
                data = {coopAgentId:id};
                successMessage = 'Successfully Deleted Co-op Agent!';
                action = '/Manage/deleteCoopAgent';
                break;
            case 'attorney':
                data = {attorneyId:id};
                successMessage = 'Successfully Deleted Attorney!';
                action = '/Manage/deleteAttorney';
                break;
            case 'broker':
                data = {brokerId:id};
                successMessage = 'Successfully Deleted Broker!';
                action = '/Manage/deleteBroker';
                break;
            case 'builder':
                data = {builderId:id};
                successMessage = 'Successfully Deleted Builder!';
                action = '/Manage/deleteBuilder';
                break;
            case 'community':
                data = {communityId:id};
                successMessage = 'Successfully Deleted Community!';
                action = '/Manage/deleteCommunity';
                break;
            case 'contact':
                data = {contactId:id};
                successMessage = 'Successfully Deleted Contact!';
                action = '/Manage/deleteContact';
                break;
            case 'leadSource':
                data = {leadSourceId:id};
                successMessage = 'Successfully Deleted Lead Source!';
                action = '/Manage/deleteLeadSource';
                break;
            case 'lender':
                data = {lenderId:id};
                successMessage = 'Successfully Deleted Lender!';
                action = '/Manage/deleteLender';
                break;
            case 'loanOfficer':
                data = {loanOfficerId:id};
                successMessage = 'Successfully Deleted LoanOfficer!';
                action = '/Manage/deleteLoanOfficer';
                break;
            // case 'property':
            //     data = {propertyId:id};
            //     successMessage = 'Successfully Deleted Property!';
            //     action = '/Manage/deleteProperty';
            //     break;
            default:
                return;
        }

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({saving: false, deleteId: '', deleteDialog: {}, showDeleteDialog: false}, () => this.successAndReload(successMessage))
            },
            (error) => {
                this.setState({saving: false, deleteId: '', deleteDialog: {}, showDeleteDialog: false}, () => this.props.showSnack('error',error))
            })
    }

    successAndReload(successMessage) {
        this.props.showSnack('success',successMessage);
        this.reload();
    }

    reload(){
        this.setState({isLoading: true, saving: false, columns: [], data: []}, () => this.loadOthers())
    }

    // errorLoadingNewOther(error){
    //     this.setState({showUpdateOther: false}, () => this.props.showSnack('error',error))
    // }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    whiteSpace: 'nowrap'
                }
            },
            MUIDataTableBody: {
                emptyTitle: {
                    textAlign: 'left',
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    width: 250,
                    paddingLeft: 15
                }
            },
            MUIDataTableToolbar: {
                titleText: {
                    textAlign: 'left !important',
                }
            }
        }
    });

    getTitle(currentOption) {
        let title = '';

        switch (currentOption) {
            case 'agent':
                title = 'TRU Agents';
                break;
            case 'coopAgent':
                title = 'Co-op Agents';
                break;
            case 'attorney':
                title = 'Attorneys';
                break;
            case 'broker':
                title = 'Brokers';
                break;
            case 'builder':
                title = 'Builders';
                break;
            case 'community':
                title = 'Communities';
                break;
            case 'contact':
                title = 'Contacts';
                break;
            case 'leadSource':
                title = 'Lead Sources';
                break;
            case 'lender':
                title = 'Lenders';
                break;
            case 'loanOfficer':
                title = 'Loan Officers';
                break;
            case 'property':
                title = 'Properties';
                break;
            default:
                break;
        }

        return title;
    }

    render() {
        const { classes } = this.props;
        const {
            isLoading,
            columns,
            data,
            option,
            showUpdateOther,
            dialogBody,
            dialogTitle,
            deleteDialog,
            showDeleteDialog,
            currentOption,
            saving,
            deleteId,
            // showImportCount
        } = this.state;
        // const title = currentOption.toUpperCase();

        const options = {
            viewColumns: false,
            filter: false,
            print: false,
            download: false,
            jumpToPage: true,
            selectableRowsOnClick: true,
            selectableRowsHeader: true,
            selectableRows: 'single',
            responsive: 'standard',
            textLabels: {
                body: {
                    noMatch: !isLoading ? "No Results found" : "Loading..",
                },
            },
            rowsPerPage: 10,
            rowsPerPageOptions: [10,25,50,100],
            tableBodyHeight: '440px',
            tableBodyMaxHeight: '440px',

            customToolbarSelect: (selectedRows) => (
                <CustomToolbarSelect showDelete={currentOption !== 'property' && currentOption !== 'contact'} showEdit={(currentOption !== 'builder')} delete={(o) => this.confirmDelete(o)} edit={(o) => this.edit(o)} selectedRows={selectedRows} data={data} />
                // <CustomToolbarSelect showListingOptions={true} showConstructionOptions={true} showDelete={currentOption !== 'property'} showEdit={(currentOption !== 'builder' && currentOption !== 'community')} delete={(o) => this.confirmDelete(o)} edit={(o) => this.edit(o)} selectedRows={selectedRows} data={data} />
            ),
        };

        const deleteDialogButtons = [
            {
                disable: saving,
                text: 'Yes',
                callback: () => {
                    this.setState({saving: true}, () => this.delete(deleteId));
                }
            },
            {
                disable: saving,
                text: 'No',
                callback: () => {
                    this.setState({
                        deleteDialog: {},
                        showDeleteDialog: false,
                        deleteId: ''
                    })
                }
            }
        ];
        
        return (
            <div>
                <div className={classes.controls}>
                    <div className={classes.grow}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="option-select-label">Table</InputLabel>
                            <Select
                                name="option"
                                id="option-select"
                                value={option}
                                onChange={(e) => this.handleChange(e)}
                                disabled={isLoading}
                            >
                                <MenuItem value={'agent'}>TRU Agents</MenuItem>
                                <MenuItem value={'coopAgent'}>Co-op Agents</MenuItem>
                                <MenuItem value={'attorney'}>Attorneys</MenuItem>
                                <MenuItem value={'broker'}>Brokers</MenuItem>
                                <MenuItem value={'builder'}>Builders</MenuItem>
                                <MenuItem value={'community'}>Communities</MenuItem>
                                <MenuItem value={'contact'}>Contacts</MenuItem>
                                <MenuItem value={'leadSource'}>Lead Sources</MenuItem>
                                <MenuItem value={'lender'}>Lenders</MenuItem>
                                <MenuItem value={'loanOfficer'}>Loan Officers</MenuItem>
                                <MenuItem value={'property'}>Properties</MenuItem>
                            </Select>
                            <FormHelperText>Load data for specified type</FormHelperText>
                        </FormControl>
                        <Fab
                            // size="small"
                            color="primary"
                            aria-label="load"
                            className={classes.margin}
                            disabled={isLoading || currentOption === option}
                            onClick={() => this.setState({currentOption: option, isLoading: true, columns: [], data: []}, () => this.loadOthers())
                            }>
                            <SendIcon/>
                        </Fab>
                        {/*<div className={classes.import}>*/}
                        {/*    {(!isLoading && currentOption === option && showImportCount >= 10) &&*/}
                        {/*    <Import type={currentOption} showSnack={(t,m) => this.props.showSnack(t,m)} reload={() => this.reload()} />*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>
                    <div className={classes.fabProgressWrapper}>
                        <Fab
                            aria-label="reload"
                            // color="secondary"
                            // className={classes.margin}
                            onClick={() => this.reload()}
                            disabled={isLoading}
                        >
                            <RefreshIcon/>
                        </Fab>
                        {isLoading && <CircularProgress size={68} className={classes.fabProgress} />}
                    </div>
                </div>
                <div className={classes.root}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                            title={this.getTitle(currentOption)}
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
                {/*<div style={{*/}
                {/*    height: 10,*/}
                {/*    width: 10,*/}
                {/*    position: 'fixed',*/}
                {/*    bottom: 0,*/}
                {/*    right: 0,*/}
                {/*    cursor: 'pointer'*/}
                {/*}} onClick={() => this.setState({showImportCount: this.state.showImportCount + 1})}></div>*/}
                <Dialog closeCallback={() => this.setState({dialogTitle: '', dialogBody: null, showUpdateOther: false})} showDialog={showUpdateOther} title={dialogTitle} bodyHtml={dialogBody}/>
                <Dialog closeCallback={() => this.setState({deleteDialog: {}, deleteId: '', showDeleteDialog: false})} showDialog={showDeleteDialog} title={deleteDialog.title||''} body={deleteDialog.body||''} buttons={deleteDialogButtons} />
            </div>
        );
    }
}

export default withStyles(styles)(OtherTable);