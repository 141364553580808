import React from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import NotFound from './NotFound';
import history from '../history';
import Dashboard from "./Dashboard";
import Sheets from "./Sheets";
import Landing from "./Landing";
import Admin from "./Admin";
import {createMuiTheme} from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import {ThemeProvider} from "@material-ui/styles";
import Layout from "./Layout";

const AuthRoutes = (props) => {
    const auth = props.auth;

    const RouteContent = React.forwardRef((props, ref) =>
        <Switch>
            <Route exact path="/" render={(props) => <Redirect to="/Home"/>}/>
            <Route exact path="/Admin" render={(props) => <Redirect to="/Home"/>}/>
            {/*<Route exact path="/Admin" render={(props) => <Redirect to="/Admin/Landing"/>}/>*/}
            <Route exact path="/Admin/Dashboard" render={(props) => <Dashboard {...props} />}/>
            <Route exact path="/Admin/Manage" render={(props) => <Sheets {...props} />}/>
            <Route exact path="/Admin/Users" render={(props) => <Admin {...props} />}/>
            <Route exact path="/Home" render={(props) => <Landing {...props} />}/>

            <Route render={(props) =>
                <NotFound {...props}/>
            }/>
        </Switch>
    );

    if(!auth.isAuthenticated()){
        auth.login();
        return null;
    } else {
        const theme = createMuiTheme(
            {
                typography: {
                    useNextVariants: true,
                },
                palette: {
                    primary: blue,
                    secondary: red,
                }
            }
        );

        return (
            <Router history={history}>
                <ThemeProvider theme={theme}>
                    <Layout auth={auth}>
                        <RouteContent/>
                    </Layout>
                </ThemeProvider>
            </Router>
        );
    }
};

export default AuthRoutes;