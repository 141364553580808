import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateEmail, validateField, validateLenientName} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
});

class LoanOfficer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            loadingLenders: true,
            lenders: [],
            lenderId: '',
            validations: [],
            isLoading: false
        };
    }

    componentDidMount() {
        const loanOfficerId = this.props.loanOfficerId || null;
        if(loanOfficerId !== null){
            this.setState({isLoading:true},() => this.loadLoanOfficer(loanOfficerId))
        } else {
            const loanOfficer = this.props.loanOfficer || null;
            if(loanOfficer !== null){
                this.setState({
                    name: loanOfficer.name || '',
                    phone: loanOfficer.phone || '',
                    email: loanOfficer.email || '',
                }, () => this.getLenders())
            } else {
                this.getLenders()
            }
        }
    }

    loadLoanOfficer(loanOfficerId) {
        request(
            'GET',
            '/Manage/getLoanOfficer?loanOfficerId=' + loanOfficerId,
            null,
            (data) => {
                this.loadData(data.loanOfficer);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            loadingLenders: true,
            isLoading: false,
            lenderId: data.lenderId || '',
            name: data.name || '',
            phone: data.phone || '',
            email: data.email || '',
        },() => this.getLenders())
    }

    handleError(error){
        this.setState({isLoading: false, loadingLenders: false}, () => this.props.showSnack('error',error))
    }

    getLenders() {
        const showLender = this.props.showLender || false;
        if(this.state.lenders.length > 0 || !showLender) {
            this.setState({loadingLenders: false})
        } else {
            request(
                'GET',
                '/Manage/getLenders',
                null,
                (data) => {
                    this.setState({lenders: data.lenders, loadingLenders: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    validateAll() {
        const fieldsToValidate = [
            'name',
            'phone',
            'email',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    };

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'name':
                isValid = validateLenientName(value);
                invalidationReason = 'Please enter the Loan Officer\'s Name';
                break;
            case 'phone':
                isValid = validateField(value,10,10);
                invalidationReason = 'Please enter the Loan Officer\'s phone';
                break;
            case 'email':
                isValid = validateEmail(value);
                invalidationReason = 'Please enter the Loan Officer\'s email';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    };

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;
        if (field === 'phone') {
            value = value.replace(/\D+/g, '');
            if(value.length > 10){
                value = value.substring(0,10);
            }
        }
        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    };

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    };

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    // handleSwitchChange(e){
    //     const field = e.target.name;
    //     const value = this.state[field];
    //
    //     this.setState({[field]: !value});
    // }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const readOnlyMode = this.props.readOnlyMode || false;
        if(readOnlyMode){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;

    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE';
        const readOnlyMode = this.props.readOnlyMode || false;
        let loanOfficerId = this.props.loanOfficerId || null;
        if(loanOfficerId === null){
            const loanOfficer = this.props.loanOfficer || null;
            if(loanOfficer !== null) {
                loanOfficerId = loanOfficer.loanOfficerId || null;
            }
        }
        
        const showLender = this.props.showLender || false;

        const {
            name,
            phone,
            email,
            isLoading,
            loadingLenders,
            lenders,
            lenderId
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                        value={name}
                        name="name"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('name')}
                        helperText={this.fieldErrorMessage('name')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={phone}
                        name="phone"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Phone # (10 Digits)"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('phone')}
                        helperText={this.fieldErrorMessage('phone')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={email}
                        name="email"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Email"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('email')}
                        helperText={this.fieldErrorMessage('email')}
                        required
                        disabled={readOnlyMode}
                    />
                    {showLender &&
                    <div>
                        {loadingLenders ? (
                            <Loading contained={true}/>
                        ) : (
                            <div className={classes.flexWrapper}>
                                <FormControl className={classes.dropdown}>
                                    <InputLabel htmlFor="lender-select">Lender</InputLabel>
                                    <Select
                                        id="lender-select"
                                        name="lenderId"
                                        value={lenderId}
                                        onChange={(e) => this.handleSelectChange(e)}
                                        disabled={readOnlyMode}
                                    >
                                        {lenders.map((item, index) => (
                                            <MenuItem key={item.lenderId} value={item.lenderId}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                    </div>
                    }
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.props.save({loanOfficerId,name,phone,email,lenderId})} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(LoanOfficer);