import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import {validateField,validateLenientName,request} from '../../utils'
import Switch from '@material-ui/core/Switch';
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    inlineButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
});

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactType: '',
            company: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            noAddressAvailable: false,
            //Mailing Address at time of contract
            address1: '', //No PO Boxes
            address2: '',
            city: '',
            state: '',
            zip: '',
            reason: '', //reason required if mailing address not provided
            validations: [],
            isLoading: false,
            showSelect: false,
            selectedContact: null
        };
    }

    componentDidMount() {
        const contactId = this.props.contactId || null;
        const contact = this.props.contact || null;
        const contacts = this.props.contacts || null;
        const contactType = this.props.contactType || null;

        if(contactId !== null){
            this.setState({isLoading:true},() => this.loadContact(contactId))
        } else if(contact !== null){
            const reason = contact.reason || '';
            this.setState({
                contactType: contact.contactType || '',
                company: contact.company || '',
                firstName: contact.firstName || '',
                lastName: contact.lastName || '',
                email: contact.email || '',
                phone: contact.phone || '',
                noAddressAvailable: (reason||null) !== null,
                address1: contact.address1 || '',
                address2: contact.address2 || '',
                city: contact.city || '',
                state: contact.state || '',
                zip: contact.zip || '',
                reason: reason,
                showSelect: contacts !== null
            })
        } else if(contactType !== null){
            this.setState({contactType: contactType,showSelect: contacts !== null})
        } else {
            this.setState({showSelect: contacts !== null})
        }
    }

    loadContact(contactId) {
        request(
            'GET',
            '/Manage/getContact?contactId=' + contactId,
            null,
            (data) => {
                this.loadData(data.contact);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            contactType: data.contactType,
            company: data.company,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            noAddressAvailable: (data.reason||null) !== null,
            address1: data.address1,
            address2: data.address2,
            city: data.city,
            state: data.state,
            zip: data.zip,
            reason: data.reason,
        })
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }

    handleContactTypeChange = event => {
        this.setState({contactType: event.target.value});
    };

    validateAll() {
        const fieldsToValidate = [
            'company',
            'firstName',
            'lastName',
            'email',
            'phone',
            'address1',
            'address2',
            'city',
            'state',
            'zip',
            'reason'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';
        const noAddressAvailable = this.state.noAddressAvailable;
        // const externalOverride = this.props.externalOverride || false;

        switch(fieldName){
            case 'company':
                break;
            case 'firstName':
                isValid = validateLenientName(value);
                invalidationReason = 'First Name is required';
                break;
            case 'lastName':
                isValid = validateLenientName(value);
                invalidationReason = 'Last Name is required';
                break;
            case 'email':
                // if(value.length > 0) {
                //     isValid = validateEmail(value);
                //     invalidationReason = 'Please enter a valid email address or clear this field';
                // }
                break;
            case 'phone':
                // if(value.length > 0){
                //     isValid = validateField(value,10,10);
                //     invalidationReason = 'Please enter a 10 digit phone number or clear this field';
                // }
                break;
            case 'address1': //No PO Boxes
                if(!noAddressAvailable){
                    if((value||null) !== null && (value.toLowerCase().indexOf('po b') !== -1 || value.toLowerCase().indexOf('p.o') !== -1 || value.toLowerCase().indexOf('post office') !== -1)){
                        isValid = false;
                        invalidationReason = 'P.O. Boxes are not allowed';
                    } else {
                        isValid = validateField(value);
                        invalidationReason = 'Please enter the street # and name';
                    }
                }
                break;
            case 'address2':
                break;
            case 'city':
                if(!noAddressAvailable){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the city name';
                }
                break;
            case 'state':
                if(!noAddressAvailable){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the state';
                }
                break;
            case 'zip':
                if(!noAddressAvailable){
                    isValid = validateField(value,5,5);
                    invalidationReason = 'Please enter the 5 digit zip code';
                }
                break;
            case 'reason': //reason required if mailing address not provided
                if(noAddressAvailable){
                    isValid = validateField(value);
                    invalidationReason = 'You must provide a reason if an address is not available';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }
    
    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;
        if (field === 'phone') {
            value = value.replace(/\D+/g, '');
            if(value.length > 10){
                value = value.substring(0,10);
            }
        }
        if (field === 'zip') {
            value = value.replace(/\D+/g, '');
            if(value.length > 5){
                value = value.substring(0,5);
            }
        }
        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleNoAddressChange() {
        const noAddressAvailable = this.state.noAddressAvailable;
        this.setState({noAddressAvailable: !noAddressAvailable})
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const readOnlyMode = this.props.readOnlyMode || false;
        if(readOnlyMode){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;

    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE';
        const hideContactType = this.props.hideContactType || true;
        const readOnlyMode = this.props.readOnlyMode || false;
        let contactId = this.props.contactId || null;
        const contact = this.props.contact || null;
        if(contactId === null){
            if(contact !== null) {
                contactId = contact.contactId || null;
            }
        }

        const isReusable = this.props.isReusable || false;
        const idAppend = _.random(10000,999999)

        const { contactType,company,firstName,lastName,email,phone,noAddressAvailable,address1,address2,city,state,zip,reason,isLoading,showSelect,selectedContact } = this.state;
        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div>
                    {showSelect &&
                    <div className={classes.flexWrapper}>
                        <div className={classes.dropdown}>
                            <Autocomplete
                                id={'contact-list-'+idAppend}
                                options={this.props.contacts}
                                getOptionLabel={option => (option.company||null) !== null ? option.company : option.displayName}
                                // style={{ width: '100%' }}
                                renderInput={params => <TextField autoComplete="new"  {...params} label="Select Contact"/>}
                                onChange={(e, v) => this.setState({selectedContact: v})}
                                // inputValue={contact}
                            />
                        </div>
                        <div>
                            <Button variant="contained" color="primary" disabled={selectedContact === null} onClick={() => this.loadData(selectedContact)} className={classes.inlineButton}>Load</Button>
                        </div>
                    </div>
                    }
                    <div className={classes.flexWrapper}>
                        {!hideContactType &&
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Contact Type</FormLabel>
                            <RadioGroup row aria-label="contactType" name="contactType" value={contactType} onChange={(e) => this.handleContactTypeChange(e)}>
                                <FormControlLabel value="Buyer" control={<Radio/>} label="Buyer"/>
                                <FormControlLabel value="Seller" control={<Radio/>} label="Seller"/>
                                <FormControlLabel value="Lessor" control={<Radio/>} label="Lessor"/>
                                <FormControlLabel value="Lessee" control={<Radio/>} label="Lessee"/>
                            </RadioGroup>
                        </FormControl>
                        }
                        <TextField autoComplete="new"
                            value={firstName}
                            name="firstName"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="First Name"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('firstName')}
                            helperText={this.fieldErrorMessage('firstName')}
                            required
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            value={lastName}
                            name="lastName"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Last Name"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('lastName')}
                            helperText={this.fieldErrorMessage('lastName')}
                            required
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            value={email}
                            name="email"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Email Address"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('email')}
                            helperText={this.fieldErrorMessage('email')}
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            value={phone}
                            name="phone"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Phone (10 Digits)"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('phone')}
                            helperText={this.fieldErrorMessage('phone')}
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            value={company}
                            name="company"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Company"
                            className={classes.textField}
                            margin="normal"
                            disabled={readOnlyMode}
                        />
                        <br/>
                        <FormControlLabel
                            className={classes.switch}
                            control={
                                <Switch
                                    checked={noAddressAvailable}
                                    onChange={() => this.handleNoAddressChange()}
                                    color="primary"
                                    disabled={readOnlyMode}
                                />
                            }
                            label="No Address Available (reason required)"
                        />
                        <br/>
                        {!noAddressAvailable ? (
                            <div className={classes.flexWrapper}>
                                <TextField autoComplete="new"
                                    value={address1}
                                    name="address1"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Address 1"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('address1')}
                                    helperText={this.fieldErrorMessage('address1')}
                                    disabled={readOnlyMode}
                                    required
                                />
                                <TextField autoComplete="new"
                                    value={address2}
                                    name="address2"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Address 2"
                                    className={classes.textField}
                                    margin="normal"
                                    disabled={readOnlyMode}
                                />
                                <TextField autoComplete="new"
                                    value={city}
                                    name="city"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="City"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('city')}
                                    helperText={this.fieldErrorMessage('city')}
                                    disabled={readOnlyMode}
                                    required
                                />
                                <TextField autoComplete="new"
                                    value={state}
                                    name="state"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="State"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('state')}
                                    helperText={this.fieldErrorMessage('state')}
                                    disabled={readOnlyMode}
                                    required
                                />
                                <TextField autoComplete="new"
                                    value={zip}
                                    name="zip"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Zip"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('zip')}
                                    helperText={this.fieldErrorMessage('zip')}
                                    disabled={readOnlyMode}
                                    required
                                />
                            </div>
                        ) : (
                            <TextField autoComplete="new"
                                value={reason}
                                name="reason"
                                onChange={(e) => this.handleFieldChange(e)}
                                label="Reason"
                                className={classes.textField}
                                margin="normal"
                                fullWidth
                                multiline
                                rowsMax="4"
                                error={this.fieldHasError('reason')}
                                helperText={this.fieldErrorMessage('reason') || 'Required when address unavailable'}
                                disabled={readOnlyMode}
                                required
                            />
                        )}
                    </div>
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.props.save({contactId,contactType,company,firstName,lastName,email,phone,reason,address1,address2,city,state,zip,isReusable})} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(Contact);