import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});

class Broker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firm: '', //Firm Name
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            fax: '',
            mlsOfficeCode: '', //MLS Office Code
            firmLicenseNumber: '', //Brokerage Firm License Number
            validations: [],
            isLoading: false,
            hasChanges: false
        };
    }

    componentDidMount() {
        const brokerId = this.props.brokerId || null;
        if(brokerId !== null){
            this.setState({isLoading:true},() => this.loadBroker(brokerId))
        } else {
            const broker = this.props.broker || null;
            if(broker !== null){
                this.setState({
                    firm: broker.firm || '',
                    address1: broker.address1 || '',
                    address2: broker.address2 || '',
                    city: broker.city || '',
                    state: broker.state || '',
                    zip: broker.zip || '',
                    phone: broker.phone || '',
                    fax: broker.fax || '',
                    mlsOfficeCode: broker.mlsOfficeCode || '',
                    firmLicenseNumber: broker.firmLicenseNumber || '',
                })
            }
        }
    }

    loadBroker(brokerId) {
        request(
            'GET',
            '/Manage/getBroker?brokerId=' + brokerId,
            null,
            (data) => {
                this.loadData(data.broker);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            firm: data.firm || '',
            address1: data.address1 || '',
            address2: data.address2 || '',
            city: data.city || '',
            state: data.state || '',
            zip: data.zip || '',
            phone: data.phone || '',
            fax: data.fax || '',
            mlsOfficeCode: data.mlsOfficeCode || '',
            firmLicenseNumber: data.firmLicenseNumber || '',
            hasChanges: true
        })
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }
    
    validateAll() {
        const fieldsToValidate = [
            'firm',
            'address1',
            'address2',
            'city',
            'state',
            'zip',
            'phone',
            'fax',
            'mlsOfficeCode',
            'firmLicenseNumber'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    };

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'firm':
                isValid = validateField(value);
                invalidationReason = 'Please enter the Firm Name';
                break;
            case 'address1':
                isValid = validateField(value);
                invalidationReason = 'Please enter the address';
                break;
            case 'address2':
                break;
            case 'city':
                isValid = validateField(value);
                invalidationReason = 'Please enter the city';
                break;
            case 'state':
                isValid = validateField(value);
                invalidationReason = 'Please enter the state';
                break;
            case 'zip':
                isValid = validateField(value,5,5);
                invalidationReason = 'Please enter the 5 digit zip code';
                break;
            case 'phone':
                if(value !== null && value.length > 0){
                    isValid = validateField(value,10,10);
                    invalidationReason = 'Please enter a 10 digit phone number';
                }
                break;
            case 'fax':
                if(value !== null && value.length > 0){
                    isValid = validateField(value,10,10);
                    invalidationReason = 'Please enter a 10 digit fax number';
                }
                break;
            case 'mlsOfficeCode':
                isValid = validateField(value);
                invalidationReason = 'Please enter the MLS Office Code';
                break;
            case 'firmLicenseNumber':
                isValid = validateField(value);
                invalidationReason = 'Please enter the Firm License #';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    };

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;
        if (field === 'phone') {
            value = value.replace(/\D+/g, '');
            if(value.length > 10){
                value = value.substring(0,10);
            }
        }
        if (field === 'zip') {
            value = value.replace(/\D+/g, '');
            if(value.length > 5){
                value = value.substring(0,5);
            }
        }
        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations, hasChanges: true});
    };

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const readOnlyMode = this.props.readOnlyMode || false;
        if(readOnlyMode || !this.state.hasChanges){
            return false;
        }

        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE';
        let brokerId = this.props.brokerId || null;
        if(brokerId === null){
            const broker = this.props.broker || null;
            if(broker !== null) {
                brokerId = broker.brokerId || null;
            }
        }
        
        const readOnlyMode = this.props.readOnlyMode || false;

        const {
            isLoading,
            firm,
            address1,
            address2,
            city,
            state,
            zip,
            phone,
            fax,
            mlsOfficeCode,
            firmLicenseNumber
            } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                        value={firm}
                        name="firm"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Firm Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('firm')}
                        helperText={this.fieldErrorMessage('firm')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={address1}
                        name="address1"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Address 1"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('address1')}
                        helperText={this.fieldErrorMessage('address1')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={address2}
                        name="address2"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Address 2"
                        className={classes.textField}
                        margin="normal"
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={city}
                        name="city"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="City"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('city')}
                        helperText={this.fieldErrorMessage('city')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={state}
                        name="state"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="State"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('state')}
                        helperText={this.fieldErrorMessage('state')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={zip}
                        name="zip"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Zip"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('zip')}
                        helperText={this.fieldErrorMessage('zip')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={phone}
                        name="phone"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Phone"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('phone')}
                        helperText={this.fieldErrorMessage('phone')}
                        // required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={fax}
                        name="fax"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Fax"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('fax')}
                        helperText={this.fieldErrorMessage('fax')}
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={mlsOfficeCode}
                        name="mlsOfficeCode"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="MLS Office Code"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('mlsOfficeCode')}
                        helperText={this.fieldErrorMessage('mlsOfficeCode')}
                        required
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={firmLicenseNumber}
                        name="firmLicenseNumber"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Firm License Number"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('firmLicenseNumber')}
                        helperText={this.fieldErrorMessage('firmLicenseNumber')}
                        required
                        disabled={readOnlyMode}
                    />
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.setState({hasChanges: false},() => this.props.save({
                    brokerId,
                    firm,
                    address1,
                    address2,
                    city,
                    state,
                    zip,
                    phone,
                    fax,
                    mlsOfficeCode,
                    firmLicenseNumber
                }))} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(Broker);