import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import TextField from "@material-ui/core/TextField";
import {request} from "../../utils";
import _ from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Loading from "../Loading";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    card: {
        flexGrow: 1,
        margin: theme.spacing(2),
    },
    cardContent: {
    },
    update: {

    },
    updateValue: {

    }
});

class UpdateLenderUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            applicationDate: null,
            qualified: false,
            approvalDate: null,
            status: '',
            notes: '',
            ctc: false,
            clearToCloseDate: null,
            clearToCloseNotes: '',
            validations: [],
        };
    }

    componentDidMount() {
        const lenderUpdate = this.props.lenderUpdate || null;
        if(lenderUpdate !== null){
            this.setState(
                {
                    applicationDate: lenderUpdate.applicationDate || null,
                    qualified: lenderUpdate.qualified || false,
                    approvalDate: lenderUpdate.approvalDate || null,
                    status: lenderUpdate.status || '',
                    notes: lenderUpdate.notes || '',
                    ctc: (lenderUpdate.clearToCloseDate || null) === null ? false : true,
                    clearToCloseDate: lenderUpdate.clearToCloseDate || null,
                    clearToCloseNotes: lenderUpdate.clearToCloseNotes || '',
                }
            );
        }
    }

    validateAll() {
        const fieldsToValidate = [
            'applicationDate',
            'qualified',
            'approvalDate',
            'status',
            'notes',
            'ctc',
            'clearToCloseDate'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';
        const {ctc} = this.state;

        switch(fieldName){
            case 'applicationDate':
                // isValid = value !== null;
                // invalidationReason = 'Please enter the Application Date';
                break;
            case 'qualified':
                break;
            case 'approvalDate':
                break;
            case 'status':
                break;
            case 'notes':
                break;
            case 'ctc':
                break;
            case 'clearToCloseDate':
                if(ctc){
                    isValid = value !== null;
                    invalidationReason = 'Please enter the Clear To Close Date';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        if(field === 'ctc'){
            this.setState({[field]: !value, clearToCloseDate: null});
        } else {
            this.setState({[field]: !value});
        }
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        if(i > -1){
            return false;
        }
        return true;
    }


    saveLenderUpdate() {
        const lenderUpdateId = this.props.lenderUpdate.lenderUpdateId;
        const {
            applicationDate,
            qualified,
            approvalDate,
            status,
            notes,
            clearToCloseDate,
            clearToCloseNotes
        } = this.state;

        const data = {
            lenderUpdateId,
            applicationDate,
            qualified,
            approvalDate,
            status,
            notes,
            clearToCloseDate,
            clearToCloseNotes
        };

        request(
            'POST',
            '/Manage/updateLenderUpdate',
            data,
            (response) => {
                this.props.success(data);
            },
            (error) => {
                this.setState({isLoading:false},() => this.props.showSnack('error',error));
            })
    }

    render() {
        const { classes,payment } = this.props;
        const {
            isLoading,
            applicationDate,
            qualified,
            approvalDate,
            status,
            notes,
            ctc,
            clearToCloseDate,
            clearToCloseNotes
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                {payment !== 'Cash' &&
                <div>
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            margin="normal"
                            className={classes.dateInput}
                            label="Application Date"
                            value={applicationDate}
                            onChange={(d) => this.handleDateChange('applicationDate', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                            // required
                        />
                    </MuiPickersUtilsProvider>
                    <br/>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="qualified"
                                checked={qualified}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                            />
                        }
                        label="Qualifies?"
                    />
                    <br/>
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            margin="normal"
                            className={classes.dateInput}
                            label="Approval Date"
                            value={approvalDate}
                            onChange={(d) => this.handleDateChange('approvalDate', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                        />
                    </MuiPickersUtilsProvider>
                    <br/>
                    <FormControl className={classes.dropdown}>
                        <InputLabel htmlFor="construction-status-select">Status</InputLabel>
                        <Select
                            id="construction-status-select"
                            name="status"
                            value={status}
                            onChange={(e) => this.handleSelectChange(e)}
                        >
                            <MenuItem value="Good">Good</MenuItem>
                            <MenuItem value="Attention Required">Attention Required</MenuItem>
                            <MenuItem value="In Progress">In Progress</MenuItem>
                        </Select>
                    </FormControl>
                    <br/>
                </div>
                }
                <TextField autoComplete="new"
                   value={notes}
                   name="notes"
                   onChange={(e) => this.handleFieldChange(e)}
                   label="Notes"
                   className={classes.textField}
                   margin="normal"
                   error={this.fieldHasError('notes')}
                   helperText={this.fieldErrorMessage('notes')}
                   fullWidth
                />
                <div>
                    <br/>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="ctc"
                                checked={ctc}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                            />
                        }
                        label="Clear to Close?"
                    />
                    <br/>
                    {ctc &&
                    <div>
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                margin="normal"
                                className={classes.dateInput}
                                label="Clear to Close Date"
                                value={clearToCloseDate}
                                onChange={(d) => this.handleDateChange('clearToCloseDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                                required
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    }
                    <br/>
                    <TextField autoComplete="new"
                       value={clearToCloseNotes}
                       name="clearToCloseNotes"
                       onChange={(e) => this.handleFieldChange(e)}
                       label="CTC Notes"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('clearToCloseNotes')}
                       helperText={this.fieldErrorMessage('clearToCloseNotes')}
                       fullWidth
                    />
                </div>
                <br/>
                <br/>
                <Button variant="contained" color="primary" onClick={() => this.setState({isLoading: true}, () => this.saveLenderUpdate())} className={classes.button}>SAVE</Button>
            </div>
        );
    }
}

export default withStyles(styles)(UpdateLenderUpdate);