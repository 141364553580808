import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import logo from "../TW.png"
import LandingPageLink from "./LandingPageLink";
// import Loading from "./Loading";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {request} from "../utils";

const styles = theme => ({
    grid: {
        padding: theme.spacing(2) + 'px 0',
        display: 'flex'
        // minHeight: 200
    },
    grow: {
        flex: 1
    },
    logo: {
        margin: "0 auto",
        display: "block",
        width: 150,
    },
    card: {
        minHeight: theme.spacing(4) + 60,
        overflow: 'unset !important'
    },
    cardContent: {
        padding: theme.spacing(2) + 'px !important',
        minHeight: theme.spacing(4) + 60,
        display: 'flex'
    },
    cardLeft: {
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        height: 60,
        minHeight: 60,
        maxHeight: 60,
        position: 'relative',
        marginRight: theme.spacing(1)
        // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    cardRight: {
        textAlign: 'left'
    },
    flex: {
        display: 'flex'
    },
    skeletonText: {
        width: '100%'
    },
    skeletonImage: {
        width: 60,
        height: 60,
        marginRight: theme.spacing(1),
    },
    greeting: {
        fontSize: 32,
        margin: '0 auto',
        textAlign: 'center',
        fontFamily: '\'Pacifico\', cursive'
    },
    skeletonGreeting: {
        width: 400,
        maxWidth: '100%',
        margin: '0 auto',
        fontSize: 32,
    },
    skeletonCenter: {
        margin: '0 auto',
    },
    skeletonDivider: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontSize: 30,
        width: 250
    },
    divider: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    dividerText: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: '#555',
        fontSize: 24,
        textTransform: 'uppercase',
        fontWeight: 300
    },
    dividerLine: {
        flex: 1,
        borderBottom: 'solid #eeeeee 2px'
    }
});

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            user: '',
            isLoading: true
        };
    }

    componentDidMount() {
        this.getUser();
    }

    getUser() {
        request(
            'GET',
            '/user/getUser',
            null,
            (data) => {
                this.setState({user: data.user}, () => this.getTemplate());
            },
            (error) => {
                this.setState({isLoading: false});
            })
    }

    getTemplate(){
        request(
            'GET',
            '/user/getTemplate',
            null,
            (data) => {
                this.setState({categories: data.template.categories, isLoading: false});
            },
            (error) => {
                this.setState({isLoading: false});
            })
    }

    linkClicked(link){
        window.open(link.url,'_blank');
    }

    render() {
        const { classes } = this.props;
        const { isLoading,categories,user } = this.state;
        const skeletons = 6;

        return (
            <div>
                <div>
                    <img className={classes.logo} src={logo} alt="logo" />
                </div>
                {isLoading ? (
                    <div className={classes.skeletonGreeting}>
                        <Skeleton width="65%" className={classes.skeletonCenter} />
                        <Skeleton />
                    </div>
                ) : (
                    <div className={classes.greeting}>
                        {(user||null) !== null &&
                        <div>Hello {user}!</div>
                        }
                        <div>Welcome to TW Central</div>
                    </div>
                )}

                <Grid>
                {isLoading ? (
                        <div>
                            <div className={classes.divider}>
                                <div className={classes.dividerLine}/>
                                <div className={classes.skeletonDivider}><Skeleton/></div>
                                <div className={classes.dividerLine}/>
                            </div>
                            <Grid container spacing={2} className={classes.grid} justify="flex-start" alignItems="flex-start">
                                {skeletons > 0 &&
                                [...Array(skeletons)].map((item,index) =>
                                <Grid item xs={12} sm={6} md={4} lg={3} key={'skeleton-' + index}>
                                    <div style={{position: 'relative'}}>
                                        <Card className={classes.card}>
                                            <CardContent className={classes.cardContent}>
                                                <div className={classes.cardLeft}>
                                                    <Skeleton variant="rect" width={60} height={60} className={classes.skeletonImage} />
                                                </div>
                                                <div className={classes.skeletonText}>
                                                    <Skeleton />
                                                    <Skeleton width="60%" />
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                                )}
                            </Grid>
                        </div>
                    ) : (
                    <div>
                        {(categories || null) !== null &&
                        categories.map((item,index) =>
                            <div key={'category-' + index}>
                                <div className={classes.divider}>
                                    <div className={classes.dividerLine}/>
                                    <div className={classes.dividerText}>{item.name}</div>
                                    <div className={classes.dividerLine}/>
                                </div>
                                <Grid container spacing={2} className={classes.grid} justify="flex-start" alignItems="flex-start">
                                    {(item.links || null) !== null &&
                                    item.links.map((link,index) =>
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={'link-' + index}>
                                            <LandingPageLink title={link.title} summary={link.summary} url={link.url} imageUrl={link.imageUrl} color={link.color} backgroundColor={link.backgroundColor} onClick={() => this.linkClicked(link)}/>
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        )}
                    </div>
                    )}
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Landing);