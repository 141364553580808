import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField, validatePrice, validatePercentage} from '../../utils';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Loading from '../Loading';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from "@material-ui/core/FormHelperText";
import Attorney from "./Attorney";
import Broker from "./Broker";
import Chip from '@material-ui/core/Chip';
import AddIcon from "@material-ui/icons/Add";
import Divider from '@material-ui/core/Divider';
import Contact from "./Contact";
import UpdateCloseDate from "./UpdateCloseDate";
import UpdateContract from "./UpdateContract";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import Log from "../Log";
import Dialog from "../Dialog";
import UnlockEditMode from "./UnlockEditMode";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    agentWrapper: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > * > *': {
            margin: theme.spacing(0.5),
        },
    },
    contactWrapper: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > * > *': {
            margin: theme.spacing(0.5),
        },
    },
    readOnly: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // padding: theme.spacing(1),
        // border: 'solid 1px',
        // borderColor: '#e7e7e7'
    },
    roTitle: {
        fontWeight: 600
    },
    roValue: {
        fontWeight: 300
    },
    editButton: {
        position: 'fixed',
        bottom: theme.spacing(1),
        right: theme.spacing(2),
        zIndex: 9999
    },
    margin: {
        margin: theme.spacing(1),
    },
});

class UpdateListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listingId: '',
            builder: '',
            property: '',
            readOnlyMode: true,
            showEdit: true,
            // constructions: [],
            // loadingConstructions: false,

            // propertyId: '',
            // properties: [],
            // property: {},
            // propertyMode: 'existing',
            // loadingProperties: false,

            loadingListing: true,
            savingListing: false,

            constructionSummary: '',
            constructionStage: '',
            relationship: '',
            type: '',
            status: '',
            signReturned: '',
            reasonSignNotReturned: '',

            buyers: [],
            lessees: [],
            seller: null,
            lessor: null,
            contacts: null,
            showContactDialog: false,
            contactDialogMode: '',
            selectedContact: null,

            loadingBrokers: false,
            brokers: [],
            remaxTruId: '',
            listingBroker: {},
            listingBrokerMode: 'tw',
            listingBrokerId: '',
            sellingBroker: {},
            sellingBrokerMode: 'existing',
            sellingBrokerId: '',
            hasSellingBroker: false,

            agents: [],
            agentsAtContract: [],
            agentsAtClosing: [],
            selectedAgentId: '',

            leadSources: [],
            leadSource: '',
            leadSourceMode: 'existing',

            closeDate: null,
            contract: null,

            closingPhotosReceived: false, //(New construction only)

            closingCostId: '',
            sellerPaid: '',
            lenderPaid: '',
            buyerPaid: 'N/A',

            //// listingCommission: {},
            combinedCommissionPercentage: '',
            totalCommissionPercentage: '',
            totalCommissionAmount: '',
            tamraCommissionPercentage: '',
            agentCommissionPercentage: '',
            tamraCommissionAmount: '',
            agentCommissionAmount: '',
            fmlsCommission: '',
            remaxCommission: '',
            randyCommission: '',
            tamraCmnCommission: '',
            agentCmnCommission: '',
            gsmCommission: '',
            attorneyId: '',
            attorneys: [],
            attorney: {},
            attorneyMode: 'existing',
            loadingAttorneys: false,

            loadingAgents: false,
            loadingContacts: false,
            loadingLeadSources: false,

            notes: [],
            newNote: '',

            existingAttorney: false,
            existingSellingBroker: false,
            existingListingBroker: false,

            validations: [],

            showUnlockDialog: false,
            unlocked: false,
        };
    }

    componentDidMount() {
        const readOnlyMode = this.props.readOnlyMode || false;
        const listingId = this.props.listingId;
        if(readOnlyMode){
            this.setState({showEdit: false, listingId},() => this.loadListing(listingId));
        } else {
            this.setState({listingId},() => this.loadListing(listingId));
        }
    }

    loadListing(listingId) {
        request(
            'GET',
            '/Manage/getListing?listingId=' + listingId,
            null,
            (data) => {
                this.loadData(data.listing);
            },
            (error) => {
                this.handleError(error)
            })
    }

    loadData(data)
    {
        const existingAttorney = (!_.isEmpty(data.attorney) && (data.attorney.attorneyId||null) !== null);
        const existingSellingBroker = (!_.isEmpty(data.sellingBroker) && (data.sellingBroker.brokerId||null) !== null);
        const existingListingBroker = (!_.isEmpty(data.listingBroker) && (data.listingBroker.brokerId||null) !== null);

        this.setState({
            loadingListing: false,
            builder: data.builder || '',
            property: data.property || '',
            constructionSummary: data.constructionSummary || '',
            constructionStage: data.constructionStage || '',
            relationship: data.relationship || '',
            type: data.type || '',
            status: data.status || '',
            signReturned: data.signReturned || '',
            reasonSignNotReturned: data.reasonSignNotReturned || '',
            buyers: data.buyers || [],
            lessees: data.lessees || [],
            seller: data.seller || null,
            lessor: data.lessor || null,
            listingBroker: data.listingBroker || {},
            sellingBroker: data.sellingBroker || {},
            agentsAtContract: data.agentsAtContract || [],
            agentsAtClosing: data.agentsAtClosing || [],
            leadSource: data.leadSource || '',
            leadSourceMode: (data.leadSource || null) !== null ? 'new' : 'existing',
            closeDate: data.closeDate || null,
            contract: data.contract || null,
            closingPhotosReceived: data.closingPhotosReceived || false,

            closingCostId: data.closingCostId || '',
            sellerPaid: data.sellerPaid || '0',
            lenderPaid: data.lenderPaid || '0',
            buyerPaid: data.buyerPaid || 'N/A',

            combinedCommissionPercentage: data.combinedCommissionPercentage || '',
            totalCommissionPercentage: data.totalCommissionPercentage || '',
            totalCommissionAmount: data.totalCommissionAmount || '',
            tamraCommissionPercentage: data.tamraCommissionPercentage || '',
            agentCommissionPercentage: data.agentCommissionPercentage || '',
            tamraCommissionAmount: data.tamraCommissionAmount || '',
            agentCommissionAmount: data.agentCommissionAmount || '',
            fmlsCommission: data.fmlsCommission || '',
            remaxCommission: data.remaxCommission || '',
            randyCommission: data.randyCommission || '',
            tamraCmnCommission: data.tamraCmnCommission || '',
            agentCmnCommission: data.agentCmnCommission || '',
            gsmCommission: data.gsmCommission || '',
            attorney: data.attorney || {},
            notes: data.notes || [],
            hasSellingBroker: existingSellingBroker,
            existingAttorney,
            existingSellingBroker,
            existingListingBroker
        })
    }

    getAll() {
        this.setState({
            loadingAttorneys: true,
            loadingLeadSources: true,
            loadingBrokers: true,
            loadingAgents: true,
            loadingContacts: true,
        }, () => this.getAgents())
    }

    getAgents() {
        if(this.state.agents.length > 0) {
            this.setState({loadingAgents: false}, () => this.getAttorneys())
        } else {
            request(
                'GET',
                '/Manage/getAgents',
                null,
                (data) => {
                    this.setState({agents: data.agents, loadingAgents: false}, () => this.getAttorneys())
                },
                (error) => {
                    this.setState({loadingAgents: false}, () => {
                        console.log(error);
                        this.getAttorneys()
                    })
                })
        }
    }

    getAttorneys() {
        if(this.state.attorneys.length > 0) {
            this.setState({loadingAttorneys: false}, () => this.getBrokers())
        } else {
            request(
                'GET',
                '/Manage/getAttorneys',
                null,
                (data) => {
                    this.setState({attorneys: data.attorneys, loadingAttorneys: false}, () => this.getBrokers())
                },
                (error) => {
                    this.setState({loadingAttorneys: false}, () => {
                        console.log(error);
                        this.getBrokers()
                    })
                })
        }
    }

    getBrokers() {
        if(this.state.brokers.length > 0) {
            this.setState({loadingBrokers: false}, () => this.getLeadSources())
        } else {
            request(
                'GET',
                '/Manage/getBrokers',
                null,
                (data) => {
                    this.setState({brokers: data.brokers, remaxTruId: data.remaxTruId, loadingBrokers: false}, () => this.getLeadSources())
                },
                (error) => {
                    this.setState({loadingBrokers: false}, () => {
                        console.log(error);
                        this.getLeadSources()
                    })
                })
        }
    }

    getLeadSources() {
        if(this.state.leadSources.length > 0) {
            this.setState({loadingLeadSources: false})
        } else {
            request(
                'GET',
                '/Manage/getLeadSources',
                null,
                (data) => {
                    this.setState({leadSources: data.leadSources, loadingLeadSources: false}, () => this.getContacts())
                },
                (error) => {
                    this.setState({loadingLeadSources: false}, () => {
                        console.log(error);
                        this.getContacts()
                    })
                })
        }
    }

    getContacts() {
        // const {type,relationship} = this.state;

        request(
            'GET',
            '/Manage/getContacts',
            null,
            (data) => {
                this.setState({contacts: data.contacts, loadingContacts: false})

                // if(relationship === 'Buyer'){
                //     this.setState({buyer: data.contact, loadingContacts: false})
                // }
                // if(relationship === 'Seller'){
                //     this.setState({seller: data.contact, loadingContacts: false})
                // }
                // if(relationship === 'Lessee'){
                //     this.setState({lessee: data.contact, loadingContacts: false})
                // }
                // if(relationship === 'Lessor'){
                //     this.setState({lessor: data.contact, loadingContacts: false})
                // }
            },
            (error) => {
                this.handleError(error);
            })
    }

    handleError(error){
        this.setState({
            loadingListing: false,
            // loadingConstructions: false,
            // loadingProperties: false,
            loadingAttorneys: false,
            loadingLeadSources: false,
            loadingBrokers: false,
            loadingAgents: false,
            loadingContacts: false,
            savingListing: false
        }, () => this.props.showSnack('error',error))
    }

    updateAttorney(a) {
        const successMessage = 'Successfully Updated Attorney!';
        const action = '/Manage/updateAttorney';
        this.setState({savingListing: true}, () => {
            request(
                'POST',
                action,
                a,
                (data) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('success',successMessage))
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    addAttorneyToListing(attorneyId) {
        const d = {
            listingId: this.state.listingId,
            attorneyId: attorneyId || this.state.attorneyId
        };
        const successMessage = 'Successfully Added Attorney!';

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                '/Manage/addAttorneyToListing',
                d,
                (data) => {
                    this.setState({savingListing: false, attorney: data.attorney, existingAttorney: true}, () => this.props.showSnack('success', successMessage))
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    addAttorney(a) {
        request(
            'POST',
            '/Manage/addAttorney',
            a,
            (data) => {
                const attorney = data.attorney;
                this.addAttorneyToListing(attorney.attorneyId);
            },
            (error) => {
                this.setState({savingListing: false}, () => this.props.showSnack('error',error))
            })
    }

    updateListingBroker(b) {
        const successMessage = 'Successfully Updated Listing Broker!';
        const action = '/Manage/updateBroker';
        this.setState({savingListing: true}, () => {
            request(
                'POST',
                action,
                b,
                (data) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('success',successMessage))
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    addListingBrokerToListing(brokerId) {
        const d = {
            listingId: this.state.listingId,
            listingBrokerId: brokerId || this.state.listingBrokerId
        };
        
        const successMessage = 'Successfully Added Broker!';

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                '/Manage/addBrokerToListing',
                d,
                (data) => {
                    this.setState({savingListing: false, listingBroker: data.broker, existingListingBroker: true}, () => this.props.showSnack('success', successMessage))
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    addListingBroker(b) {
        request(
            'POST',
            '/Manage/addBroker',
            b,
            (data) => {
                const broker = data.broker;
                this.addListingBrokerToListing(broker.brokerId);
            },
            (error) => {
                this.setState({savingListing: false}, () => this.props.showSnack('error',error))
            })
    }

    updateSellingBroker(b) {
        const successMessage = 'Successfully Updated Selling Broker!';
        const action = '/Manage/updateBroker';
        this.setState({savingListing: true}, () => {
            request(
                'POST',
                action,
                b,
                (data) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('success',successMessage))
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    addSellingBrokerToListing(brokerId) {
        const d = {
            listingId: this.state.listingId,
            sellingBrokerId: brokerId || this.state.sellingBrokerId
        };

        const successMessage = 'Successfully Added Broker!';

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                '/Manage/addBrokerToListing',
                d,
                (data) => {
                    this.setState({savingListing: false, sellingBroker: data.broker, existingSellingBroker: true}, () => this.props.showSnack('success', successMessage))
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    addSellingBroker(b) {
        request(
            'POST',
            '/Manage/addBroker',
            b,
            (data) => {
                const broker = data.broker;
                this.addSellingBrokerToListing(broker.brokerId);
            },
            (error) => {
                this.setState({savingListing: false}, () => this.props.showSnack('error',error))
            })
    }

    updateContact(data,type) {
        const successMessage = 'Successfully Updated ' + type + '!';
        const action = '/Manage/updateContact';
        this.setState({savingListing: true}, () => {
            request(
                'POST',
                action,
                data,
                (data) => {
                    if(type === 'buyer'){
                        const buyers = this.state.buyers;
                        const clone = _.cloneDeep(buyers);
                        const contactId = data.contact.contactId||null;
                        if(contactId !== null){
                            const vIndex = _.findIndex(clone, {contactId: contactId});
                            if(vIndex > -1){
                                clone[vIndex] = data.contact;
                                this.setState({savingListing: false, buyers: clone, showContactDialog: false, contactDialogMode: '', selectedContact: null}, () => this.props.showSnack('success',successMessage));
                            }
                        } else {
                            this.setState({savingListing: false}, () => this.props.showSnack('success',successMessage));
                        }
                    }
                    if(type === 'seller'){
                        this.setState({savingListing: false, seller: data.contact}, () => this.props.showSnack('success',successMessage))
                    }
                    if(type === 'lessee'){
                        const lessees = this.state.lessees;
                        const clone = _.cloneDeep(lessees);
                        const contactId = data.contact.contactId||null;
                        if(contactId !== null){
                            const vIndex = _.findIndex(clone, {contactId: contactId});
                            if(vIndex > -1){
                                clone[vIndex] = data.contact;
                                this.setState({savingListing: false, lessees: clone, showContactDialog: false, contactDialogMode: '', selectedContact: null}, () => this.props.showSnack('success',successMessage));
                            }
                        } else {
                            this.setState({savingListing: false}, () => this.props.showSnack('success',successMessage));
                        }
                    }
                    if(type === 'lessor'){
                        this.setState({savingListing: false, lessor: data.contact}, () => this.props.showSnack('success',successMessage))
                    }
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    addBuyer(c){
        const buyers = this.state.buyers;
        const data = {
            listingId: this.state.listingId,
            contact: c
        };

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                '/Manage/addBuyerToListing',
                data,
                (data) => {
                    buyers.push(data.contact);
                    this.setState({savingListing: false, buyers: buyers}, () => this.props.showSnack('success', 'Successfully Added Buyer!'))
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    removeBuyer(contactId){
        const data = {
            listingId: this.state.listingId,
            contactId: contactId
        };

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                '/Manage/removeBuyerFromListing',
                data,
                (data) => {
                    const buyers = this.state.buyers;
                    const clone = _.cloneDeep(buyers);
                    const vIndex = _.findIndex(clone, {contactId: contactId});
                    if(vIndex > -1){
                        clone.splice(vIndex,1);
                        this.setState({savingListing: false, buyers: clone}, () => this.props.showSnack('success', 'Successfully Removed Buyer!'))
                    }
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    addLessee(c){
        const lessees = this.state.lessees;
        const data = {
            listingId: this.state.listingId,
            contact: c
        };

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                '/Manage/addLesseeToListing',
                data,
                (data) => {
                    lessees.push(data.contact);
                    this.setState({savingListing: false, lessees: lessees}, () => this.props.showSnack('success', 'Successfully Added Lessee!'))
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    removeLessee(contactId){
        const data = {
            listingId: this.state.listingId,
            contactId: contactId
        };

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                '/Manage/removeLesseeFromListing',
                data,
                (data) => {
                    const lessees = this.state.lessees;
                    const clone = _.cloneDeep(lessees);
                    const vIndex = _.findIndex(clone, {contactId: contactId});
                    if(vIndex > -1){
                        clone.splice(vIndex,1);
                        this.setState({savingListing: false, lessees: clone}, () => this.props.showSnack('success', 'Successfully Removed Lessee!'))
                    }
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    addAgentAtContract(){
        const agentsAtContract = this.state.agentsAtContract;
        const agentId = this.state.selectedAgentId;
        const aac1 = _.findIndex(agentsAtContract, {agentId: agentId});
        const aac2 = _.findIndex(agentsAtContract, {parentAgentId: agentId});
        if(aac1 > -1 || aac2 > -1){
            this.props.showSnack('warning','Agent already added.');
        } else {
            const data = {
                listingId: this.state.listingId,
                contractAgentId: agentId
            };

            this.setState({savingListing: true}, () => {
                request(
                    'POST',
                    '/Manage/addAgentToListing',
                    data,
                    (data) => {
                        agentsAtContract.push(data.agent);
                        this.setState({savingListing: false, agentsAtContract: agentsAtContract}, () => this.props.showSnack('success', 'Successfully Added Agent!'))
                    },
                    (error) => {
                        this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                    })
            });
        }
    }

    addAgentAtClosing(){
        const agentsAtClosing = this.state.agentsAtClosing;
        const agentId = this.state.selectedAgentId;
        const aac1 = _.findIndex(agentsAtClosing, {agentId: agentId});
        const aac2 = _.findIndex(agentsAtClosing, {parentAgentId: agentId});
        if(aac1 > -1 || aac2 > -1){
            this.props.showSnack('warning','Agent already added.');
        } else {
            const data = {
                listingId: this.state.listingId,
                closingAgentId: agentId
            };

            this.setState({savingListing: true}, () => {
                request(
                    'POST',
                    '/Manage/addAgentToListing',
                    data,
                    (data) => {
                        const agentsAtClosing = this.state.agentsAtClosing;
                        agentsAtClosing.push(data.agent);
                        this.setState({savingListing: false, agentsAtClosing: agentsAtClosing}, () => this.props.showSnack('success', 'Successfully Added Agent!'))
                    },
                    (error) => {
                        this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                    })
            });
        }
    }

    removeAgentAtContract(agentId){
        const data = {
            listingId: this.state.listingId,
            contractAgentId: agentId
        };

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                '/Manage/removeAgentFromListing',
                data,
                (data) => {
                    const agentsAtContract = this.state.agentsAtContract;
                    const clone = _.cloneDeep(agentsAtContract);
                    const vIndex = _.findIndex(clone, {agentId: agentId});
                    if(vIndex > -1){
                        clone.splice(vIndex,1);
                        this.setState({savingListing: false, agentsAtContract: clone}, () => this.props.showSnack('success', 'Successfully Removed Agent!'))
                    }
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    removeAgentAtClosing(agentId){
        const data = {
            listingId: this.state.listingId,
            closingAgentId: agentId
        };

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                '/Manage/removeAgentFromListing',
                data,
                (data) => {
                    const agentsAtClosing = this.state.agentsAtClosing;
                    const clone = _.cloneDeep(agentsAtClosing);
                    const vIndex = _.findIndex(clone, {agentId: agentId});
                    if(vIndex > -1){
                        clone.splice(vIndex,1);
                        this.setState({savingListing: false, agentsAtClosing: clone}, () => this.props.showSnack('success', 'Successfully Removed Agent!'))
                    }
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    // editContractAgent() {
    //
    // }
    //
    // editCloseAgent() {
    //
    // }

    update(action,data,successMessage){
        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({savingListing: false}, () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.setState({savingListing: false}, () => this.props.showSnack('error',error))
            })
    }

    updateStatus() {
        const data = {
            listingId: this.state.listingId,
            status: this.state.status
        };
        const successMessage = 'Successfully Updated Status!';
        const action = '/Manage/updateListingStatus';
        this.setState({savingListing: true}, () => this.update(action,data,successMessage));
    }

    updateBuilder() {
        const data = {
            listingId: this.state.listingId,
            builder: this.state.builder
        };
        const successMessage = 'Successfully Updated Builder!';
        const action = '/Manage/updateListingBuilder';
        this.setState({savingListing: true}, () => this.update(action,data,successMessage));
    }

    updateClosingCost() {
        const {
            closingCostId,
            sellerPaid,
            lenderPaid,
            buyerPaid,
        } = this.state;

        const data = {
            closingCostId,
            sellerPaid,
            lenderPaid,
            buyerPaid,
        };

        const successMessage = 'Successfully Updated Closing Cost!';
        const action = '/Manage/updateClosingCost';
        this.setState({savingListing: true}, () => this.update(action,data,successMessage));
    }

    updateAdditional() {
        const {
            listingId,
            signReturned,
            reasonSignNotReturned,
            closingPhotosReceived,
            leadSource
        } = this.state;
        
        const data = {
            listingId,
            signReturned,
            reasonSignNotReturned,
            closingPhotosReceived,
            leadSource
        };
        
        const successMessage = 'Successfully Updated Additional Info!';
        const action = '/Manage/UpdateListingAdditional';
        this.setState({savingListing: true}, () => this.update(action,data,successMessage));
    }

    updateCommissions() {
            const {
                listingId,
                combinedCommissionPercentage,
                totalCommissionPercentage,
                totalCommissionAmount,
                tamraCommissionPercentage,
                agentCommissionPercentage,
                tamraCommissionAmount,
                agentCommissionAmount,
                fmlsCommission,
                remaxCommission,
                randyCommission,
                tamraCmnCommission,
                agentCmnCommission,
                gsmCommission,
                newNote,
            } = this.state;
        
        const data = {
            listingId,
            combinedCommissionPercentage,
            totalCommissionPercentage,
            totalCommissionAmount,
            tamraCommissionPercentage,
            agentCommissionPercentage,
            tamraCommissionAmount,
            agentCommissionAmount,
            fmlsCommission,
            remaxCommission,
            randyCommission,
            tamraCmnCommission,
            agentCmnCommission,
            gsmCommission,
            notes: newNote,
        };
        
        const successMessage = 'Successfully Updated Commissions!';
        const action = '/Manage/updateListingCommissions';
        this.setState({savingListing: true}, () => this.update(action,data,successMessage));
    }

    removeSellingBroker() {
        const data = {
            listingId: this.state.listingId,
        };
        const successMessage = 'Successfully Removed Selling Broker!';
        const action = '/Manage/removeSellingBrokerFromListing';

        this.setState({savingListing: true}, () => {
            request(
                'POST',
                action,
                data,
                (data) => {
                    this.setState({savingListing: false, sellingBroker: {}, sellingBrokerId: '', existingSellingBroker: false, hasSellingBroker: false}, () => this.props.showSnack('success',successMessage))
                },
                (error) => {
                    this.setState({savingListing: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    updateContract(contract){
        const successMessage = 'Successfully updated Property Details!';

        request(
            'POST',
            '/Manage/updateContract',
            contract,
            (data) => {
                this.setState(
                    {savingListing: false, readOnlyMode: true},
                    () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    updateMonies(m) {
        const successMessage = 'Successfully Updated Earnest Money!';
        const action = '/Manage/updateMoney';
        request(
            'POST',
            action,
            m,
            (data) => {
                const mid = data.moneyId||null;
                if(mid !== null){
                    m.MoneyId = mid;
                }
                const contract = this.state.contract;
                contract.money = m;
                this.setState({savingListing: false, contract: contract}, () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.setState({savingListing: false}, () => this.props.showSnack('error',error))
            })
    }

    updateCloseDate(closeDate){
        const successMessage = 'Successfully updated Property Details!';

        request(
            'POST',
            '/Manage/updateCloseDate',
            closeDate,
            (data) => {
                this.setState(
                    {savingListing: false},
                    () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    validateAll() {
        const fieldsToValidate = [
            'relationship',
            'type',
            'status',
            'leadSource',
            //does not apply to lease
            // 'earnestMoneyTotal',
            'earnestMoneyRemaxTru',
            'earnestMoneyOther', //who?
            'earnestMoneyOtherAmount', //how much?
            'optionMoney',
            'optionMoneyNotes',
            'sellerPaid',
            'lenderPaid',
            'buyerPaid',
            'signReturned',
            'reasonSignNotReturned',
            'newNote',
            'totalCommissionPercentage',
            'totalCommissionAmount',
            'tamraCommissionPercentage',
            'agentCommissionPercentage',
            'tamraCommissionAmount',
            'agentCommissionAmount',
            'fmlsCommission',
            'remaxCommission',
            'randyCommission',
            'tamraCmnCommission',
            'agentCmnCommission',
            'gsmCommission'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateFields(fieldsToValidate) {
        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    areFieldsValid(fieldsToValidate){
        if(this.state.savingListing || this.state.readOnlyMode){
            return false;
        }
        const validations = this.validateFields(fieldsToValidate);
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;

    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'status':
                isValid = (value || null) !== null;
                invalidationReason = 'Please select status.';
                break;
            case 'relationship':
                isValid = (value || null) !== null;
                invalidationReason = 'Please select relationship.';
                break;
            case 'type':
                isValid = (value || null) !== null;
                invalidationReason = 'Please select type.';
                break;
            case 'leadSource':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            // case 'earnestMoneyTotal':
            //     if(value.length !== 0){
            //         isValid = validatePrice(value);
            //         invalidationReason = 'Please enter a valid amount.';
            //     }
            //     break;
            case 'earnestMoneyRemaxTru':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'earnestMoneyOther':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            case 'earnestMoneyOtherAmount':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please enter the garage direction.';
                }
                break;
            case 'optionMoneyNotes':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            case 'optionMoney':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'sellerPaid':
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'lenderPaid':
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'buyerPaid':
                // if(value.length !== 0){
                //     isValid = validatePrice(value);
                //     invalidationReason = 'Please provide a valid amount.';
                // }
                break;
            case 'signReturned':
                // isValid = validateField(value);
                // invalidationReason = 'Please enter .';
                break;
            case 'reasonSignNotReturned':
                if(this.state.signReturned === 'No'){
                    isValid = validateField(value);
                    invalidationReason = 'Please provide a reason.';
                }
                break;
            case 'newNote':
                // isValid = validateStage(value);
                // invalidationReason = 'Please enter .';
                break;
            case 'builder':
                // if(this.state.type === 'Resale'){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter the Builder\'s name.';
                // }
                break;
            case 'combinedCommissionPercentage':
                if(value.length !== 0){
                    isValid = validatePercentage(value);
                    invalidationReason = 'Please provide a valid percentage.';
                }
                break;
            case 'totalCommissionPercentage':
                if(value.length !== 0){
                    isValid = validatePercentage(value);
                    invalidationReason = 'Please provide a valid percentage.';
                }
                break;
            case 'totalCommissionAmount':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'tamraCommissionPercentage':
                if(value.length !== 0){
                    isValid = validatePercentage(value);
                    invalidationReason = 'Please provide a valid percentage.';
                }
                break;
            case 'agentCommissionPercentage':
                if(value.length !== 0){
                    isValid = validatePercentage(value);
                    invalidationReason = 'Please provide a valid percentage.';
                }
                break;
            case 'tamraCommissionAmount':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'agentCommissionAmount':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'fmlsCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'remaxCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'randyCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'tamraCmnCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'agentCmnCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'gsmCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        switch(field){
            case 'status':
                break;
            case 'relationship':
                break;
            case 'type':
                break;
            case 'leadSource':
                break;
            // case 'earnestMoneyTotal':
            //     value = value.replace(/[^0-9.]/, '')
            //
            //     if(value.indexOf('.') > -1){
            //         value = value.substring(0,value.indexOf('.')+3);
            //     }
            //     break;
            case 'earnestMoneyRemaxTru':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'earnestMoneyOther':
                break;
            case 'earnestMoneyOtherAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'optionMoney':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'optionMoneyNotes':
                break;
            case 'sellerPaid':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'lenderPaid':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'buyerPaid':
                break;
            case 'signReturned':
                break;
            case 'reasonSignNotReturned':
                break;
            case 'newNote':
                break;
            case 'builder':
                break;
            case 'combinedCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'totalCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'totalCommissionAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'tamraCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'agentCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'tamraCommissionAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'agentCommissionAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'fmlsCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'remaxCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'randyCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'tamraCmnCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'agentCmnCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'gsmCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        if(field === 'listingBrokerMode'){
            if(value === 'tw'){
                this.setState({[field]: value, listingBrokerId: this.state.remaxTruId});
            } else {
                this.setState({[field]: value});
            }
        } else {
            this.setState({[field]: value});
        }
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        this.setState({[field]: !value});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    editBuyer(buyer){
        this.setState({selectedContact: buyer, showContactDialog: true, contactDialogMode: 'buyer'})
    }

    editLessee(lessee){
        this.setState({selectedContact: lessee, showContactDialog: true, contactDialogMode: 'lessee'})
    }

    updateListingNotes(updates){
        const successMessage = 'Successfully updated Notes!';
        const listingId = this.props.listingId;

        request(
            'POST',
            '/Manage/updateListingNotes',
            {
                listingId: listingId,
                notes: {updates}
            },
            (data) => {
                this.setState(
                    {savingListing: false, notes: {updates}},
                    () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    render() {
        const {classes} = this.props;

        const {
            readOnlyMode,
            loadingAttorneys,
            loadingListing,
            loadingLeadSources,
            loadingBrokers,
            loadingAgents,
            loadingContacts,
            savingListing,
            attorneys,
            attorneyMode,
            brokers,
            agents,
            leadSourceMode,
            hasSellingBroker,
            listingBrokerMode,
            sellingBrokerMode,
            existingAttorney,
            existingSellingBroker,
            existingListingBroker,
        } = this.state;

        const {
            constructionSummary,
            constructionStage,
            builder,
            property,
            relationship,
            type,
            status,
            buyers,
            lessees,
            showContactDialog,
            contactDialogMode,
            selectedContact,
            seller,
            lessor,
            contacts,

            listingBroker,
            sellingBroker,
            listingBrokerId,
            sellingBrokerId,

            agentsAtContract,
            agentsAtClosing,
            selectedAgentId,

            leadSources,
            leadSource,

            closeDate,
            contract,

            sellerPaid,
            lenderPaid,
            buyerPaid,

            combinedCommissionPercentage,
            totalCommissionPercentage,
            totalCommissionAmount,
            tamraCommissionPercentage,
            agentCommissionPercentage,
            tamraCommissionAmount,
            agentCommissionAmount,
            fmlsCommission,
            remaxCommission,
            randyCommission,
            tamraCmnCommission,
            agentCmnCommission,
            gsmCommission,
            attorneyId,
            attorney,

            signReturned,
            reasonSignNotReturned,
            closingPhotosReceived,
            notes,
            newNote,
            showUnlockDialog,
            unlocked
        } = this.state;

        const unlockDialogBody = (
            <UnlockEditMode summary={property} unlock={() => this.setState({unlocked: true, showUnlockDialog: false})} />
        );

        //contactDialogBody
        const contactDialogBody = (
            <div>
                {contactDialogMode === 'buyer' &&
                <Contact contactType="Buyer" readOnlyMode={savingListing} contacts={null} hideContactType={true} saveBtnText="SAVE" contact={selectedContact} save={(c) => this.updateContact(c,'buyer')} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                }
                {contactDialogMode === 'lessee' &&
                <Contact contactType="Lessee" readOnlyMode={savingListing} contacts={null} hideContactType={true} saveBtnText="SAVE" contact={selectedContact} save={(c) => this.updateContact(c,'lessee')} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                }
            </div>
        );

        const typeRelationshipStatus = (
            <div>
                <Divider className={classes.divider} />
                <Typography variant="h6">Listing Status</Typography>

                <FormControl className={classes.dropdown} required>
                    <InputLabel htmlFor="listing-status-select">Status</InputLabel>
                    <Select
                        id="listing-status-select"
                        name="status"
                        value={status}
                        onChange={(e) => this.handleSelectChange(e)}
                        disabled={readOnlyMode || status === 'Terminated' || status === 'Closed'}
                    >
                        <MenuItem value="Under Contract">Under Contract</MenuItem>
                        <MenuItem value="Active">Active</MenuItem>
                        {status === 'Closed' &&
                        <MenuItem value="Closed">Closed</MenuItem>
                        }
                        {status === 'Terminated' &&
                        <MenuItem value="Terminated">Terminated</MenuItem>
                        }
                    </Select>
                </FormControl>
                <br/>
                <br/>
                <Button disabled={!this.areFieldsValid(['status']) || status === 'Terminated' || status === 'Closed'} variant="contained" color="primary" onClick={() => this.updateStatus()} className={classes.button}>SAVE</Button>
            </div>
        );

        const builderSection = (
            <div>
                {(type === 'New Construction' && relationship === 'Seller') &&
                <div>
                    <Divider className={classes.divider} />
                    <Typography variant="h6">Linked Property Details</Typography>
                    <div className={classes.readOnly}>
                        {/*<div className={classes.roTitle}></div>*/}
                        <div className={classes.roValue}>{constructionSummary||property}</div>
                    </div>
                </div>
                }
                {(type === 'Resale' || relationship === 'Buyer') &&
                <div>
                    <Divider className={classes.divider} />
                    <Typography variant="h6">Builder</Typography>

                    <TextField autoComplete="new"
                        value={builder}
                        name="builder"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Builder Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('builder')}
                        helperText={this.fieldErrorMessage('builder')}
                        // required
                        disabled={readOnlyMode}
                    />
                    <br/>
                    <br/>
                    <Button
                        disabled={!this.areFieldsValid(['builder'])}
                        variant="contained"
                        color="primary"
                        onClick={() => this.updateBuilder()}
                        className={classes.button}
                    >SAVE</Button>
                </div>
                }
            </div>
        );

        const listingBrokerSection = (
            <div>
                <Divider className={classes.divider} />
                <Typography variant="h6">Listing Broker</Typography>

                {existingListingBroker ? (
                    <div>
                        <Broker readOnlyMode={readOnlyMode} saveBtnText="SAVE" broker={listingBroker} save={(b) => this.updateListingBroker(b)} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                    </div>
                ) : (
                    <div>
                        {loadingBrokers ? (
                            <Loading contained={true}/>
                        ) : (
                            <div>
                                <div>
                                    <FormControl component="fieldset" className={classes.formControl} disabled={readOnlyMode}>
                                        <FormLabel component="legend">Listing Broker</FormLabel>
                                        <RadioGroup row aria-label="listingBrokerMode" name="listingBrokerMode" value={listingBrokerMode} onChange={(e) => this.handleSelectChange(e)}>
                                            <FormControlLabel value="tw" control={<Radio/>} label="RE/MAX TRU"/>
                                            <FormControlLabel value="existing" control={<Radio/>} label="Select Listing Broker"/>
                                            <FormControlLabel value="new" control={<Radio/>} label="New Listing Broker"/>
                                        </RadioGroup>
                                    </FormControl>
                                    {listingBrokerMode === 'tw' &&
                                    <div>
                                        <Button disabled={(listingBrokerId || null) === null || readOnlyMode}
                                                variant="contained" color="primary"
                                                onClick={() => this.addListingBrokerToListing()}
                                                className={classes.button}>SAVE</Button>
                                    </div>
                                    }
                                    {listingBrokerMode === 'existing' &&
                                    <div>
                                        {loadingBrokers ? (
                                            <Loading contained={true}/>
                                        ) : (
                                            <div>
                                                <FormControl className={classes.dropdown}>
                                                    <Select
                                                        id="listing-broker-select"
                                                        name="listingBrokerId"
                                                        value={listingBrokerId}
                                                        onChange={(e) => this.handleSelectChange(e)}
                                                        disabled={readOnlyMode}
                                                    >
                                                        {brokers.map((item, index) => (
                                                            <MenuItem key={'listing-broker-' + item.brokerId}
                                                                      value={item.brokerId}>{item.firm}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <br/>
                                                <Button disabled={(listingBrokerId || null) === null || readOnlyMode}
                                                        variant="contained" color="primary"
                                                        onClick={() => this.addListingBrokerToListing()}
                                                        className={classes.button}>SAVE</Button>
                                            </div>
                                        )}
                                    </div>
                                    }
                                    {listingBrokerMode === 'new' &&
                                        <Broker readOnlyMode={readOnlyMode} saveBtnText="SAVE" save={(b) => this.addListingBroker(b)} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                                    }
                                </div>

                            </div>
                        )}
                    </div>
                )}
            </div>
        );

        const sellingBrokerSection = (
            <div>
                {type !== 'Lease' &&
                    <div>
                        <Divider className={classes.divider} />
                        <Typography variant="h6">Selling Broker</Typography>

                        <div>
                            <br/>
                            {(!(_.isEmpty(sellingBroker)) || sellingBrokerId !== '') ? (
                                <Button disabled={readOnlyMode} variant="contained" color="primary" onClick={() => this.removeSellingBroker()} className={classes.button}>REMOVE SELLING BROKER</Button>
                            ) : (
                                <FormControlLabel
                                    className={classes.switch}
                                    control={
                                        <Switch
                                            name="hasSellingBroker"
                                            checked={hasSellingBroker}
                                            onChange={(e) => this.handleSwitchChange(e)}
                                            color="primary"
                                            disabled={readOnlyMode}
                                        />
                                    }
                                    label="Does this Listing have a Selling Broker?"
                                />
                            )}
                        </div>

                        {existingSellingBroker ? (
                            <div>
                                {hasSellingBroker &&
                                <div>
                                    <Broker readOnlyMode={readOnlyMode} saveBtnText="SAVE" broker={sellingBroker} save={(b) =>  this.updateSellingBroker(b)} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                                </div>
                                }
                            </div>
                        ) : (
                            <div>
                                {hasSellingBroker &&
                                <div>
                                    {loadingBrokers ? (
                                        <Loading contained={true}/>
                                    ) : (
                                        <div>
                                            <FormControl component="fieldset" className={classes.formControl} disabled={readOnlyMode}>
                                                <FormLabel component="legend">Selling Broker</FormLabel>
                                                <RadioGroup row aria-label="sellingBrokerMode" name="sellingBrokerMode" value={sellingBrokerMode} onChange={(e) => this.handleSelectChange(e)}>
                                                    <FormControlLabel value="existing" control={<Radio/>} label="Select Selling Broker"/>
                                                    <FormControlLabel value="new" control={<Radio/>} label="New Selling Broker"/>
                                                </RadioGroup>
                                            </FormControl>
                                            {sellingBrokerMode === 'existing' ? (
                                                <div>
                                                    {loadingBrokers ? (
                                                        <Loading contained={true}/>
                                                    ) : (
                                                        <div>
                                                            <FormControl className={classes.dropdown}>
                                                                <Select
                                                                    id="selling-broker-select"
                                                                    name="sellingBrokerId"
                                                                    value={sellingBrokerId}
                                                                    onChange={(e) => this.handleSelectChange(e)}
                                                                    disabled={readOnlyMode}
                                                                >
                                                                    {brokers.map((item, index) => (
                                                                        <MenuItem key={'selling-broker-'+item.brokerId} value={item.brokerId}>{item.firm}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            <br/>
                                                            <Button disabled={(sellingBrokerId || null) === null || readOnlyMode} variant="contained" color="primary" onClick={() => this.addSellingBrokerToListing()} className={classes.button}>SAVE</Button>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <Broker readOnlyMode={readOnlyMode} saveBtnText="SAVE" save={(b) =>  this.addSellingBroker(b)} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                                            )}
                                        </div>
                                    )}
                                </div>
                                }
                            </div>
                        )}
                    </div>
                }
            </div>
        );

        const buyer = (
            <div>
                <strong>Buyer</strong>
                <Contact
                    contactType="Buyer"
                    hideContactType={true}
                    saveBtnText="ADD BUYER"
                    contacts={contacts}
                    readOnlyMode={readOnlyMode}
                    save={(c) => this.addBuyer(c)}
                    showSnack={(t,e) => this.props.showSnack(t,e)}
                />
                <br/>
                <div className={classes.contactWrapper}>
                    {buyers.length !== 0 &&
                    <div>
                        {buyers.map((item, index) => (
                            <Chip key={'buyer-chip-' + index}
                                  label={(item.company||null) !== null ? item.company : `${item.firstName} ${item.lastName}`}
                                  onDelete={() => this.removeBuyer(item.contactId)}
                                  icon={<EditIcon />}
                                  onClick={() => this.editBuyer(item)}
                                  color="primary"
                                  variant="outlined"
                                  disabled={readOnlyMode}
                            />
                        ))}
                    </div>
                    }
                </div>
            </div>
        );

        const lessee = (
            <div>
                <strong>Lessee</strong>
                <Contact
                    contactType="Lessee"
                    hideContactType={true}
                    saveBtnText="ADD LESSEE"
                    contacts={contacts}
                    readOnlyMode={readOnlyMode}
                    save={(c) => this.addLessee(c)}
                    showSnack={(t,e) => this.props.showSnack(t,e)}
                />
                <br/>
                <div className={classes.contactWrapper}>
                    {lessees.length !== 0 &&
                    <div>
                        {lessees.map((item, index) => (
                            <Chip key={'lessee-chip-' + index}
                                  label={(item.company||null) !== null ? item.company : `${item.firstName} ${item.lastName}`}
                                  onDelete={() => this.removeLessee(item.contactId)}
                                  icon={<EditIcon />}
                                  onClick={() => this.editLessee(item)}
                                  color="primary"
                                  variant="outlined"
                                  disabled={readOnlyMode}
                            />
                        ))}
                    </div>
                    }
                </div>
            </div>
        );

        const parties = (
            <div>
                <Divider className={classes.divider} />
                <Typography variant="h6">Parties</Typography>

                {loadingContacts ? (
                    <Loading contained={true}/>
                ) : (
                    <div>
                        {(relationship === 'Seller' || relationship === 'Seller and Buyer') &&
                        <div>
                            <strong>Seller</strong>
                            <Contact contactType="Seller" contacts={contacts} readOnlyMode={readOnlyMode} hideContactType={true} saveBtnText="SAVE" contact={seller} save={(c) => this.updateContact(c,'seller')} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                            <br/>
                            {buyer}
                        </div>
                        }
                        {relationship === 'Buyer' &&
                        <div>
                            {buyer}
                            <br/>
                            <strong>Seller</strong>
                            <Contact contactType="Seller" contacts={contacts} readOnlyMode={readOnlyMode} hideContactType={true} saveBtnText="SAVE" contact={seller} save={(c) => this.updateContact(c,'seller')} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                        </div>
                        }
                        {relationship === 'Lessor' &&
                        <div>
                            <strong>Lessor</strong>
                            <Contact contactType="Lessor" contacts={contacts} readOnlyMode={readOnlyMode} hideContactType={true} saveBtnText="SAVE" contact={lessor} save={(c) => this.updateContact(c,'lessor')} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                            <br/>
                            {lessee}
                        </div>
                        }
                        {relationship === 'Lessee' &&
                        <div>
                            {lessee}
                            <br/>
                            <strong>Lessor</strong>
                            <Contact contactType="Lessor" contacts={contacts} readOnlyMode={readOnlyMode} hideContactType={true} saveBtnText="SAVE" contact={lessor} save={(c) => this.updateContact(c,'lessor')} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                        </div>
                        }
                    </div>
                )}
            </div>
        );

        const agentSection = (
            <div>
                <Divider className={classes.divider} />
                <Typography variant="h6">Agents</Typography>

                {loadingAgents ? (
                    <Loading contained={true}/>
                ) : (
                    <div>
                        {(status !== 'Terminated' && (status !== 'Closed' || (status === 'Closed' && !readOnlyMode))) &&
                            <div>
                                <FormControl className={classes.dropdown}>
                                    {/*<InputLabel id="agent-select-label">Agents at Contract</InputLabel>*/}
                                    <Select
                                        id="agent-select"
                                        name="selectedAgentId"
                                        value={selectedAgentId}
                                        onChange={(e) => this.handleSelectChange(e)}
                                        disabled={readOnlyMode}
                                    >
                                        {agents.map((item, index) => (
                                            <MenuItem key={'agent-select-'+item.agentId} value={item.agentId}>{item.licenseeName}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>Select an Agent you would like to Add</FormHelperText>
                                </FormControl>
                                <br/>
                                <Button variant="outlined" size="small" color="primary" disabled={(selectedAgentId === '' || loadingAgents || readOnlyMode)} onClick={() => this.addAgentAtContract()} className={classes.button} startIcon={<AddIcon />}>AT CONTRACT</Button>
                                <Button variant="outlined" size="small" color="primary" disabled={(selectedAgentId === '' || loadingAgents || readOnlyMode)} onClick={() => this.addAgentAtClosing()} className={classes.button} startIcon={<AddIcon />}>AT CLOSING</Button>
                            </div>
                        }
                    </div>
                )}

                <Divider className={classes.divider} />
                <strong>Agents At Contract</strong>
                <br/>
                <div className={classes.agentWrapper}>
                    {agentsAtContract.length !== 0 ? (
                        <div>
                            {agentsAtContract.map((item, index) => (
                                <Chip
                                    // icon={<EditIcon />}
                                    // onClick={() => this.editContractAgent(item.agentId)}
                                    key={'agent-contract-chip-'+item.agentId}
                                    label={item.licenseeName}
                                    onDelete={() => this.removeAgentAtContract(item.agentId)}
                                    color="primary"
                                    variant="outlined"
                                    disabled={readOnlyMode}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className={classes.margin}>No Agents At Contract..</div>
                    )}
                </div>
                <Divider className={classes.divider} />
                <strong>Agents At Close</strong>
                <br/>
                <div className={classes.agentWrapper}>
                    {agentsAtClosing.length !== 0 ? (
                        <div>
                            {agentsAtClosing.map((item, index) => (
                                <Chip
                                    // icon={<EditIcon />}
                                    // onClick={() => this.editCloseAgent(item.agentId)}
                                    key={'agent-close-chip-'+item.agentId}
                                    label={item.licenseeName}
                                    onDelete={() => this.removeAgentAtClosing(item.agentId)}
                                    color="primary"
                                    variant="outlined"
                                    disabled={readOnlyMode}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className={classes.margin}>No Agents At Closing..</div>
                    )}
                </div>
            </div>
        );

        const attorneySection = (
            <div>
                {type !== 'Lease' &&
                    <div>
                        <Divider className={classes.divider} />
                        <Typography variant="h6">Attorney</Typography>

                        {existingAttorney ? (
                            <div>
                                <Attorney readOnlyMode={readOnlyMode} saveBtnText="SAVE" attorney={attorney} save={(a) => this.setState({savingListing: true}, () => this.updateAttorney(a))} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                            </div>
                        ) : (
                            <div>
                                {loadingAttorneys ? (
                                    <Loading contained={true}/>
                                ) : (
                                    <div>
                                        <FormControl component="fieldset" className={classes.formControl} disabled={readOnlyMode}>
                                            <FormLabel component="legend">Attorney</FormLabel>
                                            <RadioGroup row aria-label="attorneyMode" name="attorneyMode" value={attorneyMode} onChange={(e) => this.handleSelectChange(e)}>
                                                <FormControlLabel value="existing" control={<Radio/>} label="Select Attorney"/>
                                                <FormControlLabel value="new" control={<Radio/>} label="New Attorney"/>
                                            </RadioGroup>
                                        </FormControl>
                                        {attorneyMode === 'existing' &&
                                        <div>
                                            {loadingAttorneys ? (
                                                <Loading contained={true}/>
                                            ) : (
                                                <div>
                                                    <FormControl className={classes.dropdown}>
                                                        <Select
                                                            id="attorney-select"
                                                            name="attorneyId"
                                                            value={attorneyId}
                                                            onChange={(e) => this.handleSelectChange(e)}
                                                            disabled={readOnlyMode}
                                                        >
                                                            {attorneys.map((item, index) => (
                                                                <MenuItem key={item.attorneyId} value={item.attorneyId}>{item.name}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <br/>
                                                    <Button disabled={(attorneyId || null) === null || readOnlyMode} variant="contained" color="primary" onClick={() => this.addAttorneyToListing()} className={classes.button}>SAVE</Button>
                                                </div>
                                            )}
                                        </div>
                                        }
                                        {attorneyMode === 'new' &&
                                        <Attorney readOnlyMode={readOnlyMode} saveBtnText="SAVE" save={(a) => this.setState({savingListing: true}, () => this.addAttorney(a))} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                }
            </div>
        );

        const closingCost = (
            <div>
                {type !== 'Lease' &&
                <div>
                    <Divider className={classes.divider} />
                    <Typography variant="h6">Closing Cost</Typography>
                    <br/>
                    <TextField autoComplete="new"
                       name="sellerPaid"
                       value={sellerPaid}
                       onChange={(e) => this.handleFieldChange(e)}
                       label="Seller Paid"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('sellerPaid')}
                       helperText={this.fieldErrorMessage('sellerPaid')}
                       InputProps={{
                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                       }}
                       disabled={readOnlyMode}
                    />
                    <br/>
                    <TextField autoComplete="new"
                       name="lenderPaid"
                       value={lenderPaid}
                       onChange={(e) => this.handleFieldChange(e)}
                       label="Lender Paid"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('lenderPaid')}
                       helperText={this.fieldErrorMessage('lenderPaid')}
                       InputProps={{
                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                       }}
                       disabled={readOnlyMode}
                    />
                    <br/>
                    <FormControl component="fieldset" className={classes.formControl} disabled={readOnlyMode}>
                        <FormLabel component="legend">Buyer Paid</FormLabel>
                        <RadioGroup row aria-label="buyerPaid" name="buyerPaid" value={buyerPaid} onChange={(e) => this.handleSelectChange(e)}>
                            <FormControlLabel value="N/A" control={<Radio/>} label="N/A"/>
                            <FormControlLabel value="All" control={<Radio/>} label="All"/>
                        </RadioGroup>
                    </FormControl>
                    <br/>
                    <Button disabled={!this.areFieldsValid(['sellerPaid','lenderPaid','buyerPaid'])} variant="contained" color="primary" onClick={() => this.updateClosingCost()} className={classes.button}>SAVE</Button>
                </div>
                }
            </div>
        );

        const commissions = (
            <div>
                {type !== 'Lease' &&
                    <div>
                        <Divider className={classes.divider} />
                        <Typography variant="h6">Commissions</Typography>

                        <TextField autoComplete="new"
                            value={combinedCommissionPercentage}
                            name="combinedCommissionPercentage"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Combined Total %"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('combinedCommissionPercentage')}
                            helperText={this.fieldErrorMessage('combinedCommissionPercentage')}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            value={totalCommissionPercentage}
                            name="totalCommissionPercentage"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="TRU Total %"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('totalCommissionPercentage')}
                            helperText={this.fieldErrorMessage('totalCommissionPercentage')}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            name="totalCommissionAmount"
                            value={totalCommissionAmount}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="TRU Total $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('totalCommissionAmount')}
                            helperText={this.fieldErrorMessage('totalCommissionAmount')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            value={tamraCommissionPercentage}
                            name="tamraCommissionPercentage"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Tamra %"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('tamraCommissionPercentage')}
                            helperText={this.fieldErrorMessage('tamraCommissionPercentage')}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            name="tamraCommissionAmount"
                            value={tamraCommissionAmount}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Tamra $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('tamraCommissionAmount')}
                            helperText={this.fieldErrorMessage('tamraCommissionAmount')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            value={agentCommissionPercentage}
                            name="agentCommissionPercentage"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Agent %"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('agentCommissionPercentage')}
                            helperText={this.fieldErrorMessage('agentCommissionPercentage')}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            name="agentCommissionAmount"
                            value={agentCommissionAmount}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Agent $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('agentCommissionAmount')}
                            helperText={this.fieldErrorMessage('agentCommissionAmount')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            name="fmlsCommission"
                            value={fmlsCommission}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="FMLS $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('fmlsCommission')}
                            helperText={this.fieldErrorMessage('fmlsCommission')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            name="remaxCommission"
                            value={remaxCommission}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Remax $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('remaxCommission')}
                            helperText={this.fieldErrorMessage('remaxCommission')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            name="randyCommission"
                            value={randyCommission}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Randy $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('randyCommission')}
                            helperText={this.fieldErrorMessage('randyCommission')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                           name="tamraCmnCommission"
                           value={tamraCmnCommission}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Tamra CMN $"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('tamraCmnCommission')}
                           helperText={this.fieldErrorMessage('tamraCmnCommission')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                           disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                           name="agentCmnCommission"
                           value={agentCmnCommission}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Agent CMN $"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('agentCmnCommission')}
                           helperText={this.fieldErrorMessage('agentCmnCommission')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                           disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                           name="gsmCommission"
                           value={gsmCommission}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="GSM $"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('gsmCommission')}
                           helperText={this.fieldErrorMessage('gsmCommission')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                           disabled={readOnlyMode}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            value={newNote}
                            name="newNote"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Notes"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('newNote')}
                            helperText={this.fieldErrorMessage('newNote')}
                            disabled={readOnlyMode}
                        />
                        <br/>
                        <Log readOnlyMode={readOnlyMode} save={(u) => this.setState({savingListing: true},() => this.updateListingNotes(u))} title="Notes" log={notes} type="listing" noUpdatesText="No notes found.." />
                        <Divider className={classes.divider} />
                        <br/>
                        <Button disabled={!this.areFieldsValid([
                            'combinedCommissionPercentage',
                            'totalCommissionPercentage',
                            'totalCommissionAmount',
                            'tamraCommissionPercentage',
                            'agentCommissionPercentage',
                            'tamraCommissionAmount',
                            'agentCommissionAmount',
                            'fmlsCommission',
                            'remaxCommission',
                            'randyCommission',
                            'tamraCmnCommission',
                            'agentCmnCommission',
                            'gsmCommission',
                            'newNote'
                        ])} variant="contained" color="primary" onClick={() => this.updateCommissions()} className={classes.button}>SAVE</Button>
                    </div>
                }
            </div>
        );

        const other = (
            <div>
                <Divider className={classes.divider} />
                <Typography variant="h6">Additional Listing Info</Typography>

                <FormControl className={classes.dropdown}>
                    <InputLabel htmlFor="listing-signReturned-select">Sign Returned</InputLabel>
                    <Select
                        id="listing-signReturned-select"
                        name="signReturned"
                        value={signReturned}
                        onChange={(e) => this.handleSelectChange(e)}
                        disabled={readOnlyMode}
                    >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="Lost">Lost</MenuItem>
                        <MenuItem value="N/A">N/A</MenuItem>
                    </Select>
                </FormControl>
                <br/>
                {signReturned === 'No' &&
                <div>
                    <TextField autoComplete="new"
                        value={reasonSignNotReturned}
                        name="reasonSignNotReturned"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Reason Not Returned"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('reasonSignNotReturned')}
                        helperText={this.fieldErrorMessage('reasonSignNotReturned')}
                        required
                        disabled={readOnlyMode}
                    />
                    <br/>
                </div>
                }
                {type === 'New Construction' &&
                <div>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="closingPhotosReceived"
                                checked={closingPhotosReceived}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                                disabled={readOnlyMode}
                            />
                        }
                        label="Closing Photos Received?"
                    />
                    <br/>
                </div>
                }
                <div>
                    <br/>
                    {loadingLeadSources ? (
                        <Loading contained={true}/>
                    ) : (
                        <div>
                            {leadSources.length > 0 &&
                            <div>
                                <FormControlLabel
                                    className={classes.switch}
                                    control={
                                        <Switch
                                            name="leadSourceMode"
                                            checked={leadSourceMode === 'new'}
                                            onChange={(e) => this.handleSwitchChange(e)}
                                            color="primary"
                                            disabled={readOnlyMode}
                                        />
                                    }
                                    label="Enter Lead Source Manually?"
                                />
                                <br/>
                            </div>
                            }
                            {(leadSources.length > 0 && leadSourceMode === 'existing') ? (
                                <FormControl className={classes.dropdown}>
                                    <InputLabel htmlFor="leadSource-select">Lead Source</InputLabel>
                                    <Select
                                        id="leadSource-select"
                                        name="leadSource"
                                        value={leadSource}
                                        onChange={(e) => this.handleSelectChange(e)}
                                        disabled={readOnlyMode}
                                    >
                                        {leadSources.map((item, index) => (
                                            <MenuItem key={'lead-source-'+index} value={item.name}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <TextField autoComplete="new"
                                    value={leadSource}
                                    name="leadSource"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Lead Source"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('leadSource')}
                                    helperText={this.fieldErrorMessage('leadSource')}
                                    disabled={readOnlyMode}
                                />
                            )}
                            <br/>
                        </div>
                    )}
                </div>
                <br/>
                <br/>
                <Button disabled={!this.areFieldsValid(['signReturned','reasonSignNotReturned','closingPhotosReceived','leadSource'])} variant="contained" color="primary" onClick={() => this.updateAdditional()} className={classes.button}>SAVE</Button>
            </div>
        );

        return (
            <div className={classes.root}>
                <div>
                    {loadingListing ? (
                        <Loading text="Loading..."/>
                    ) :(
                        <div>
                            <div className={classes.editButton}>
                                {(readOnlyMode && ((status !== 'Terminated' && status !== 'Closed') || unlocked)) &&
                                <Fab size="small" color="primary" aria-label="edit" className={classes.margin} onClick={() => this.setState({readOnlyMode: false}, () => this.getAll())}>
                                    <EditIcon/>
                                </Fab>
                                }
                                {(readOnlyMode && (status === 'Terminated' || status === 'Closed') && !unlocked) &&
                                <Fab size="small" color="primary" aria-label="unlock" className={classes.margin}
                                     onClick={() => this.setState({showUnlockDialog: true})}>
                                    <LockIcon/>
                                </Fab>
                                }
                            </div>
                            {(type === 'Lease') &&
                            <div className={classes.readOnly}>
                                <div className={classes.roTitle}>Property</div>
                                <div className={classes.roValue}>{property}</div>
                            </div>
                            }
                            {typeRelationshipStatus}
                            {builderSection}
                            {listingBrokerSection}
                            {sellingBrokerSection}
                            {parties}
                            {agentSection}
                            {attorneySection}
                            <Divider className={classes.divider} />
                            <Typography variant="h6">Close Date</Typography>
                            <UpdateCloseDate stage={constructionStage} isLease={type === 'Lease'} underContract={status === 'Under Contract' && type === 'New Construction'} isTerminated={status === 'Terminated'} isClosed={status === 'Closed'} builder={builder} closeDate={closeDate} save={(c) => this.setState({savingListing: true}, () => this.updateCloseDate(c))} readOnlyMode={readOnlyMode} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                            <Divider className={classes.divider} />
                            <Typography variant="h6">Contract</Typography>
                            <UpdateContract parentType="listing" underContract={status === 'Under Contract' && type === 'New Construction'} contract={contract} builder={builder} save={(c) => this.setState({savingListing: true}, () => this.updateContract(c))} saveMonies={(m) => this.setState({savingListing: true}, () => this.updateMonies(m))} readOnlyMode={readOnlyMode} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                            {closingCost}
                            {commissions}
                            {other}
                            {savingListing &&
                            <Loading text="Saving..."/>
                            }
                        </div>
                    )}
                </div>
                <Dialog closeCallback={() => this.setState({showContactDialog: false, contactDialogMode: '', selectedContact: null})} showDialog={showContactDialog} title={'Edit ' + contactDialogMode} bodyHtml={contactDialogBody} />
                <Dialog closeCallback={() => this.setState({showUnlockDialog: false})} showDialog={showUnlockDialog} title={'Unlock Editing'} bodyHtml={unlockDialogBody} />
            </div>
        );
    }
}

export default withStyles(styles)(UpdateListing);