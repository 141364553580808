import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import AddLink from "./AddLink";
import LinkSummary from "../LinkSummary";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    inlineButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    categories: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    header: {
        margin: theme.spacing(1) + 'px 0',
        color: '#555',
        fontSize: 20,
        textTransform: 'uppercase',
        fontWeight: 400
    },
    divider: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    dividerText: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: '#555',
        fontSize: 18,
        textTransform: 'uppercase',
        fontWeight: 300
    },
    dividerLine: {
        flex: 1,
        borderBottom: 'solid #eeeeee 2px'
    },
    addLink: {
        // textAlign: 'right'
    },
    upper: {
        textTransform: 'uppercase'
    }
});

class UpdateTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            newCategoryName: '',
            categories: [],
            linkMode: 'new',
            links: [],
            isLoading: true,
            validations: []
        };
    }

    componentDidMount() {
        const templateId = this.props.templateId;
        this.getTemplate(templateId);
    }

    getTemplate(templateId){
        request(
            'GET',
            '/Admin/getLandingPageTemplate?landingPageTemplateId=' + templateId,
            null,
            (data) => {
                this.setState({name: data.template.name, categories: data.template.categories}, () => this.getLinks());
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error));
            })
    }

    getLinks(){
        request(
            'GET',
            '/Admin/getLandingPageLinks',
            null,
            (data) => {
                this.setState({links: data.links, isLoading: false});
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error));
            })
    }

    validateAll(){
        const fieldsToValidate = [
            'name',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(
                fieldsToValidate[i],
                this.state[fieldsToValidate[i]] || null
            );
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value){
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'name':
                isValid = validateField(value);
                invalidationReason = 'The name of the Template is required';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    };

    isValid() {
        const categories = this.state.categories||[];
        if(categories.length === 0){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    isCategoryValid(){
        const {newCategoryName,categories,isLoading} = this.state;
        return !isLoading && (newCategoryName||null) !== null && _.findIndex(categories,{name: newCategoryName}) === -1
    }

    save() {
        const {name,categories} = this.state;
        const templateId = this.props.templateId;

        const action = '/Admin/updateLandingPageTemplate';
        const data = {name, categories, landingPageTemplateId: templateId};
        const successMessage = 'Successfully Updated Template!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({isLoading: false}, () => this.props.saved(successMessage))
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error))
            })
    }

    addCategory() {
        const newCategoryName = this.state.newCategoryName;
        const categories = _.cloneDeep(this.state.categories);
        categories.push({
            name: newCategoryName,
            links: [],
            order: categories.length + 1
        });
        this.setState({categories,newCategoryName: ''})
    }

    addLink(link,categoryName) {
        const categories = _.cloneDeep(this.state.categories);
        const i = _.findIndex(categories,{name:categoryName});
        link.order = categories[i].links.length + 1;
        categories[i].links.push(link);
        this.setState({categories})
    }

    removeLink(linkTitle,categoryName,landingPageLinkId,landingPageCategoryId) {
        const categories = _.cloneDeep(this.state.categories);
        const categoryIndex = _.findIndex(categories,{name:categoryName});
        if(categoryIndex !== -1){
            if((landingPageLinkId||null) !== null){
                const action = '/Admin/removeLandingPageLink';
                const data = {landingPageLinkId,landingPageCategoryId};
                const successMessage = 'Successfully Removed Link!';

                request(
                    'POST',
                    action,
                    data,
                    (data) => {
                        const category = categories[categoryIndex];
                        const linkIndex = _.findIndex(category.links,{title:linkTitle});
                        category.links.splice(linkIndex,1);
                        for(let i = 0; i < category.links.length; i++){
                            category.links[i].order = i+1;
                        }
                        this.setState({isLoading: false,categories}, () => this.props.showSnack('success',successMessage))
                    },
                    (error) => {
                        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
                    })
            } else {
                const category = categories[categoryIndex];
                const linkIndex = _.findIndex(category.links,{title:linkTitle});
                category.links.splice(linkIndex,1);
                for(let i = 0; i < category.links.length; i++){
                    category.links[i].order = i+1;
                }
                this.setState({isLoading: false,categories})
            }
        }
    }

    moveLink(direction,linkTitle,categoryName){
        const categories = _.cloneDeep(this.state.categories);
        const categoryIndex = _.findIndex(categories,{name:categoryName});
        if(categoryIndex !== -1){
            const category = categories[categoryIndex];
            const linkIndex = _.findIndex(category.links,{title:linkTitle});
            if(direction === 'up'){
                const linkBefore = _.cloneDeep(category.links[linkIndex-1]);
                const link = _.cloneDeep(category.links[linkIndex]);
                category.links[linkIndex] = linkBefore;
                category.links[linkIndex-1] = link;
            }
            if(direction === 'down'){
                const linkAfter = _.cloneDeep(category.links[linkIndex+1]);
                const link = _.cloneDeep(category.links[linkIndex]);
                category.links[linkIndex] = linkAfter;
                category.links[linkIndex+1] = link;
            }
            for(let i = 0; i < category.links.length; i++){
                category.links[i].order = i+1;
            }
            this.setState({categories})
        }
    }

    editLink(updatedLink,link,categoryName,message){
        const categories = _.cloneDeep(this.state.categories);
        const categoryIndex = _.findIndex(categories,{name:categoryName});
        if(categoryIndex !== -1){
            const category = categories[categoryIndex];
            const linkIndex = _.findIndex(category.links,{title:link.title});
            category.links[linkIndex] = updatedLink;
            this.setState({categories}, () => this.props.showSnack('success',message))
        }
    }

    // uuidv4() {
    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //         var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    //         return v.toString(16);
    //     });
    // }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE TEMPLATE';
        const type = this.props.type;

        const {
            name,
            newCategoryName,
            categories,
            links,
            linkMode,
            isLoading
        } = this.state;
        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                               value={name}
                               name="name"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Template Name"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('name')}
                               helperText={this.fieldErrorMessage('name')}
                               required
                               disabled={type === 'user'}
                    />
                </div>
                <br/>
                <div className={classes.categories}>
                    <div className={classes.header}>Categories</div>
                    <br/>
                    {(categories || null) !== null &&
                    categories.map((category,index) =>
                        <div key={'cat-'+index}>
                            <div className={classes.divider}>
                                <div className={classes.dividerLine}/>
                                <div className={classes.dividerText}>{category.name}</div>
                                <div className={classes.dividerLine}/>
                            </div>
                            {(category.links || null) !== null &&
                            category.links.map((link,linkIndex) =>
                                <LinkSummary index={linkIndex+1} count={category.links.length} landingPageLinkId={link.landingPageLinkId} editable={true} link={link} key={'cat-' + index + '-link-' + linkIndex} remove={() => this.setState({isLoading: true},() => this.removeLink(link.title,category.name,link.landingPageLinkId,category.landingPageCategoryId))} edited={(l,m) => this.editLink(l,link,category.name,m)} moveLink={(d) => this.moveLink(d,link.title,category.name)} showSnack={(t,m) => this.props.showSnack(t,m)} />
                            )}
                            <br/>
                            <div className={classes.addLink}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">Add a Link to Category <strong className={classes.upper}>{category.name}</strong></FormLabel>
                                    <br/>
                                    <RadioGroup row aria-label="linkMode" name="linkMode" value={linkMode} onChange={(e) => this.handleSelectChange(e)}>
                                        <FormControlLabel value="new" control={<Radio/>} label="New Link"/>
                                        <FormControlLabel value="existing" control={<Radio/>} label="Select Existing Link"/>
                                    </RadioGroup>
                                </FormControl>
                                {linkMode === 'new' ? (
                                    <div>
                                        <AddLink key={'new-link-'+index} save={(l) => this.addLink(l,category.name)}/>
                                    </div>
                                ) : (
                                    <div>
                                        <AddLink key={'existing-link-'+index} links={links} categoryLinks={category.links} linkMode="existing" save={(l) => this.addLink(l,category.name)}/>
                                    </div>
                                )}

                            </div>
                        </div>
                    )}
                    {(categories||[]).length === 0 &&
                    <div>No Categories found. Please create one to continue.</div>
                    }
                </div>
                <br/>
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                               value={newCategoryName}
                               name="newCategoryName"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Category Name"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('newCategoryName')}
                               helperText={this.fieldErrorMessage('newCategoryName')}
                               required
                    />
                    <div>
                        <Button size="small" variant="contained" color="primary" disabled={!this.isCategoryValid()} onClick={() => this.addCategory()} className={classes.inlineButton}>ADD CATEGORY</Button>
                    </div>
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.setState({isLoading:true},() => this.save())} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(UpdateTemplate);