import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validatePrice, validateField} from '../../utils'
import _ from 'lodash';
import Loading from "../Loading";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Lender from "./Lender";
import LoanOfficer from "./LoanOfficer";
import Log from "../Log";
import LenderUpdates from "./LenderUpdates";
import CoopAgent from "./CoopAgent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Installment from "./AddInstallment";
import UpdateInstallment from "./UpdateInstallment";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    moneyButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginLeft: 0,
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    installmentWrapper: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > * > *': {
            margin: theme.spacing(0.5),
        },
    },
    payout: {
        '& > span': {
            marginLeft: theme.spacing(2),
        },
    }
});

class UpdateContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLease: false,

            contractId: '',
            price: '',
            closedPrice: null,
            pricingLog: [],
            payment: '', //Preferred Lender, Outside Lender, Cash
            loanType: '',
            proofOfFunds: false, //If Cash - Proof of funds? Yes/No
            dateGenerated: null,
            bindingDate: null,

            lenderId: '',
            lenders: [],
            lender: null,
            newLender: null,
            loadingLenders: false,
            lenderMode: '',
            lenderSaved: false,
            lenderUpdates: [],
            // readOnlyLender: true,

            loanOfficerId: '',
            loanOfficer: null,
            newLoanOfficer: null,
            loanOfficers: [],
            loadingLoanOfficers: false,
            loanOfficerMode: '',
            loanOfficerSaved: false,
            // readOnlyLoanOfficer: true,

            coopAgent: null,
            hasCoopAgent: false,

            moneyId: '',
            earnestMoneyRemaxTru: '',
            earnestMoneyOther: '', //who?
            earnestMoneyOtherAmount: '', //how much?
            optionMoney: '',
            optionMoneyNotes: '',
            installments: [],
            optionInstallments: [],
            payouts: [],

            potentialTerm: false,
            potentialTermReason: '',
            
            savingContract: false,

            validations: []
        };
    }

    componentDidMount() {
        const contract = this.props.contract || {};
        const money = contract.money || {};
        const isLease = this.props.isLease || false;

        if(isLease){
            this.setState({
                contractId: contract.contractId,
                isLease: isLease,
                dateGenerated: contract.dateGenerated || null,
                bindingDate: contract.bindingDate || null,
                hasCoopAgent: (contract.coopAgent||null) !== null,
                potentialTerm: contract.potentialTerm || false,
                potentialTermReason: contract.potentialTermReason || ''
            })
        } else {
            this.setState({
                contractId: contract.contractId,
                isLease: isLease,
                pricingLog: contract.pricingLog || [],
                price: contract.price || '',
                closedPrice: contract.closedPrice || null,
                payment: contract.payment || '',
                loanType: contract.loanType || '',
                proofOfFunds: contract.proofOfFunds || false,
                dateGenerated: contract.dateGenerated || null,
                bindingDate: contract.bindingDate || null,
                lender: contract.lender || null,
                loanOfficer: contract.loanOfficer || null,
                coopAgent: contract.coopAgent || null,
                lenderMode: (contract.lender||null) === null ? 'na' : 'edit',
                loanOfficerMode: (contract.loanOfficer||null) === null ? 'na' : 'edit',
                hasCoopAgent: (contract.coopAgent||null) !== null,
                lenderUpdates: contract.lenderUpdates || [],

                moneyId: money.moneyId || '',
                earnestMoneyRemaxTru: money.earnestMoneyRemaxTru || '',
                earnestMoneyOther: money.earnestMoneyOther || '',
                earnestMoneyOtherAmount: money.earnestMoneyOtherAmount || '',
                optionMoney: money.optionMoney || '',
                optionMoneyNotes: money.optionMoneyNotes || '',
                installments: money.installments || [],
                optionInstallments: money.optionInstallments || [],
                payouts: money.payouts || [],

                potentialTerm: contract.potentialTerm || false,
                potentialTermReason: contract.potentialTermReason || ''
            })
        }
    }

    getLenders() {
        const payment = this.state.payment || '';
        const queryString = payment === 'Preferred Lender' ? '?preferred=true' : '?preferred=false';

        if(this.state.lenders.length > 0) {
            this.setState({loadingLenders: false})
        } else {
            request(
                'GET',
                '/Manage/getLenders' + queryString,
                null,
                (data) => {
                    this.setState({lenders: data.lenders, loadingLenders: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getLoanOfficers(lenderId) {
        if((lenderId||null) === null){
            lenderId = (this.state.lenderId || null) !== null ? this.state.lenderId : this.state.lender !== null && (this.state.lender.parentLenderId||null) !== null ? this.state.lender.parentLenderId : null;
        }

        if(lenderId === null){
            this.setState({loadingLoanOfficers: false})
        }

        request(
            'GET',
            '/Manage/getLoanOfficers?lenderId=' + lenderId,
            null,
            (data) => {
                this.setState({loanOfficers: data.loanOfficers || [], loadingLoanOfficers: false})
            },
            (error) => {
                this.handleError(error);
            })
    }

    handleError(error){
        this.setState({loadingLenders: false, loadingLoanOfficers: false, savingContract: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'price',
            'payment',
            'loanType',
            'dateGenerated',
            'bindingDate',
            'potentialTerm',
            'potentialTermReason'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateMoney() {
        const fieldsToValidate = [
            'earnestMoneyRemaxTru',
            'earnestMoneyOther', //who?
            'earnestMoneyOtherAmount', //how much?
            'optionMoney',
            'optionMoneyNotes',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';
        const potentialTerm = this.state.potentialTerm || false;
        const payment = this.state.payment || '';
        const underContract = this.props.underContract || false;

        switch(fieldName){
            case 'price':
                if(underContract || value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please enter a valid contract price.';
                }
                break;
            case 'earnestMoneyRemaxTru':
                if(underContract || value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'earnestMoneyOther':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            case 'earnestMoneyOtherAmount':
                if(underContract || value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'optionMoneyNotes':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            case 'optionMoney':
                if(underContract || value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'potentialTerm':
                break;
            case 'potentialTermReason':
                if(potentialTerm){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter a reason.';
                }
                break;
            case 'payment':
                if(underContract || value.length !== 0){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select an option.';
                }
                break;
            case 'loanType':
                if(payment !== 'Cash' && (underContract || value.length !== 0)){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select an option.';
                }
                break;
            case 'dateGenerated':
                if(underContract){
                    isValid = (value||null) !== null;
                    invalidationReason = 'Please provide a valid date.';
                }
                break;
            case 'bindingDate':
                if(underContract){
                    isValid = (value||null) !== null;
                    invalidationReason = 'Please provide a valid date.';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        } else {
            console.log(invalidationReason);
        }


        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        switch(field){
            case 'price':
                value = value.replace(/[^0-9.]/, '');
                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'earnestMoneyRemaxTru':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'earnestMoneyOther':
                break;
            case 'earnestMoneyOtherAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'optionMoney':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'optionMoneyNotes':
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;
        const lenders = this.state.lenders || [];

        if(field === 'payment' && (value === 'Preferred Lender' || value === 'Outside Lender') && lenders.length === 0)
        {
            this.setState({[field]: value,loadingLenders: true}, () => this.getLenders());
        } else if(field === 'lenderId' && value.length !== 0) {
            this.setState({[field]: value, loadingLoanOfficers: true}, () => this.getLoanOfficers(value));
        } else {
            this.setState({[field]: value});
        }
    }

    handleRadioChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        if(field === 'loanOfficerMode' && value !== 'new'){
            if(value === 'existing'){
                this.setState({[field]: value, loanOfficerSaved: false, newLoanOfficer: null, loadingLoanOfficers: true}, () => this.getLoanOfficers());
            } else {
                this.setState({[field]: value, loanOfficerSaved: false, newLoanOfficer: null});
            }
        } else if(field === 'lenderMode' && value !== 'new'){
            if(value === 'existing'){
                this.setState({[field]: value, lenderSaved: false, newLender: null, loadingLenders: true }, () => this.getLenders());
            } else if(value === 'na'){
                this.setState({[field]: value, loanOfficerMode: 'na', lenderSaved: false, newLender: null, loanOfficerSaved: false, newLoanOfficer: null});
            } else {
                this.setState({[field]: value, lenderSaved: false, newLender: null});
            }
        } else {
            this.setState({[field]: value});
        }
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];
        this.setState({[field]: !value});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    updateLender(lender) {
        request(
            'POST',
            '/Manage/updateLender',
            lender,
            (data) => {
                this.setState(
                    {
                        savingContract: false,
                        // readOnlyLender: true
                    },
                    () => this.props.showSnack('success','Lender Updated Successfully!'))
            },
            (error) => {
                this.handleError(error);
            })
    }

    updateLoanOfficer(loanOfficer) {
        request(
            'POST',
            '/Manage/updateLoanOfficer',
            loanOfficer,
            (data) => {
                this.setState(
                    {
                        savingContract: false,
                        // readOnlyLoanOfficer: true
                    },
                    () => this.props.showSnack('success','Loan Officer Updated Successfully!'))
            },
            (error) => {
                this.handleError(error);
            })
    }

    updateCoopAgent(coopAgent) {
        const contractId = this.state.contractId || '';
        coopAgent.contractId = contractId;
        request(
            'POST',
            '/Manage/updateCoopAgent',
            coopAgent,
            (data) => {
                this.setState(
                    {savingContract: false, readOnlyCoopAgent: true },
                    () => this.props.showSnack('success','Co-op Agent Updated Successfully!'))
            },
            (error) => {
                this.handleError(error);
            })
    }

    addInstallment(installment) {
        installment.contractId = this.state.contractId || '';
        installment.moneyId = this.state.moneyId;
        const successMessage = 'Successfully Added Installment!';
        const action = '/Manage/addInstallment';
        this.setState({savingContract: true}, () => {
            request(
                'POST',
                action,
                installment,
                (data) => {
                    if(installment.type.toLowerCase() === 'earnest') {
                        const clone = _.cloneDeep(this.state.installments);
                        installment.installmentId = data.installmentId;
                        clone.push(installment);
                        this.setState({savingContract: false, installments: clone}, () => this.props.showSnack('success',successMessage))
                    } else {
                        const clone = _.cloneDeep(this.state.optionInstallments);
                        installment.installmentId = data.installmentId;
                        clone.push(installment);
                        this.setState({savingContract: false, optionInstallments: clone}, () => this.props.showSnack('success',successMessage))
                    }
                },
                (error) => {
                    this.setState({savingContract: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    updateInstallment(installment) {
        installment.moneyId = this.state.moneyId;
        const successMessage = 'Successfully Updated Installment!';
        const action = '/Manage/updateInstallment';
        this.setState({savingContract: true}, () => {
            request(
                'POST',
                action,
                installment,
                (data) => {
                    this.setState({savingContract: false}, () => this.props.showSnack('success',successMessage))
                },
                (error) => {
                    this.setState({savingContract: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    deleteInstallment(installmentId, type) {
        console.log(installmentId);

        const data = {
            moneyId: this.state.moneyId,
            installmentId: installmentId
        };
        const successMessage = 'Successfully Deleted Installment!';
        const action = '/Manage/deleteInstallment';

        this.setState({savingContract: true}, () => {
            request(
                'POST',
                action,
                data,
                (data) => {
                    if(type.toLowerCase() === 'earnest') {
                        const clone = _.cloneDeep(this.state.installments);
                        const vIndex = _.findIndex(clone, {installmentId: installmentId});
                        if(vIndex > -1){
                            clone.splice(vIndex,1);
                            this.setState({savingContract: false, installments: clone}, () => this.props.showSnack('success',successMessage))
                        }
                    } else {
                        const clone = _.cloneDeep(this.state.optionInstallments);
                        const vIndex = _.findIndex(clone, {installmentId: installmentId});
                        if(vIndex > -1){
                            clone.splice(vIndex,1);
                            this.setState({savingContract: false, optionInstallments: clone}, () => this.props.showSnack('success',successMessage))
                        }
                    }
                },
                (error) => {
                    this.setState({savingContract: false}, () => this.props.showSnack('error',error))
                })
        });
    }

    updateMonies() {
        const {
            contractId,
            moneyId,
            earnestMoneyRemaxTru,
            earnestMoneyOther,
            earnestMoneyOtherAmount,
            optionMoney,
            optionMoneyNotes,
        } = this.state;

        const data = {
            contractId,
            moneyId,
            earnestMoneyRemaxTru,
            earnestMoneyOther,
            earnestMoneyOtherAmount,
            optionMoney,
            optionMoneyNotes,
        };

        this.props.saveMonies(data);
    }

    saveContract() {
        const {
            contractId,
            price,
            payment,
            loanType,
            proofOfFunds,
            bindingDate,
            dateGenerated,
            lenderId,
            newLender,
            lenderMode,
            loanOfficerId,
            newLoanOfficer,
            loanOfficerMode,
            hasCoopAgent,
            // coopAgent,
            potentialTerm,
            potentialTermReason
        } = this.state;

        this.props.save({
            contractId,
            price,
            payment,
            loanType,
            proofOfFunds,
            bindingDate,
            dateGenerated,
            // coopAgent,
            hasCoopAgent,
            lenderId: lenderMode === 'existing' ? lenderId : '',
            lender: lenderMode === 'new' ? newLender : null,
            loanOfficerId: loanOfficerMode === 'existing' ? loanOfficerId : '',
            loanOfficer: loanOfficerMode === 'new' ? newLoanOfficer : null,
            potentialTerm,
            potentialTermReason
        });
    }

    isValid() {
        const {lenderMode, loanOfficerMode, lenderSaved, loanOfficerSaved} = this.state;
        if(lenderMode === 'new' && !lenderSaved){
            return false;
        }
        if(loanOfficerMode === 'new' && !loanOfficerSaved){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    isMoneyValid() {
        const validations = this.validateMoney();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes, readOnlyMode } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE CONTRACT';
        const builder = this.props.builder || '';
        const underContract = this.props.underContract || false;

        const { savingContract,isLease, pricingLog, lenderUpdates, loadingLenders, lenders, lenderSaved, lenderMode,loadingLoanOfficers, loanOfficers, loanOfficerSaved, loanOfficerMode } = this.state;
        const {
            contractId,
            price,
            closedPrice,
            payment,
            loanType,
            proofOfFunds,
            bindingDate,
            dateGenerated,
            lenderId,
            newLender,
            lender,
            loanOfficerId,
            newLoanOfficer,
            loanOfficer,
            coopAgent,
            hasCoopAgent,

            earnestMoneyRemaxTru,
            earnestMoneyOther,
            earnestMoneyOtherAmount,
            optionMoney,
            optionMoneyNotes,
            installments,
            optionInstallments,
            payouts,

            potentialTerm,
            potentialTermReason
        } = this.state;

        const lenderSection = (
            <div>
                <FormControl component="fieldset" className={classes.formControl} disabled={readOnlyMode}>
                    <FormLabel component="legend">Lender</FormLabel>
                    <RadioGroup row aria-label="lenderMode" name="lenderMode" value={lenderMode} onChange={(e) => this.handleRadioChange(e)}>
                        {lender !== null &&
                        <FormControlLabel value="edit" control={<Radio/>} label="Edit Lender"/>
                        }
                        <FormControlLabel value="existing" control={<Radio/>} label="Select Lender"/>
                        <FormControlLabel value="new" control={<Radio/>} label="New Lender"/>
                        <FormControlLabel value="na" control={<Radio/>} label="N/A"/>
                    </RadioGroup>
                </FormControl>
                {lenderMode === 'existing' &&
                <div>
                    {loadingLenders ? (
                        <Loading contained={true}/>
                    ) : (
                        <div>
                            {lenders.length > 0 ? (
                                <div>
                                    <FormControl className={classes.dropdown}>
                                        <InputLabel htmlFor="lender-select">Lender</InputLabel>
                                        <Select
                                            id="lender-select"
                                            name="lenderId"
                                            value={lenderId}
                                            onChange={(e) => this.handleSelectChange(e)}
                                        >
                                            {lenders.map((item, index) => (
                                                <MenuItem key={'lender'+item.lenderId} value={item.lenderId}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br/>
                                    {!readOnlyMode &&
                                    <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.saveContract()} className={classes.button}>{saveBtnText}</Button>
                                    }
                                </div>
                            ) : (
                                <div>No Lenders Found..</div>
                            )}
                        </div>
                    )}
                </div>
                }
                {lenderMode === 'new' &&
                <div>
                    <Lender readOnlyMode={lenderSaved} saveBtnText="SAVE LENDER" lender={newLender} save={(l) => this.setState({newLender: l, lenderSaved: true})} showSnack={(t,e) => this.props.showSnack(t,e)} />
                    {(!readOnlyMode && lenderSaved) &&
                    <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.saveContract()} className={classes.button}>{saveBtnText}</Button>
                    }
                </div>
                }
                {lenderMode === 'edit' &&
                <Lender readOnlyMode={readOnlyMode} saveBtnText="UPDATE LENDER" lender={lender} save={(l) => this.setState({savingContract: true}, () => this.updateLender(l))} showSnack={(t,e) => this.props.showSnack(t,e)} />
                }
            </div>
        );

        const loanOfficerSection = (
            <div>
                <FormControl component="fieldset" className={classes.formControl} disabled={readOnlyMode}>
                    <FormLabel component="legend">Loan Officer</FormLabel>
                    <RadioGroup row aria-label="loanOfficerMode" name="loanOfficerMode" value={loanOfficerMode} onChange={(e) => this.handleRadioChange(e)}>
                        {loanOfficer !== null &&
                        <FormControlLabel value="edit" control={<Radio/>} label="Edit Loan Officer"/>
                        }
                        <FormControlLabel value="existing" control={<Radio/>} label="Select Loan Officer"/>
                        <FormControlLabel value="new" control={<Radio/>} label="New Loan Officer"/>
                        <FormControlLabel value="na" control={<Radio/>} label="N/A"/>
                    </RadioGroup>
                </FormControl>
                {loanOfficerMode === 'existing' &&
                <div>
                    {loadingLoanOfficers ? (
                        <Loading contained={true}/>
                    ) : (
                        <div>
                            {loanOfficers.length > 0 ? (
                                <div>
                                    <FormControl className={classes.dropdown}>
                                        <InputLabel htmlFor="loanOfficer-select">Loan Officer</InputLabel>
                                        <Select
                                            id="loanOfficer-select"
                                            name="loanOfficerId"
                                            value={loanOfficerId}
                                            onChange={(e) => this.handleSelectChange(e)}
                                        >
                                            {loanOfficers.map((item, index) => (
                                                <MenuItem key={'loan-officer-' + item.loanOfficerId}
                                                          value={item.loanOfficerId}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <br/>
                                    {!readOnlyMode &&
                                    <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.saveContract()} className={classes.button}>{saveBtnText}</Button>
                                    }
                                </div>
                            ) : (
                                <div>No Loan Officers found..</div>
                            )}
                        </div>
                    )}
                </div>
                }
                {loanOfficerMode === 'new' &&
                <div>
                    <LoanOfficer readOnlyMode={loanOfficerSaved} saveBtnText="SAVE LOAN OFFICER" loanOfficer={newLoanOfficer} save={(l) => this.setState({newLoanOfficer: l, loanOfficerSaved: true})} showSnack={(t,e) => this.props.showSnack(t,e)} />
                    {(!readOnlyMode && loanOfficerSaved) &&
                    <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.saveContract()} className={classes.button}>{saveBtnText}</Button>
                    }
                </div>
                }
                {loanOfficerMode === 'edit' &&
                <div>
                    <LoanOfficer readOnlyMode={readOnlyMode} saveBtnText="UPDATE LOAN OFFICER" loanOfficer={loanOfficer} save={(l) => this.setState({savingContract: true}, () => this.updateLoanOfficer(l))} showSnack={(t,e) => this.props.showSnack(t,e)} />
                </div>
                }
            </div>
        );

        const lenderUpdatesSection = (
            <div>
                <LenderUpdates payment={payment} readOnlyMode={readOnlyMode} contractId={contractId} lenderUpdates={lenderUpdates} showSnack={(t,e) => this.props.showSnack(t,e)}/>
            </div>
        );

        const earnestMoneys = (
            <div>
                <Divider className={classes.divider} />
                <Typography variant="h6">Earnest Money</Typography>
                <br/>
                <TextField autoComplete="new"
                           name="earnestMoneyRemaxTru"
                           value={earnestMoneyRemaxTru}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="RemaxTru"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('earnestMoneyRemaxTru')}
                           helperText={this.fieldErrorMessage('earnestMoneyRemaxTru')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                           disabled={readOnlyMode}
                           required={underContract}
                />
                <br/>
                <TextField autoComplete="new"
                           name="earnestMoneyOtherAmount"
                           value={earnestMoneyOtherAmount}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Other"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('earnestMoneyOtherAmount')}
                           helperText={this.fieldErrorMessage('earnestMoneyOtherAmount')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                           disabled={readOnlyMode}
                           required={underContract}
                />
                <TextField autoComplete="new"
                           value={earnestMoneyOther}
                           name="earnestMoneyOther"
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Other Party"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('earnestMoneyOther')}
                           helperText={this.fieldErrorMessage('earnestMoneyOther')}
                           disabled={readOnlyMode}
                />
                <br/>
                <TextField autoComplete="new"
                           name="optionMoney"
                           value={optionMoney}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Option Money"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('optionMoney')}
                           helperText={this.fieldErrorMessage('optionMoney')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                           disabled={readOnlyMode}
                           required={underContract}
                />
                <TextField autoComplete="new"
                           value={optionMoneyNotes}
                           name="optionMoneyNotes"
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Option Money Notes"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('optionMoneyNotes')}
                           helperText={this.fieldErrorMessage('optionMoneyNotes')}
                           disabled={readOnlyMode}
                />
                <br/>
                <Divider className={classes.divider} />
                <strong>Earnest Money Deposit Schedule</strong>
                <br/>
                {!readOnlyMode &&
                <Installment readOnlyMode={readOnlyMode} type="Earnest" installments={installments.length} save={(i) => this.addInstallment(i)}/>
                }
                <br/>
                <div className={classes.installmentWrapper}>
                    {installments.length !== 0 &&
                    <div>
                        {installments.map((item, index) => (
                            <UpdateInstallment readOnlyMode={readOnlyMode} installment={item} key={'installment-'+index} delete={(i,t) => this.deleteInstallment(i,t)} save={(i) => this.updateInstallment(i)} />
                        ))}
                    </div>
                    }
                    <div className={classes.countOfTotal}>{`${installments.length} of 5 added`}</div>
                </div>
                <Divider className={classes.divider} />
                <strong>Option Money Deposit Schedule</strong>
                <br/>
                {!readOnlyMode &&
                <Installment readOnlyMode={readOnlyMode} type="Option" installments={optionInstallments.length} save={(i) => this.addInstallment(i)}/>
                }
                <br/>
                <div className={classes.installmentWrapper}>
                    {optionInstallments.length !== 0 &&
                    <div>
                        {optionInstallments.map((item, index) => (
                            <UpdateInstallment readOnlyMode={readOnlyMode} installment={item} key={'option-installment-'+index} delete={(i,t) => this.deleteInstallment(i,t)} save={(i) => this.updateInstallment(i)} />
                        ))}
                    </div>
                    }
                    <div className={classes.countOfTotal}>{`${optionInstallments.length} of 5 added`}</div>
                </div>
                <br/>
                <Button disabled={readOnlyMode|| !this.isMoneyValid()} variant="contained" color="primary" onClick={() => this.updateMonies()} className={classes.moneyButton}>SAVE MONIES</Button>
            </div>
        );

        return (
            <div>
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        className={classes.dateInput}
                        margin="normal"
                        label="Date Generated"
                        value={dateGenerated}
                        onChange={(d) => this.handleDateChange('dateGenerated', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                        disabled={readOnlyMode}
                        required={underContract}
                    />
                </MuiPickersUtilsProvider>
                <br/>
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        className={classes.dateInput}
                        margin="normal"
                        label="Binding Date"
                        value={bindingDate}
                        onChange={(d) => this.handleDateChange('bindingDate', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                        disabled={readOnlyMode}
                        required={underContract}
                    />
                </MuiPickersUtilsProvider>
                {!isLease &&
                <div>
                    <br/>
                    <TextField autoComplete="new"
                        name="price"
                        value={price}
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Contract Price"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('price')}
                        helperText={this.fieldErrorMessage('price')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={readOnlyMode}
                        required={underContract}
                    />
                    {closedPrice !== null &&
                    <div style={{margin: '15px 0', textDecoration: 'underline'}}>
                        <strong>Closed Price: </strong> ${closedPrice}
                        <br/>
                    </div>
                    }
                    <Log title="Contract Price Log" log={pricingLog} type="price" noUpdatesText="No previous changes to contract price found.." />
                    <br/>
                    {!readOnlyMode &&
                    <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.saveContract()} className={classes.button}>{saveBtnText}</Button>
                    }
                    <br/>
                    <FormControl className={classes.dropdown} required={underContract}>
                        <InputLabel htmlFor="payment-select">Finance</InputLabel>
                        <Select
                            id="payment-select"
                            name="payment"
                            value={payment}
                            onChange={(e) => this.handleSelectChange(e)}
                            disabled={readOnlyMode}
                        >
                            <MenuItem value="Preferred Lender">Preferred Lender</MenuItem>
                            <MenuItem value="Outside Lender">Outside Lender</MenuItem>
                            <MenuItem value="Cash">Cash</MenuItem>
                        </Select>
                    </FormControl>
                    <br/>
                    {payment === 'Cash' &&
                    <div>
                        <FormControlLabel
                            className={classes.switch}
                            control={
                                <Switch
                                    name="proofOfFunds"
                                    checked={proofOfFunds}
                                    onChange={(e) => this.handleSwitchChange(e)}
                                    color="primary"
                                    disabled={readOnlyMode}
                                />
                            }
                            label="Proof of Funds?"
                        />
                        <br/>
                        {!readOnlyMode &&
                        <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.saveContract()} className={classes.button}>{saveBtnText}</Button>
                        }
                        <br/>
                        {lenderUpdatesSection}
                    </div>
                    }
                    <br/>
                    {((payment||null) !== null && payment !== 'Cash') &&
                    <div>
                        <FormControl className={classes.dropdown} required={underContract}>
                            <InputLabel htmlFor="loanType-select">Loan Type</InputLabel>
                            <Select
                                id="loanType-select"
                                name="loanType"
                                value={loanType}
                                onChange={(e) => this.handleSelectChange(e)}
                                disabled={readOnlyMode}
                            >
                                <MenuItem value="Conventional">Conventional</MenuItem>
                                <MenuItem value="FHA">FHA</MenuItem>
                                <MenuItem value="VA">VA</MenuItem>
                                <MenuItem value="USDA">USDA</MenuItem>
                            </Select>
                        </FormControl>
                        <br/>
                        {!readOnlyMode &&
                        <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.saveContract()} className={classes.button}>{saveBtnText}</Button>
                        }
                        <br/>
                        {lenderSection}
                        <br/>
                        {loanOfficerSection}
                        <br/>
                        {lenderUpdatesSection}
                    </div>
                    }
                </div>
                }
                <br/>
                <strong>Co-op Agent</strong>
                <br/>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            name="hasCoopAgent"
                            checked={hasCoopAgent}
                            onChange={(e) => this.handleSwitchChange(e)}
                            color="primary"
                            disabled={readOnlyMode}
                        />
                    }
                    label="Is there a Co-op Agent?"
                />
                {hasCoopAgent &&
                <CoopAgent showSelect={true} showAddBrokerOption={true} readOnlyMode={readOnlyMode} saveBtnText="SAVE CO-OP AGENT"
                           coopAgent={coopAgent} save={(a) => this.updateCoopAgent(a)}
                           showSnack={(t, e) => this.props.showSnack(t, e)}/>
                }
                <br/>
                {builder === 'Stephen Elliott' &&
                    <div>
                        <FormControlLabel
                            className={classes.switch}
                            control={
                                <Switch
                                    name="potentialTerm"
                                    checked={potentialTerm}
                                    onChange={(e) => this.handleSwitchChange(e)}
                                    color="primary"
                                    disabled={readOnlyMode}
                                />
                            }
                            label="Potential Term?"
                        />
                        {potentialTerm &&
                        <div>
                            <br/>
                            <TextField autoComplete="new"
                               value={potentialTermReason}
                               name="potentialTermReason"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Reason for Potential Term"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('potentialTermReason')}
                               helperText={this.fieldErrorMessage('potentialTermReason')}
                               disabled={readOnlyMode}
                               multiline
                               rows={3}
                               fullWidth
                            />
                        </div>
                        }
                        <br/>
                        {!readOnlyMode &&
                        <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.saveContract()} className={classes.button}>{saveBtnText}</Button>
                        }
                    </div>
                }
                {savingContract &&
                <Loading/>
                }
                {!isLease &&
                <div>
                    <br/>
                    {earnestMoneys}
                </div>
                }
                <div>
                    {payouts.length !== 0 &&
                    <div>
                        <br/>
                        <strong>PAYOUTS</strong>
                        <br/>
                        {payouts.map((item, index) => (
                            <div className={classes.payout} key={'payout-'+index}><strong>{item.type} Money</strong> <span>Party: {item.party}</span> <span>Amount: ${item.amount}</span></div>
                        ))}
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(UpdateContract);