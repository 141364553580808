import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import {request} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});

class AssignTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            selectedUsers: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers(){
        request(
            'GET',
            '/Admin/getUsers',
            null,
            (data) => {
                this.setState({users: data.users, isLoading: false});
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error));
            })
    }

    handleChange(user){
        const selectedUsers = _.cloneDeep(this.state.selectedUsers);
        const i = _.indexOf(selectedUsers, user.userId)
        if(i === -1){
            selectedUsers.push(user.userId);
        } else {
            selectedUsers.splice(i,1);
        }

        this.setState({selectedUsers})
    }

    isValid() {
        const selectedUsers = this.state.selectedUsers||null;
        return selectedUsers !== null && selectedUsers.length > 0;
    }

    save() {
        const selectedUsers = this.state.selectedUsers;
        const templateId = this.props.templateId;

        const action = '/Admin/assignLandingPageTemplate';
        const data = {userIds: selectedUsers, landingPageTemplateId: templateId};
        const successMessage = 'Successfully Assigned Template!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({isLoading: false}, () => this.props.saved(successMessage))
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error))
            })
    }

    render() {
        const { classes } = this.props;
        const {
            users,
            selectedUsers,
            isLoading
        } = this.state;
        const saveBtnText = 'ASSIGN TO ' + selectedUsers.length + ' USERS';

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                {(users || null) !== null &&
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">Assign Template <strong>{this.props.name}</strong> to User(s)</FormLabel>
                        <br/>
                        <FormGroup>
                            {users.map((user,index) =>
                            <FormControlLabel key={'assign-' + index}
                                control={<Checkbox color="primary" checked={_.indexOf(selectedUsers, user.userId) !== -1} onChange={() => this.handleChange(user)} name={user.name} />}
                                label={user.name}
                            />
                            )}
                        </FormGroup>
                        <FormHelperText>Assigning a template to a user will override any existing template already configured for the user.</FormHelperText>
                    </FormControl>
                }
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.setState({isLoading: true}, () => this.save())} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(AssignTemplate);