import React, { Component } from 'react';
import {
    request,
    validateField,
    validatePrice,
    validatePercentage,
    validateEmail,
    validateLenientName
} from '../../utils';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddIcon from '@material-ui/icons/Add';
import Chip from "@material-ui/core/Chip";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    inlineButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    fullWidth: {
        flex: '0 0 100%'
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flex: '0 0 100%'
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    agentWrapper: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > * > *': {
            margin: theme.spacing(0.5),
        },
    }
});

class CloseListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            type: '',
            agentsAtClosing: [],
            actualCloseDate: null,
            moveInDate: null,
            bindingDate: null,
            closedPrice: '',
            closingPhotosReceived: false,
            signReturned: '',
            reasonSignNotReturned: '',
            combinedCommissionPercentage: '',
            totalCommissionPercentage: '',
            totalCommissionAmount: '',
            tamraCommissionPercentage: '',
            agentCommissionPercentage: '',
            tamraCommissionAmount: '',
            agentCommissionAmount: '',
            fmlsCommission: '',
            remaxCommission: '',
            randyCommission: '',
            tamraCmnCommission: '',
            agentCmnCommission: '',
            gsmCommission: '',
            notes: '',
            planAndElevation: '',
            loadingAgents: false,
            selectedAgentId: '',
            agents: [],

            payment: '',
            lenderId: '',
            lenderName: '',
            // lenderEmail: '',
            lenderPhone: '',
            lenderAddress1: '',
            lenderAddress2: '',
            lenderCity: '',
            lenderState: '',
            lenderZip: '',
            lenders: [],
            selectedLender: null,

            loanOfficerId: '',
            loanOfficerName: '',
            loanOfficerPhone: '',
            loanOfficerEmail: '',

            closedPending: false,

            validations: []
        };
    }

    componentDidMount() {
        const listingId = this.props.listingId || null;
        if(listingId !== null){
            this.loadListingInfo(listingId)
        }
    }

    loadListingInfo(listingId) {
        request(
            'GET',
            '/Manage/getListingClosingInfo?listingId=' + listingId,
            null,
            (data) => {
                this.loadData(data.listing);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            agentsAtClosing: data.agentsAtClosing || [],
            actualCloseDate: data.actualCloseDate || null,
            moveInDate: data.moveInDate || null,
            type: data.type || '',
            bindingDate: data.bindingDate || null,
            closedPrice: data.closedPrice || '',
            closingPhotosReceived: data.closingPhotosReceived || false,
            signReturned: data.signReturned || '',
            reasonSignNotReturned: data.reasonSignNotReturned || '',
            combinedCommissionPercentage: data.combinedCommissionPercentage || '',
            totalCommissionPercentage: data.totalCommissionPercentage || '',
            totalCommissionAmount: data.totalCommissionAmount || '',
            tamraCommissionPercentage: data.tamraCommissionPercentage || '',
            agentCommissionPercentage: data.agentCommissionPercentage || '',
            tamraCommissionAmount: data.tamraCommissionAmount || '',
            agentCommissionAmount: data.agentCommissionAmount || '',
            fmlsCommission: data.fmlsCommission || '',
            remaxCommission: data.remaxCommission || '',
            randyCommission: data.randyCommission || '',
            tamraCmnCommission: data.tamraCmnCommission || '',
            agentCmnCommission: data.agentCmnCommission || '',
            gsmCommission: data.gsmCommission || '',
            planAndElevation: data.planAndElevation || '',
            agents: data.agents || [],
            lenderId: (data.lender||null) !== null ? data.lender.lenderId : '',
            lenderName: (data.lender||null) !== null ? data.lender.name || '' : '',
            // lenderEmail: (data.lender||null) !== null ? data.lender.email || '' : '',
            lenderPhone: (data.lender||null) !== null ? data.lender.phone || '' : '',
            lenderAddress1: (data.lender||null) !== null ? data.lender.address1 || '' : '',
            lenderAddress2: (data.lender||null) !== null ? data.lender.address2 || '' : '',
            lenderCity: (data.lender||null) !== null ? data.lender.city || '' : '',
            lenderState: (data.lender||null) !== null ? data.lender.state || '' : '',
            lenderZip: (data.lender||null) !== null ? data.lender.zip || '' : '',
            lenders: data.lenders || [],
            // loanOfficer: data.loanOfficer,
            loanOfficerId: (data.loanOfficer||null) !== null ? data.loanOfficer.loanOfficerId || '' : '',
            loanOfficerName: (data.loanOfficer||null) !== null ? data.loanOfficer.name || '' : '',
            loanOfficerEmail: (data.loanOfficer||null) !== null ? data.loanOfficer.email || '' : '',
            loanOfficerPhone: (data.loanOfficer||null) !== null ? data.loanOfficer.phone || '' : '',
            payment: data.payment || '',
            closedPending: data.closedPending || false
        })
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'agentsAtClosing',
            'closingPhotosReceived',
            'signReturned',
            'reasonSignNotReturned',
            'combinedCommissionPercentage',
            'totalCommissionPercentage',
            'totalCommissionAmount',
            'tamraCommissionPercentage',
            'agentCommissionPercentage',
            'tamraCommissionAmount',
            'agentCommissionAmount',
            'fmlsCommission',
            'remaxCommission',
            'randyCommission',
            'tamraCmnCommission',
            'agentCmnCommission',
            'gsmCommission',
            'actualCloseDate',
            'moveInDate',
            'bindingDate',
            'closedPrice',
            'planAndElevation',
            'payment',
            'lenderName',
            // 'lenderEmail',
            'lenderPhone',
            'lenderAddress1',
            'lenderAddress2',
            'lenderCity',
            'lenderState',
            'lenderZip',
            'loanOfficerName',
            'loanOfficerPhone',
            'loanOfficerEmail'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';
        const type = this.state.type;
        const payment = this.state.payment;
        const closedPending = this.state.closedPending;

        switch(fieldName){
            case 'agentsAtClosing':
                if(closedPending) break;
                isValid = value.length > 0;
                invalidationReason = 'Please select at least one agent.';
                break;
            case 'closedPrice':
                if(closedPending) break;
                if(type !== 'Lease'){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'planAndElevation':
                // if(closedPending) break;
                if(type !== 'Lease'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the Plan and Elevation.';
                }
                break;
            case 'actualCloseDate':
                if(closedPending) break;
                if(type !== 'Lease'){
                    isValid = (value||null) !== null;
                    invalidationReason = 'Please enter a valid date.';
                }
                break;
            case 'moveInDate':
                if(type === 'Lease'){
                    isValid = (value||null) !== null;
                    invalidationReason = 'Please enter a valid date.';
                }
                break;
            case 'bindingDate':
                if(closedPending) break;
                isValid = (value||null) !== null;
                invalidationReason = 'Please enter a valid date.';
                break;
            case 'closingPhotosReceived':
                // isValid = validateField(value);
                // invalidationReason = 'Please enter .';
                break;
            case 'signReturned':
                // isValid = validateField(value);
                // invalidationReason = 'Please enter .';
                break;
            case 'reasonSignNotReturned':
                if(closedPending) break;
                if(this.state.signReturned === 'No'){
                    isValid = validateField(value);
                    invalidationReason = 'Please provide a reason.';
                }
                break;
            case 'combinedCommissionPercentage':
                if(closedPending) break;
                isValid = validatePercentage(value);
                invalidationReason = 'Please provide a valid percentage.';
                break;
            case 'totalCommissionPercentage':
                if(closedPending) break;
                isValid = validatePercentage(value);
                invalidationReason = 'Please provide a valid percentage.';
                break;
            case 'totalCommissionAmount':
                if(closedPending) break;
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'tamraCommissionPercentage':
                if(closedPending) break;
                isValid = validatePercentage(value);
                invalidationReason = 'Please provide a valid percentage.';
                break;
            case 'agentCommissionPercentage':
                if(closedPending) break;
                isValid = validatePercentage(value);
                invalidationReason = 'Please provide a valid percentage.';
                break;
            case 'tamraCommissionAmount':
                if(closedPending) break;
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'agentCommissionAmount':
                if(closedPending) break;
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'fmlsCommission':
                if(closedPending) break;
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'remaxCommission':
                if(closedPending) break;
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'randyCommission':
                if(closedPending) break;
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'tamraCmnCommission':
                if(closedPending) break;
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'agentCmnCommission':
                if(closedPending) break;
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'gsmCommission':
                if(closedPending) break;
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'payment':
                if(closedPending) break;
                if(type !== 'Lease'){
                    isValid = validateField(value);
                    invalidationReason = 'Please select Finance type.';
                }
                break;
            case 'lenderName':
                if(closedPending) break;
                if(type !== 'Lease' && payment !== 'Cash'){
                    isValid = validateField(value);
                    invalidationReason = 'The Lender\'s name is required';
                }
                break;
            // case 'lenderEmail':
            //     if(value !== null && value.length > 0) {
            //         isValid = validateEmail(value);
            //         invalidationReason = 'Please enter a valid email address';
            //     }
            //     break;
            case 'lenderPhone':
                if(value !== null && value.length > 0){
                    isValid = validateField(value,10,10);
                    invalidationReason = 'Please enter a 10 digit phone number';
                }
                break;
            case 'lenderAddress1':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the street # and name';
                }
                break;
            case 'lenderAddress2':
                break;
            case 'lenderCity':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the city name';
                }
                break;
            case 'lenderState':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter State';
                }
                break;
            case 'lenderZip':
                if(value !== null && value.length > 0){
                    isValid = validateField(value,5,5);
                    invalidationReason = 'Please enter the 5 digit zip code';
                }
                break;
            case 'loanOfficerName':
                if(closedPending) break;
                if(type !== 'Lease' && payment !== 'Cash'){
                    isValid = validateLenientName(value);
                    invalidationReason = 'Please enter the Loan Officer\'s Name';
                }
                break;
            case 'loanOfficerPhone':
                if(value !== null && value.length > 0){
                    isValid = validateField(value,10,10);
                    invalidationReason = 'Please enter the Loan Officer\'s phone';
                }
                break;
            case 'loanOfficerEmail':
                if(value !== null && value.length > 0){
                    isValid = validateEmail(value);
                    invalidationReason = 'Please enter the Loan Officer\'s email';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        } else {
            console.log(invalidationReason);
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        switch(field){

            case 'signReturned':
                break;
            case 'reasonSignNotReturned':
                break;
            case 'closingPhotosReceived':
                break;
            case 'combinedCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'totalCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'totalCommissionAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'tamraCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'agentCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'tamraCommissionAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'agentCommissionAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'fmlsCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'remaxCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'randyCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'tamraCmnCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'agentCmnCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'gsmCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'agentsAtClosing':
                break;
            case 'actualCloseDate':
                break;
            case 'bindingDate':
                break;
            case 'closedPrice':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'planAndElevation':
                break;

            case 'lenderPhone':
                value = value.replace(/\D+/g, '');
                if(value.length > 10){
                    value = value.substring(0,10);
                }
                break;
            case  'lenderZip':
                value = value.replace(/\D+/g, '');
                if(value.length > 5){
                    value = value.substring(0,5);
                }
                break;
            case  'loanOfficerPhone':
                value = value.replace(/\D+/g, '');
                if(value.length > 10){
                    value = value.substring(0,10);
                }
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    };

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        this.setState({[field]: !value});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }
    
    loadLender(l) {
        this.setState({
            // lenderId: l.lenderId,
            lenderName: l.name || '',
            // lenderEmail: l.email || '',
            lenderPhone: l.phone || '',
            lenderAddress1: l.address1 || '',
            lenderAddress2: l.address2 || '',
            lenderCity: l.city || '',
            lenderState: l.state || '',
            lenderZip: l.zip || ''
        });
    }
    
    close() {
        const {
            agentsAtClosing,
            closingPhotosReceived,
            signReturned,
            reasonSignNotReturned,
            combinedCommissionPercentage,
            totalCommissionPercentage,
            totalCommissionAmount,
            tamraCommissionPercentage,
            agentCommissionPercentage,
            tamraCommissionAmount,
            agentCommissionAmount,
            fmlsCommission,
            remaxCommission,
            randyCommission,
            tamraCmnCommission,
            agentCmnCommission,
            gsmCommission,
            notes,
            actualCloseDate,
            moveInDate,
            bindingDate,
            type,
            closedPrice,
            planAndElevation,
            lenderId,
            lenderName,
            // lenderEmail,
            lenderPhone,
            lenderAddress1,
            lenderAddress2,
            lenderCity,
            lenderState,
            lenderZip,
            payment,
            loanOfficerId,
            loanOfficerName,
            loanOfficerPhone,
            loanOfficerEmail,
            closedPending
        } = this.state;

        this.props.close({
            listingId: this.props.listingId,
            agentsAtClosing,
            closingPhotosReceived,
            signReturned,
            reasonSignNotReturned,
            combinedCommissionPercentage,
            totalCommissionPercentage,
            totalCommissionAmount,
            tamraCommissionPercentage,
            agentCommissionPercentage,
            tamraCommissionAmount,
            agentCommissionAmount,
            fmlsCommission,
            remaxCommission,
            randyCommission,
            tamraCmnCommission,
            agentCmnCommission,
            gsmCommission,
            notes,
            actualCloseDate,
            moveInDate,
            bindingDate,
            closedPrice: type === 'Lease' ? '0' : closedPrice,
            planAndElevation,
            lender: {
                lenderId,
                name: lenderName,
                // email: lenderEmail,
                phone: lenderPhone,
                address1: lenderAddress1,
                address2: lenderAddress2,
                city: lenderCity,
                state: lenderState,
                zip: lenderZip,
            },
            loanOfficer: {
                loanOfficerId,
                name: loanOfficerName,
                phone: loanOfficerPhone,
                email: loanOfficerEmail
            },
            payment,
            closedPending
        });
    }

    addAgentAtClosing(){
        const agentId = this.state.selectedAgentId;
        const agentsAtClosing = this.state.agentsAtClosing;
        const agents = this.state.agents;

        const vIndex = _.findIndex(agents, {agentId: agentId});
        if(vIndex > -1){
            const aac = _.findIndex(agentsAtClosing, {agentId: agentId});
            if(aac === -1){
                agentsAtClosing.push(agents[vIndex]);
                this.setState({agentsAtClosing: agentsAtClosing});
            } else {
                this.props.showSnack('warning','Agent already added.');
            }
        }
    }

    removeAgentAtClosing(agentId){
        const agentsAtClosing = this.state.agentsAtClosing;
        const clone = _.cloneDeep(agentsAtClosing);
        const vIndex = _.findIndex(clone, {agentId: agentId});
        if(vIndex > -1){
            clone.splice(vIndex,1);
            this.setState({agentsAtClosing: clone});
        }
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'CLOSE SALES DETAILS';
        // const listingId = this.props.listingId || null;
        const {
            isLoading,
            agentsAtClosing,
            closingPhotosReceived,
            signReturned,
            reasonSignNotReturned,
            combinedCommissionPercentage,
            totalCommissionPercentage,
            totalCommissionAmount,
            tamraCommissionPercentage,
            agentCommissionPercentage,
            tamraCommissionAmount,
            agentCommissionAmount,
            fmlsCommission,
            remaxCommission,
            randyCommission,
            tamraCmnCommission,
            agentCmnCommission,
            gsmCommission,
            notes,
            actualCloseDate,
            moveInDate,
            type,
            bindingDate,
            closedPrice,
            planAndElevation,
            loadingAgents,
            selectedAgentId,
            agents,
            lenderId,
            lenderName,
            // lenderEmail,
            lenderPhone,
            lenderAddress1,
            lenderAddress2,
            lenderCity,
            lenderState,
            lenderZip,
            lenders,
            selectedLender,
            payment,
            loanOfficerName,
            loanOfficerPhone,
            loanOfficerEmail,
            closedPending
        } = this.state;

        return (
            <div>
                {isLoading ? (
                    <Loading/>
                ) : (
                    <div>
                        <div className={classes.flexWrapper}>
                            <strong>Please provide the following information in order to close the Sales Details at {this.props.summary}</strong>
                            <div>
                                {type !== 'Lease' ? (
                                    <div>
                                        <div>
                                            <FormControlLabel
                                                className={classes.switch}
                                                control={
                                                    <Switch
                                                        name="closedPending"
                                                        checked={closedPending}
                                                        onChange={(e) => this.handleSwitchChange(e)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Closed - Pending"
                                            />
                                            <br/>
                                        </div>
                                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/DD/YYYY"
                                                className={classes.dateInput}
                                                margin="normal"
                                                id="actualCloseDate"
                                                label={'Close Date'}
                                                value={actualCloseDate}
                                                onChange={(d) => this.handleDateChange('actualCloseDate', d)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                autoOk={true}
                                                required={!closedPending}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <TextField autoComplete="new"
                                            name="closedPrice"
                                            value={closedPrice}
                                            onChange={(e) => this.handleFieldChange(e)}
                                            label="Closed Price"
                                            className={classes.textField}
                                            margin="normal"
                                            error={this.fieldHasError('closedPrice')}
                                            helperText={this.fieldErrorMessage('closedPrice')}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            required={!closedPending}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/DD/YYYY"
                                                className={classes.dateInput}
                                                margin="normal"
                                                id="moveInDate"
                                                label={'Move In Date'}
                                                value={moveInDate}
                                                onChange={(d) => this.handleDateChange('moveInDate', d)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                autoOk={true}
                                                required={!closedPending}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                )}
                                <br/>
                                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/DD/YYYY"
                                        className={classes.dateInput}
                                        margin="normal"
                                        label="Binding Date"
                                        value={bindingDate}
                                        onChange={(d) => this.handleDateChange('bindingDate', d)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                        required={!closedPending}
                                    />
                                </MuiPickersUtilsProvider>
                                <br/>
                                <Divider className={classes.divider} />
                                <strong className={classes.fullWidth}>Agents at Close</strong>
                                {loadingAgents ? (
                                    <Loading contained={true}/>
                                ) : (
                                    <div>
                                        <FormControl className={classes.dropdown}>
                                            <Select
                                                id="agent-select"
                                                name="selectedAgentId"
                                                value={selectedAgentId}
                                                onChange={(e) => this.handleSelectChange(e)}
                                            >
                                                {agents.map((item, index) => (
                                                    <MenuItem key={'agent-select-'+item.agentId} value={item.agentId}>{item.licenseeName}</MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>Select an Agent you would like to Add</FormHelperText>
                                        </FormControl>
                                        <br/>
                                        <Button variant="outlined" size="small" color="primary" disabled={(selectedAgentId === '' || loadingAgents)} onClick={() => this.addAgentAtClosing()} className={classes.button} startIcon={<AddIcon />}>AT CLOSING</Button>
                                    </div>
                                )}
                                <br/>
                                <strong>Selected Agents</strong>
                                <br/>
                                <div className={classes.agentWrapper}>
                                    {agentsAtClosing.length !== 0 ? (
                                        <div>
                                            {agentsAtClosing.map((item, index) => (
                                                <Chip
                                                    // icon={<EditIcon />}
                                                    // onClick={() => this.editCloseAgent(item.agentId)}
                                                    key={'agent-close-chip-'+item.agentId}
                                                    label={item.licenseeName}
                                                    onDelete={() => this.removeAgentAtClosing(item.agentId)}
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            ))}
                                        </div>
                                    ) : (
                                        <div className={classes.margin}>No Agents At Closing..</div>
                                    )}
                                </div>
                            </div>
                            <br/>
                            <Divider className={classes.divider} />
                            <strong className={classes.fullWidth}>Additional Info</strong>
                            <FormControl className={classes.dropdown} required={!closedPending}>
                                <InputLabel htmlFor="listing-signReturned-select">Sign Returned</InputLabel>
                                <Select
                                    id="listing-signReturned-select"
                                    name="signReturned"
                                    value={signReturned}
                                    onChange={(e) => this.handleSelectChange(e)}
                                >
                                    <MenuItem value="No">No</MenuItem>
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="Lost">Lost</MenuItem>
                                    <MenuItem value="N/A">N/A</MenuItem>
                                </Select>
                            </FormControl>
                            <br/>
                            {signReturned === 'No' &&
                            <div>
                                <TextField autoComplete="new"
                                    value={reasonSignNotReturned}
                                    name="reasonSignNotReturned"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Reason Not Returned"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('reasonSignNotReturned')}
                                    helperText={this.fieldErrorMessage('reasonSignNotReturned')}
                                    required={!closedPending}
                                />
                                <br/>
                            </div>
                            }
                            {type === 'New Construction' &&
                            <div>
                                <FormControlLabel
                                    className={classes.switch}
                                    control={
                                        <Switch
                                            name="closingPhotosReceived"
                                            checked={closingPhotosReceived}
                                            onChange={(e) => this.handleSwitchChange(e)}
                                            color="primary"
                                            required={!closedPending}
                                        />
                                    }
                                    label="Closing Photos Received?"
                                />
                                <br/>
                            </div>
                            }
                            <br/>
                            <Divider className={classes.divider} />
                            <strong className={classes.fullWidth}>Commissions</strong>
                            <div>
                                <TextField autoComplete="new"
                                    value={combinedCommissionPercentage}
                                    name="combinedCommissionPercentage"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Combined Total %"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('combinedCommissionPercentage')}
                                    helperText={this.fieldErrorMessage('combinedCommissionPercentage')}
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <br/>
                                <TextField autoComplete="new"
                                    value={totalCommissionPercentage}
                                    name="totalCommissionPercentage"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="TRU Total %"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('totalCommissionPercentage')}
                                    helperText={this.fieldErrorMessage('totalCommissionPercentage')}
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <TextField autoComplete="new"
                                    name="totalCommissionAmount"
                                    value={totalCommissionAmount}
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="TRU Total $"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('totalCommissionAmount')}
                                    helperText={this.fieldErrorMessage('totalCommissionAmount')}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <br/>
                                <TextField autoComplete="new"
                                    value={tamraCommissionPercentage}
                                    name="tamraCommissionPercentage"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Tamra %"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('tamraCommissionPercentage')}
                                    helperText={this.fieldErrorMessage('tamraCommissionPercentage')}
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <TextField autoComplete="new"
                                    name="tamraCommissionAmount"
                                    value={tamraCommissionAmount}
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Tamra $"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('tamraCommissionAmount')}
                                    helperText={this.fieldErrorMessage('tamraCommissionAmount')}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <br/>
                                <TextField autoComplete="new"
                                    value={agentCommissionPercentage}
                                    name="agentCommissionPercentage"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Agent %"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('agentCommissionPercentage')}
                                    helperText={this.fieldErrorMessage('agentCommissionPercentage')}
                                    type="number"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <TextField autoComplete="new"
                                    name="agentCommissionAmount"
                                    value={agentCommissionAmount}
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Agent $"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('agentCommissionAmount')}
                                    helperText={this.fieldErrorMessage('agentCommissionAmount')}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <br/>
                                <TextField autoComplete="new"
                                    name="fmlsCommission"
                                    value={fmlsCommission}
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="FMLS $"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('fmlsCommission')}
                                    helperText={this.fieldErrorMessage('fmlsCommission')}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <TextField autoComplete="new"
                                    name="remaxCommission"
                                    value={remaxCommission}
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Remax $"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('remaxCommission')}
                                    helperText={this.fieldErrorMessage('remaxCommission')}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <TextField autoComplete="new"
                                    name="randyCommission"
                                    value={randyCommission}
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Randy $"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('randyCommission')}
                                    helperText={this.fieldErrorMessage('randyCommission')}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    required={!closedPending}
                                />
                                <TextField autoComplete="new"
                                   name="tamraCmnCommission"
                                   value={tamraCmnCommission}
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="Tamra CMN $"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('tamraCmnCommission')}
                                   helperText={this.fieldErrorMessage('tamraCmnCommission')}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                   }}
                                   required={!closedPending}
                                />
                                <TextField autoComplete="new"
                                   name="agentCmnCommission"
                                   value={agentCmnCommission}
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="Agent CMN $"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('agentCmnCommission')}
                                   helperText={this.fieldErrorMessage('agentCmnCommission')}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                   }}
                                   required={!closedPending}
                                />
                                <TextField autoComplete="new"
                                   name="gsmCommission"
                                   value={gsmCommission}
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="GSM $"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('gsmCommission')}
                                   helperText={this.fieldErrorMessage('gsmCommission')}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                   }}
                                   required={!closedPending}
                                />
                                {/* Todo: Add new commission types here */}
                                <TextField autoComplete="new"
                                   value={notes}
                                   name="notes"
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="Notes"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('notes')}
                                   helperText={this.fieldErrorMessage('notes')}
                                   fullWidth
                                />
                            </div>
                            {type !== 'Lease' &&
                            <div>
                                <Divider className={classes.divider} />
                                <br/>
                                <TextField autoComplete="new"
                                    value={planAndElevation}
                                    name="planAndElevation"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Plan and Elevation"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('planAndElevation')}
                                    helperText={this.fieldErrorMessage('planAndElevation')}
                                    required
                                    fullWidth
                                />
                                <br/>
                                {payment === '' &&
                                <div className={classes.fullWidth}>
                                    <Divider className={classes.divider} />
                                    <FormControl className={classes.dropdown}>
                                        <InputLabel htmlFor="payment-select">Finance</InputLabel>
                                        <Select
                                            id="payment-select"
                                            name="payment"
                                            value={payment}
                                            onChange={(e) => this.handleSelectChange(e)}
                                        >
                                            {/*<MenuItem value="">--Select One--</MenuItem>*/}
                                            <MenuItem value="Preferred Lender">Preferred Lender</MenuItem>
                                            <MenuItem value="Outside Lender">Outside Lender</MenuItem>
                                            <MenuItem value="Cash">Cash</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                }
                                {payment !== 'Cash' &&
                                <div>
                                    <Divider className={classes.divider} />
                                    <strong className={classes.fullWidth}>Lender</strong>
                                    {(lenderId||null) === null &&
                                    <div>
                                        <div className={classes.dropdown}>
                                            <Autocomplete
                                                id={'lender-list'}
                                                options={lenders}
                                                getOptionLabel={option => option.name}
                                                renderInput={params => <TextField autoComplete="new"  {...params} label="Select Lender"/>}
                                                onChange={(e, v) => this.setState({selectedLender: v})}
                                            />
                                        </div>
                                        <div>
                                            <Button variant="contained" color="primary" disabled={selectedLender === null} onClick={() => this.loadLender(selectedLender)} className={classes.inlineButton}>Load</Button>
                                        </div>
                                    </div>
                                    }
                                    <br/>
                                    <TextField autoComplete="new"
                                        value={lenderName}
                                        name="lenderName"
                                        onChange={(e) => this.handleFieldChange(e)}
                                        label="Lender's Name"
                                        className={classes.textField}
                                        margin="normal"
                                        error={this.fieldHasError('lenderName')}
                                        helperText={this.fieldErrorMessage('lenderName')}
                                        required={!closedPending}
                                    />
                                    {/*<TextField autoComplete="new"*/}
                                    {/*    value={lenderEmail}*/}
                                    {/*    name="lenderEmail"*/}
                                    {/*    onChange={(e) => this.handleFieldChange(e)}*/}
                                    {/*    label="Email Address"*/}
                                    {/*    className={classes.textField}*/}
                                    {/*    margin="normal"*/}
                                    {/*    error={this.fieldHasError('lenderEmail')}*/}
                                    {/*    helperText={this.fieldErrorMessage('lenderEmail')}*/}
                                    {/*/>*/}
                                    <TextField autoComplete="new"
                                        value={lenderPhone}
                                        name="lenderPhone"
                                        onChange={(e) => this.handleFieldChange(e)}
                                        label="Phone (10 Digits)"
                                        className={classes.textField}
                                        margin="normal"
                                        error={this.fieldHasError('lenderPhone')}
                                        helperText={this.fieldErrorMessage('lenderPhone')}
                                    />
                                    <TextField autoComplete="new"
                                        value={lenderAddress1}
                                        name="lenderAddress1"
                                        onChange={(e) => this.handleFieldChange(e)}
                                        label="Address 1"
                                        className={classes.textField}
                                        margin="normal"
                                        error={this.fieldHasError('lenderAddress1')}
                                        helperText={this.fieldErrorMessage('lenderAddress1')}
                                    />
                                    <TextField autoComplete="new"
                                        value={lenderAddress2}
                                        name="lenderAddress2"
                                        onChange={(e) => this.handleFieldChange(e)}
                                        label="Address 2"
                                        className={classes.textField}
                                        margin="normal"
                                    />
                                    <TextField autoComplete="new"
                                        value={lenderCity}
                                        name="lenderCity"
                                        onChange={(e) => this.handleFieldChange(e)}
                                        label="City"
                                        className={classes.textField}
                                        margin="normal"
                                        error={this.fieldHasError('lenderCity')}
                                        helperText={this.fieldErrorMessage('lenderCity')}
                                    />
                                    <TextField autoComplete="new"
                                        value={lenderState}
                                        name="lenderState"
                                        onChange={(e) => this.handleFieldChange(e)}
                                        label="State"
                                        className={classes.textField}
                                        margin="normal"
                                        error={this.fieldHasError('lenderState')}
                                        helperText={this.fieldErrorMessage('lenderState')}
                                    />
                                    <TextField autoComplete="new"
                                        value={lenderZip}
                                        name="lenderZip"
                                        onChange={(e) => this.handleFieldChange(e)}
                                        label="Zip"
                                        className={classes.textField}
                                        margin="normal"
                                        error={this.fieldHasError('lenderZip')}
                                        helperText={this.fieldErrorMessage('lenderZip')}
                                    />
                                    <Divider className={classes.divider} />
                                    <strong className={classes.fullWidth}>Loan Officer</strong>
                                    <br/>
                                    <TextField autoComplete="new"
                                               value={loanOfficerName}
                                               name="loanOfficerName"
                                               onChange={(e) => this.handleFieldChange(e)}
                                               label="Name"
                                               className={classes.textField}
                                               margin="normal"
                                               error={this.fieldHasError('loanOfficerName')}
                                               helperText={this.fieldErrorMessage('loanOfficerName')}
                                               required={!closedPending}
                                    />
                                    <TextField autoComplete="new"
                                               value={loanOfficerPhone}
                                               name="loanOfficerPhone"
                                               onChange={(e) => this.handleFieldChange(e)}
                                               label="Phone # (10 Digits)"
                                               className={classes.textField}
                                               margin="normal"
                                               error={this.fieldHasError('loanOfficerPhone')}
                                               helperText={this.fieldErrorMessage('loanOfficerPhone')}
                                               // required={!closedPending}
                                    />
                                    <TextField autoComplete="new"
                                               value={loanOfficerEmail}
                                               name="loanOfficerEmail"
                                               onChange={(e) => this.handleFieldChange(e)}
                                               label="Email"
                                               className={classes.textField}
                                               margin="normal"
                                               error={this.fieldHasError('loanOfficerEmail')}
                                               helperText={this.fieldErrorMessage('loanOfficerEmail')}
                                               // required={!closedPending}
                                    />
                                </div>
                                }
                            </div>
                            }
                        </div>
                        <br/>
                        <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.setState({isLoading: true}, () => this.close())} className={classes.button}>{saveBtnText}</Button>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(CloseListing);