import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Agent from './Forms/Agent'
import CoopAgent from './Forms/CoopAgent'
import Attorney from './Forms/Attorney'
import Broker from './Forms/Broker'
import Builder from './Forms/Builder'
import Community from './Forms/Community'
import AddConstruction from './Forms/AddConstruction'
import Contact from './Forms/Contact'
import Lender from './Forms/Lender'
import LoanOfficer from './Forms/LoanOfficer'
import AddListing from './Forms/AddListing'
import Property from './Forms/Property'
import Dialog from '../Components/Dialog'
import {withStyles} from "@material-ui/core/styles/index";
import {request} from "../utils";
import Loading from "./Loading";
import LeadSource from "./Forms/LeadSource";

const styles = theme => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
});

class ManageControls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            option: '',
            showDialog: false,
            dialogTitle: '',
            dialogBody: null,
            dialogButtons: [],
            saving: false
        };
    }

    handleChange = event => {
        this.setState({option: event.target.value});
    };

    closeDialog = () => {
        this.setState({showDialog: false, dialogTitle: '', dialogBody: null});
    };

    update(action,data,successMessage){
        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({saving: false, showDialog: false, dialogTitle: '', dialogBody: null}, () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.setState({saving: false}, () => this.props.showSnack('error',error))
            })
    }

    addAgent = (data) => {
        const successMessage = 'Successfully Added TRU Agent!';
        const action = '/Manage/addAgent';
        this.update(action,data,successMessage);
    };

    addCoopAgent = (data) => {
        const successMessage = 'Successfully Added Co-op Agent!';
        const action = '/Manage/addCoopAgent';
        this.update(action,data,successMessage);
    };

    addAttorney = (data) => {
        const successMessage = 'Successfully Added Attorney!';
        const action = '/Manage/addAttorney';
        this.update(action,data,successMessage);
    };

    addBroker = (data) => {
        const successMessage = 'Successfully Added Broker!';
        const action = '/Manage/addBroker';
        this.update(action,data,successMessage);
    };

    addBuilder = (data) => {
        const successMessage = 'Successfully Added Builder!';
        const action = '/Manage/addBuilder';
        this.update(action,data,successMessage);
    };

    addCommunity = (data) => {
        const successMessage = 'Successfully Added Community!';
        const action = '/Manage/addCommunity';
        this.update(action,data,successMessage);
    };

    addContact = (data) => {
        const successMessage = 'Successfully Added Contact!';
        const action = '/Manage/addContact';
        this.update(action,data,successMessage);
    };

    addLeadSource = (data) => {
        const successMessage = 'Successfully Added Lead Source!';
        const action = '/Manage/addLeadSource';
        this.update(action,data,successMessage);
    };

    addLender = (data) => {
        const successMessage = 'Successfully Added Lender!';
        const action = '/Manage/addLender';
        this.update(action,data,successMessage);
    };

    addLoanOfficer = (data) => {
        const successMessage = 'Successfully Added LoanOfficer!';
        const action = '/Manage/addLoanOfficer';
        this.update(action,data,successMessage);
    };

    addProperty = (data) => {
        const successMessage = 'Successfully Added Property!';
        const action = '/Manage/addProperty';
        this.update(action,data,successMessage);
    };

    openDialog = (action, data) => {
        let title = '';
        let body = null;

        switch (action) {
            case 'addListing':
                title = 'Add Sales Details';
                body = (
                    <AddListing success={(m) => this.setState({showDialog: false, dialogTitle: '', dialogBody: null}, () => this.props.showSnack('success',m))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'addNewConstruction':
                title = 'Add Property Details';
                body = (
                    <AddConstruction success={(m) => this.setState({showDialog: false, dialogTitle: '', dialogBody: null}, () => this.props.showSnack('success',m))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'agent':
                title = 'Add TRU Agent';
                body = (
                    <Agent save={(a) => this.setState({saving:true}, () => this.addAgent(a))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'coopAgent':
                title = 'Add Co-op Agent';
                body = (
                    <CoopAgent save={(a) => this.setState({saving:true}, () => this.addCoopAgent(a))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'attorney':
                title = 'Add Attorney';
                body = (
                    <Attorney save={(a) => this.setState({saving:true}, () => this.addAttorney(a))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'broker':
                title = 'Add Broker';
                body = (
                    <Broker save={(b) => this.setState({saving:true}, () => this.addBroker(b))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'builder':
                title = 'Add Builder';
                body = (
                    <Builder save={(b) => this.setState({saving:true}, () => this.addBuilder(b))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'community':
                title = 'Add Community';
                body = (
                    <Community save={(c) => this.setState({saving:true}, () => this.addCommunity(c))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'contact':
                title = 'Add Contact';
                body = (
                    <Contact isReusable={true} save={(c) => this.setState({saving:true}, () => this.addContact(c))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'leadSource':
                title = 'Add Lead Source';
                body = (
                    <LeadSource save={(l) => this.setState({saving:true}, () => this.addLeadSource(l))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'lender':
                title = 'Add Lender';
                body = (
                    <Lender save={(l) => this.setState({saving:true}, () => this.addLender(l))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'loanOfficer':
                title = 'Add Loan Officer';
                body = (
                    <LoanOfficer showLender={true} save={(l) => this.setState({saving:true}, () => this.addLoanOfficer(l))} showSnack={this.props.showSnack}/>
                );
                break;
            case 'property':
                title = 'Add Property';
                body = (
                    <Property save={(p) => this.setState({saving:true}, () => this.addProperty(p))} showSnack={this.props.showSnack}/>
                );
                break;
            default:
                break;
        }

        this.setState({showDialog: true, dialogTitle: title, dialogBody: body});
    };

    render() {
        const { classes } = this.props;
        const { option, showDialog, dialogTitle, dialogBody, dialogButtons, saving } = this.state;

        return (
            <div>
                <div>
                    <Button variant="contained" size="small" color="primary" className={classes.margin}
                            onClick={() => this.openDialog('addListing')}>
                        Add Sales Details
                    </Button>
                    <Button variant="contained" size="small" color="primary" className={classes.margin}
                            onClick={() => this.openDialog('addNewConstruction')}>
                        Add Property Details
                    </Button>
                    <FormControl className={classes.formControl}>
                        {/*<InputLabel id="other-select-label">Add Other</InputLabel>*/}
                        <Select
                            // labelId="other-select-label"
                            id="other-select"
                            value={option}
                            onChange={(e) => this.handleChange(e)}
                        >
                            {/*<MenuItem value=""><em>-- SELECT ONE --</em></MenuItem>*/}
                            <MenuItem value={'agent'}>TRU Agent</MenuItem>
                            <MenuItem value={'coopAgent'}>Co-op Agent</MenuItem>
                            <MenuItem value={'attorney'}>Attorney</MenuItem>
                            <MenuItem value={'broker'}>Broker</MenuItem>
                            <MenuItem value={'builder'}>Builder</MenuItem>
                            <MenuItem value={'community'}>Community</MenuItem>
                            <MenuItem value={'contact'}>Contact</MenuItem>
                            <MenuItem value={'leadSource'}>Lead Source</MenuItem>
                            <MenuItem value={'lender'}>Lender</MenuItem>
                            <MenuItem value={'loanOfficer'}>Loan Officer</MenuItem>
                            <MenuItem value={'property'}>Property</MenuItem>
                        </Select>
                        <FormHelperText>Other data you would like to add separately</FormHelperText>
                    </FormControl>
                    <Fab size="small" color="secondary" aria-label="add" className={classes.margin} disabled={option === ''} onClick={() => this.openDialog(option)}>
                        <AddIcon/>
                    </Fab>
                </div>
                {saving &&
                <Loading/>
                }
                <Dialog closeCallback={() => this.closeDialog()} showDialog={showDialog} title={dialogTitle} bodyHtml={dialogBody} buttons={dialogButtons}/>
            </div>
        );
    }
}

export default withStyles(styles)(ManageControls);