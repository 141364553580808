import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField, validatePrice, validatePercentage} from '../../utils'
import _ from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Property from './Property';
import Loading from '../Loading';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddContract from "./AddContract";
import AddCloseDate from "./AddCloseDate";
import FormHelperText from "@material-ui/core/FormHelperText";
import Attorney from "./Attorney";
import Broker from "./Broker";
import Chip from '@material-ui/core/Chip';
import AddIcon from "@material-ui/icons/Add";
import Divider from '@material-ui/core/Divider';
import Contact from "./Contact";
import {CircularProgress} from "@material-ui/core";
// import moment from 'moment'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    agentWrapper: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > * > *': {
            margin: theme.spacing(0.5),
        },
    },
    contactWrapper: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > * > *': {
            margin: theme.spacing(0.5),
        },
    },
    countOfTotal: {
        textAlign: 'right',
        fontSize: 10
    },
    inlineProgressWrapper: {
        display: 'inline-block',
        position: 'relative',
        height: theme.spacing(3),
        width: theme.spacing(3),
    },
    inlineProgress: {
        position: 'absolute',
        top: theme.spacing(.5),
        left: theme.spacing(.5),
    },
    associationWarning: {
        margin: theme.spacing(1),
        fontSize: 12,
        fontWeight: 300
    },
    contactListWrapper: {

    }
});

class AddListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            builder: '',
            constructions: [],
            loadingConstructions: false,
            constructionId: '',
            verifyingConstruction: false,
            constructionEligible: false,

            propertyId: '',
            properties: [],
            property: {},
            propertyMode: 'existing',
            loadingProperties: false,

            loadingListing: false,
            savingListing: false,

            relationship: '',
            type: '',
            status: '',
            signReturned: '',
            reasonSignNotReturned: '',
            
            buyers: [],
            lessees: [],
            seller: null,
            lessor: null,
            contacts: null,

            loadingBrokers: false,
            brokers: [],
            remaxTruId: '',
            listingBroker: {},
            listingBrokerMode: 'tw',
            listingBrokerId: '',
            sellingBroker: {},
            sellingBrokerMode: 'existing',
            sellingBrokerId: '',
            hasSellingBroker: false,

            agents: [], //[{agentId:'1234',name:'Dan'},{agentId:'9876',name:'Bob'}],
            agentsAtContract: [],
            agentsAtClosing: [],
            selectedAgentId: '',

            leadSources: [],
            leadSource: '',
            leadSourceMode: 'existing',

            closeDate: null,
            contract: null,

            closingPhotosReceived: false, //(New construction only)

            //closingcost
            sellerPaid: '0',
            lenderPaid: '0',
            buyerPaid: 'N/A',

            //// listingCommission: {},
            combinedCommissionPercentage: '',
            totalCommissionPercentage: '',
            totalCommissionAmount: '',
            tamraCommissionPercentage: '',
            agentCommissionPercentage: '',
            tamraCommissionAmount: '',
            agentCommissionAmount: '',
            fmlsCommission: '',
            remaxCommission: '',
            randyCommission: '',
            tamraCmnCommission: '',
            agentCmnCommission: '',
            gsmCommission: '',

            attorneyId: '',
            attorneys: [],
            attorney: {},
            attorneyMode: 'existing',
            loadingAttorneys: false,

            loadingAgents: false,
            loadingContacts: false,
            loadingLeadSources: false,
            
            notes: '',

            validations: [],
            activeStep: 0
        };
    }

    componentDidMount() {

    }

    getConstructions() {
        if(this.state.constructions.length > 0) {
            this.setState({loadingConstructions: false})
        } else {
            request(
                'GET',
                '/Manage/getConstructionsForListing',
                null,
                (data) => {
                    this.setState({constructions: data.constructions, loadingConstructions: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getProperties() {
        if(this.state.properties.length > 0) {
            this.setState({loadingProperties: false})
        } else {
            request(
                'GET',
                '/Manage/getProperties?salesDetails=true',
                null,
                (data) => {
                    this.setState({properties: data.properties, loadingProperties: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getAttorneys() {
        if(this.state.attorneys.length > 0) {
            this.setState({loadingAttorneys: false})
        } else {
            request(
                'GET',
                '/Manage/getAttorneys',
                null,
                (data) => {
                    this.setState({attorneys: data.attorneys, loadingAttorneys: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getLeadSources() {
        if(this.state.leadSources.length > 0) {
            this.setState({loadingLeadSources: false})
        } else {
            request(
                'GET',
                '/Manage/getLeadSources',
                null,
                (data) => {
                    this.setState({leadSources: data.leadSources, loadingLeadSources: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getContacts() {
        // const relationship = this.state.relationship;

        request(
            'GET',
            '/Manage/getContacts',
            null,
            (data) => {
                this.setState({contacts: data.contacts, loadingContacts: false})
            },
            (error) => {
                this.handleError(error);
            })
    }
    
    getAgents() {
        if(this.state.agents.length > 0) {
            this.setState({loadingAgents: false})
        } else {
            request(
                'GET',
                '/Manage/getAgents',
                null,
                (data) => {
                    this.setState({agents: data.agents, loadingAgents: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }
    
    getBrokers() {
        if(this.state.brokers.length > 0) {
            this.setState({loadingBrokers: false})
        } else {
            request(
                'GET',
                '/Manage/getBrokers',
                null,
                (data) => {
                    this.setState({brokers: data.brokers, remaxTruId: data.remaxTruId, listingBrokerId: data.remaxTruId||'', loadingBrokers: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getConstructionEligibility(constructionId){
        request(
            'GET',
            '/Manage/getConstructionEligibility?constructionId='+constructionId,
            null,
            (data) => {
                this.setState({constructionEligible: true, verifyingConstruction: false})
            },
            (error) => {
                this.setState({
                    constructionEligible: false,
                    verifyingConstruction: false,
                }, () => this.props.showSnack('error',error))
            })
    }

    handleError(error){
        this.setState({
            loadingConstructions: false,
            loadingProperties: false,
            loadingAttorneys: false,
            loadingLeadSources: false,
            loadingBrokers: false,
            loadingAgents: false,
            loadingContacts: false,
            savingListing: false,
            verifyingConstruction: false,
        }, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'relationship',
            'type',
            'status',
            'leadSource',
            'sellerPaid',
            'lenderPaid',
            'buyerPaid',
            'signReturned',
            'reasonSignNotReturned',
            'notes',
            'totalCommissionPercentage',
            'totalCommissionAmount',
            'tamraCommissionPercentage',
            'agentCommissionPercentage',
            'tamraCommissionAmount',
            'agentCommissionAmount',
            'fmlsCommission',
            'remaxCommission',
            'randyCommission',
            'tamraCmnCommission',
            'agentCmnCommission',
            'gsmCommission'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateFields(fieldsToValidate) {
        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    };

    areFieldsValid(fieldsToValidate){
        if(this.state.savingListing){
            return false;
        }
        const validations = this.validateFields(fieldsToValidate);
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'status':
                isValid = (value || null) !== null;
                invalidationReason = 'Please select status.';
                break;
            case 'relationship':
                isValid = (value || null) !== null;
                invalidationReason = 'Please select relationship.';
                break;
            case 'type':
                isValid = (value || null) !== null;
                invalidationReason = 'Please select type.';
                break;
            case 'leadSource':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            case 'sellerPaid':
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'lenderPaid':
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'buyerPaid':
                // if(value.length !== 0){
                //     isValid = validatePrice(value);
                //     invalidationReason = 'Please provide a valid amount.';
                // }
                break;
            case 'signReturned':
                // isValid = validateField(value);
                // invalidationReason = 'Please enter .';
                break;
            case 'reasonSignNotReturned':
                if(this.state.signReturned === 'No'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the reason the sign was not returned.';
                }
                break;
            case 'notes':
                // isValid = validateStage(value);
                // invalidationReason = 'Please enter .';
                break;
            case 'builder':
                // if(this.state.type === 'Resale'){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter the Builder\'s name.';
                // }
                break;
            case 'combinedCommissionPercentage':
                if(value.length !== 0){
                    isValid = validatePercentage(value);
                    invalidationReason = 'Please provide a valid percentage.';
                }
                break;
            case 'totalCommissionPercentage':
                if(value.length !== 0){
                    isValid = validatePercentage(value);
                    invalidationReason = 'Please provide a valid percentage.';
                }
                break;
            case 'totalCommissionAmount':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'tamraCommissionPercentage':
                if(value.length !== 0){
                    isValid = validatePercentage(value);
                    invalidationReason = 'Please provide a valid percentage.';
                }
                break;
            case 'agentCommissionPercentage':
                if(value.length !== 0){
                    isValid = validatePercentage(value);
                    invalidationReason = 'Please provide a valid percentage.';
                }
                break;
            case 'tamraCommissionAmount':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'agentCommissionAmount':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'fmlsCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'remaxCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'randyCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'tamraCmnCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'agentCmnCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'gsmCommission':
                if(value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        switch(field){
            case 'status':
                break;
            case 'relationship':
                break;
            case 'type':
                break;
            case 'leadSource':
                break;
            case 'sellerPaid':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'lenderPaid':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'buyerPaid':
                break;
            case 'signReturned':
                break;
            case 'reasonSignNotReturned':
                break;
            case 'notes':
                break;
            case 'builder':
                break;
            case 'combinedCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'totalCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'totalCommissionAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'tamraCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'agentCommissionPercentage':
                if(value < 0){
                    value = 0;
                } else if(value > 100) {
                    value = 100;
                }
                break;
            case 'tamraCommissionAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'agentCommissionAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'fmlsCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'remaxCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'randyCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'tamraCmnCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'agentCmnCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'gsmCommission':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        if(field === 'type'){
            this.setState({[field]: value,relationship: ''});
        } else if(field === 'constructionId') {
            const constructions = this.state.constructions;
            const vIndex = _.findIndex(constructions, {constructionId: value});
            if(vIndex > -1){
                const construction = constructions[vIndex] || null;
                const builder = construction !== null ? (construction.builder || '') : '';
                this.setState({[field]: value,builder,verifyingConstruction: true}, () => this.getConstructionEligibility(value));
            } else {
                this.setState({[field]: value});
            }
        } else if(field === 'listingBrokerMode'){
            if(value === 'tw'){
                this.setState({[field]: value, listingBrokerId: this.state.remaxTruId});
            } else {
                this.setState({[field]: value});
            }
        } else {
            this.setState({[field]: value});
        }
    }

    handleConstructionChange(construction) {
        if((construction||null) !== null && (construction.constructionId||null) !== null){
            const constructionId = construction.constructionId;
            const constructions = this.state.constructions;
            const vIndex = _.findIndex(constructions, {constructionId: constructionId});
            if(vIndex > -1){
                const construction = constructions[vIndex] || null;
                const builder = construction !== null ? (construction.builder || '') : '';
                this.setState({constructionId: constructionId,builder,verifyingConstruction: true}, () => this.getConstructionEligibility(constructionId));
            } else {
                this.setState({constructionId: ''});
            }
        } else {
            this.setState({constructionId: ''});
        }
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        if(field === 'leadSourceMode'){
            const leadSourceMode = value === 'existing' ? 'new' : 'existing';
            this.setState({leadSourceMode});
        } else {
            this.setState({[field]: !value});
        }
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    handleNext(next){
        const step = this.state.activeStep;
        const type = this.state.type;

        if(next === 'builder' && type === 'New Construction'){
            this.setState({activeStep: step + 1, loadingConstructions: true}, () => this.getConstructions())
        } else if (next === 'property' && type !== 'New Construction') {
            this.setState({activeStep: step + 1, loadingProperties: true}, () => this.getProperties())
        } else if (next === 'attorney' && type !== 'Lease') {
            this.setState({activeStep: step + 1, loadingAttorneys: true}, () => this.getAttorneys())
        } else if (next === 'other') {
            this.setState({activeStep: step + 1, loadingLeadSources: true}, () => this.getLeadSources())
        } else if (next === 'broker') {
            this.setState({activeStep: step + 1, loadingBrokers: true}, () => this.getBrokers())
        } else if (next === 'agent') {
            this.setState({activeStep: step + 1, loadingAgents: true}, () => this.getAgents())
        } else if (next === 'party1') {
            this.setState({activeStep: step + 1, loadingContacts: true}, () => this.getContacts())
        } else {
            this.setState({activeStep: step + 1})
        }
    }

    // handleBack() {
    //     const step = this.state.activeStep;
    //     this.setState({activeStep: step - 1})
    // }
    //
    // handleReset() {
    //     this.setState({activeStep: 0})
    // }

    stepClicked(clicked) {
        const step = this.state.activeStep;
        if(clicked < step){
            this.setState({activeStep: clicked})
        }
    }

    isListingValid(){
        if(this.state.savingListing){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;

    }

    saveListing() {
        const {
            constructionId,
            builder,
            propertyId,
            property,
            relationship,
            type,
            status,
            buyers,
            lessees,
            seller,
            lessor,
            listingBroker,
            sellingBroker,
            listingBrokerId,
            sellingBrokerId,
            agentsAtContract,
            agentsAtClosing,
            leadSource,
            closeDate,
            contract,
            sellerPaid,
            lenderPaid,
            buyerPaid,
            combinedCommissionPercentage,
            totalCommissionPercentage,
            totalCommissionAmount,
            tamraCommissionPercentage,
            agentCommissionPercentage,
            tamraCommissionAmount,
            agentCommissionAmount,
            fmlsCommission,
            remaxCommission,
            randyCommission,
            tamraCmnCommission,
            agentCmnCommission,
            gsmCommission,
            attorneyId,
            attorney,
            signReturned,
            reasonSignNotReturned,
            closingPhotosReceived,
            notes,
        } = this.state;

        const data = {
            constructionId,
            builder,
            propertyId,
            property: _.isEmpty(property) ? null : property,
            relationship,
            type,
            status,
            buyers,
            lessees,
            seller,
            lessor,
            listingBroker: _.isEmpty(listingBroker) ? null : listingBroker,
            sellingBroker: _.isEmpty(sellingBroker) ? null : sellingBroker,
            listingBrokerId,
            sellingBrokerId,
            agentsAtContract,
            agentsAtClosing,
            leadSource,
            closeDate,
            contract,
            closingCost: {
                sellerPaid,
                lenderPaid,
                buyerPaid,
            },
            combinedCommissionPercentage,
            totalCommissionPercentage,
            totalCommissionAmount,
            tamraCommissionPercentage,
            agentCommissionPercentage,
            tamraCommissionAmount,
            agentCommissionAmount,
            fmlsCommission,
            remaxCommission,
            randyCommission,
            tamraCmnCommission,
            agentCmnCommission,
            gsmCommission,
            attorneyId,
            attorney: _.isEmpty(attorney) ? null : attorney,
            signReturned,
            reasonSignNotReturned,
            closingPhotosReceived,
            notes,
        };

        const successMessage = 'Successfully added Sales Details!';

        request(
            'POST',
            '/Manage/addListing',
            data,
            (data) => {
                this.setState(
                    {savingListing: false},
                    () => this.props.success(successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    addAgentAtContract(){
        const agentId = this.state.selectedAgentId;
        const agentsAtContract = this.state.agentsAtContract;
        const agents = this.state.agents;

        const vIndex = _.findIndex(agents, {agentId: agentId});
        if(vIndex > -1){
            const aac = _.findIndex(agentsAtContract, {agentId: agentId});
            if(aac === -1){
                agentsAtContract.push(agents[vIndex]);
                this.setState({agentsAtContract: agentsAtContract});
            } else {
                this.props.showSnack('warning','Agent already added.');
            }
        }
    }

    addAgentAtClosing(){
        const agentId = this.state.selectedAgentId;
        const agentsAtClosing = this.state.agentsAtClosing;
        const agents = this.state.agents;

        const vIndex = _.findIndex(agents, {agentId: agentId});
        if(vIndex > -1){
            const aac = _.findIndex(agentsAtClosing, {agentId: agentId});
            if(aac === -1){
                agentsAtClosing.push(agents[vIndex]);
                this.setState({agentsAtClosing: agentsAtClosing});
            } else {
                this.props.showSnack('warning','Agent already added.');
            }
        }
    }

    removeAgentAtContract(agentId){
        const agentsAtContract = this.state.agentsAtContract;
        const clone = _.cloneDeep(agentsAtContract);
        const vIndex = _.findIndex(clone, {agentId: agentId});
        if(vIndex > -1){
            clone.splice(vIndex,1);
            this.setState({agentsAtContract: clone});
        }
    }

    removeAgentAtClosing(agentId){
        const agentsAtClosing = this.state.agentsAtClosing;
        const clone = _.cloneDeep(agentsAtClosing);
        const vIndex = _.findIndex(clone, {agentId: agentId});
        if(vIndex > -1){
            clone.splice(vIndex,1);
            this.setState({agentsAtClosing: clone});
        }
    }

    addBuyer(i){
        const buyers = this.state.buyers;
        buyers.push(i);
        this.setState({buyers});
    }

    removeBuyer(i){
        const buyers = this.state.buyers;
        buyers.splice(i,1);
        this.setState({buyers});
    }

    addLessee(i){
        const lessees = this.state.lessees;
        lessees.push(i);
        this.setState({lessees});
    }

    removeLessee(i){
        const lessees = this.state.lessees;
        lessees.splice(i,1);
        this.setState({lessees});
    }

    render() {
        const {classes} = this.props;
        const {
            loadingAttorneys,
            loadingConstructions,
            loadingProperties,
            loadingLeadSources,
            loadingBrokers,
            loadingAgents,
            loadingContacts,
            savingListing,
            activeStep,
            constructions,
            attorneys,
            attorneyMode,
            brokers,
            agents,
            properties,
            propertyMode,
            leadSourceMode,
            hasSellingBroker,
            listingBrokerMode,
            sellingBrokerMode,

        } = this.state;

        const {
            constructionEligible,
            verifyingConstruction,
            constructionId,
            builder,
            propertyId,
            property,

            relationship,
            type,
            status,

            // editDefaultContact,
            buyers,
            lessees,
            seller,
            lessor,
            contacts,
            
            listingBroker,
            sellingBroker,
            listingBrokerId,
            sellingBrokerId,

            agentsAtContract,
            agentsAtClosing,
            selectedAgentId,

            leadSources,
            leadSource,

            closeDate,
            contract,

            sellerPaid,
            lenderPaid,
            buyerPaid,

            combinedCommissionPercentage,
            totalCommissionPercentage,
            totalCommissionAmount,
            tamraCommissionPercentage,
            agentCommissionPercentage,
            tamraCommissionAmount,
            agentCommissionAmount,
            fmlsCommission,
            remaxCommission,
            randyCommission,
            tamraCmnCommission,
            agentCmnCommission,
            gsmCommission,

            attorneyId,
            attorney,

            signReturned,
            reasonSignNotReturned,
            closingPhotosReceived, //(New construction only)
            notes,
        } = this.state;

        const intro = (
            <div>
                <Typography>Use this wizard to Add Sales Details.</Typography>
                <br/>
                <Button variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
            </div>
        );

        const outro = (
            <div>
                {(this.isListingValid()) &&
                <div className={classes.formControl}>
                    <Typography>You have completed the Sales Details wizard. Click the save button below to save and finish.</Typography>
                </div>
                }
                <br/>
                <Button disabled={!this.isListingValid()} variant="contained" color="primary" onClick={() => this.setState({savingListing: true}, () => this.saveListing())} className={classes.button}>SAVE</Button>
            </div>
        );
        
        const typeRelationshipStatus = (
            <div>
                <FormControl className={classes.dropdown} required>
                    <InputLabel htmlFor="listing-type-select">Type</InputLabel>
                    <Select
                        id="listing-type-select"
                        name="type"
                        value={type}
                        onChange={(e) => this.handleSelectChange(e)}
                    >
                        <MenuItem value="New Construction">New Construction</MenuItem>
                        <MenuItem value="Resale">Resale</MenuItem>
                        <MenuItem value="Lease">Lease</MenuItem>
                    </Select>
                </FormControl>
                <br/>
                <FormControl className={classes.dropdown} required>
                    <InputLabel htmlFor="listing-relationship-select">Relationship</InputLabel>
                    <Select
                        id="listing-relationship-select"
                        name="relationship"
                        value={relationship}
                        onChange={(e) => this.handleSelectChange(e)}
                        disabled={type === ''}
                    >
                        {type === 'Lease' &&
                        <MenuItem value="Lessor">Lessor</MenuItem>
                        }
                        {type === 'Lease' &&
                        <MenuItem value="Lessee">Lessee</MenuItem>
                        }
                        {type !== 'Lease' &&
                        <MenuItem value="Seller">Seller</MenuItem>
                        }
                        {type !== 'Lease' &&
                        <MenuItem value="Buyer">Buyer</MenuItem>
                        }
                        {type !== 'Lease' &&
                        <MenuItem value="Seller and Buyer">Seller and Buyer</MenuItem>
                        }
                    </Select>
                </FormControl>
                <br/>
                <FormControl className={classes.dropdown} required>
                    <InputLabel htmlFor="listing-status-select">Status</InputLabel>
                    <Select
                        id="listing-status-select"
                        name="status"
                        value={status}
                        onChange={(e) => this.handleSelectChange(e)}
                    >
                        <MenuItem value="Under Contract">Under Contract</MenuItem>
                        <MenuItem value="Active">Active</MenuItem>
                        {/*<MenuItem value="Closed">Closed</MenuItem>*/}
                        {/*<MenuItem value="Terminated">Terminated</MenuItem>*/}
                    </Select>
                </FormControl>
                <br/>
                <br/>
                <Button disabled={!this.areFieldsValid(['type','relationship','status'])} variant="contained" color="primary" onClick={() => this.handleNext('builder')} className={classes.button}>NEXT</Button>
            </div>
        );

        const builderSection = (
            <div>
                {(type === 'New Construction' && relationship === 'Seller') &&
                <div>
                    {loadingConstructions ? (
                        <Loading contained={true}/>
                    ) : (
                        <div>
                            <FormControl className={classes.dropdown}>
                                {/*<InputLabel htmlFor="construction-select">Property Details</InputLabel>*/}
                                {/*<Select*/}
                                {/*    id="construction-select"*/}
                                {/*    name="constructionId"*/}
                                {/*    value={constructionId}*/}
                                {/*    onChange={(e) => this.handleSelectChange(e)}*/}
                                {/*    disabled={verifyingConstruction}*/}
                                {/*>*/}
                                {/*    {constructions.map((item, index) => (*/}
                                {/*        <MenuItem key={item.constructionId} value={item.constructionId}>{item.summary}</MenuItem>*/}
                                {/*    ))}*/}
                                {/*</Select>*/}

                                <Autocomplete
                                    id={'constructions-list'}
                                    options={constructions}
                                    getOptionLabel={option => option.summary}
                                    renderInput={params => <TextField autoComplete="new" {...params} label="Property Details"/>}
                                    onChange={(e, v) => this.handleConstructionChange(v||null)}
                                />
                                <FormHelperText>Select a Property Details to associate with this Sales Details</FormHelperText>
                            </FormControl>

                            <br/>
                            {((constructionId || null) !== null && !constructionEligible) &&
                                <div className={classes.associationWarning}>
                                    <div>The Property Details you selected is already associated with a Sales Details.</div>
                                    <br/>
                                    <div>Please select another Property Details to continue.</div>
                                </div>
                            }
                            <Button disabled={(constructionId || null) === null||verifyingConstruction||!constructionEligible} variant="contained" color="primary" onClick={() => this.handleNext('property')} className={classes.button}>NEXT</Button>
                            {verifyingConstruction &&
                            <div className={classes.inlineProgressWrapper}>
                                <CircularProgress size={24} className={classes.inlineProgress}/>
                            </div>
                            }
                        </div>
                    )}
                </div>
                }
                {(type === 'Resale' || relationship === 'Buyer') &&
                <div>
                    <TextField autoComplete="new"
                        value={builder}
                        name="builder"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Builder Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('builder')}
                        helperText={this.fieldErrorMessage('builder')}
                        // required
                    />
                    <br/>
                    <br/>
                    <Button
                        disabled={!this.areFieldsValid(['builder'])}
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleNext('property')}
                        className={classes.button}
                    >NEXT</Button>
                </div>
                }
                {type === 'Lease' &&
                <div>
                    <Typography>This section does not apply to a lease. Please click the next button below to skip this step.</Typography>
                    <br/>
                    <Button variant="contained" color="primary" onClick={() => this.handleNext('property')} className={classes.button}>NEXT</Button>
                </div>
                }
            </div>
        );

        const propertySection = (
            <div>
                {(type === 'New Construction' && (constructionId||null) !== null) ? (
                    <div>
                        <Typography>The property associated with the Property Details you selected will be used for this listing. Please click the next button below to skip this step.</Typography>
                        <br/>
                        <Button variant="contained" color="primary" onClick={() => this.handleNext('broker')} className={classes.button}>NEXT</Button>
                    </div>
                ) : (
                    <div>
                        {loadingProperties ? (
                            <Loading contained={true}/>
                        ) : (
                            <div>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">Property</FormLabel>
                                    <RadioGroup row aria-label="propertyMode" name="propertyMode" value={propertyMode} onChange={(e) => this.handleSelectChange(e)}>
                                        <FormControlLabel value="existing" control={<Radio/>} label="Select Property"/>
                                        <FormControlLabel value="new" control={<Radio/>} label="New Property"/>
                                    </RadioGroup>
                                </FormControl>
                                {propertyMode === 'existing' ? (
                                    <div>
                                        {loadingProperties ? (
                                            <Loading contained={true}/>
                                        ) : (
                                            <div>
                                                <FormControl className={classes.dropdown}>
                                                    <Select
                                                        id="property-select"
                                                        name="propertyId"
                                                        value={propertyId}
                                                        onChange={(e) => this.handleSelectChange(e)}
                                                    >
                                                        {properties.map((item, index) => (
                                                            <MenuItem key={item.propertyId} value={item.propertyId}>{item.displayAddress}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <br/>
                                                <Button disabled={(propertyId || null) === null} variant="contained" color="primary" onClick={() => this.handleNext('broker')} className={classes.button}>NEXT</Button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <Property lotNumberRequired={type === 'New Construction'} underContract={true} saveBtnText="NEXT" property={property} save={(p) => this.setState({property: p}, () => this.handleNext('broker'))} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );

        const listingBrokerSection = (
            <div>
                {loadingBrokers ? (
                    <Loading contained={true}/>
                ) : (
                    <div>
                        <div>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Listing Broker</FormLabel>
                                <RadioGroup row aria-label="listingBrokerMode" name="listingBrokerMode" value={listingBrokerMode} onChange={(e) => this.handleSelectChange(e)}>
                                    <FormControlLabel value="tw" control={<Radio/>} label="RE/MAX TRU"/>
                                    <FormControlLabel value="existing" control={<Radio/>} label="Select Listing Broker"/>
                                    <FormControlLabel value="new" control={<Radio/>} label="New Listing Broker"/>
                                </RadioGroup>
                            </FormControl>
                            {listingBrokerMode === 'tw' &&
                                <div>
                                    <Button variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                                </div>
                            }
                            {listingBrokerMode === 'existing' &&
                                <div>
                                {loadingBrokers ? (
                                    <Loading contained={true}/>
                                ) : (
                                    <div>
                                        <FormControl className={classes.dropdown}>
                                            <Select
                                                id="listing-broker-select"
                                                name="listingBrokerId"
                                                value={listingBrokerId}
                                                onChange={(e) => this.handleSelectChange(e)}
                                            >
                                                {brokers.map((item, index) => (
                                                    <MenuItem key={'listing-broker-'+item.brokerId} value={item.brokerId}>{item.firm}</MenuItem>
                                                ))}
                                            </Select>
                                            {/*<Autocomplete*/}
                                            {/*    id={'listing-broker-list'}*/}
                                            {/*    options={brokers}*/}
                                            {/*    getOptionLabel={option => option.firm}*/}
                                            {/*    renderInput={params => <TextField autoComplete="new" {...params} label="Listing Broker"/>}*/}
                                            {/*    onChange={(e, v) => this.setState({listingBrokerId: v.brokerId})}*/}
                                            {/*/>*/}
                                            {/*<FormHelperText>Select the Listing Broker</FormHelperText>*/}
                                        </FormControl>

                                        <br/>
                                        <Button disabled={(listingBrokerId || null) === null} variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                                    </div>
                                )}
                                </div>
                            }
                            {listingBrokerMode === 'new' &&
                                <Broker saveBtnText="NEXT" broker={listingBroker} save={(b) => this.setState({listingBroker: b},() => this.handleNext())} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                            }
                        </div>

                    </div>
                )}
            </div>
        );

        const sellingBrokerSection = (
            <div>
                {type === 'Lease' ? (
                    <div>
                        <Typography>This section does not apply to a lease. Please click the next button below to skip this step.</Typography>
                        <br/>
                        <Button variant="contained" color="primary" onClick={() => this.handleNext('party1')} className={classes.button}>NEXT</Button>
                    </div>
                ) : (
                    <div>
                        {loadingBrokers ? (
                            <Loading contained={true}/>
                        ) : (
                            <div>
                                <div>
                                    <FormControlLabel
                                        className={classes.switch}
                                        control={
                                            <Switch
                                                name="hasSellingBroker"
                                                checked={hasSellingBroker}
                                                onChange={(e) => this.handleSwitchChange(e)}
                                                color="primary"
                                            />
                                        }
                                        label="Does this Sales Details have a Selling Broker?"
                                    />
                                    <br/>
                                </div>
                                {hasSellingBroker ? (
                                    <div>
                                        <div>
                                            <FormControl component="fieldset" className={classes.formControl}>
                                                <FormLabel component="legend">Selling Broker</FormLabel>
                                                <RadioGroup row aria-label="sellingBrokerMode" name="sellingBrokerMode" value={sellingBrokerMode} onChange={(e) => this.handleSelectChange(e)}>
                                                    <FormControlLabel value="existing" control={<Radio/>} label="Select Selling Broker"/>
                                                    <FormControlLabel value="new" control={<Radio/>} label="New Selling Broker"/>
                                                </RadioGroup>
                                            </FormControl>
                                            {sellingBrokerMode === 'existing' ? (
                                                <div>
                                                    {loadingBrokers ? (
                                                        <Loading contained={true}/>
                                                    ) : (
                                                        <div>
                                                            <FormControl className={classes.dropdown}>
                                                                <Select
                                                                    id="selling-broker-select"
                                                                    name="sellingBrokerId"
                                                                    value={sellingBrokerId}
                                                                    onChange={(e) => this.handleSelectChange(e)}
                                                                >
                                                                    {brokers.map((item, index) => (
                                                                        <MenuItem key={'selling-broker-'+item.brokerId} value={item.brokerId}>{item.firm}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            <br/>
                                                            <Button disabled={(sellingBrokerId || null) === null} variant="contained" color="primary" onClick={() => this.handleNext('party1')} className={classes.button}>NEXT</Button>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <Broker saveBtnText="NEXT" broker={sellingBroker} save={(b) => this.setState({sellingBroker: b}, () => this.handleNext('party1'))} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <Button variant="contained" color="primary" onClick={() => this.handleNext('party1')} className={classes.button}>NEXT</Button>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );

        const party1 = (
            <div>
                {loadingContacts ? (
                    <Loading contained={true}/>
                ) : (
                    <div>
                        {(relationship === 'Seller' || relationship === 'Seller and Buyer') &&
                        <div>
                            <strong>Seller</strong>
                            <Contact
                                contactType="Seller"
                                hideContactType={true}
                                saveBtnText="NEXT"
                                contact={seller}
                                contacts={contacts}
                                // readOnlyMode={!editDefaultContact}
                                save={(c) => this.setState({seller: c}, () => this.handleNext('party2'))}
                                showSnack={(t,e) => this.props.showSnack(t,e)}
                            />
                        </div>
                        }
                        {relationship === 'Buyer' &&
                        <div>
                            <strong>Buyer</strong>
                            <Contact
                                contactType="Buyer"
                                hideContactType={true}
                                saveBtnText="ADD BUYER"
                                // contact={buyer}
                                contacts={contacts}
                                // readOnlyMode={!editDefaultContact}
                                save={(c) => this.addBuyer(c)}
                                showSnack={(t,e) => this.props.showSnack(t,e)}
                            />
                            <br/>
                            <div className={classes.contactListWrapper}>
                                {buyers.length !== 0 &&
                                <div>
                                    <Divider className={classes.divider} />
                                    <strong>Buyers</strong>
                                    <br/>
                                    <div className={classes.contactWrapper}>
                                        {buyers.length !== 0 &&
                                        <div>
                                            {buyers.map((item, index) => (
                                                <Chip key={'buyer-p1-chip-' + index}
                                                      label={(item.company||null) !== null ? item.company : `${item.firstName} ${item.lastName}`}
                                                      onDelete={() => this.removeBuyer(index)} color="primary"
                                                      variant="outlined"/>
                                            ))}
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                            <br/>
                            <Button variant="contained" color="primary" onClick={() => this.handleNext('party2')} className={classes.button}>NEXT</Button>
                        </div>
                        }
                        {relationship === 'Lessor' &&
                        <div>
                            <strong>Lessor</strong>
                            <Contact
                                contactType="Lessor"
                                hideContactType={true}
                                saveBtnText="NEXT"
                                contact={lessor}
                                contacts={contacts}
                                // readOnlyMode={!editDefaultContact}
                                save={(c) => this.setState({lessor: c}, () => this.handleNext('party2'))}
                                showSnack={(t,e) => this.props.showSnack(t,e)}
                            />
                        </div>
                        }
                        {relationship === 'Lessee' &&
                        <div>
                            <strong>Lessee</strong>
                            <Contact
                                contactType="Lessee"
                                hideContactType={true}
                                saveBtnText="ADD LESSEE"
                                // contact={lessee}
                                contacts={contacts}
                                // readOnlyMode={!editDefaultContact}
                                save={(c) => this.addLessee(c)}
                                showSnack={(t,e) => this.props.showSnack(t,e)}
                            />
                            <br/>
                            <div className={classes.contactListWrapper}>
                                {lessees.length !== 0 &&
                                <div>
                                    <Divider className={classes.divider} />
                                    <strong>Lessees</strong>
                                    <br/>
                                    <div className={classes.contactWrapper}>
                                        {lessees.length !== 0 &&
                                        <div>
                                            {lessees.map((item, index) => (
                                                <Chip key={'lessee-p1-chip-' + index}
                                                      label={(item.company||null) !== null ? item.company : `${item.firstName} ${item.lastName}`}
                                                      onDelete={() => this.removeLessee(index)} color="primary"
                                                      variant="outlined"/>
                                            ))}
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                            <Button variant="contained" color="primary" onClick={() => this.handleNext('party2')} className={classes.button}>NEXT</Button>
                        </div>
                        }
                    </div>
                )}
            </div>
        );

        const party2 = (
            <div>
                {loadingContacts ? (
                    <Loading contained={true}/>
                ) : (
                    <div>
                        {(relationship === 'Seller' || relationship === 'Seller and Buyer') &&
                        <div>
                            <strong>Buyer</strong>
                            <Contact
                                contactType="Buyer"
                                hideContactType={true}
                                saveBtnText="ADD BUYER"
                                // contact={buyer}
                                contacts={contacts}
                                save={(c) => this.addBuyer(c)}
                                showSnack={(t,e) => this.props.showSnack(t,e)}
                            />
                            <br/>
                            <div className={classes.contactListWrapper}>
                                {buyers.length !== 0 &&
                                <div>
                                    <Divider className={classes.divider} />
                                    <strong>Buyers</strong>
                                    <br/>
                                    <div className={classes.contactWrapper}>
                                        {buyers.length !== 0 &&
                                        <div>
                                            {buyers.map((item, index) => (
                                                <Chip key={'buyer-p2-chip-' + index}
                                                      label={(item.company||null) !== null ? item.company : `${item.firstName} ${item.lastName}`}
                                                      onDelete={() => this.removeBuyer(index)} color="primary"
                                                      variant="outlined"/>
                                            ))}
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                            <br/>
                            <Button variant="contained" color="primary" onClick={() => this.handleNext('agent')} className={classes.button}>NEXT</Button>
                        </div>
                        }
                        {relationship === 'Buyer' &&
                        <div>
                            <strong>Seller</strong>
                            <Contact
                                contactType="Seller"
                                hideContactType={true}
                                saveBtnText="NEXT"
                                contact={seller}
                                contacts={contacts}
                                save={(c) => this.setState({seller: c}, () => this.handleNext('agent'))}
                                showSnack={(t,e) => this.props.showSnack(t,e)}
                            />
                        </div>
                        }
                        {relationship === 'Lessor' &&
                        <div>
                            <strong>Lessee</strong>
                            <Contact
                                contactType="Lessee"
                                hideContactType={true}
                                saveBtnText="ADD LESSEE"
                                // contact={lessee}
                                contacts={contacts}
                                save={(c) => this.addLessee(c)}
                                showSnack={(t,e) => this.props.showSnack(t,e)}
                            />
                            <br/>
                            <div className={classes.contactListWrapper}>
                                {lessees.length !== 0 &&
                                <div>
                                    <Divider className={classes.divider} />
                                    <strong>Lessees</strong>
                                    <br/>
                                    <div className={classes.contactWrapper}>
                                        {lessees.length !== 0 &&
                                        <div>
                                            {lessees.map((item, index) => (
                                                <Chip key={'lessee-p2-chip-' + index}
                                                      label={(item.company||null) !== null ? item.company : `${item.firstName} ${item.lastName}`}
                                                      onDelete={() => this.removeLessee(index)} color="primary"
                                                      variant="outlined"/>
                                            ))}
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                            <br/>
                            <Button variant="contained" color="primary" onClick={() => this.handleNext('agent')} className={classes.button}>NEXT</Button>
                        </div>
                        }
                        {relationship === 'Lessee' &&
                        <div>
                            <strong>Lessor</strong>
                            <Contact
                                contactType="Lessor"
                                hideContactType={true}
                                saveBtnText="NEXT"
                                contacts={contacts}
                                contact={lessor}
                                save={(c) => this.setState({lessor: c}, () => this.handleNext('agent'))}
                                showSnack={(t,e) => this.props.showSnack(t,e)}
                            />
                        </div>
                        }
                    </div>
                )}
            </div>
        );

        const agentSection = (
            <div>
                {loadingAgents ? (
                    <Loading contained={true}/>
                ) : (
                    <div>
                        <FormControl className={classes.dropdown}>
                            {/*<InputLabel id="agent-select-label">Agents at Contract</InputLabel>*/}
                            <Select
                                id="agent-select"
                                name="selectedAgentId"
                                value={selectedAgentId}
                                onChange={(e) => this.handleSelectChange(e)}
                            >
                                {agents.map((item, index) => (
                                    <MenuItem key={'agent-select-'+item.agentId} value={item.agentId}>{item.licenseeName}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Select an Agent you would like to Add</FormHelperText>
                        </FormControl>
                        <br/>
                        <Button variant="outlined" size="small" color="primary" disabled={(selectedAgentId === '' || loadingAgents)} onClick={() => this.addAgentAtContract()} className={classes.button} startIcon={<AddIcon />}>AT CONTRACT</Button>
                        <Button variant="outlined" size="small" color="primary" disabled={(selectedAgentId === '' || loadingAgents)} onClick={() => this.addAgentAtClosing()} className={classes.button} startIcon={<AddIcon />}>AT CLOSING</Button>
                    </div>
                )}

                <Divider className={classes.divider} />
                <strong>Agents At Contract</strong>
                <br/>
                <div className={classes.agentWrapper}>
                    {agentsAtContract.length !== 0 ? (
                        <div>
                            {agentsAtContract.map((item, index) => (
                                <Chip key={'agent-chip-'+item.agentId} label={item.licenseeName} onDelete={() => this.removeAgentAtContract(item.agentId)} color="primary" variant="outlined" />
                            ))}
                        </div>
                    ) : (
                        <div className={classes.margin}>No Agents At Contract Selected..</div>
                    )}
                </div>
                <Divider className={classes.divider} />
                <strong>Agents At Close</strong>
                <br/>
                <div className={classes.agentWrapper}>
                    {agentsAtClosing.length !== 0 ? (
                        <div>
                            {agentsAtClosing.map((item, index) => (
                                <Chip key={'agent-chip-'+item.agentId} label={item.licenseeName} onDelete={() => this.removeAgentAtClosing(item.agentId)} color="primary" variant="outlined" />
                            ))}
                        </div>
                    ) : (
                        <div className={classes.margin}>No Agents At Closing Selected..</div>
                    )}
                </div>
                <br/>
                <Button variant="contained" color="primary" onClick={() => this.handleNext('attorney')} className={classes.button}>NEXT</Button>
            </div>
        );

        const attorneySection = (
            <div>
                {type === 'Lease' ? (
                    <div>
                        <Typography>This section does not apply to a lease. Please click the next button below to skip this step.</Typography>
                        <br/>
                        <Button variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                    </div>
                ) : (
                    <div>
                        {loadingAttorneys ? (
                            <Loading contained={true}/>
                        ) : (
                            <div>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">Attorney</FormLabel>
                                    <RadioGroup row aria-label="attorneyMode" name="attorneyMode" value={attorneyMode} onChange={(e) => this.handleSelectChange(e)}>
                                        <FormControlLabel value="existing" control={<Radio/>} label="Select Attorney"/>
                                        <FormControlLabel value="new" control={<Radio/>} label="New Attorney"/>
                                        <FormControlLabel value="na" control={<Radio/>} label="N/A"/>
                                    </RadioGroup>
                                </FormControl>
                                {attorneyMode === 'existing' &&
                                    <div>
                                        {loadingAttorneys ? (
                                            <Loading contained={true}/>
                                        ) : (
                                            <div>
                                                <FormControl className={classes.dropdown}>
                                                    <Select
                                                        id="attorney-select"
                                                        name="attorneyId"
                                                        value={attorneyId}
                                                        onChange={(e) => this.handleSelectChange(e)}
                                                    >
                                                        {attorneys.map((item, index) => (
                                                            <MenuItem key={item.attorneyId} value={item.attorneyId}>{item.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <br/>
                                                <Button disabled={(attorneyId || null) === null} variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                                            </div>
                                        )}
                                    </div>
                                }
                                {attorneyMode === 'new' &&
                                <Attorney saveBtnText="NEXT" attorney={attorney} save={(a) => this.setState({attorney: a}, () => this.handleNext())} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                                }
                                {attorneyMode === 'na' &&
                                    <div>
                                        <br/>
                                        <br/>
                                        <Button variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                )}
            </div>
        );

        const closingCost = (
            <div>
                {type === 'Lease' ? (
                    <div>
                        <Typography>This section does not apply to a lease. Please click the next button below to skip this step.</Typography>
                        <br/>
                        <Button variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                    </div>
                ) : (
                    <div>
                        <br/>
                        <TextField autoComplete="new"
                           name="sellerPaid"
                           value={sellerPaid}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Seller Paid"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('sellerPaid')}
                           helperText={this.fieldErrorMessage('sellerPaid')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                           name="lenderPaid"
                           value={lenderPaid}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Lender Paid"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('lenderPaid')}
                           helperText={this.fieldErrorMessage('lenderPaid')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                        />
                        <br/>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Buyer Paid</FormLabel>
                            <RadioGroup row aria-label="buyerPaid" name="buyerPaid" value={buyerPaid} onChange={(e) => this.handleSelectChange(e)}>
                                <FormControlLabel value="N/A" control={<Radio/>} label="N/A"/>
                                <FormControlLabel value="All" control={<Radio/>} label="All"/>
                            </RadioGroup>
                        </FormControl>
                        <br/>
                        <Button disabled={!this.areFieldsValid(['sellerPaid','lenderPaid','buyerPaid'])} variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                    </div>
                )}
            </div>
        );

        const commissions = (
            <div>
                {type === 'Lease' ? (
                    <div>
                        <Typography>This section does not apply to a lease. Please click the next button below to skip this step.</Typography>
                        <br/>
                        <Button variant="contained" color="primary" onClick={() => this.handleNext('other')} className={classes.button}>NEXT</Button>
                    </div>
                ) : (
                    <div>
                        <TextField autoComplete="new"
                            value={combinedCommissionPercentage}
                            name="combinedCommissionPercentage"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Combined Total %"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('combinedCommissionPercentage')}
                            helperText={this.fieldErrorMessage('combinedCommissionPercentage')}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            value={totalCommissionPercentage}
                            name="totalCommissionPercentage"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="TRU Total %"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('totalCommissionPercentage')}
                            helperText={this.fieldErrorMessage('totalCommissionPercentage')}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                        <TextField autoComplete="new"
                            name="totalCommissionAmount"
                            value={totalCommissionAmount}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="TRU Total $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('totalCommissionAmount')}
                            helperText={this.fieldErrorMessage('totalCommissionAmount')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            value={tamraCommissionPercentage}
                            name="tamraCommissionPercentage"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Tamra %"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('tamraCommissionPercentage')}
                            helperText={this.fieldErrorMessage('tamraCommissionPercentage')}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                        <TextField autoComplete="new"
                            name="tamraCommissionAmount"
                            value={tamraCommissionAmount}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Tamra $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('tamraCommissionAmount')}
                            helperText={this.fieldErrorMessage('tamraCommissionAmount')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            value={agentCommissionPercentage}
                            name="agentCommissionPercentage"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Agent %"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('agentCommissionPercentage')}
                            helperText={this.fieldErrorMessage('agentCommissionPercentage')}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                        />
                        <TextField autoComplete="new"
                            name="agentCommissionAmount"
                            value={agentCommissionAmount}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Agent $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('agentCommissionAmount')}
                            helperText={this.fieldErrorMessage('agentCommissionAmount')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            name="fmlsCommission"
                            value={fmlsCommission}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="FMLS $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('fmlsCommission')}
                            helperText={this.fieldErrorMessage('fmlsCommission')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            name="remaxCommission"
                            value={remaxCommission}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Remax $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('remaxCommission')}
                            helperText={this.fieldErrorMessage('remaxCommission')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            name="randyCommission"
                            value={randyCommission}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Randy $"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('randyCommission')}
                            helperText={this.fieldErrorMessage('randyCommission')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                           name="tamraCmnCommission"
                           value={tamraCmnCommission}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Tamra CMN $"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('tamraCmnCommission')}
                           helperText={this.fieldErrorMessage('tamraCmnCommission')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                        />
                        <TextField autoComplete="new"
                           name="agentCmnCommission"
                           value={agentCmnCommission}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Agent CMN $"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('agentCmnCommission')}
                           helperText={this.fieldErrorMessage('agentCmnCommission')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                           name="gsmCommission"
                           value={gsmCommission}
                           onChange={(e) => this.handleFieldChange(e)}
                           label="GSM $"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('gsmCommission')}
                           helperText={this.fieldErrorMessage('gsmCommission')}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           }}
                        />
                        <br/>
                        <TextField autoComplete="new"
                            value={notes}
                            name="notes"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Notes"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('notes')}
                            helperText={this.fieldErrorMessage('notes')}
                        />
                        <br/>
                        <br/>
                        <Button disabled={!this.areFieldsValid([
                            'totalCommissionPercentage',
                            'totalCommissionAmount',
                            'tamraCommissionPercentage',
                            'agentCommissionPercentage',
                            'tamraCommissionAmount',
                            'agentCommissionAmount',
                            'fmlsCommission',
                            'remaxCommission',
                            'randyCommission',
                            'tamraCmnCommission',
                            'agentCmnCommission',
                            'gsmCommission',
                            'notes'
                        ])} variant="contained" color="primary" onClick={() => this.handleNext('other')} className={classes.button}>NEXT</Button>
                    </div>
                )}
            </div>
        );

        const other = (
            <div>
                <FormControl className={classes.dropdown}>
                    <InputLabel htmlFor="listing-signReturned-select">Sign Returned</InputLabel>
                    <Select
                        id="listing-signReturned-select"
                        name="signReturned"
                        value={signReturned}
                        onChange={(e) => this.handleSelectChange(e)}
                    >
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="Lost">Lost</MenuItem>
                        <MenuItem value="N/A">N/A</MenuItem>
                    </Select>
                </FormControl>
                <br/>
                {signReturned === 'No' &&
                    <div>
                        <TextField autoComplete="new"
                            value={reasonSignNotReturned}
                            name="reasonSignNotReturned"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Reason Not Returned"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('reasonSignNotReturned')}
                            helperText={this.fieldErrorMessage('reasonSignNotReturned')}
                            required
                        />
                        <br/>
                    </div>
                }
                {type === 'New Construction' &&
                    <div>
                        <FormControlLabel
                            className={classes.switch}
                            control={
                                <Switch
                                    name="closingPhotosReceived"
                                    checked={closingPhotosReceived}
                                    onChange={(e) => this.handleSwitchChange(e)}
                                    color="primary"
                                />
                            }
                            label="Closing Photos Received?"
                        />
                        <br/>
                    </div>
                }
                <div>
                    <br/>
                    {loadingLeadSources ? (
                        <Loading contained={true}/>
                    ) : (
                        <div>
                            {leadSources.length > 0 &&
                            <div>
                                <FormControlLabel
                                    className={classes.switch}
                                    control={
                                        <Switch
                                            name="leadSourceMode"
                                            checked={leadSourceMode === 'new'}
                                            onChange={(e) => this.handleSwitchChange(e)}
                                            color="primary"
                                        />
                                    }
                                    label="Enter Lead Source Manually?"
                                />
                                <br/>
                            </div>
                            }
                            {(leadSources.length > 0 && leadSourceMode === 'existing') ? (
                                <FormControl className={classes.dropdown}>
                                    <InputLabel htmlFor="leadSource-select">Lead Source</InputLabel>
                                    <Select
                                        id="leadSource-select"
                                        name="leadSource"
                                        value={leadSource}
                                        onChange={(e) => this.handleSelectChange(e)}
                                    >
                                        {leadSources.map((item, index) => (
                                            <MenuItem key={'lead-source-'+index} value={item.name}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <TextField autoComplete="new"
                                    value={leadSource}
                                    name="leadSource"
                                    onChange={(e) => this.handleFieldChange(e)}
                                    label="Lead Source"
                                    className={classes.textField}
                                    margin="normal"
                                    error={this.fieldHasError('leadSource')}
                                    helperText={this.fieldErrorMessage('leadSource')}
                                />
                            )}
                            <br/>
                        </div>
                    )}
                </div>
                <br/>
                <br/>
                <Button disabled={!this.areFieldsValid(['signReturned','reasonSignNotReturned','closingPhotosReceived'])} variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
            </div>
        );

        const closeDateSection = (
            <div>
                {(type === 'New Construction' && (constructionId||null) !== null) ? (
                    <div>
                        <Typography>The close dates associated with the Property Details you selected will be used for this listing. You can update this information by editing the Sales Details after it has been created. Please click the next button below to skip this step.</Typography>
                        <br/>
                        <Button variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                    </div>
                ) : (
                    <AddCloseDate underContract={status === 'Under Contract' && type === 'New Construction'} isLease={type === 'Lease'} builder={builder} save={(c) => this.setState({closeDate: c}, () => this.handleNext())} closeDate={closeDate} showSnack={(t,e) => this.props.showSnack(t,e)} />
                )}
            </div>
        );

        const contractSection = (
            <div>
                {(type === 'New Construction' && (constructionId||null) !== null) ? (
                    <div>
                        <Typography>The contract related information associated with the Property Details you selected will be used for this listing. You can update this information by editing the Sales Details after it has been created. Please click the next button below to skip this step.</Typography>
                        <br/>
                        <Button variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                    </div>
                ) : (
                    <AddContract underContract={status === 'Under Contract' && type === 'New Construction'} isLease={type === 'Lease'} builder={builder} save={(c) => this.setState({contract: c}, () => this.handleNext())} contract={contract} parentType="listing" showSnack={(t,e) => this.props.showSnack(t,e)} />
                )}
            </div>
        );

        const party1Title = (relationship||null) === null ? '1st Party' : relationship === 'Seller and Buyer' ? 'Seller' : relationship;
        const party2Title = relationship === 'Seller and Buyer' ? 'Buyer' : relationship === 'Seller' ? 'Buyer' : relationship === 'Buyer' ? 'Seller' : relationship === 'Lessor' ? 'Lessee' : relationship === 'Lessee' ? 'Lessor' : '2nd Party';

        const steps = [
            {title: 'Intro',body: intro},
            {title: 'Type',body: typeRelationshipStatus},
            {title: type === 'New Construction' && relationship === 'Seller' ? 'Property Details' : 'Builder',body: builderSection},
            {title: 'Property',body: propertySection},
            {title: 'Listing Broker',body: listingBrokerSection},
            {title: 'Selling Broker',body: sellingBrokerSection},
            {title: party1Title, body: party1},
            {title: party2Title, body: party2},
            {title: 'Agents',body: agentSection},
            {title: 'Attorney',body: attorneySection},
            {title: 'Close Date',body: closeDateSection},
            {title: 'Contract',body: contractSection},
            {title: 'Closing Cost',body: closingCost},
            {title: 'Commission',body: commissions},
            {title: 'Additional',body: other},
            {title: 'Save',body: outro},
        ];

        return (
            <div className={classes.root}>
                <div>
                    <div>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step onClick={() => this.stepClicked(index)} key={'listing-step-' + index}>
                                    <StepLabel>{step.title}</StepLabel>
                                    <StepContent>
                                        {step.body}
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    {savingListing &&
                    <Loading text="Saving..."/>
                    }
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(AddListing);