import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField, validatePrice, validateStage, validateNumber} from '../../utils'
import _ from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import Builder from './Builder';
import Property from './Property';
import Loading from '../Loading';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';
// import moment from 'moment'
import InputAdornment from '@material-ui/core/InputAdornment';
import DateMomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker, KeyboardDateTimePicker,
} from '@material-ui/pickers';
import AddContract from "./AddContract";
import AddCloseDate from "./AddCloseDate";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 220
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    dateTimeInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
});

const dateTimeTheme = createMuiTheme();

dateTimeTheme.typography.h3 = {
    fontSize: '3rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.04,
    letterSpacing: '0em',
};

dateTimeTheme.typography.subtitle1 = {
    fontSize: '.85rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
};

class AddConstruction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            builderId: '',
            builders: [],
            loadingBuilders: false,

            propertyId: '',
            properties: [],
            property: {},
            propertyMode: 'existing',
            loadingProperties: false,

            loadingConstruction: false,
            savingConstruction: false,

            status: '',// Lot, Spec, Model, UnderContract
            fmls: '',
            gamls: '',
            navica: '',
            foundation: '', //basement or slab
            garageDoors: 0,
            garageDirection: '',
            stage: '',
            planAndElevation: '',
            finish: '', //only SE
            dateSelectionsDue: null, // SE = Finish Schedule Due
            dateSelectionsReceived: null, // SE = Finish Schedule Received
            firstWalkThruDate: null,
            finalWalkThruDate: null,
            firstWalkThruDateHasTime: false,
            finalWalkThruDateHasTime: false,
            listPrice: '',
            buyerLastName: '',
            contingency: '', //None, Sale and close, or close only
            contingencyDate: null,
            finalClosingAmendmentCompleted: false,
            constructionNotes: '',
            buildPermit: false, //bool
            certificateOfOccupancy: false, //bool
            warranty: '', //QBW or 2/10 or 1 year only

            termiteLetterRequested: '',
            constructionStartDate: null,
            qbwWarranty: '',

            salesNotes: '',

            closeDate: null,
            contract: null,

            validations: [],
            activeStep: 0
        };
    }

    componentDidMount() {

    }

    getBuilders() {
        if(this.state.builders.length > 0) {
            this.setState({loadingBuilders: false})
        } else {
            request(
                'GET',
                '/Manage/getBuilders',
                null,
                (data) => {
                    this.setState({builders: data.builders, loadingBuilders: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getProperties() {
        if(this.state.properties.length > 0) {
            this.setState({loadingProperties: false})
        } else {
            request(
                'GET',
                '/Manage/getProperties',
                null,
                (data) => {
                    this.setState({properties: data.properties, loadingProperties: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    handleError(error){
        this.setState({loadingBuilders: false,loadingProperties: false,loadingConstruction: false,savingConstruction: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'builderId',
            'propertyId',
            'status',
            'buyerLastName',
            'fmls',
            'gamls',
            'navica',
            'foundation',
            'garageDoors',
            'garageDirection',
            'stage',
            'planAndElevation',
            'finish',
            'dateSelectionsDue',
            'dateSelectionsReceived',
            'firstWalkThruDate',
            'finalWalkThruDate',
            'listPrice',
            'contingency',
            'contingencyDate',
            'finalClosingAmendmentCompleted',
            'constructionNotes',
            'buildPermit',
            'certificateOfOccupancy',
            'warranty',
            'salesNotes',
            'termiteLetterRequested',
            'constructionStartDate',
            'qbwWarranty'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'status':
                isValid = validateField(value);
                invalidationReason = 'Please select the construction status.';
                break;
            case 'buyerLastName':
                if(this.state.status === 'Under Contract'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the buyer\'s last name.';
                }
                break;
            case 'fmls':
                if(this.state.status === 'Under Contract'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the FMLS.';
                }
                break;
            case 'gamls':
                if(this.state.status === 'Under Contract'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the GAMLS.';
                }
                break;
            case 'navica':
                // isValid = validateField(value);
                // invalidationReason = 'Please enter the NAVICA.';
                break;
            case 'foundation':
                if(this.state.status !== 'Lot'){
                    isValid = validateField(value);
                    invalidationReason = 'Please select the construction foundation type.';
                }
                break;
            case 'garageDoors':
                isValid = validateNumber(value);
                invalidationReason = 'Please enter the number of garage doors.';
                break;
            case 'garageDirection':
                if(this.state.garageDoors > 0){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select the garage direction.';
                }
                break;
            case 'stage':
                if(this.state.status !== 'Lot'){
                    isValid = validateStage(value);
                    invalidationReason = 'Please enter the stage.';
                }
                break;
            case 'planAndElevation':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'finish':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'dateSelectionsDue':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'dateSelectionsReceived':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'firstWalkThruDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'finalWalkThruDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'listPrice':
                if(this.state.status === 'Under Contract'){
                    isValid = value === '0' || validatePrice(value);
                    invalidationReason = 'Please enter the listing price.';
                }
                break;
            case 'contingency':
                if(this.state.status === 'Under Contract'){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select the construction contingency.';
                }
                break;
            case 'contingencyDate':
                const contingency = this.state.contingency || 'None';
                if(contingency !== 'None' && contingency !== 'Lease'){
                    isValid = (value || null) !== null;
                    if(!isValid){
                        invalidationReason = 'Please enter a contingency date.';
                    }
                }
                break;
            // case 'finalClosingAmendmentCompleted':
            //     // isValid = validateField(value);
            //     // invalidationReason = '';
            //     break;
            case 'constructionNotes':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            // case 'buildPermit':
            //     // isValid = validateField(value);
            //     // invalidationReason = '';
            //     break;
            // case 'certificateOfOccupancy':
            //     // isValid = validateField(value);
            //     // invalidationReason = '';
            //     break;
            case 'warranty':
                if(this.state.status === 'Under Contract'){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select the warranty.';
                }
                break;
            case 'salesNotes':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        switch(field){
            case 'buyerLastName':
                break;
            case 'fmls':
                break;
            case 'gamls':
                break;
            case 'navica':
                break;
            case 'garageDoors':
                // value = value.replace(/\D+/g, '')
                if(value < 0){
                    value = 0;
                }
                break;
            case 'garageDirection':
                break;
            case 'stage':
                value = value.replace(/[^0-9.]/, '');
                break;
            case 'planAndElevation':
                break;
            case 'finish':
                break;
            case 'listPrice':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'contingency':
                break;
            case 'constructionNotes':
                break;
            case 'salesNotes':
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        // switch(field){
        //     case 'status':
        //         break;
        //     case 'foundation':
        //         break;
        //     case 'warranty':
        //         break;
        // }

        this.setState({[field]: value});
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        if(field === 'firstWalkThruDateHasTime' && value === true){
            const firstWalkThruDate = this.state.firstWalkThruDate;
            if(firstWalkThruDate !== null){
                const fwtd = moment(firstWalkThruDate);
                if(fwtd.isValid()){
                    const f = fwtd.format("YYYY-MM-DD");
                    this.setState({[field]: !value, firstWalkThruDate: f+'T00:00:00'});
                }
            }
        }
        else if(field === 'finalWalkThruDateHasTime' && value === true){
            const finalWalkThruDate = this.state.firstWalkThruDate;
            if(finalWalkThruDate !== null){
                const fwtd = moment(finalWalkThruDate);
                if(fwtd.isValid()){
                    const f = fwtd.format("YYYY-MM-DD");
                    this.setState({[field]: !value, finalWalkThruDate: f+'T00:00:00'});
                }
            }
        }
        else {
            this.setState({[field]: !value});
        }
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    handleNext(next){
        const step = this.state.activeStep;

        if(next === 'builder'){
            this.setState({activeStep: step + 1, loadingBuilders: true}, () => this.getBuilders())
        } else if (next === 'property') {
            this.setState({activeStep: step + 1, loadingProperties: true}, () => this.getProperties())
        } else {
            this.setState({activeStep: step + 1})
        }
    };

    // handleBack() {
    //     const step = this.state.activeStep;
    //     this.setState({activeStep: step - 1})
    // };
    //
    // handleReset() {
    //     this.setState({activeStep: 0})
    // };

    updateProperty(property) {
        const step = this.state.activeStep;
        this.setState({property: property, activeStep: step + 1})
    };

    stepClicked(clicked) {
        const step = this.state.activeStep;
        if(clicked < step){
            this.setState({activeStep: clicked})
        }
    };

    handlePropertyModeChange(e) {
        this.setState({propertyMode: e.target.value});
    }

    getBuilderName(builderId) {
        const builders = this.state.builders;
        const vIndex = _.findIndex(builders, {builderId: builderId});
        if(vIndex > -1){
            return builders[vIndex].name;
        }
        return '';
    }

    isConstructionValid(){
        if(this.state.savingConstruction){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;

    }

    saveNewConstruction() {
        const {
            builderId,
            propertyId,
            property,
            status,
            buyerLastName,
            fmls,
            gamls,
            navica,
            foundation,
            garageDoors,
            garageDirection,
            stage,
            planAndElevation,
            finish,
            dateSelectionsDue,
            dateSelectionsReceived,
            firstWalkThruDate,
            finalWalkThruDate,
            listPrice,
            contingency,
            contingencyDate,
            finalClosingAmendmentCompleted,
            constructionNotes,
            buildPermit,
            certificateOfOccupancy,
            warranty,
            salesNotes,
            closeDate,
            contract,
            termiteLetterRequested,
            constructionStartDate,
            qbwWarranty
        } = this.state;

        const data = {
            builderId: builderId,
            propertyId: propertyId,
            property: property,
            status: status,
            buyerLastName: buyerLastName,
            fmls: fmls,
            gamls: gamls,
            navica: navica,
            foundation: foundation,
            garageDoors: garageDoors,
            garageDirection: garageDirection,
            stage: stage,
            planAndElevation: planAndElevation,
            finish: finish,
            dateSelectionsDue: dateSelectionsDue,
            dateSelectionsReceived: dateSelectionsReceived,
            firstWalkThruDate: firstWalkThruDate,
            finalWalkThruDate: finalWalkThruDate,
            listPrice: listPrice,
            contingency: contingency,
            contingencyDate: contingencyDate,
            finalClosingAmendmentCompleted: finalClosingAmendmentCompleted,
            constructionNotes: constructionNotes,
            buildPermit: buildPermit,
            certificateOfOccupancy: certificateOfOccupancy,
            warranty: warranty,
            salesNotes: salesNotes,
            closeDate: closeDate,
            contract: contract,
            termiteLetterRequested: termiteLetterRequested,
            constructionStartDate: constructionStartDate,
            qbwWarranty: qbwWarranty
        };

        const successMessage = 'Successfully added Property Details!';

        request(
            'POST',
            '/Manage/addConstruction',
            data,
            (data) => {
                this.setState(
                    {savingConstruction: false},
                    () => this.props.success(successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    render() {
        const {classes} = this.props;
        const { loadingBuilders,loadingProperties,loadingConstruction,savingConstruction, activeStep, builders, properties, propertyMode, property} = this.state;

        const {
            builderId,
            propertyId,
            status,
            buyerLastName,
            fmls,
            gamls,
            navica,
            foundation,
            garageDoors,
            garageDirection,
            stage,
            planAndElevation,
            finish,
            dateSelectionsDue,
            dateSelectionsReceived,
            firstWalkThruDate,
            finalWalkThruDate,
            firstWalkThruDateHasTime,
            finalWalkThruDateHasTime,
            listPrice,
            contingency,
            contingencyDate,
            finalClosingAmendmentCompleted,
            constructionNotes,
            buildPermit,
            certificateOfOccupancy,
            warranty,
            salesNotes,
            closeDate,
            contract,
            termiteLetterRequested,
            constructionStartDate,
            qbwWarranty
        } = this.state;

        const intro = (
            <div>
                <Typography>Use this wizard to Add Property Details.</Typography>
                <br/>
                <Button variant="contained" color="primary" onClick={() => this.handleNext('builder')} className={classes.button}>NEXT</Button>
            </div>
        );

        const outro = (
            <div>
                {(this.isConstructionValid()) &&
                <div className={classes.formControl}>
                    <Typography>You have completed the Property Details wizard. Click the save button below to save and finish.</Typography>
                </div>
                }
                <br/>
                <Button disabled={!this.isConstructionValid()} variant="contained" color="primary" onClick={() => this.setState({savingConstruction: true}, () => this.saveNewConstruction())} className={classes.button}>SAVE</Button>
            </div>
        );

        const builderSection = (
            <div>
                {loadingBuilders ? (
                    <Loading contained={true}/>
                ) : (
                    <div>
                        <FormControl className={classes.dropdown}>
                            <InputLabel htmlFor="builder-select">Builder</InputLabel>
                            <Select
                                id="builder-select"
                                name="builderId"
                                value={builderId}
                                onChange={(e) => this.handleSelectChange(e)}
                            >
                                {builders.map((item, index) => (
                                    <MenuItem key={item.builderId} value={item.builderId}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br/>
                        <Button disabled={(builderId || null) === null} variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                    </div>
                )}
            </div>
        );

        const propertySection = (
            <div>
                {loadingProperties ? (
                    <Loading contained={true}/>
                ) : (
                    <div>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Property</FormLabel>
                            <RadioGroup row aria-label="propertyMode" name="propertyMode" value={propertyMode} onChange={(e) => this.handlePropertyModeChange(e)}>
                                <FormControlLabel value="existing" control={<Radio/>} label="Select Property"/>
                                <FormControlLabel value="new" control={<Radio/>} label="New Property"/>
                            </RadioGroup>
                        </FormControl>
                        {propertyMode === 'existing' ? (
                            <div>
                                {loadingProperties ? (
                                    <Loading contained={true}/>
                                ) : (
                                    <div>
                                        <FormControl className={classes.dropdown}>
                                            <Select
                                                id="property-select"
                                                name="propertyId"
                                                value={propertyId}
                                                onChange={(e) => this.handleSelectChange(e)}
                                            >
                                                {properties.map((item, index) => (
                                                    <MenuItem key={item.propertyId} value={item.propertyId}>{item.displayAddress}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <br/>
                                        <Button disabled={(propertyId || null) === null} variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Property status lotNumberRequired={true} underContract={status === 'Under Contract'} saveBtnText="NEXT" property={property} save={(p) => this.updateProperty(p)} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                        )}
                    </div>
                )}
            </div>
        );

        const statusSection = (
            <div>
                <FormControl className={classes.dropdown} required>
                    <InputLabel htmlFor="construction-status-select">Construction Status</InputLabel>
                    <Select
                        id="construction-status-select"
                        name="status"
                        value={status}
                        onChange={(e) => this.handleSelectChange(e)}
                    >
                        <MenuItem value="Under Contract">Under Contract</MenuItem>
                        <MenuItem value="Lot">Lot</MenuItem>
                        <MenuItem value="Contract in the Works">Contract in the Works</MenuItem>
                        <MenuItem value="SPEC">SPEC</MenuItem>
                        <MenuItem value="Model">Model</MenuItem>
                    </Select>
                </FormControl>
                <br/>
                <Button disabled={(status || null) === null} variant="contained" color="primary" onClick={() => this.handleNext('property')} className={classes.button}>NEXT</Button>
            </div>
        );

        const constructionSection = (
            <div>
                <TextField autoComplete="new"
                    value={fmls}
                    name="fmls"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="FMLS"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('fmls')}
                    helperText={this.fieldErrorMessage('fmls')}
                    required={status === 'Under Contract'}
                />
                <TextField autoComplete="new"
                    value={gamls}
                    name="gamls"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="GAMLS"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('gamls')}
                    helperText={this.fieldErrorMessage('gamls')}
                    required={status === 'Under Contract'}
                />
                <TextField autoComplete="new"
                    value={navica}
                    name="navica"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="NAVICA"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('navica')}
                    helperText={this.fieldErrorMessage('navica')}
                />
                <br/>
                <FormControl className={classes.dropdown} required={status !== 'Lot'}>
                    <InputLabel htmlFor="construction-foundation-select">Foundation Type</InputLabel>
                    <Select
                        id="construction-foundation-select"
                        name="foundation"
                        value={foundation}
                        onChange={(e) => this.handleSelectChange(e)}
                    >
                        <MenuItem value="Basement">Basement</MenuItem>
                        <MenuItem value="Slab">Slab</MenuItem>
                    </Select>
                </FormControl>
                <TextField autoComplete="new"
                    value={garageDoors}
                    name="garageDoors"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="How Many Garage Doors?"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('garageDoors')}
                    helperText={this.fieldErrorMessage('garageDoors')}
                    type="number"
                />
                <FormControl className={classes.dropdown} required={status !== 'Lot'}>
                    <InputLabel htmlFor="garageDirection-select">Garage Direction</InputLabel>
                    <Select
                        id="garageDirection-select"
                        name="garageDirection"
                        value={garageDirection}
                        onChange={(e) => this.handleSelectChange(e)}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Front">Front</MenuItem>
                        <MenuItem value="Side">Side</MenuItem>
                        <MenuItem value="Side & Carriage">Side & Carriage</MenuItem>
                        <MenuItem value="Rear">Rear</MenuItem>
                    </Select>
                </FormControl>
                <br/>
                <br/>
                <TextField autoComplete="new"
                    value={stage}
                    name="stage"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Stage"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('stage')}
                    helperText={this.fieldErrorMessage('stage')}
                    required={status !== 'Lot'}
                />
                <TextField autoComplete="new"
                    value={planAndElevation}
                    name="planAndElevation"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Plan and Elevation"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('planAndElevation')}
                    helperText={this.fieldErrorMessage('planAndElevation')}
                />
                {this.getBuilderName(builderId) === "Stephen Elliott" &&
                <TextField autoComplete="new"
                    value={finish}
                    name="finish"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Finish"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('finish')}
                    helperText={this.fieldErrorMessage('finish')}
                />
                }
                <br/>
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        margin="normal"
                        className={classes.dateInput}
                        label={this.getBuilderName(builderId) === "Stephen Elliott" ? "Finish Schedule Due" : "Date Selections Due"}
                        value={dateSelectionsDue}
                        onChange={(d) => this.handleDateChange('dateSelectionsDue', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                    />
                </MuiPickersUtilsProvider>
                <br/>
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        className={classes.dateInput}
                        margin="normal"
                        label={this.getBuilderName(builderId) === "Stephen Elliott" ? "Finish Schedule Received" : "Date Selections Received"}
                        value={dateSelectionsReceived}
                        onChange={(d) => this.handleDateChange('dateSelectionsReceived', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                    />
                </MuiPickersUtilsProvider>
                <br/>
                <ThemeProvider theme={dateTimeTheme}>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="firstWalkThruDateHasTime"
                                checked={firstWalkThruDateHasTime}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                            />
                        }
                        label="First Walkthrough Date has Time?"
                    />
                    {firstWalkThruDateHasTime ? (
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDateTimePicker
                                ampm
                                format="MM/DD/YYYY hh:mm a"
                                onError={console.log}
                                className={classes.dateTimeInput}
                                margin="normal"
                                label="First WalkThru Date"
                                value={firstWalkThruDate}
                                onChange={(d) => this.handleDateChange('firstWalkThruDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                // autoOk={true}
                            />
                        </MuiPickersUtilsProvider>
                    ) : (
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                className={classes.dateInput}
                                margin="normal"
                                label="First WalkThru Date"
                                value={firstWalkThruDate}
                                onChange={(d) => this.handleDateChange('firstWalkThruDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                            />
                        </MuiPickersUtilsProvider>
                    )}
                    <br/>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="finalWalkThruDateHasTime"
                                checked={finalWalkThruDateHasTime}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                            />
                        }
                        label="Final Walkthrough Date has Time?"
                    />
                    {finalWalkThruDateHasTime ? (
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDateTimePicker
                                ampm
                                format="MM/DD/YYYY hh:mm a"
                                onError={console.log}
                                className={classes.dateTimeInput}
                                margin="normal"
                                label="Final WalkThru Date"
                                value={finalWalkThruDate}
                                onChange={(d) => this.handleDateChange('finalWalkThruDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date and time',
                                }}
                                // autoOk={true}
                            />
                        </MuiPickersUtilsProvider>
                    ) : (
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                className={classes.dateInput}
                                margin="normal"
                                label="Final WalkThru Date"
                                value={finalWalkThruDate}
                                onChange={(d) => this.handleDateChange('finalWalkThruDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                            />
                        </MuiPickersUtilsProvider>
                    )}
                </ThemeProvider>
                <br/>
                <TextField autoComplete="new"
                    name="listPrice"
                    value={listPrice}
                    onChange={(e) => this.handleFieldChange(e)}
                    label="List Price"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('listPrice')}
                    helperText={this.fieldErrorMessage('listPrice')}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    required={status === 'Under Contract'}
                />
                <TextField autoComplete="new"
                    value={buyerLastName}
                    name="buyerLastName"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Buyer's Last Name"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('buyerLastName')}
                    helperText={this.fieldErrorMessage('buyerLastName')}
                    required={status === 'Under Contract'}
                />
                <br/>
                <FormControl className={classes.dropdown} required={status === 'Under Contract'}>
                    <InputLabel htmlFor="construction-contingency-select">Construction Contingency</InputLabel>
                    <Select
                        id="construction-contingency-select"
                        name="contingency"
                        value={contingency}
                        onChange={(e) => this.handleSelectChange(e)}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Sale and Close">Sale and Close</MenuItem>
                        <MenuItem value="Close Only">Close Only</MenuItem>
                        <MenuItem value="Lease">Lease</MenuItem>
                    </Select>
                </FormControl>
                {(contingency !== 'None' && contingency !== 'Lease') &&
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        className={classes.dateInput}
                        margin="normal"
                        label="Contingency Date"
                        value={contingencyDate}
                        onChange={(d) => this.handleDateChange('contingencyDate', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                    />
                </MuiPickersUtilsProvider>
                }
                <br/>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            name="finalClosingAmendmentCompleted"
                            checked={finalClosingAmendmentCompleted}
                            onChange={(e) => this.handleSwitchChange(e)}
                            color="primary"
                        />
                    }
                    label="Final Closing Amendment Completed?"
                />
                <br/>
                <TextField autoComplete="new"
                    value={constructionNotes}
                    name="constructionNotes"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Construction Notes"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('constructionNotes')}
                    helperText={this.fieldErrorMessage('constructionNotes')}
                    multiline
                    rows={3}
                    fullWidth
                />
                <br/>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            name="buildPermit"
                            checked={buildPermit}
                            onChange={(e) => this.handleSwitchChange(e)}
                            color="primary"
                        />
                    }
                    label="Build Permit?"
                />
                <br/>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            name="certificateOfOccupancy"
                            checked={certificateOfOccupancy}
                            onChange={(e) => this.handleSwitchChange(e)}
                            color="primary"
                        />
                    }
                    label="Certificate Of Occupancy?"
                />
                <br/>
                <FormControl className={classes.dropdown} required={status === 'Under Contract'}>
                    <InputLabel htmlFor="warranty-select">Warranty</InputLabel>
                    <Select
                        id="warranty-select"
                        name="warranty"
                        value={warranty}
                        onChange={(e) => this.handleSelectChange(e)}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="QBW">QBW</MenuItem>
                        <MenuItem value="2-10">2-10</MenuItem>
                        <MenuItem value="StrucSure">StrucSure</MenuItem>
                        <MenuItem value="1 Year Only">1 Year Only</MenuItem>
                    </Select>
                </FormControl>
                <br/>
                {(this.getBuilderName(builderId) === "My Home Communities" && status === 'Under Contract') &&
                <div>
                    <TextField autoComplete="new"
                       value={termiteLetterRequested}
                       name="termiteLetterRequested"
                       onChange={(e) => this.handleFieldChange(e)}
                       label="Termite Letter Requested?"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('termiteLetterRequested')}
                       helperText={this.fieldErrorMessage('termiteLetterRequested')}
                       fullWidth
                    />
                    {/*<FormControlLabel*/}
                    {/*    className={classes.switch}*/}
                    {/*    control={*/}
                    {/*        <Switch*/}
                    {/*            name="termiteLetterRequested"*/}
                    {/*            checked={termiteLetterRequested}*/}
                    {/*            onChange={(e) => this.handleSwitchChange(e)}*/}
                    {/*            color="primary"*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*    label="Termite Letter Requested?"*/}
                    {/*/>*/}
                    <br/>
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            className={classes.dateInput}
                            margin="normal"
                            label="Construction Start Date"
                            value={constructionStartDate}
                            onChange={(d) => this.handleDateChange('constructionStartDate', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                        />
                    </MuiPickersUtilsProvider>
                    <br/>
                    <TextField autoComplete="new"
                       value={qbwWarranty}
                       name="qbwWarranty"
                       onChange={(e) => this.handleFieldChange(e)}
                       label="Registered Warranty"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('qbwWarranty')}
                       helperText={this.fieldErrorMessage('qbwWarranty')}
                       fullWidth
                    />
                    {/*<MuiPickersUtilsProvider utils={DateMomentUtils}>*/}
                    {/*    <KeyboardDatePicker*/}
                    {/*        disableToolbar*/}
                    {/*        variant="inline"*/}
                    {/*        format="MM/DD/YYYY"*/}
                    {/*        className={classes.dateInput}*/}
                    {/*        margin="normal"*/}
                    {/*        label="QBW Warranty Order Date"*/}
                    {/*        value={qbwWarranty}*/}
                    {/*        onChange={(d) => this.handleDateChange('qbwWarranty', d)}*/}
                    {/*        KeyboardButtonProps={{*/}
                    {/*            'aria-label': 'change date',*/}
                    {/*        }}*/}
                    {/*        autoOk={true}*/}
                    {/*    />*/}
                    {/*</MuiPickersUtilsProvider>*/}
                </div>
                }
                <br/>
                <TextField autoComplete="new"
                    value={salesNotes}
                    name="salesNotes"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Sales Notes"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('salesNotes')}
                    helperText={this.fieldErrorMessage('salesNotes')}
                    multiline
                    rows={3}
                    fullWidth
                />
                <Button disabled={!this.isConstructionValid()} variant="contained" color="primary" onClick={() => this.handleNext()} className={classes.button}>NEXT</Button>
            </div>
        );

        const closeDateSection = (
            <AddCloseDate underContract={status === 'Under Contract'} stage={stage} builder={this.getBuilderName(builderId)} save={(c) => this.setState({closeDate: c}, () => this.handleNext())} closeDate={closeDate} showSnack={(t,e) => this.props.showSnack(t,e)} />
        );

        const contractSection = (
            <AddContract underContract={status === 'Under Contract'} stage={parseInt((stage||'1'))} builder={this.getBuilderName(builderId)} save={(c) => this.setState({contract: c}, () => this.handleNext())} contract={contract} parentType="construction" showSnack={(t,e) => this.props.showSnack(t,e)} />
        );

        const steps = [
            {title: 'Intro',body: intro},
            {title: 'Builder',body: builderSection},
            {title: 'Status', body: statusSection},
            {title: 'Property',body: propertySection},
            {title: 'Construction',body: constructionSection},
            {title: 'Close Date',body: closeDateSection},
            {title: 'Contract',body: contractSection},
            {title: 'Save',body: outro},
        ];

        return (
            <div className={classes.root}>
                {loadingConstruction ? (
                    <Loading/>
                ) : (
                    <div>
                        <div>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step onClick={() => this.stepClicked(index)} key={'construction-step-' + index}>
                                        <StepLabel>{step.title}</StepLabel>
                                        <StepContent>
                                            {step.body}
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        {savingConstruction &&
                        <Loading text="Saving..."/>
                        }
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(AddConstruction);