import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // flexGrow: 1,
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    inlineButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    fullWidth: {
        flex: '0 0 100%'
    }
});

class CloseConstruction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            validations: []
        };
    }

    close() {
        this.props.close(this.props.constructionId);
    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'CLOSE PROPERTY DETAILS';

        const {
            isLoading
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <strong>Please confirm that you would like to close the Property Details at {this.props.summary}</strong>
                </div>
                <br/>
                <Button variant="contained" color="primary" onClick={() => this.setState({isLoading: true},() => this.close())} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(CloseConstruction);