import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import lightBlue from "@material-ui/core/colors/lightBlue";
import indigo from "@material-ui/core/colors/indigo";
import deepPurple from "@material-ui/core/colors/deepPurple";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import NotesIcon from '@material-ui/icons/Notes';
import HomeIcon from '@material-ui/icons/Home';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventIcon from '@material-ui/icons/Event';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import HistoryIcon from '@material-ui/icons/History';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaletteIcon from '@material-ui/icons/Palette';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

//Palette
import Divider from '@material-ui/core/Divider';
import CountUp from 'react-countup';

// import Chip from '@material-ui/core/Chip';
// import LabelIcon from '@material-ui/icons/Label';

const styles = theme => ({
    card: {
        marginTop: 30,
    },
    cardIconWrapper: {
        backgroundColor: '#fff',
        position: 'absolute',
        top: -30,
        left: theme.spacing(2),
        color: 'rgba(0, 0, 0, 0.87)',
        width: 66,
        height: 66,
        padding: 0,
        minWidth: 0,
        boxSizing: 'border-box',
        minHeight: 36,
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        fontWeight: 500,
        lineHeight: 1.75,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center'
    },
    cardIcon: {
        width: '100%',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
    },
    icon: {
        // color: '#333',
        fontSize: '2.5rem'
    },
    cardContent: {
        paddingBottom: theme.spacing(2) + 'px !important',
        minHeight: 185
    },
    cardRight: {
        marginBottom: theme.spacing(2),
        paddingLeft: 76
    },
    cardFooter: {
        color: '#999',
        fontSize: 12,
        padding: '16px 0 0 0',
        textAlign: 'left'
    },
    cardFooterIcon: {
        fontSize: 12
    },
    add: {
        backgroundColor: green.A700,
        '&:hover': {
            backgroundColor: green.A400,
        },
    },
    remove: {
        backgroundColor: red.A700,
        '&:hover': {
            backgroundColor: red.A400,
        },
    },
    edit: {
        backgroundColor: lightBlue.A700,
        '&:hover': {
            backgroundColor: lightBlue.A400,
        },
    },
    flex: {
        display: 'flex'
    },
    grow: {
        flexGrow: 1,
    },

    wrapper: {
        textAlign: 'right'
    },
    label: {
        fontWeight: 300,
        fontSize: 14,
        display: 'block',
        height: 60
    },
    value: {
        fontWeight: 400,
        fontSize: 28,
    },
    notificationIcons: {
        padding: theme.spacing(1)
    },
    activeIcon: {
        color: green.A700,
        marginRight: 4
    },
    disabledIcon: {
        color: grey["400"],
        marginRight: 4,
        cursor: 'not-allowed'
    }
});

class DashboardCard extends Component {

    render() {
        const { classes, color, icon, count, period, label } = this.props;

        let style = {};

        switch(color) {
            case 'red':
                style = {backgroundColor: red[500]};
                break;
            case 'green':
                style = {backgroundColor: green[500],color: '#fff'};
                break;
            case 'purple':
                style = {backgroundColor: deepPurple[500]};
                break;
            case 'indigo':
                style = {backgroundColor: indigo[500]};
                break;
            case 'lightBlue':
                style = {backgroundColor: lightBlue[500],color: '#fff'};
                break;
            case 'blue':
                style = {backgroundColor: blue[500]};
                break;
            case 'orange':
                style = {backgroundColor: orange[500],color: '#fff'};
                break;
            default:
        }
        return (
            <div onClick={() => this.props.onClick()} style={{position: 'relative',cursor:'pointer'}}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.flex}>
                            <div className={classes.grow}>
                                <div className={classes.cardIconWrapper} style={style}>
                                    <div className={classes.cardIcon}>
                                        {icon === 'receipt' &&
                                        <ReceiptIcon className={classes.icon} />
                                        }
                                        {icon === 'home' &&
                                        <HomeIcon className={classes.icon} />
                                        }
                                        {icon === 'schedule' &&
                                        <ScheduleIcon className={classes.icon} />
                                        }
                                        {icon === 'daterange' &&
                                        <DateRangeIcon className={classes.icon} />
                                        }
                                        {icon === 'eventbusy' &&
                                        <EventBusyIcon className={classes.icon} />
                                        }
                                        {icon === 'note' &&
                                        <NotesIcon className={classes.icon} />
                                        }
                                        {icon === 'history' &&
                                        <HistoryIcon className={classes.icon} />
                                        }
                                        {icon === 'eventavailable' &&
                                        <EventAvailableIcon className={classes.icon} />
                                        }
                                        {icon === 'palette' &&
                                        <PaletteIcon className={classes.icon} />
                                        }
                                        {icon === 'localShipping' &&
                                        <LocalShippingIcon className={classes.icon} />
                                        }
                                        {icon === 'event' &&
                                        <EventIcon className={classes.icon} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={classes.cardRight}>
                                <div className={classes.wrapper}>
                                    <span className={classes.label}>{label}</span>
                                </div>
                                <div className={classes.wrapper}>
                                    <span className={classes.value}><CountUp start={0} end={count} /></span>
                                </div>
                            </div>
                        </div>
                        <Divider/>
                        <div className={classes.cardFooter}>
                            <ScheduleIcon className={classes.cardFooterIcon}/><span> {period}</span>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(DashboardCard);