import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField, validateNumber} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {CircularProgress} from "@material-ui/core";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    inlineButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    inlineProgressWrapper: {
        display: 'inline-block',
        position: 'relative',
        height: theme.spacing(3),
        width: theme.spacing(3),
    },
    inlineProgress: {
        position: 'absolute',
        top: theme.spacing(.5),
        left: theme.spacing(.5),
    },
    existingWarning: {
        margin: theme.spacing(1),
        fontSize: 12,
        fontWeight: 300
    }
});

class Property extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lotNumber: '',
            streetNumber: '',
            streetName: '',
            address2: '',
            city: '',
            state: 'GA',
            zip: '',
            county: '',
            loadingCommunities: true,
            communities: [],
            communityId: '',
            validations: [],
            isLoading: false,
            checkingExisting: false,
            existingProperty: null,
            ignoreExisting: false,
            checkExisting: true,
        };
    }

    componentDidMount() {
        const propertyId = this.props.propertyId || null;
        if(propertyId !== null){
            this.setState({isLoading:true},() => this.loadProperty(propertyId))
        } else {
            const property = this.props.property || null;
            if(property !== null){
                this.setState({
                    lotNumber: property.lotNumber || '',
                    streetNumber: property.streetNumber || '',
                    streetName: property.streetName || '',
                    address2: property.address2 || '',
                    city: property.city || '',
                    zip: property.zip || '',
                    county: property.county || '',
                    communityId: property.communityId || '',
                }, () => this.getCommunities())
            } else {
                this.getCommunities()
            }
        }
    }

    getCommunities() {
        if(this.state.communities.length > 0) {
            this.setState({loadingCommunities: false})
        } else {
            request(
                'GET',
                '/Manage/getCommunities',
                null,
                (data) => {
                    this.setState({communities: data.communities, loadingCommunities: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getSimilarExistingProperty() {
        const propertyId = this.props.propertyId || null;
        const {
            lotNumber,
            streetNumber,
            streetName,
            city,
            zip,
            county,
            checkExisting
        } = this.state;

        const q = `?lotNumber=${lotNumber}&streetNumber=${streetNumber}&streetName=${streetName}&city=${city}&zip=${zip}&county=${county}`;

        if(propertyId === null && this.isValid() && checkExisting){
            this.setState({checkingExisting: true},() =>
                request(
                    'GET',
                    '/Manage/getSimilarExistingProperty'+q,
                    null,
                    (data) => {
                        this.setState({checkingExisting: false, existingProperty: data.property, ignoreExisting: false})
                    },
                    (error) => {
                        this.setState({checkingExisting: false, existingProperty: null})
                    })
            )
        }
    }

    loadProperty(propertyId) {
        request(
            'GET',
            '/Manage/getProperty?propertyId=' + propertyId,
            null,
            (data) => {
                this.loadData(data.property);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            lotNumber: data.lotNumber || '',
            streetNumber: data.streetNumber || '',
            streetName: data.streetName || '',
            address2: data.address2 || '',
            city: data.city || '',
            zip: data.zip || '',
            county: data.county || '',
            communityId: data.communityId || '',
            isLoading: false,
            checkExisting: false,
        }, () => this.getCommunities())
    }

    handleError(error){
        this.setState({isLoading: false, loadingCommunities: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'lotNumber',
            'streetNumber',
            'streetName',
            'address2',
            'city',
            'state',
            'zip',
            'county'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';
        const lotNumberRequired = this.props.lotNumberRequired || false;
        const underContract = this.props.underContract || false;

        switch(fieldName){
            case 'lotNumber':
                if(lotNumberRequired){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the lot number.';
                }
                break;
            case 'streetNumber':
                if(value !== null && value.length > 0) {
                    isValid = validateNumber(value);
                    invalidationReason = 'Please enter the street #';
                }
                break;
            case 'streetName':
                if(underContract||value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the street name';
                }
                break;
            case 'address2':
                break;
            case 'city':
                if(underContract||value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the city name';
                }
                break;
            case 'zip':
                if(underContract||value.length > 0){
                    isValid = validateField(value,5,5);
                    invalidationReason = 'Please enter the 5 digit zip code';
                }
                break;
            case 'county':
                if(underContract||value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the county name';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;
        if (field === 'lotNumber') {
            value = value.replace(/[^a-zA-Z\d./]+/g, '')
        }
        if (field === 'streetNumber') {
            value = value.replace(/[^a-zA-Z\d.]+/g, '')
        }
        if (field === 'zip') {
            value = value.replace(/\D+/g, '');
            if(value.length > 5){
                value = value.substring(0,5);
            }
        }
        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations},() => this.getSimilarExistingProperty());
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    // handleSwitchChange(e){
    //     const field = e.target.name;
    //     const value = this.state[field];
    //
    //     this.setState({[field]: !value});
    // }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    populateFromCommunity() {
        const {
            communities,
            communityId,
            city,
            zip,
            county,
        } = this.state;
        const vIndex = _.findIndex(communities, {communityId: communityId});
        if(vIndex > -1){
            const community = communities[vIndex] || null;
            if (community !== null){
                const newCity = community.city || city;
                const newZip = community.zip || zip;
                const newCounty = community.county || county;
                this.setState({city: newCity, county: newCounty, zip: newZip}, () => this.validateAll());
            }
        }
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes } = this.props;
        const { 
            lotNumber,
            streetNumber,
            streetName,
            address2,
            city,
            state,
            zip,
            county,
            loadingCommunities,
            communities,
            communityId,
            isLoading,
            checkingExisting,
            existingProperty,
            ignoreExisting
        } = this.state;
        const saveBtnText = this.props.saveBtnText || 'SAVE';

        const lotNumberRequired = this.props.lotNumberRequired || false;
        const underContract = this.props.underContract || false;

        let propertyId = this.props.propertyId || null;
        if(propertyId === null){
            const property = this.props.property || null;
            if(property !== null) {
                propertyId = property.propertyId || null;
            }
        }

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <div>
                        {loadingCommunities ? (
                            <Loading contained={true}/>
                        ) : (
                            <div>
                                <div className={classes.flexWrapper}>
                                    <FormControl className={classes.dropdown}>
                                        <InputLabel htmlFor="community-select">Community</InputLabel>
                                        <Select
                                            id="community-select"
                                            name="communityId"
                                            value={communityId}
                                            onChange={(e) => this.handleSelectChange(e)}
                                            // disabled={propertyId !== null}
                                        >
                                            {communities.map((item, index) => (
                                                <MenuItem key={item.communityId} value={item.communityId}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <div className={classes.inlineButton}>
                                        <Button disabled={(communityId||null) === null} variant="contained" size="small" color="primary" onClick={() => this.populateFromCommunity()}>Load From Community</Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <br/>
                    <TextField autoComplete="new"
                        value={lotNumber}
                        name="lotNumber"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Lot Number"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('lotNumber')}
                        helperText={this.fieldErrorMessage('lotNumber')}
                        required={lotNumberRequired}
                    />
                    <TextField autoComplete="new"
                        value={streetNumber}
                        name="streetNumber"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Street Number"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('streetNumber')}
                        helperText={this.fieldErrorMessage('streetNumber')}
                    />
                    <TextField autoComplete="new"
                        value={streetName}
                        name="streetName"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Street Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('streetName')}
                        helperText={this.fieldErrorMessage('streetName')}
                        required={underContract}
                    />
                    <TextField autoComplete="new"
                        value={address2}
                        name="address2"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Address 2"
                        className={classes.textField}
                        margin="normal"
                    />
                    <TextField autoComplete="new"
                        value={city}
                        name="city"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="City"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('city')}
                        helperText={this.fieldErrorMessage('city')}
                        required={underContract}
                    />
                    <TextField autoComplete="new"
                        value={state}
                        name="state"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="State"
                        className={classes.textField}
                        margin="normal"
                        disabled={true}
                    />
                    <TextField autoComplete="new"
                        value={zip}
                        name="zip"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Zip"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('zip')}
                        helperText={this.fieldErrorMessage('zip')}
                        required={underContract}
                    />
                    <TextField autoComplete="new"
                        value={county}
                        name="county"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="County"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('county')}
                        helperText={this.fieldErrorMessage('county')}
                        required={underContract}
                    />
                </div>
                <br/>

                {((existingProperty||null) !== null && !ignoreExisting && !checkingExisting) &&
                <div>
                    <div className={classes.existingWarning}>
                        <div>A similar property already exist for: {existingProperty}.</div>
                        <div>You should consider using the existing property to avoid duplicates.</div>
                    </div>
                    <Button variant="contained" color="primary" className={classes.button} onClick={() => this.setState({ignoreExisting: true})}>Ignore</Button>
                </div>
                }
                <Button variant="contained" color="primary" disabled={!this.isValid()||isLoading||checkingExisting||(!ignoreExisting&&(existingProperty||null) !== null)} onClick={() => this.props.save({lotNumber,streetNumber,streetName,address2,city,state,zip,county,communityId,propertyId})} className={classes.button}>{saveBtnText}</Button>
                {checkingExisting &&
                <div className={classes.inlineProgressWrapper}>
                    <CircularProgress size={24} className={classes.inlineProgress}/>
                </div>
                }
            </div>
        );
    }
}

export default withStyles(styles)(Property);