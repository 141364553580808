import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from '@material-ui/core/Divider';
import UpdateTemplate from "./UpdateTemplate";
// import AddTemplate from "./AddTemplate";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    inlineButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    header: {
        margin: theme.spacing(1) + 'px 0',
        color: '#555',
        fontSize: 22,
        textTransform: 'uppercase',
        fontWeight: 400
    },
});

class UpdateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            name: '',
            email: '',
            isActive: true,
            roleId: '',
            templateId: '',
            roles: [],
            isLoading: true,
            validations: []
        };
    }

    componentDidMount() {
        const userId = this.props.userId || null;
        if(userId !== null){
            const {name,email,isActive,roleId,templateId} = this.props;
            this.setState({userId,name,email,isActive,roleId,templateId}, () => this.getRoles());
        } else {
            this.setState({isLoading: false}, () => this.props.showSnack('error','Error loading User'))
        }
    }

    getRoles() {
        request(
            'GET',
            '/Admin/getRoles',
            null,
            (data) => {
                this.setState({roles: data.roles, isLoading: false})
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error))
            })
    }

    validateAll(){
        const fieldsToValidate = [
            // 'name',
            // 'email',
            'roleId',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(
                fieldsToValidate[i],
                this.state[fieldsToValidate[i]] || null
            );
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value){
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'name':
                isValid = validateField(value);
                invalidationReason = 'The User\'s name is required';
                break;
            case 'email':
                isValid = validateField(value);
                invalidationReason = 'The User\'s email is required';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    };

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    saveRole() {
        const action = '/Admin/updateUser';
        const {userId, roleId} = this.state;
        const data = {userId, roleId};
        const successMessage = 'Successfully Updated User!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({isLoading: false}, () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error))
            })
    }

    templateAdded(templateId) {

    }

    templateUpdated(message){
        this.setState({isLoading: false},() => this.props.showSnack('success',message))
    }

    render() {
        const { classes } = this.props;
        // const saveBtnText = this.props.saveBtnText || 'SAVE';
        // const userId = this.props.userId || null;

        const {
            name,
            email,
            // isActive,
            roleId,
            roles,
            isLoading
        } = this.state;

        const templateId = this.state.templateId || null;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                               value={name}
                               name="name"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="User Name"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('name')}
                               helperText={this.fieldErrorMessage('name')}
                               disabled
                    />
                    <br/>
                    <TextField autoComplete="new"
                               value={email}
                               name="email"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Email"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('email')}
                               helperText={this.fieldErrorMessage('email')}
                               disabled
                    />
                </div>
                <br/>
                {/*<Divider className={classes.divider}/>*/}
                {((roles||[]).length > 0 && (roleId||null) !== null) &&
                <div className={classes.flexWrapper}>
                    <FormControl className={classes.dropdown}>
                        <InputLabel htmlFor="role-select">Role</InputLabel>
                        <Select
                            id="role-select"
                            name="roleId"
                            value={roleId}
                            onChange={(e) => this.handleSelectChange(e)}
                        >
                            {roles.map((item, index) => (
                                <MenuItem key={item.roleId} value={item.roleId}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {roleId !== this.props.roleId &&
                    <div className={classes.inlineButton}>
                        <Button variant="contained" color="primary" disabled={(roleId||null) === null} onClick={() => this.setState({isLoading: true}, () => this.saveRole())}>SAVE ROLE</Button>
                    </div>
                    }
                </div>
                }
                <Divider className={classes.divider}/>
                {(!isLoading && templateId !== null) ? (
                    <div>
                        <div className={classes.header}>Edit User's Template</div>
                        <UpdateTemplate saved={(m) => this.setState({isLoading: true}, () => this.templateUpdated(m))} type="user" templateId={templateId} showSnack={(t,m) => this.props.showSnack(t,m)}/>
                    </div>
                ) : (
                    <div>
                        {/*<div className={classes.header}>Create User's Template</div>*/}
                        {/*<AddTemplate type="user" saved={(i,m) => this.props.templateAdded(i,m)} showSnack={(t,m) => this.props.showSnack(t,m)}/>*/}
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(UpdateUser);