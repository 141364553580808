import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
    iconButton: {
    },
    iconContainer: {
        marginRight: "24px",
    },
    inverseIcon: {
        transform: "rotate(90deg)",
    },
};

class CustomToolbarSelect extends React.Component {
    getSelected(){
        const data = this.props.data;
        const row = this.props.selectedRows.data[0].dataIndex;
        return data[row];
    }

    render() {
        const { classes } = this.props;
        const showEdit = this.props.showEdit || false;
        const showDelete = this.props.showDelete || false;
        const showListingDelete = this.props.showListingDelete || false;
        const showListingClose = this.props.showListingClose || false;
        const showListingTerminate = this.props.showListingTerminate || false;
        const showConstructionClose = this.props.showConstructionClose || false;
        const showConstructionDelete = this.props.showConstructionDelete || false;
        const data = this.props.data;
        const hasData = data.length > 0;
        const selected = hasData ? this.getSelected() : null;

        return (
            <div>
                <div className={classes.iconContainer}>
                {(hasData && showListingDelete) &&
                    <Tooltip title={"Delete Selected"}>
                        <IconButton className={classes.iconButton} onClick={() => this.props.delete(selected)}>
                            <DeleteIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                }
                {(hasData && showListingClose) &&
                    <Tooltip title={"Close Selected Sales Details"}>
                        <IconButton className={classes.iconButton} onClick={() => this.props.close(selected)}>
                            <DoneIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                }
                {(hasData && showListingTerminate) &&
                    <Tooltip title={"Terminate Selected Sales Details"}>
                        <IconButton className={classes.iconButton} onClick={() => this.props.terminate(selected)}>
                            <CloseIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                }
                {(hasData && showConstructionDelete) &&
                    <Tooltip title={"Delete Selected"}>
                        <IconButton className={classes.iconButton} onClick={() => this.props.delete(selected)}>
                            <DeleteIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                }
                {(hasData && showConstructionClose) &&
                    <Tooltip title={"Close Selected"}>
                        <IconButton className={classes.iconButton} onClick={() => this.props.close(selected)}>
                            <DoneIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                }
                {(hasData && showEdit) &&
                    <Tooltip title={"Edit Selected"}>
                        <IconButton className={classes.iconButton} onClick={() => this.props.edit(selected)}>
                            <EditIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                }
                {(hasData && showDelete) &&
                    <Tooltip title={"Delete Selected"}>
                        <IconButton className={classes.iconButton} onClick={() => this.props.delete(selected)}>
                            <DeleteIcon className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                }
                </div>
            </div>

        );
    }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);