import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {request} from "../utils";
import TemplateSummary from "./TemplateSummary";
import Dialog from "./Dialog";
import AddTemplate from "./Forms/AddTemplate";
import UpdateTemplate from "./Forms/UpdateTemplate";
import AssignTemplate from "./Forms/AssignTemplate";
import Fab from "@material-ui/core/Fab";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import {blue} from "@material-ui/core/colors";

const styles = theme => ({
    grid: {
        padding: theme.spacing(2) + 'px 0',
        display: 'flex'
        // minHeight: 200
    },
    grow: {
        flex: 1
    },
    card: {
        // minHeight: theme.spacing(4) + 60,
        overflow: 'unset !important'
    },
    cardContent: {
        padding: theme.spacing(2) + 'px !important',
        // minHeight: theme.spacing(4) + 60,
        display: 'flex'
    },
    cardLeft: {
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        height: 40,
        minHeight: 40,
        maxHeight: 40,
        position: 'relative',
        marginRight: theme.spacing(1)
        // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    cardRight: {
        textAlign: 'left'
    },
    flex: {
        display: 'flex'
    },
    skeletonText: {
        width: '100%',
        fontSize: 21,
    },
    skeletonImage: {
        width: 40,
        height: 40,
        marginRight: theme.spacing(1),
        borderRadius: '50%'
    },
    greeting: {
        fontSize: 32,
        margin: '0 auto',
        textAlign: 'center',
        fontFamily: '\'Pacifico\', cursive'
    },
    skeletonGreeting: {
        width: 400,
        maxWidth: '100%',
        margin: '0 auto',
        fontSize: 32,
    },
    skeletonCenter: {
        margin: '0 auto',
    },
    skeletonDivider: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontSize: 30,
        width: 250
    },
    divider: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    dividerText: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: '#555',
        fontSize: 24,
        textTransform: 'uppercase',
        fontWeight: 300
    },
    dividerLine: {
        flex: 1,
        borderBottom: 'solid #eeeeee 2px'
    },
    fabProgress: {
        color: blue[500],
        position: 'absolute',
        top: -4,
        left: -4,
        zIndex: 1,
    },
    fabProgressWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    }
});

class Templates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            showDialog: false,
            dialogTitle: '',
            dialogBody: null,
            isLoading: true
        };
    }

    componentDidMount() {
        this.getTemplates();
    }

    getTemplates(){
        request(
            'GET',
            '/Admin/getLandingPageTemplates',
            null,
            (data) => {
                this.setState({templates: data.templates, isLoading: false});
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error));
            })
    }

    edit(template){
        const dialogTitle = 'Edit Template';
        const dialogBody = (
            <UpdateTemplate type="public" templateId={template.landingPageTemplateId} saved={(m) => this.setState({showDialog: false, dialogTitle: '', dialogBody: ''}, () => this.props.showSnack('success',m))} showSnack={(t,m) => this.props.showSnack(t,m)}/>
        );
        this.setState({showDialog: true, dialogTitle, dialogBody})
    }

    assign(template){
        const dialogTitle = 'Assign Template';
        const dialogBody = (
            <AssignTemplate name={template.name} templateId={template.landingPageTemplateId} saved={(m) => this.setState({showDialog: false, dialogTitle: '', dialogBody: ''}, () => this.props.showSnack('success',m))} showSnack={(t,m) => this.props.showSnack(t,m)}/>
        );
        this.setState({showDialog: true, dialogTitle, dialogBody})
    }

    add(){
        const dialogTitle = 'Add Template';
        const dialogBody = (
            <AddTemplate type="public" saved={(i,m) => this.setState({showDialog: false, dialogTitle: '', dialogBody: ''}, () => this.props.showSnack('success',m + ' | Refresh to display new Template'))} showSnack={(t,m) => this.props.showSnack(t,m)}/>
        );
        this.setState({showDialog: true, dialogTitle, dialogBody})
    }

    render() {
        const { classes } = this.props;
        const { isLoading, templates, showDialog, dialogBody, dialogTitle } = this.state;
        const skeletons = 6;

        return (
            <div className={classes.tab}>
                <div className={classes.flex}>
                    <div className={classes.grow}>
                        <Button disabled={isLoading} variant="contained" size="small" color="primary" className={classes.margin}
                                onClick={() => this.add()}>
                            Add Template
                        </Button>
                    </div>
                    <div className={classes.fabProgressWrapper}>
                        <Fab
                            size="small"
                            aria-label="reload"
                            onClick={() => this.setState({isLoading:true,templates:[]},() => this.getTemplates())}
                            disabled={isLoading}
                        >
                            <RefreshIcon/>
                        </Fab>
                        {isLoading && <CircularProgress size={48} className={classes.fabProgress} />}
                    </div>
                </div>
                <Grid>
                    {isLoading ? (
                        <Grid container spacing={2} className={classes.grid} justify="flex-start" alignItems="flex-start">
                            {skeletons > 0 &&
                            [...Array(skeletons)].map((item,index) =>
                                <Grid item xs={12} sm={6} md={4} lg={3} key={'skeleton-' + index}>
                                    <div style={{position: 'relative'}}>
                                        <Card className={classes.card}>
                                            <CardContent className={classes.cardContent}>
                                                {/*<div className={classes.cardLeft}>*/}
                                                {/*    <Skeleton variant="rect" width={40} height={40} className={classes.skeletonImage} />*/}
                                                {/*</div>*/}
                                                <div className={classes.skeletonText}>
                                                    {/*<Skeleton width="75%" />*/}
                                                    <Skeleton />
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <Grid container spacing={2} className={classes.grid} justify="flex-start" alignItems="flex-start">
                            {(templates || null) !== null &&
                            templates.map((template,index) =>
                                <Grid item xs={12} sm={6} md={4} lg={3} key={'template-' + index}>
                                    <TemplateSummary name={template.name} edit={() => this.edit(template)} assign={() => this.assign(template)}/>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Dialog closeCallback={() => this.setState({dialogTitle: '', dialogBody: null, showDialog: false})} showDialog={showDialog} title={dialogTitle} bodyHtml={dialogBody}/>
            </div>
        );
    }
}

export default withStyles(styles)(Templates);