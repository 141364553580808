import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField, validatePrice} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import InputAdornment from "@material-ui/core/InputAdornment";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // flexGrow: 1,
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    inlineButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    fullWidth: {
        flex: '0 0 100%'
    }
});

class TerminateListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dateTerminated: null,
            earnestMoneyParty: '',
            earnestMoneyAmount: '',
            optionMoneyParty: '',
            optionMoneyAmount: '',
            constructionStatus: '',
            listPrice: '',
            hasConstruction: false,
            validations: []
        };
    }

    componentDidMount() {
        const listingId = this.props.listingId || null;
        if(listingId !== null){
            this.loadListingInfo(listingId)
        }
    }

    loadListingInfo(listingId) {
        request(
            'GET',
            '/Manage/getListingTerminationInfo?listingId=' + listingId,
            null,
            (data) => {
                this.loadData(data.listing);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            dateTerminated: data.dateTerminated || null,
            hasConstruction: data.hasConstruction,
        })
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'dateTerminated',
            'earnestMoneyParty',
            'earnestMoneyAmount',
            'optionMoneyParty',
            'optionMoneyAmount',
            'constructionStatus',
            'listPrice'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'dateTerminated':
                isValid = moment(value).isValid();
                invalidationReason = 'Please enter a termination date.';
                break;
            case 'earnestMoneyParty':
                isValid = validateField(value);
                invalidationReason = 'Please select the Party.';
                break;
            case 'earnestMoneyAmount':
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'optionMoneyParty':
                isValid = validateField(value);
                invalidationReason = 'Please select the Party.';
                break;
            case 'optionMoneyAmount':
                isValid = validatePrice(value);
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'listPrice':
                if(this.state.hasConstruction){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'constructionStatus':
                if(this.state.hasConstruction){
                    isValid = validateField(value);
                    invalidationReason = 'Please select a status.';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        switch(field){
            case 'earnestMoneyAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'optionMoneyAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'listPrice':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleDateChange(name,date) {
        let validations = this.state.validations;
        const validation = this.validateField(name,date);

        const vIndex = _.findIndex(validations, {fieldName: name});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[name]: date, validations});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        this.setState({[field]: !value});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    };

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    terminate() {
        const {
            dateTerminated,
            earnestMoneyParty,
            earnestMoneyAmount,
            optionMoneyParty,
            optionMoneyAmount,
            constructionStatus,
            listPrice
        } = this.state;

        this.props.terminate({
            listingId: this.props.listingId,
            dateTerminated,
            earnestMoneyParty,
            earnestMoneyAmount,
            optionMoneyParty,
            optionMoneyAmount,
            constructionStatus,
            listPrice
        })
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'TERMINATE SALES DETAILS';
        // const listingId = this.props.listingId || null;

        const {
            isLoading,
            dateTerminated,
            earnestMoneyParty,
            earnestMoneyAmount,
            optionMoneyParty,
            optionMoneyAmount,
            hasConstruction,
            constructionStatus,
            listPrice
        } = this.state;
        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <strong>Please provide the following information in order to terminate the Sales Details at {this.props.summary}</strong>
                    <br/>
                    <div className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                className={classes.dateInput}
                                margin="normal"
                                id="dateTerminated"
                                label={'Date Terminated'}
                                value={dateTerminated}
                                onChange={(d) => this.handleDateChange('dateTerminated', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                                required
                                fullWidth
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <br/>
                    <strong className={classes.fullWidth}>Payouts</strong>
                    <FormControl className={classes.dropdown} required>
                        <InputLabel htmlFor="earnestMoneyParty-select">Earnest Money Party</InputLabel>
                        <Select
                            id="earnestMoneyParty-select"
                            name="earnestMoneyParty"
                            value={earnestMoneyParty}
                            onChange={(e) => this.handleSelectChange(e)}
                        >
                            <MenuItem value="Buyer">Buyer</MenuItem>
                            <MenuItem value="Seller">Seller</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField autoComplete="new"
                        name="earnestMoneyAmount"
                        value={earnestMoneyAmount}
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Earnest Money Amount"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('earnestMoneyAmount')}
                        helperText={this.fieldErrorMessage('earnestMoneyAmount')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                    />
                    <br/>
                    <FormControl className={classes.dropdown} required>
                        <InputLabel htmlFor="optionMoneyParty-select">Option Money Party</InputLabel>
                        <Select
                            id="optionMoneyParty-select"
                            name="optionMoneyParty"
                            value={optionMoneyParty}
                            onChange={(e) => this.handleSelectChange(e)}
                        >
                            <MenuItem value="Buyer">Buyer</MenuItem>
                            <MenuItem value="Seller">Seller</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField autoComplete="new"
                        name="optionMoneyAmount"
                        value={optionMoneyAmount}
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Option Money Amount"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('optionMoneyAmount')}
                        helperText={this.fieldErrorMessage('optionMoneyAmount')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                    />
                    <br/>
                    {hasConstruction &&
                    <div className={classes.fullWidth}>
                        <br/>
                        <strong>Update Property Details</strong>
                        <br/>
                        <FormControl className={classes.dropdown} required>
                            <InputLabel htmlFor="construction-status-select">Construction Status</InputLabel>
                            <Select
                                id="construction-status-select"
                                name="constructionStatus"
                                value={constructionStatus}
                                onChange={(e) => this.handleSelectChange(e)}
                            >
                                <MenuItem value="Lot">Lot</MenuItem>
                                <MenuItem value="SPEC">SPEC</MenuItem>
                                <MenuItem value="Model">Model</MenuItem>
                                {/*<MenuItem value="Under Contract">Under Contract</MenuItem>*/}
                            </Select>
                        </FormControl>
                        <br/>
                        <TextField autoComplete="new"
                            name="listPrice"
                            value={listPrice}
                            onChange={(e) => this.handleFieldChange(e)}
                            label="List Price"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('listPrice')}
                            helperText={this.fieldErrorMessage('listPrice')}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            required
                        />
                        <br/>
                    </div>
                    }
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.setState({isLoading: true},() => this.terminate())} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(TerminateListing);