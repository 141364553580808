import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {validatePrice} from '../../utils';
import _ from 'lodash';
import InputAdornment from "@material-ui/core/InputAdornment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import Dialog from "../Dialog";
import moment from "moment";

const styles = theme => ({
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(.25),
        marginBottom: theme.spacing(.5),
        maxWidth: 100,
        flexGrow: 1
    },
    dateInput: {
        margin: theme.spacing(.25),
        marginBottom: theme.spacing(.5),
        maxWidth: 140,
        flexGrow: 1
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: 2,
        marginTop: theme.spacing(1),
    },
    header: {
        textAlign: 'center',
        fontWeight: '500'
    },
    icon: {
        marginTop: theme.spacing(2.25),
    },
    margin: {
        // marginTop: theme.spacing(1.5),
    }
});

class UpdateInstallment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            installmentId: '',
            type: '',
            amount: '',
            dateDue: null,
            dateReceived: null,
            editMode: false,
            showConfirmDelete: false,
            validations: []
        };
    }

    componentDidMount() {
        const installment = this.props.installment || null;
        if(installment !== null){
            this.setState(
                // installment
                {
                installmentId: installment.installmentId,
                type: installment.type,
                amount: installment.amount,
                dateDue: (installment.dateDue || null) !== null ? moment(installment.dateDue) : null,
                dateReceived: (installment.dateReceived || null) !== null ? moment(installment.dateReceived) : null
                }
            );
        }
    }

    validateAll() {
        const fieldsToValidate = [
            // 'type',
            'amount',
            'dateDue',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            // case 'type':
            //     isValid = (value||null) !== null;
            //     invalidationReason = 'The type is required';
            //     break;
            case 'amount':
                isValid = validatePrice(value);
                invalidationReason = 'The amount is required';
                break;
            case 'dateDue':
                isValid = (value||null) !== null;
                invalidationReason = 'The Date Due is required';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        switch (field) {
            case 'amount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    save() {
        const {
            // party,
            installmentId,
            type,
            amount,
            dateDue,
            dateReceived
        } = this.state;

        const due = dateDue.format('YYYY-MM-DD');
        const received = (dateReceived||null) !== null ? dateReceived.format('YYYY-MM-DD') : null;

        const installment = {
            installmentId,
            // party,
            type,
            amount,
            dateDue: due,
            dateReceived: received
        };

        this.props.save(installment);
    }

    delete() {
        const {
            installmentId,
            type
        } = this.state;

        this.props.delete(installmentId,type);
    }

    render() {
        const { classes } = this.props;
        const {
            // type,
            // showType,
            amount,
            dateDue,
            dateReceived,
            editMode,
            showConfirmDelete
        } = this.state;

        const readOnlyMode = this.props.readOnlyMode || false;

        const buttons = [
            {
                text: 'Yes',
                callback: () => {
                    this.setState({
                        showConfirmDelete: false,
                    },() => this.delete())
                }
            },
            {
                text: 'No',
                callback: () => {
                    this.setState({
                        showConfirmDelete: false,
                    })
                }
            }
        ];

        return (
            <div>
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                        name="amount"
                        value={amount}
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Amount"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('amount')}
                        helperText={this.fieldErrorMessage('amount')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={!editMode}
                    />
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            className={classes.dateInput}
                            margin="normal"
                            label="Due"
                            value={dateDue}
                            onChange={(d) => this.handleDateChange('dateDue', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                            disabled={!editMode}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            className={classes.dateInput}
                            margin="normal"
                            label="Received"
                            value={dateReceived}
                            onChange={(d) => this.handleDateChange('dateReceived', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                            disabled={!editMode}
                        />
                    </MuiPickersUtilsProvider>
                    {!readOnlyMode &&
                    <div>
                        {!editMode ? (
                            <div className={classes.icon}>
                                <IconButton className={classes.margin} aria-label="edit" onClick={() => this.setState({editMode: true})} size="small" color="primary">
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton className={classes.margin} aria-label="delete" onClick={() => this.setState({showConfirmDelete: true})} size="small">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        ) : (
                            <div className={classes.icon}>
                                <IconButton className={classes.margin} aria-label="save" disabled={!this.isValid()} onClick={() => this.save()} size="small">
                                    <SaveIcon fontSize="small" />
                                </IconButton>
                                <IconButton className={classes.margin} aria-label="cancel" onClick={() => this.setState({editMode: false})} size="small" color="secondary">
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                        )}
                    </div>
                    }
                </div>
                <Dialog closeCallback={() => this.setState({showConfirmDelete: false})} showDialog={showConfirmDelete} title="Delete Installment" bodyHtml="Are you sure you want to delete this installment?" buttons={buttons} />
            </div>
        );
    }
}

export default withStyles(styles)(UpdateInstallment);