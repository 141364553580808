import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReceiptIcon from '@material-ui/icons/Receipt';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import Paper from '@material-ui/core/Paper';
import ListingTable from "./ListingTable";
import ConstructionTable from "./ConstructionTable";
import ManageControls from "./ManageControls";
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { amber, green, red, blue} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import OtherTable from "./OtherTable";

const styles = theme => ({
    root: {

    },
    appBar: {
        backgroundColor: '#fefefe'
    },
    tab: {
        // padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        borderRadius: 0
    },

    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: red[600],
    },
    info: {
        backgroundColor: blue[600],
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

class Sheets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            snackMessage: '',
            snackType: 'test',
            showSnack: false
        };
    }

    handleChange = (event, value) => {
        this.setState({ tab: value });
    };

    // handleChangeIndex = index => {
    //     this.setState({ tab: index });
    // };

    handleSnackbarClose() {
        this.setState({snackMessage: '', snackType: '', showSnack: false});
    }

    showSnack(type,message){
        this.setState({snackMessage: message, snackType: type, showSnack: true});
    }

    render() {
        const { classes } = this.props;
        const {
            tab,
            snackMessage,
            snackType,
            showSnack,
        } = this.state;

        return (
            <div className={classes.root}>
                <ManageControls showSnack={(t,m) => this.showSnack(t,m)}/>
                <AppBar elevation={2} className={classes.appBar} position="static" color="default">
                    <Tabs
                        value={this.state.tab}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="Sales Details" icon={<ReceiptIcon/>}/>
                        <Tab label="Property Details" icon={<HomeIcon/>}/>
                        <Tab label="Other" icon={<ExtensionIcon/>}/>
                    </Tabs>
                </AppBar>
                <Paper className={classes.paper} elevation={2}>
                    {tab === 0 &&
                        <div className={classes.tab}>
                            <ListingTable type={'Listings'} title="Sales Details" fileName="tw_closings.csv" showSnack={(t,m) => this.showSnack(t,m)}/>
                        </div>
                    }
                    {tab === 1 &&
                        <div className={classes.tab}>
                            <ConstructionTable showSnack={(t,m) => this.showSnack(t,m)}/>
                        </div>
                    }
                    {tab === 2 &&
                    <div className={classes.tab}>
                        <OtherTable showSnack={(t,m) => this.showSnack(t,m)} />
                    </div>
                    }
                </Paper>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={showSnack}
                    autoHideDuration={4000}
                    onClose={() => this.handleSnackbarClose()}
                >
                    <SnackbarContent
                        className={classes[snackType]}
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar" className={classes.message}>
                                {snackType === 'error' &&
                                <ErrorIcon className={classes.iconVariant}/>
                                }
                                {snackType === 'warning' &&
                                <WarningIcon className={classes.iconVariant}/>
                                }
                                {snackType === 'info' &&
                                <InfoIcon className={classes.iconVariant}/>
                                }
                                {snackType === 'success' &&
                                <SuccessIcon className={classes.iconVariant}/>
                                }
                                {snackMessage}
                            </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={() => this.handleSnackbarClose()}>
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div>
        );
    }
}

export default withStyles(styles)(Sheets);