import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateEmail, validateField, validateLenientName} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Broker from "./Broker";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    inlineButton: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
});

class CoopAgent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseeName: '',
            georgiaRealEstateLicenseNumber: '',
            licenseePhone: '',
            licenseeEmail: '',
            alternateEmail: '',
            realtorMembership: '',
            validations: [],
            isLoading: false,
            coopAgentId: '',

            loadingBrokers: true,
            brokers: [],
            brokerId: '',
            broker: null,
            showAddBrokerOption: false,
            showAddBroker: false,

            showSelect: false,
            selectedCoopAgent: null,
            loadingCoopAgents: true,
            coopAgents: [],

            hasChanges: false
        };
    }

    componentDidMount() {
        const coopAgentId = this.props.coopAgentId || null;
        if(coopAgentId !== null){
            this.setState({isLoading:true},() => this.loadCoopAgent(coopAgentId))
        } else {
            const coopAgent = this.props.coopAgent || null;
            if(coopAgent !== null){
                this.setState({
                    licenseeName: coopAgent.licenseeName || '',
                    georgiaRealEstateLicenseNumber: coopAgent.georgiaRealEstateLicenseNumber || '',
                    licenseePhone: coopAgent.licenseePhone || '',
                    licenseeEmail: coopAgent.licenseeEmail || '',
                    alternateEmail: coopAgent.alternateEmail || '',
                    realtorMembership: coopAgent.realtorMembership || '',
                    brokerId: coopAgent.brokerId || '',
                    broker: coopAgent.broker || null,
                    coopAgentId: coopAgent.coopAgentId || '',
                    coopAgents: this.props.coopAgents || [],
                    showSelect: this.props.showSelect || false,
                    showAddBrokerOption: this.props.showAddBrokerOption || false,
                    showAddBroker: (coopAgent.broker||null) !== null
                }, () => this.getBrokers())
            } else {
                this.setState({
                    coopAgents: this.props.coopAgents || [],
                    showSelect: this.props.showSelect || false,
                    showAddBrokerOption: this.props.showAddBrokerOption || false,
                }, () => this.getBrokers())
            }
        }
    }

    getCoopAgents() {
        if(
            (this.state.brokerId||null) === null ||
            // this.state.coopAgents.length > 0 ||
            !this.state.showSelect
        ) {
            this.setState({loadingCoopAgents: false})
        } else {
            request(
                'GET',
                '/Manage/getCoopAgentsByBroker?brokerId=' + this.state.brokerId,
                null,
                (data) => {
                    this.setState({coopAgents: data.coopAgents, loadingCoopAgents: false})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getBrokers() {
        if(this.state.brokers.length > 1) {
            this.setState({loadingBrokers: false}, () => this.getCoopAgents())
        } else {
            request(
                'GET',
                '/Manage/getBrokers',
                null,
                (data) => {
                    this.setState({brokers: data.brokers, loadingBrokers: false}, () => this.getCoopAgents())
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getBroker(brokerId) {
        request(
            'GET',
            '/Manage/getBroker?brokerId=' + brokerId,
            null,
            (data) => {
                const brokers = [];
                brokers.push(data.broker);
                this.setState({brokers, loadingBrokers: false});
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadCoopAgent(coopAgentId) {
        request(
            'GET',
            '/Manage/getCoopAgent?coopAgentId=' + coopAgentId,
            null,
            (data) => {
                this.loadData(data.coopAgent);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            licenseeName: data.licenseeName || '',
            georgiaRealEstateLicenseNumber: data.georgiaRealEstateLicenseNumber || '',
            licenseePhone: data.licenseePhone || '',
            licenseeEmail: data.licenseeEmail || '',
            alternateEmail: data.alternateEmail || '',
            realtorMembership: data.realtorMembership || '',
            brokerId: data.brokerId || '',
            coopAgentId: data.coopAgentId || '',
            hasChanges: true
        }, () => {
            if((data.brokerId||null) !== null){
                this.getBroker(data.brokerId);
            } else {
                this.getBrokers();
            }
        })
    }

    handleError(error){
        this.setState({isLoading: false, loadingCoopAgents: false, loadingBrokers: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'licenseeName',
            'georgiaRealEstateLicenseNumber',
            'licenseePhone',
            'licenseeEmail',
            'alternateEmail',
            'realtorMembership',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    };

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'licenseeName':
                isValid = validateLenientName(value);
                invalidationReason = 'Please enter the Licensee\'s Name';
                break;
            case 'georgiaRealEstateLicenseNumber':
                isValid = validateField(value);
                invalidationReason = 'Please enter the GA Real Estate License #';
                break;
            case 'licenseePhone':
                isValid = validateField(value,10,10);
                invalidationReason = 'Please enter the Licensee\'s phone';
                break;
            case 'licenseeEmail':
                isValid = validateEmail(value);
                invalidationReason = 'Please enter the Licensee\'s email';
                break;
            case 'alternateEmail':
                // isValid = validateEmail(value);
                // invalidationReason = 'Please enter an alternate email';
                break;
            case 'realtorMembership':
                // isValid = validateField(value);
                // invalidationReason = 'Please enter the Realtor Membership';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    };

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;
        if (field === 'licenseePhone') {
            value = value.replace(/\D+/g, '');
            if(value.length > 10){
                value = value.substring(0,10);
            }
        }
        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations, hasChanges: true});
    };

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value, hasChanges: true});
    }

    handleRadioChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        if(field === 'showAddBroker'){
            if(value === 'true'){
                this.setState({showAddBroker: true});
            } else {
                this.setState({showAddBroker: false, broker: null});
            }
        } else {
            this.setState({[field]: value});
        }
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        if(this.state.showAddBroker){
            const broker = this.state.broker||null;
            if(broker === null){
                return false;
            }
        } else if(this.state.brokerId === ''){
            return false;
        }
        const readOnlyMode = this.props.readOnlyMode || false;
        if(readOnlyMode){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE';
        let coopAgentId = this.state.coopAgentId;

        const readOnlyMode = this.props.readOnlyMode || false;

        const {
            licenseeName,
            georgiaRealEstateLicenseNumber,
            licenseePhone,
            licenseeEmail,
            alternateEmail,
            realtorMembership,
            isLoading,
            brokerId,
            brokers,
            broker,
            loadingBrokers,
            coopAgents,
            showSelect,
            selectedCoopAgent,
            hasChanges,
            showAddBrokerOption,
            showAddBroker
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div>
                    <div>
                        {showAddBrokerOption &&
                        <FormControl component="fieldset" className={classes.formControl} disabled={readOnlyMode}>
                            {/*<FormLabel component="legend"></FormLabel>*/}
                            <RadioGroup row aria-label="showAddBroker" name="showAddBroker" value={showAddBroker ? 'true' : 'false'} onChange={(e) => this.handleRadioChange(e)}>
                                <FormControlLabel value={'false'} control={<Radio/>} label="Select Existing Broker"/>
                                <FormControlLabel value={'true'} control={<Radio/>} label="Create New Broker"/>
                            </RadioGroup>
                        </FormControl>
                        }
                        {showAddBroker ? (
                            <div>
                                <Broker readOnlyMode={readOnlyMode} saveBtnText={(broker||null) === null ? 'SAVE BROKER' : 'UPDATE BROKER'} broker={broker} save={(b) => this.setState({broker: b, hasChanges: true})}/>
                            </div>
                        ) : (
                            <div>
                                {loadingBrokers ? (
                                    <Loading contained={true}/>
                                ) : (
                                    <div className={classes.flexWrapper}>
                                        {brokers.length === 1 ? (
                                            <div>
                                                <FormControl className={classes.dropdown} required>
                                                    <InputLabel htmlFor="broker-select">Broker</InputLabel>
                                                    <Select
                                                        id="broker-select"
                                                        name="brokerId"
                                                        value={brokerId}
                                                        onChange={(e) => this.handleSelectChange(e)}
                                                        disabled={readOnlyMode}
                                                    >
                                                        {brokers.map((item, index) => (
                                                            <MenuItem key={item.brokerId} value={item.brokerId}>{item.firm}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <IconButton className={classes.margin} aria-label="edit" onClick={() => this.setState({loadingBrokers: true}, () => this.getBrokers())} size="small" color="primary">
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        ) : (
                                            <div className={classes.dropdown}>
                                                <Autocomplete
                                                    id={'broker-list'}
                                                    options={brokers}
                                                    getOptionLabel={option => `${option.firm}`}
                                                    renderInput={params => <TextField autoComplete="new"  {...params} label="Broker *"/>}
                                                    onChange={(e, v) => this.setState({brokerId: v.brokerId}, () => this.getCoopAgents())}
                                                    disabled={readOnlyMode}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {(showSelect && coopAgents.length > 0) &&
                    <div className={classes.flexWrapper}>
                        <div className={classes.dropdown}>
                            <Autocomplete
                                id={'coopAgent-list'}
                                options={coopAgents}
                                getOptionLabel={option => `${option.licenseeName}`}
                                // style={{ width: '100%' }}
                                renderInput={params => <TextField autoComplete="new"  {...params} label="Select Co-op Agent"/>}
                                onChange={(e, v) => this.setState({selectedCoopAgent: v})}
                                // inputValue={coopAgent}
                                disabled={readOnlyMode}
                            />
                        </div>
                        <div>
                            <Button variant="contained" color="primary" disabled={selectedCoopAgent === null} onClick={() => this.loadData(selectedCoopAgent)} className={classes.inlineButton}>Load</Button>
                        </div>
                    </div>
                    }
                    <div className={classes.flexWrapper}>
                        <TextField autoComplete="new"
                            value={licenseeName}
                            name="licenseeName"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Licensee's Name"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('licenseeName')}
                            helperText={this.fieldErrorMessage('licenseeName')}
                            required
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            value={georgiaRealEstateLicenseNumber}
                            name="georgiaRealEstateLicenseNumber"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="GA Real Estate License #"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('georgiaRealEstateLicenseNumber')}
                            helperText={this.fieldErrorMessage('georgiaRealEstateLicenseNumber')}
                            required
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            value={licenseePhone}
                            name="licenseePhone"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Phone # (10 Digits)"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('licenseePhone')}
                            helperText={this.fieldErrorMessage('licenseePhone')}
                            required
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            value={licenseeEmail}
                            name="licenseeEmail"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Email"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('licenseeEmail')}
                            helperText={this.fieldErrorMessage('licenseeEmail')}
                            required
                            disabled={readOnlyMode}
                        />
                        <TextField autoComplete="new"
                            value={alternateEmail}
                            name="alternateEmail"
                            onChange={(e) => this.handleFieldChange(e)}
                            label="Alternate Email"
                            className={classes.textField}
                            margin="normal"
                            error={this.fieldHasError('alternateEmail')}
                            helperText={this.fieldErrorMessage('alternateEmail')}
                            disabled={readOnlyMode}
                        />
                    </div>
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid() || !hasChanges} onClick={() => this.setState({hasChanges: false},() => this.props.save({licenseeName,georgiaRealEstateLicenseNumber,licenseePhone,licenseeEmail,alternateEmail,realtorMembership,coopAgentId,brokerId,broker}))} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(CoopAgent);