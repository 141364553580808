import React, { Component } from 'react';
import Loading from './Loading'
// import {baseUrl} from '../utils'
// import history from '../history';
// import { Redirect } from "react-router-dom";

class Callback extends Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount(){
        const auth = this.props.auth;
        auth.handleAuthentication(
            () => {
                // const parsed = queryString.parse(this.props.location.search);
                // const membershipType = parsed.membershipType;
                // let path = '/account/' + membershipType;
                // window.location = path;
                window.location = '/Admin';
            }
        );
    }

    render() {
        return (
            <Loading/>
        );
    }
}

export default Callback;