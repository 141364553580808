import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CardActions from '@material-ui/core/CardActions';

const styles = theme => ({
    card: {
        overflow: 'unset !important'
    },
    cardContent: {
        padding: theme.spacing(2) + 'px !important'
    },
    cardLeft: {
        minWidth: 0,
        position: 'relative',
        flex: 1
    },
    cardRight: {
        textAlign: 'left'
    },
    cardFooterIcon: {
        fontSize: 12
    },
    flex: {
        display: 'flex'
    },
    grow: {
        flexGrow: 1,
    },
    label: {
        fontWeight: 500,
        // fontSize: 12,
        textTransform: 'uppercase',
        display: 'block',
        // maxWidth: 190,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '30px'
    },
    summary: {
        fontWeight: 300,
        fontSize: 12,
        display: 'block',
        textTransform: 'uppercase',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    cardFooter: {
        color: '#999',
        fontSize: 12,
        borderTop: 'solid 1px #ebebeb'
    },
    cardFooterLeft: {
        minWidth: 0,
        flex: 1
        // textAlign: 'right'
    },
    cardFooterRight: {

    },
    iconButton: {
        padding: 4,
        marginLeft: theme.spacing(.5)
    },
    icon: {
        height: 16,
        width: 16,
        fontSize: 16
    }
});

class TemplateSummary extends Component {

    render() {
        const { classes, name } = this.props;

        return (
            <div style={{position: 'relative'}}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.flex}>
                            <div className={classes.cardLeft}>
                                <span className={classes.label} title={name}>{name}</span>
                            </div>
                            {/*<div className={classes.cardRight}>*/}

                            {/*</div>*/}
                        </div>
                    </CardContent>
                    <CardActions className={classes.cardFooter}>
                        <div className={classes.cardFooterLeft}></div>
                        <div className={classes.cardFooterRight}>
                            <IconButton className={classes.iconButton} size="small" aria-label="Assign Template" title="Assign Template" onClick={() => this.props.assign()}>
                                <GroupAddIcon className={classes.icon} />
                            </IconButton>
                            <IconButton className={classes.iconButton} size="small" color="primary" aria-label="Edit Template" title="Edit Template" onClick={() => this.props.edit()}>
                                <EditIcon className={classes.icon}/>
                            </IconButton>
                        </div>
                    </CardActions>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(TemplateSummary);