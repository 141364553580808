import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField, validatePrice} from '../../utils'
import _ from 'lodash';
import Loading from "../Loading";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Lender from "./Lender";
import LoanOfficer from "./LoanOfficer";
import CoopAgent from "./CoopAgent";
import Divider from "@material-ui/core/Divider";
import Installment from "./AddInstallment";
import Chip from "@material-ui/core/Chip";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    header: {
        textAlign: 'left',
        fontWeight: '500'
    },
    installmentWrapper: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > * > *': {
            margin: theme.spacing(0.5),
        },
    },
});

class AddContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            price: '',
            payment: '', //Preferred Lender, Outside Lender, Cash
            loanType: '',
            proofOfFunds: false, //If Cash - Proof of funds? Yes/No
            dateGenerated: null,
            bindingDate: null,

            lenderId: '',
            lenders: [],
            lender: null,
            loadingLenders: false,
            lenderMode: 'existing',
            lenderSaved: false,

            loanOfficerId: '',
            loanOfficer: null,
            loanOfficers: [],
            loadingLoanOfficers: false,
            loanOfficerMode: 'existing',
            loanOfficerSaved: false,

            hasCoopAgent: false,
            coopAgent: null,
            isLoading: true,

            earnestMoneyRemaxTru: '0',
            earnestMoneyOther: '', //who?
            earnestMoneyOtherAmount: '0', //how much?
            optionMoney: '0',
            optionMoneyNotes: '',
            installments: [],
            optionInstallments: [],

            potentialTerm: false,
            potentialTermReason: '',

            validations: []
        };
    }

    componentDidMount() {
        const contract = this.props.contract || {};
        const money = contract.money || {};
        const isLease = this.props.isLease || false;

        if(isLease){
            this.setState({
                isLease: isLease,
                dateGenerated: contract.dateGenerated || null,
                bindingDate: contract.bindingDate || null,
                coopAgent: contract.coopAgent || null,
                hasCoopAgent: contract.hasCoopAgent || false,
                potentialTerm: contract.potentialTerm || false,
                potentialTermReason: contract.potentialTermReason || '',
                isLoading: false
            })
        } else if((contract.lender||null) === null && (contract.lenderId||null) === null){
            this.setState({
                isLease: isLease,
                price: contract.price || '',
                payment: contract.payment || '',
                loanType: contract.loanType || '',
                proofOfFunds: contract.proofOfFunds || false,
                dateGenerated: contract.dateGenerated || null,
                bindingDate: contract.bindingDate || null,
                lenderMode: 'na',
                loanOfficerMode: 'na',
                coopAgent: contract.coopAgent || null,
                hasCoopAgent: contract.hasCoopAgent || false,
                potentialTerm: contract.potentialTerm || false,
                potentialTermReason: contract.potentialTermReason || '',

                moneyId: money.moneyId || '',
                earnestMoneyRemaxTru: money.earnestMoneyRemaxTru || '',
                earnestMoneyOther: money.earnestMoneyOther || '',
                earnestMoneyOtherAmount: money.earnestMoneyOtherAmount || '',
                optionMoney: money.optionMoney || '',
                optionMoneyNotes: money.optionMoneyNotes || '',
                installments: money.installments || [],
                optionInstallments: money.optionInstallments || [],

                isLoading: false
            })
        } else if((contract.lenderId||null) !== null){
            this.setState({
                isLease: isLease,
                price: contract.price || '',
                payment: contract.payment || '',
                loanType: contract.loanType || '',
                proofOfFunds: contract.proofOfFunds || false,
                dateGenerated: contract.dateGenerated || null,
                bindingDate: contract.bindingDate || null,
                lenderId: contract.lenderId || '',
                loanOfficerId: contract.loanOfficerId || '',
                coopAgent: contract.coopAgent || null,
                hasCoopAgent: contract.hasCoopAgent || false,
                potentialTerm: contract.potentialTerm || false,
                potentialTermReason: contract.potentialTermReason || '',

                moneyId: money.moneyId || '',
                earnestMoneyRemaxTru: money.earnestMoneyRemaxTru || '',
                earnestMoneyOther: money.earnestMoneyOther || '',
                earnestMoneyOtherAmount: money.earnestMoneyOtherAmount || '',
                optionMoney: money.optionMoney || '',
                optionMoneyNotes: money.optionMoneyNotes || '',
                installments: money.installments || [],
                optionInstallments: money.optionInstallments || [],

                isLoading: false
            }, () => this.getLenders(true))
        } else {
            this.setState({
                isLease: isLease,
                price: contract.price || '',
                payment: contract.payment || '',
                loanType: contract.loanType || '',
                proofOfFunds: contract.proofOfFunds || false,
                dateGenerated: contract.dateGenerated || null,
                bindingDate: contract.bindingDate || null,
                lender: contract.lender || null,
                loanOfficer: contract.loanOfficer || null,
                lenderMode: 'new',
                loanOfficerMode: 'new',
                coopAgent: contract.coopAgent || null,
                hasCoopAgent: contract.hasCoopAgent || false,
                potentialTerm: contract.potentialTerm || false,
                potentialTermReason: contract.potentialTermReason || '',

                moneyId: money.moneyId || '',
                earnestMoneyRemaxTru: money.earnestMoneyRemaxTru || '',
                earnestMoneyOther: money.earnestMoneyOther || '',
                earnestMoneyOtherAmount: money.earnestMoneyOtherAmount || '',
                optionMoney: money.optionMoney || '',
                optionMoneyNotes: money.optionMoneyNotes || '',
                installments: money.installments || [],
                optionInstallments: money.optionInstallments || [],

                isLoading: false
            })
        }
    }

    getLenders(getLoanOfficers) {
        const payment = this.state.payment || '';
        const queryString = payment === 'Preferred Lender' ? '?preferred=true' : '?preferred=false';

        if(this.state.lenders.length > 0) {
            this.setState({loadingLenders: false})
        } else {
            request(
                'GET',
                '/Manage/getLenders' + queryString,
                null,
                (data) => {
                    this.setState({lenders: data.lenders, loadingLenders: false}, () => {if((getLoanOfficers||false) && (this.state.lenderId||null) !== null) this.getLoanOfficers(this.state.lenderId)})
                },
                (error) => {
                    this.handleError(error);
                })
        }
    }

    getLoanOfficers(lenderId) {
        request(
            'GET',
            '/Manage/getLoanOfficers?lenderId=' + lenderId,
            null,
            (data) => {
                this.setState({loanOfficers: data.loanOfficers, loadingLoanOfficers: false})
            },
            (error) => {
                this.handleError(error);
            })
    }

    handleError(error){
        this.setState({loadingLenders: false, loadingLoanOfficers: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'price',
            'payment',
            'loanType',
            'dateGenerated',
            'bindingDate',
            'earnestMoneyRemaxTru',
            'earnestMoneyOther', //who?
            'earnestMoneyOtherAmount', //how much?
            'optionMoney',
            'optionMoneyNotes',
            'potentialTerm',
            'potentialTermReason'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';
        const potentialTerm = this.state.potentialTerm || false;
        const underContract = this.props.underContract || false;

        switch(fieldName){
            case 'price':
                if(underContract || value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please enter a valid contract price.';
                }
                break;
           case 'earnestMoneyRemaxTru':
                if(underContract || value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'earnestMoneyOther':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            case 'earnestMoneyOtherAmount':
                if(underContract || value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'optionMoneyNotes':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            case 'optionMoney':
                if(underContract || value.length !== 0){
                    isValid = validatePrice(value);
                    invalidationReason = 'Please provide a valid amount.';
                }
                break;
            case 'potentialTerm':
                break;
            case 'potentialTermReason':
                if(potentialTerm){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter a reason.';
                }
                break;
            case 'payment':
                if(underContract || value.length !== 0){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select an option.';
                }
                break;
            case 'loanType':
                if(underContract || value.length !== 0){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select an option.';
                }
                break;
            case 'dateGenerated':
                if(underContract){
                    isValid = (value||null) !== null;
                    invalidationReason = 'Please provide a valid date.';
                }
                break;
            case 'bindingDate':
                if(underContract){
                    isValid = (value||null) !== null;
                    invalidationReason = 'Please provide a valid date.';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        switch(field){
            case 'earnestMoneyRemaxTru':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'earnestMoneyOther':
                break;
            case 'earnestMoneyOtherAmount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'optionMoney':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'optionMoneyNotes':
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;
        const lenders = this.state.lenders || [];

        if(field === 'payment' && (value === 'Preferred Lender' || value === 'Outside Lender') && lenders.length === 0)
        {
            this.setState({[field]: value,loadingLenders: true}, () => this.getLenders());
        } else if(field === 'lenderId' && value.length !== 0) {
            this.setState({[field]: value, loadingLoanOfficers: true}, () => this.getLoanOfficers(value));
        } else {
            this.setState({[field]: value});
        }
    }

    handleRadioChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        if(field === 'loanOfficerMode' && value !== 'new'){
            if(value === 'existing' && _.isEmpty(this.state.loanOfficers) && (this.state.lenderId||null) !== null){
                this.setState({[field]: value, loanOfficerSaved: false, loanOfficer: null}, () => this.getLoanOfficers(this.state.lenderId));
            } else {
                this.setState({[field]: value, loanOfficerSaved: false, loanOfficer: null});
            }
        } else if(field === 'lenderMode') {
            if (value === 'na') {
                this.setState({
                    [field]: value,
                    loanOfficerMode: 'na',
                    lenderSaved: false,
                    lender: null,
                    loanOfficerSaved: false,
                    loanOfficer: null,
                    loanOfficerId: ''
                });
            } else if (value === 'existing') {
                if(_.isEmpty(this.state.lenders)){
                    this.setState({[field]: value, lenderSaved: false, lender: null}, () => this.getLenders(this.state.loanOfficerMode === 'existing'));
                } else {
                    this.setState({[field]: value, lenderSaved: false, lender: null});
                }
            } else {
                if(this.state.loanOfficerMode === 'existing'){
                    this.setState({
                        [field]: value,
                        loanOfficerMode: 'na',
                        lenderSaved: false,
                        lender: null,
                        loanOfficerSaved: false,
                        loanOfficer: null,
                        loanOfficerId: ''
                    });
                } else {
                    this.setState({[field]: value});
                }
            }
        } else {
            this.setState({[field]: value});
        }
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];
        this.setState({[field]: !value});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    addInstallment(i){
        const installments = this.state.installments;
        installments.push(i);
        this.setState({installments});
    }

    removeInstallment(i){
        const installments = this.state.installments;
        installments.splice(i,1);
        this.setState({installments});
    }

    addOptionInstallment(i){
        const optionInstallments = this.state.optionInstallments;
        optionInstallments.push(i);
        this.setState({optionInstallments});
    }

    removeOptionInstallment(i){
        const optionInstallments = this.state.optionInstallments;
        optionInstallments.splice(i,1);
        this.setState({optionInstallments});
    }

    isValid() {
        const {lenderMode, loanOfficerMode, lenderSaved, loanOfficerSaved, hasCoopAgent, coopAgent} = this.state;

        if(hasCoopAgent && coopAgent === null){
            return false;
        }
        if(lenderMode === 'new' && !lenderSaved){
            return false;
        }
        if(loanOfficerMode === 'new' && !loanOfficerSaved){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'NEXT';
        const builder = this.props.builder || '';
        const underContract = this.props.underContract || false;

        const { isLease, loadingLenders, lenders, lenderSaved, lenderMode, loadingLoanOfficers, loanOfficerSaved, loanOfficerMode } = this.state;
        const {
            price,
            payment,
            loanType,
            proofOfFunds,
            bindingDate,
            dateGenerated,
            lenderId,
            lender,
            loanOfficerId,
            loanOfficer,
            coopAgent,
            isLoading,
            hasCoopAgent,
            earnestMoneyRemaxTru,
            earnestMoneyOther,
            earnestMoneyOtherAmount,
            optionMoney,
            optionMoneyNotes,
            installments,
            optionInstallments,
            potentialTerm,
            potentialTermReason
        } = this.state;
        const loanOfficers = this.state.loanOfficers || [];

        const lenderSection = (
            <div>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Lender</FormLabel>
                    <RadioGroup row aria-label="lenderMode" name="lenderMode" value={lenderMode} onChange={(e) => this.handleRadioChange(e)}>
                        <FormControlLabel value="existing" control={<Radio/>} label="Select Lender"/>
                        <FormControlLabel value="new" control={<Radio/>} label="New Lender"/>
                        <FormControlLabel value="na" control={<Radio/>} label="N/A"/>
                    </RadioGroup>
                </FormControl>
                {lenderMode === 'existing' &&
                    <div>
                        {loadingLenders ? (
                            <Loading contained={true}/>
                        ) : (
                            <div>
                                {lenders.length > 0 ? (
                                    <FormControl className={classes.dropdown}>
                                        <InputLabel htmlFor="lender-select">Lender</InputLabel>
                                        <Select
                                            id="lender-select"
                                            name="lenderId"
                                            value={lenderId}
                                            onChange={(e) => this.handleSelectChange(e)}
                                        >
                                            {lenders.map((item, index) => (
                                                <MenuItem key={'lender'+item.lenderId} value={item.lenderId}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <div>No Lenders Found..</div>
                                )}
                            </div>
                        )}
                    </div>
                }
                {lenderMode === 'new' &&
                    <Lender readOnlyMode={lenderSaved} saveBtnText="SAVE LENDER" lender={lender} save={(l) => this.setState({lender: l, lenderSaved: true})} showSnack={(t,e) => this.props.showSnack(t,e)} />
                }
            </div>
        );

        const loanOfficerSection = (
            <div>
                <FormControl component="fieldset" className={classes.formControl} disabled={lenderMode === 'na'}>
                    <FormLabel component="legend">Loan Officer</FormLabel>
                    <RadioGroup row aria-label="loanOfficerMode" name="loanOfficerMode" value={loanOfficerMode} onChange={(e) => this.handleRadioChange(e)}>
                        {(lenderMode === 'existing' && (lenderId||null) !== null) &&
                        <FormControlLabel value="existing" control={<Radio/>} label="Select Loan Officer"/>
                        }
                        <FormControlLabel value="new" control={<Radio/>} label="New Loan Officer"/>
                        <FormControlLabel value="na" control={<Radio/>} label="N/A"/>
                    </RadioGroup>
                </FormControl>
                {loanOfficerMode === 'existing' &&
                <div>
                    {loadingLoanOfficers ? (
                        <Loading contained={true}/>
                    ) : (
                        <div>
                            {loanOfficers.length > 0 ? (
                                <FormControl className={classes.dropdown}>
                                    <InputLabel htmlFor="loanOfficer-select">Loan Officer</InputLabel>
                                    <Select
                                        id="loanOfficer-select"
                                        name="loanOfficerId"
                                        value={loanOfficerId}
                                        onChange={(e) => this.handleSelectChange(e)}
                                    >
                                        {loanOfficers.map((item, index) => (
                                            <MenuItem key={'loan-officer-' + item.loanOfficerId}
                                                      value={item.loanOfficerId}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <div>No Loan Officers found..</div>
                            )}
                        </div>
                    )}
                </div>
                }
                {loanOfficerMode === 'new' &&
                    <LoanOfficer readOnlyMode={loanOfficerSaved} saveBtnText="SAVE LOAN OFFICER" loanOfficer={loanOfficer} save={(l) => this.setState({loanOfficer: l, loanOfficerSaved: true})} showSnack={(t,e) => this.props.showSnack(t,e)} />
                }
            </div>
        );

        return (
            <div>
                <br/>
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        className={classes.dateInput}
                        margin="normal"
                        label="Date Generated"
                        value={dateGenerated}
                        onChange={(d) => this.handleDateChange('dateGenerated', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                        required={underContract}
                    />
                </MuiPickersUtilsProvider>
                <br/>
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        className={classes.dateInput}
                        margin="normal"
                        label="Binding Date"
                        value={bindingDate}
                        onChange={(d) => this.handleDateChange('bindingDate', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                        required={underContract}
                    />
                </MuiPickersUtilsProvider>
                <br/>
                {!isLease &&
                <div>
                    <TextField autoComplete="new"
                        name="price"
                        value={price}
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Contract Price"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('price')}
                        helperText={this.fieldErrorMessage('price')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required={underContract}
                    />
                    <br/>
                    <FormControl className={classes.dropdown} required={underContract}>
                        <InputLabel htmlFor="payment-select">Finance</InputLabel>
                        <Select
                            id="payment-select"
                            name="payment"
                            value={payment}
                            onChange={(e) => this.handleSelectChange(e)}
                        >
                            <MenuItem value="Preferred Lender">Preferred Lender</MenuItem>
                            <MenuItem value="Outside Lender">Outside Lender</MenuItem>
                            <MenuItem value="Cash">Cash</MenuItem>
                        </Select>
                    </FormControl>
                    <br/>
                    {payment === 'Cash' &&
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="proofOfFunds"
                                checked={proofOfFunds}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                            />
                        }
                        label="Proof of Funds?"
                    />
                    }
                    <br/>
                    {(payment !== 'Cash' && (payment||null) !== null ) &&
                    <div>
                        <FormControl className={classes.dropdown} required={underContract}>
                            <InputLabel htmlFor="loanType-select">Loan Type</InputLabel>
                            <Select
                                id="loanType-select"
                                name="loanType"
                                value={loanType}
                                onChange={(e) => this.handleSelectChange(e)}
                            >
                                <MenuItem value="Conventional">Conventional</MenuItem>
                                <MenuItem value="FHA">FHA</MenuItem>
                                <MenuItem value="VA">VA</MenuItem>
                                <MenuItem value="USDA">USDA</MenuItem>
                            </Select>
                        </FormControl>
                        <br/>
                        {lenderSection}
                        <br/>
                        {loanOfficerSection}
                    </div>
                    }
                </div>
                }
                <div>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="hasCoopAgent"
                                checked={hasCoopAgent}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                            />
                        }
                        label="Is there a Co-op Agent?"
                    />
                    {(!isLoading && hasCoopAgent) &&
                    <div>
                        <strong>Co-op Agent</strong>
                        <CoopAgent showSelect={true} showAddBrokerOption={true} saveBtnText="SAVE CO-OP AGENT" coopAgent={coopAgent} save={(a) => this.setState({coopAgent: a})} showSnack={(t,e) => this.props.showSnack(t,e)} />
                    </div>
                    }
                    <Divider className={classes.divider} />
                </div>
                <br/>
                {builder === 'Stephen Elliott' &&
                <div>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="potentialTerm"
                                checked={potentialTerm}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                            />
                        }
                        label="Potential Term?"
                    />
                    {potentialTerm &&
                    <div>
                        <br/>
                        <TextField autoComplete="new"
                           value={potentialTermReason}
                           name="potentialTermReason"
                           onChange={(e) => this.handleFieldChange(e)}
                           label="Reason for Potential Term"
                           className={classes.textField}
                           margin="normal"
                           error={this.fieldHasError('potentialTermReason')}
                           helperText={this.fieldErrorMessage('potentialTermReason')}
                           multiline
                           rows={3}
                           fullWidth
                        />
                    </div>
                    }
                    <Divider className={classes.divider} />
                    <br/>
                </div>
                }
                {!isLease &&
                <div>
                    <div>
                        <div className={classes.header}>Earnest Money</div>
                        <br/>
                        <TextField autoComplete="new"
                                   name="earnestMoneyRemaxTru"
                                   value={earnestMoneyRemaxTru}
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="RemaxTru"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('earnestMoneyRemaxTru')}
                                   helperText={this.fieldErrorMessage('earnestMoneyRemaxTru')}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                   }}
                                   required={underContract}
                        />
                        <br/>
                        <TextField autoComplete="new"
                                   name="earnestMoneyOtherAmount"
                                   value={earnestMoneyOtherAmount}
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="Other"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('earnestMoneyOtherAmount')}
                                   helperText={this.fieldErrorMessage('earnestMoneyOtherAmount')}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                   }}
                                   required={underContract}
                        />
                        <TextField autoComplete="new"
                                   value={earnestMoneyOther}
                                   name="earnestMoneyOther"
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="Other Party"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('earnestMoneyOther')}
                                   helperText={this.fieldErrorMessage('earnestMoneyOther')}
                        />
                        <br/>
                        <TextField autoComplete="new"
                                   name="optionMoney"
                                   value={optionMoney}
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="Option Money"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('optionMoney')}
                                   helperText={this.fieldErrorMessage('optionMoney')}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                   }}
                                   required={underContract}
                        />
                        <TextField autoComplete="new"
                                   value={optionMoneyNotes}
                                   name="optionMoneyNotes"
                                   onChange={(e) => this.handleFieldChange(e)}
                                   label="Option Money Notes"
                                   className={classes.textField}
                                   margin="normal"
                                   error={this.fieldHasError('optionMoneyNotes')}
                                   helperText={this.fieldErrorMessage('optionMoneyNotes')}
                        />
                        <br/>
                        <Divider className={classes.divider} />
                        <strong>Earnest Money Deposit Schedule</strong>
                        <br/>
                        <Installment type="Earnest" installments={installments.length} save={(i) => this.addInstallment(i)}/>
                        <br/>
                        <div className={classes.installmentWrapper}>
                            {installments.length !== 0 &&
                            <div>
                                {installments.map((item, index) => (
                                    <Chip key={'earnest-installment-chip-' + index}
                                          label={`$${item.amount} - ${item.dateDue}`}
                                          onDelete={() => this.removeInstallment(index)} color="primary"
                                          variant="outlined"/>
                                ))}
                            </div>
                            }
                            <div className={classes.countOfTotal}>{`${installments.length} of 5 added`}</div>
                        </div>
                        <Divider className={classes.divider} />
                        <strong>Option Money Deposit Schedule</strong>
                        <br/>
                        <Installment type="Option" installments={optionInstallments.length} save={(i) => this.addOptionInstallment(i)}/>
                        <br/>
                        <div className={classes.installmentWrapper}>
                            {optionInstallments.length !== 0 &&
                            <div>
                                {optionInstallments.map((item, index) => (
                                    <Chip key={'option-installment-chip-' + index}
                                          label={`$${item.amount} - ${item.dateDue}`}
                                          onDelete={() => this.removeOptionInstallment(index)} color="primary"
                                          variant="outlined"/>
                                ))}
                            </div>
                            }
                            <div className={classes.countOfTotal}>{`${optionInstallments.length} of 5 added`}</div>
                        </div>
                    </div>
                </div>
                }
                <br/>
                <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.props.save({
                    price,
                    payment,
                    loanType,
                    proofOfFunds,
                    bindingDate,
                    dateGenerated,
                    lenderId: lenderMode !== 'existing' ? '' : lenderId,
                    lender,
                    loanOfficerId: loanOfficerMode !== 'existing' ? '' : loanOfficerId,
                    loanOfficer,
                    coopAgent,
                    hasCoopAgent,
                    potentialTerm,
                    potentialTermReason,
                    money: {
                        earnestMoneyRemaxTru,
                        earnestMoneyOther, //who?
                        earnestMoneyOtherAmount, //how much?
                        optionMoney,
                        optionMoneyNotes,
                        installments: installments.concat(optionInstallments)
                    },
                })} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(AddContract);