import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField, validatePrice, validateStage, validateNumber} from '../../utils'
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Loading from '../Loading';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import DateMomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker, KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Log from "../Log";
import UpdateContract from "./UpdateContract";
import UpdateCloseDate from "./UpdateCloseDate";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import Typography from "@material-ui/core/Typography";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import UnlockEditMode from "./UnlockEditMode";
import Dialog from "../Dialog";
import moment from "moment";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 220
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    dateTimeInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginLeft: 0,
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    readOnly: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // padding: theme.spacing(1),
        // border: 'solid 1px',
        // borderColor: '#e7e7e7'
    },
    roTitle: {
        fontWeight: 600
    },
    roValue: {
        fontWeight: 300
    },
    editButton: {
        position: 'fixed',
        bottom: theme.spacing(1),
        right: theme.spacing(2),
        zIndex: 9999
    },
    margin: {
        margin: theme.spacing(1),
    },
});

const dateTimeTheme = createMuiTheme();

dateTimeTheme.typography.h3 = {
    fontSize: '3rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.04,
    letterSpacing: '0em',
};

dateTimeTheme.typography.subtitle1 = {
    fontSize: '.85rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
};

class UpdateConstruction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readOnlyMode: true,
            isActive: true,
            showEdit: true,
            builder: '',
            property: '',
            community: '',

            loadingConstruction: true,
            savingConstruction: false,

            statusModified: false,
            status: '',// Lot, Spec, Model, UnderContract
            fmls: '',
            gamls: '',
            navica: '',
            foundation: '', //basement or slab
            garageDoors: 0,
            garageDirection: '',
            stage: '',
            planAndElevation: '',
            finish: '', //only SE
            dateSelectionsDue: null, // SE = Finish Schedule Due
            dateSelectionsReceived: null, // SE = Finish Schedule Received
            bindingDate: null,
            firstWalkThruDate: null,
            finalWalkThruDate: null,
            firstWalkThruDateHasTime: false,
            finalWalkThruDateHasTime: false,
            listPrice: '',

            contract: {},

            buyerLastName: '',
            contingency: '', //None, Sale and close, or close only
            contingencyDate: null,

            closeDate: {},

            finalClosingAmendmentCompleted: false,
            buildPermit: false, //bool
            certificateOfOccupancy: false, //bool
            warranty: '', //QBW or 2/10 or 1 year only

            termiteLetterRequested: '',
            constructionStartDate: null,
            qbwWarranty: '',

            constructionNotes: [],
            newConstructionNote: '',
            salesNotes: [],
            newSalesNote: '',

            validations: [],
            activeStep: 0,

            showUnlockDialog: false,
            unlocked: false
        };
    }

    componentDidMount() {
        const readOnlyMode = this.props.readOnlyMode || false;
        const constructionId = this.props.constructionId;
        if(readOnlyMode){
            this.setState({showEdit: false},() => this.loadConstruction(constructionId));
        } else {
            this.loadConstruction(constructionId);
        }
    }

    loadConstruction(constructionId) {
        request(
            'GET',
            '/Manage/getConstruction?constructionId=' + constructionId,
            null,
            (data) => {
                this.loadData(data.construction);
            },
            (error) => {
                this.setState({loadingLenders: false,loadingConstruction: false,savingConstruction: false}, () => this.props.error(error))
            })
    }

    loadData(data)
    {
        const status = this.state.status;
        const statusModified = this.state.statusModified;

        const firstWalkThruDate = data.firstWalkThruDate || null;
        const finalWalkThruDate = data.finalWalkThruDate || null;
        let firstWalkThruDateHasTime = false;
        let finalWalkThruDateHasTime = false;
        if(firstWalkThruDate !== null){
            const firstWtd = moment(firstWalkThruDate);
            if(firstWtd.isValid() && firstWtd.hour() !== 0){
                firstWalkThruDateHasTime = true;
            }
        }
        if(finalWalkThruDate !== null){
            const finalWtd = moment(finalWalkThruDate);
            if(finalWtd.isValid() && finalWtd.hour() !== 0){
                finalWalkThruDateHasTime = true;
            }
        }

        this.setState({
            loadingConstruction: false,
            builder: data.builder || '',
            property: data.property || '',
            community: data.community || '',
            status: statusModified ? status : data.status || '',
            fmls: data.fmls || '',
            gamls: data.gamls || '',
            navica: data.navica || '',
            foundation: data.foundation || '',
            garageDoors: data.garageDoors || 0,
            garageDirection: data.garageDirection || '',
            stage: data.stage || '',
            planAndElevation: data.planAndElevation || '',
            finish: data.finish || '',
            dateSelectionsDue: data.dateSelectionsDue || null,
            dateSelectionsReceived: data.dateSelectionsReceived || null,
            firstWalkThruDate,
            finalWalkThruDate,
            firstWalkThruDateHasTime,
            finalWalkThruDateHasTime,
            listPrice: data.listPrice || '',
            buyerLastName: data.buyerLastName || '',
            contingency: data.contingency || '',
            contingencyDate: data.contingencyDate || null,
            finalClosingAmendmentCompleted: data.finalClosingAmendmentCompleted || false,
            constructionNotes: data.constructionNotes || [],
            buildPermit: data.buildPermit || false,
            certificateOfOccupancy: data.certificateOfOccupancy || false,
            warranty: data.warranty || '',
            salesNotes: data.salesNotes || [],
            contract: data.contract || {},
            closeDate: data.closeDate || {},
            isActive: data.isActive || true,
            termiteLetterRequested: data.termiteLetterRequested || '',
            constructionStartDate: data.constructionStartDate || null,
            qbwWarranty: data.qbwWarranty || '',
        })
    }

    handleError(error){
        this.setState({loadingLenders: false,loadingConstruction: false,savingConstruction: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'status',
            'buyerLastName',
            'fmls',
            'gamls',
            'navica',
            'foundation',
            'garageDoors',
            'garageDirection',
            'stage',
            'planAndElevation',
            'finish',
            'dateSelectionsDue',
            'dateSelectionsReceived',
            'firstWalkThruDate',
            'finalWalkThruDate',
            'listPrice',
            'contingency',
            'contingencyDate',
            'finalClosingAmendmentCompleted',
            'newConstructionNote',
            'buildPermit',
            'certificateOfOccupancy',
            'warranty',
            'newSalesNote',
            'termiteLetterRequested',
            'constructionStartDate',
            'qbwWarranty'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateEverything() {
        const fieldsToValidate = [
            'status',
            'buyerLastName',
            'fmls',
            'gamls',
            'navica',
            'foundation',
            'garageDoors',
            'garageDirection',
            'stage',
            'planAndElevation',
            'finish',
            'dateSelectionsDue',
            'dateSelectionsReceived',
            'firstWalkThruDate',
            'finalWalkThruDate',
            'listPrice',
            'contingency',
            'contingencyDate',
            'finalClosingAmendmentCompleted',
            'newConstructionNote',
            'buildPermit',
            'certificateOfOccupancy',
            'warranty',
            'newSalesNote',
            'termiteLetterRequested',
            'constructionStartDate',
            'qbwWarranty',
        ];

        const contractFieldsToValidate = [
            'price',
            'payment',
            'loanType',
            'dateGenerated',
            'bindingDate',
            'potentialTerm',
            'potentialTermReason',
        ];

        const moneyFieldsToValidate = [
            'earnestMoneyRemaxTru',
            'earnestMoneyOther', //who?
            'earnestMoneyOtherAmount', //how much?
            'optionMoney',
            'optionMoneyNotes',
        ];

        const closeDateFieldsToValidate = [
            'closeDateType',
            'closeDateRange',
            'constructionCloseDate',
            'moveInDate',
            'closeDateTime',
            'amendedCloseDate',
            'contractCloseDate',
            'contractCloseDateYear',
            'contractCloseDateMonth',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        const contract = this.state.contract;
        for(let i = 0; i < contractFieldsToValidate.length; i++){
            const name = contractFieldsToValidate[i];
            let value = null;
            if(_.has(contract,name)){
                value = contract[name] || null;
            }

            const validation = this.validateField(name,value);
            validations.push(validation);
        }

        const money = this.state.contract.money;
        for(let i = 0; i < moneyFieldsToValidate.length; i++){
            const name = moneyFieldsToValidate[i];
            let value = null;
            if(_.has(money,name)){
                value = money[name] || null;
            }

            const validation = this.validateField(name,value);
            validations.push(validation);
        }

        const closeDate = this.state.closeDate;
        for(let i = 0; i < closeDateFieldsToValidate.length; i++){
            const name = closeDateFieldsToValidate[i];
            let value = null;
            if(_.has(closeDate,name)){
                value = closeDate[name] || null;
            }

            const validation = this.validateField(name,value);
            validations.push(validation);
        }
        //split this up for contract and close date fields

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';
        const payment = this.state.contract.payment || '';

        switch(fieldName){
            case 'status':
                isValid = (value || null) !== null;
                invalidationReason = 'Please select the construction status.';
                break;
            case 'buyerLastName':
                if(this.state.status === 'Under Contract'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the buyer\'s last name.';
                }
                break;
            case 'fmls':
                if(this.state.status === 'Under Contract'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the FMLS.';
                }
                break;
            case 'gamls':
                if(this.state.status === 'Under Contract'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the GAMLS.';
                }
                break;
            case 'navica':
                // isValid = validateField(value);
                // invalidationReason = 'Please enter the NAVICA.';
                break;
            case 'foundation':
                if(this.state.status !== 'Lot'){
                    isValid = validateField(value);
                    invalidationReason = 'Please select the construction foundation type.';
                }
                break;
            case 'garageDoors':
                isValid = validateNumber(value);
                invalidationReason = 'Please enter the number of garage doors.';
                break;
            case 'garageDirection':
                if(this.state.garageDoors > 0){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select the garage direction.';
                }
                break;
            case 'stage':
                if(this.state.status !== 'Lot'){
                    isValid = validateStage(value);
                    invalidationReason = 'Please enter the stage.';
                }
                break;
            case 'planAndElevation':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'finish':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'dateSelectionsDue':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'dateSelectionsReceived':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'firstWalkThruDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'finalWalkThruDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'listPrice':
                if(this.state.status === 'Under Contract'){
                    isValid = value === '0' || validatePrice(value);
                    invalidationReason = 'Please enter the listing price.';
                }
                break;
            case 'contingency':
                if(this.state.status === 'Under Contract'){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select the construction contingency.';
                }
                break;
            case 'contingencyDate':
                const contingency = this.state.contingency || 'None';
                if(contingency !== 'None' && contingency !== 'Lease'){
                    isValid = (value || null) !== null;
                    if(!isValid){
                        invalidationReason = 'Please enter a contingency date.';
                    }
                }
                break;
            // case 'finalClosingAmendmentCompleted':
            //     // isValid = validateField(value);
            //     // invalidationReason = '';
            //     break;
            case 'newConstructionNote':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            // case 'buildPermit':
            //     // isValid = validateField(value);
            //     // invalidationReason = '';
            //     break;
            // case 'certificateOfOccupancy':
            //     // isValid = validateField(value);
            //     // invalidationReason = '';
            //     break;
            case 'warranty':
                if(this.state.status === 'Under Contract'){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select the warranty.';
                }
                break;
            case 'newSalesNote':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;

            //contract
            case 'price':
                isValid = validatePrice(value);
                invalidationReason = 'Please enter a valid contract price.';
                break;
            case 'earnestMoneyRemaxTru':
                isValid = (value||null) !== null ? validatePrice(value) : false;
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'earnestMoneyOther':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            case 'earnestMoneyOtherAmount':
                isValid = (value||null) !== null ? validatePrice(value) : false;
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'optionMoneyNotes':
                // if(value.length !== 0){
                //     isValid = validateField(value);
                //     invalidationReason = 'Please enter ';
                // }
                break;
            case 'optionMoney':
                isValid = (value||null) !== null ? validatePrice(value) : false;
                invalidationReason = 'Please provide a valid amount.';
                break;
            case 'potentialTerm':
                break;
            case 'potentialTermReason':
                if((this.state.contract.potentialTerm || false)){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter a reason.';
                }
                break;
            case 'payment':
                isValid = (value || null) !== null;
                invalidationReason = 'Please select an option.';
                break;
            case 'loanType':
                if(payment !== 'Cash' && payment !== ''){
                    isValid = (value || null) !== null;
                    invalidationReason = 'Please select an option.';
                }
                break;
            case 'dateGenerated':
                isValid = (value||null) !== null;
                invalidationReason = 'Please provide a valid date.';
                break;
            case 'bindingDate':
                isValid = (value||null) !== null;
                invalidationReason = 'Please provide a valid date.';
                break;


            //closedate
            case 'closeDateType':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'closeDateRange':
                if(this.state.closeDate.closeDateType === 'TwoMonthWindow' || this.state.closeDate.closeDateType === 'OneMonthWindow'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter a Close Date Range.';
                }
                break;
            case 'contractCloseDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'contractCloseDateMonth':
                if(this.state.closeDate.contractCloseDateMode === 'range'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter a Contract Close Date Range.';
                }
                break;
            case 'contractCloseDateYear':
                if(this.state.closeDate.contractCloseDateMode === 'range'){
                    isValid = (value||null) !== null ? validateField(typeof value !== 'string' ? value.toString() : value) : false;
                    invalidationReason = 'Please enter a Contract Close Date Range.';
                }
                break;
            case 'constructionCloseDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'moveInDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'closeDateTime':
                if(this.state.closeDate.closeDateType === 'ExactDate' || this.state.closeDate.closeDateType === 'DateAndTime'){
                    isValid = (value||null) !== null;
                    invalidationReason = 'Please enter a Close Date.';
                }
                break;

            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }
        // else {
            // console.log(fieldName + " invalid: " + value)
        // }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        switch(field){
            case 'buyerLastName':
                break;
            case 'fmls':
                break;
            case 'gamls':
                break;
            case 'navica':
                break;
            case 'garageDoors':
                // value = value.replace(/\D+/g, '')
                if(value < 0){
                    value = 0;
                }
                break;
            case 'garageDirection':
                break;
            case 'stage':
                value = value.replace(/[^0-9.]/, '');
                break;
            case 'planAndElevation':
                break;
            case 'finish':
                break;
            case 'listPrice':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            case 'contingency':
                break;
            case 'newConstructionNote':
                break;
            case 'newSalesNote':
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        if(field === 'status' && value === 'Under Contract'){
            const constructionId = this.props.constructionId;
            this.props.showSnack('warning','Reloading Construction Details and validating required fields..');
            this.setState({loadingConstruction: true, statusModified: true},() => this.loadConstruction(constructionId));
        }

        this.setState({[field]: value});
    };

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        if(field === 'firstWalkThruDateHasTime' && value === true){
            const firstWalkThruDate = this.state.firstWalkThruDate;
            if(firstWalkThruDate !== null){
                const fwtd = moment(firstWalkThruDate);
                if(fwtd.isValid()){
                    const f = fwtd.format("YYYY-MM-DD");
                    this.setState({[field]: !value, firstWalkThruDate: f+'T00:00:00'});
                }
            }
        }
        else if(field === 'finalWalkThruDateHasTime' && value === true){
            const finalWalkThruDate = this.state.firstWalkThruDate;
            if(finalWalkThruDate !== null){
                const fwtd = moment(finalWalkThruDate);
                if(fwtd.isValid()){
                    const f = fwtd.format("YYYY-MM-DD");
                    this.setState({[field]: !value, finalWalkThruDate: f+'T00:00:00'});
                }
            }
        }
        else {
            this.setState({[field]: !value});
        }

        this.setState({[field]: !value});
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isConstructionValid(){
        if(this.state.statusModified){
            console.log("validate everything...")
            const validations = this.validateEverything();
            const i = _.findIndex(validations, {isValid: false});
            return i <= -1;
        }
        console.log("normal validation...")
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    updateConstruction() {
        const constructionId = this.props.constructionId;

        const {
            // builderId,
            // propertyId,
            // property,
            status,
            buyerLastName,
            fmls,
            gamls,
            navica,
            foundation,
            garageDoors,
            garageDirection,
            stage,
            planAndElevation,
            finish,
            dateSelectionsDue,
            dateSelectionsReceived,
            firstWalkThruDate,
            finalWalkThruDate,
            listPrice,
            contingency,
            contingencyDate,
            finalClosingAmendmentCompleted,
            newConstructionNote,
            buildPermit,
            certificateOfOccupancy,
            warranty,
            newSalesNote,
            // closeDate,
            // contract,
            termiteLetterRequested,
            constructionStartDate,
            qbwWarranty
        } = this.state;

        const data = {
            constructionId,
            status: status,
            buyerLastName: buyerLastName,
            fmls: fmls,
            gamls: gamls,
            navica: navica,
            foundation: foundation,
            garageDoors: garageDoors,
            garageDirection: garageDirection,
            stage: stage,
            planAndElevation: planAndElevation,
            finish: finish,
            dateSelectionsDue: dateSelectionsDue,
            dateSelectionsReceived: dateSelectionsReceived,
            firstWalkThruDate: firstWalkThruDate,
            finalWalkThruDate: finalWalkThruDate,
            listPrice: listPrice,
            contingency: contingency,
            contingencyDate: contingencyDate,
            finalClosingAmendmentCompleted: finalClosingAmendmentCompleted,
            constructionNotes: newConstructionNote,
            buildPermit: buildPermit,
            certificateOfOccupancy: certificateOfOccupancy,
            warranty: warranty,
            salesNotes: newSalesNote,
            termiteLetterRequested: termiteLetterRequested,
            constructionStartDate: constructionStartDate,
            qbwWarranty: qbwWarranty
        };

        const successMessage = 'Successfully updated Property Details! If the stage has changed, please update the Close Date.';

        request(
            'POST',
            '/Manage/updateConstruction',
            data,
            (data) => {
                this.setState(
                    {savingConstruction: false, readOnlyMode: true, statusModified: false},
                    () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    updateContract(contract){
        const successMessage = 'Successfully updated Property Details!';

        request(
            'POST',
            '/Manage/updateContract',
            contract,
            (data) => {
                this.setState(
                    {savingConstruction: false, contract: contract},
                    () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    updateMonies(m) {
        const successMessage = 'Successfully Updated Earnest Money!';
        const action = '/Manage/updateMoney';
        request(
            'POST',
            action,
            m,
            (data) => {
                const mid = data.moneyId||null;
                if(mid !== null){
                    m.MoneyId = mid;
                }
                const contract = this.state.contract;
                contract.money = m;
                this.setState({savingConstruction: false, contract: contract}, () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.setState({savingConstruction: false}, () => this.props.showSnack('error',error))
            })
    }

    update(action,data,successMessage){
        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({savingConstruction: false}, () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.setState({savingConstruction: false}, () => this.props.showSnack('error',error))
            })
    }

    updateCloseDate(closeDate){
        const successMessage = 'Successfully updated Property Details!';

        request(
            'POST',
            '/Manage/updateCloseDate',
            closeDate,
            (data) => {
                this.setState(
                    {savingConstruction: false, closeDate: closeDate},
                    () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    updateConstructionNotes(updates){
        const successMessage = 'Successfully updated Construction Notes!';
        const constructionId = this.props.constructionId;

        request(
            'POST',
            '/Manage/updateConstructionNotes',
            {
                constructionId: constructionId,
                constructionNotes: {updates}
            },
            (data) => {
                this.setState(
                    {savingConstruction: false, constructionNotes: {updates}},
                    () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    updateSalesNotes(updates){
        const successMessage = 'Successfully updated Sales Notes!';
        const constructionId = this.props.constructionId;

        request(
            'POST',
            '/Manage/updateConstructionSalesNotes',
            {
                constructionId: constructionId,
                salesNotes: {updates}
            },
            (data) => {
                this.setState(
                    {savingConstruction: false, salesNotes: {updates}},
                    () => this.props.showSnack('success',successMessage))
            },
            (error) => {
                this.handleError(error);
            })
    }

    render() {
        const {classes} = this.props;
        const { loadingConstruction, savingConstruction, readOnlyMode} = this.state;

        const {
            builder,
            property,
            community,
            status,
            buyerLastName,
            fmls,
            gamls,
            navica,
            foundation,
            garageDoors,
            garageDirection,
            stage,
            planAndElevation,
            finish,
            dateSelectionsDue,
            dateSelectionsReceived,
            firstWalkThruDate,
            finalWalkThruDate,
            firstWalkThruDateHasTime,
            finalWalkThruDateHasTime,
            listPrice,
            contingency,
            contingencyDate,
            finalClosingAmendmentCompleted,
            constructionNotes,
            newConstructionNote,
            buildPermit,
            certificateOfOccupancy,
            warranty,
            contract,
            closeDate,
            salesNotes,
            newSalesNote,
            isActive,
            termiteLetterRequested,
            constructionStartDate,
            qbwWarranty,
            showUnlockDialog,
            unlocked
        } = this.state;

        const unlockDialogBody = (
            <UnlockEditMode summary={property} unlock={() => this.setState({unlocked: true, showUnlockDialog: false})} />
        );

        const constructionSection = (
            <div>
                <div className={classes.editButton}>
                    {(readOnlyMode && (isActive || unlocked)) &&
                    <Fab size="small" color="primary" aria-label="edit" className={classes.margin}
                         onClick={() => this.setState({readOnlyMode: false})}>
                        <EditIcon/>
                    </Fab>
                    }
                    {(readOnlyMode && !isActive && !unlocked) &&
                    <Fab size="small" color="primary" aria-label="unlock" className={classes.margin}
                         onClick={() => this.setState({showUnlockDialog: true})}>
                        <LockIcon/>
                    </Fab>
                    }
                </div>
                <FormControl className={classes.dropdown} required>
                    <InputLabel htmlFor="construction-status-select">Construction Status</InputLabel>
                    <Select
                        id="construction-status-select"
                        name="status"
                        value={status}
                        onChange={(e) => this.handleSelectChange(e)}
                        disabled={readOnlyMode || status === 'Under Contract'|| status === 'Closed'}
                    >
                        <MenuItem value="Under Contract">Under Contract</MenuItem>
                        <MenuItem value="Lot">Lot</MenuItem>
                        <MenuItem value="Contract in the Works">Contract in the Works</MenuItem>
                        <MenuItem value="SPEC">SPEC</MenuItem>
                        <MenuItem value="Model">Model</MenuItem>
                        {status === 'Closed' &&
                        <MenuItem value="Closed">Closed</MenuItem>
                        }
                    </Select>
                    {(status !== 'Under Contract' && status !== 'Closed') &&
                    <FormHelperText>Changing the Status to Under Contract will reload all other fields. Please save any other changes prior to doing so.</FormHelperText>
                    }
                </FormControl>
                <br/>
                <TextField autoComplete="new"
                    value={fmls}
                    name="fmls"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="FMLS"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('fmls')}
                    helperText={this.fieldErrorMessage('fmls')}
                    required={status === 'Under Contract'}
                    disabled={readOnlyMode}
                />
                <TextField autoComplete="new"
                    value={gamls}
                    name="gamls"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="GAMLS"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('gamls')}
                    helperText={this.fieldErrorMessage('gamls')}
                    required={status === 'Under Contract'}
                    disabled={readOnlyMode}
                />
                <TextField autoComplete="new"
                    value={navica}
                    name="navica"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="NAVICA"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('navica')}
                    helperText={this.fieldErrorMessage('navica')}
                    disabled={readOnlyMode}
                />
                <br/>
                <FormControl className={classes.dropdown} required={status !== 'Lot'}>
                    <InputLabel htmlFor="construction-foundation-select">Foundation Type</InputLabel>
                    <Select
                        id="construction-foundation-select"
                        name="foundation"
                        value={foundation}
                        onChange={(e) => this.handleSelectChange(e)}
                        disabled={readOnlyMode}
                    >
                        <MenuItem value="Basement">Basement</MenuItem>
                        <MenuItem value="Slab">Slab</MenuItem>
                    </Select>
                </FormControl>
                <TextField autoComplete="new"
                    value={garageDoors}
                    name="garageDoors"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="How Many Garage Doors?"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('garageDoors')}
                    helperText={this.fieldErrorMessage('garageDoors')}
                    type="number"
                    disabled={readOnlyMode}
                />

                <FormControl className={classes.dropdown}  required={status !== 'Lot'}>
                    <InputLabel htmlFor="garageDirection-select">Garage Direction</InputLabel>
                    <Select
                        id="garageDirection-select"
                        name="garageDirection"
                        value={garageDirection}
                        onChange={(e) => this.handleSelectChange(e)}
                        disabled={readOnlyMode}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Front">Front</MenuItem>
                        <MenuItem value="Side">Side</MenuItem>
                        <MenuItem value="Side & Carriage">Side & Carriage</MenuItem>
                        <MenuItem value="Rear">Rear</MenuItem>
                    </Select>
                </FormControl>
                <br/>
                <TextField autoComplete="new"
                    value={stage}
                    name="stage"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Stage"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('stage')}
                    helperText={this.fieldErrorMessage('stage')}
                    disabled={readOnlyMode}
                    required={status !== 'Lot'}
                />
                <TextField autoComplete="new"
                    value={planAndElevation}
                    name="planAndElevation"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Plan and Elevation"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('planAndElevation')}
                    helperText={this.fieldErrorMessage('planAndElevation')}
                    disabled={readOnlyMode}
                />
                {builder === "Stephen Elliott" &&
                <TextField autoComplete="new"
                    value={finish}
                    name="finish"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Finish"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('finish')}
                    helperText={this.fieldErrorMessage('finish')}
                    disabled={readOnlyMode}
                />
                }
                <br/>
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        margin="normal"
                        className={classes.dateInput}
                        label={builder === "Stephen Elliott" ? "Finish Schedule Due" : "Date Selections Due"}
                        value={dateSelectionsDue}
                        onChange={(d) => this.handleDateChange('dateSelectionsDue', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                        disabled={readOnlyMode}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        className={classes.dateInput}
                        margin="normal"
                        label={builder === "Stephen Elliott" ? "Finish Schedule Received" : "Date Selections Received"}
                        value={dateSelectionsReceived}
                        onChange={(d) => this.handleDateChange('dateSelectionsReceived', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                        disabled={readOnlyMode}
                    />
                </MuiPickersUtilsProvider>
                <br/>
                <ThemeProvider theme={dateTimeTheme}>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="firstWalkThruDateHasTime"
                                checked={firstWalkThruDateHasTime}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                                disabled={readOnlyMode}
                            />
                        }
                        label="First Walkthrough Date has Time?"
                    />
                    {firstWalkThruDateHasTime ? (
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDateTimePicker
                                ampm
                                format="MM/DD/YYYY hh:mm a"
                                onError={console.log}
                                className={classes.dateTimeInput}
                                margin="normal"
                                label="First WalkThru Date"
                                value={firstWalkThruDate}
                                onChange={(d) => this.handleDateChange('firstWalkThruDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date and time',
                                }}
                                autoOk={true}
                                disabled={readOnlyMode}
                            />
                        </MuiPickersUtilsProvider>
                    ) : (
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                className={classes.dateInput}
                                margin="normal"
                                label="First WalkThru Date"
                                value={firstWalkThruDate}
                                onChange={(d) => this.handleDateChange('firstWalkThruDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                                disabled={readOnlyMode}
                            />
                        </MuiPickersUtilsProvider>
                    )}
                    <br/>
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                name="finalWalkThruDateHasTime"
                                checked={finalWalkThruDateHasTime}
                                onChange={(e) => this.handleSwitchChange(e)}
                                color="primary"
                                disabled={readOnlyMode}
                            />
                        }
                        label="Final Walkthrough Date has Time?"
                    />
                    {finalWalkThruDateHasTime ? (
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDateTimePicker
                                ampm
                                format="MM/DD/YYYY hh:mm a"
                                onError={console.log}
                                className={classes.dateTimeInput}
                                margin="normal"
                                label="Final WalkThru Date"
                                value={finalWalkThruDate}
                                onChange={(d) => this.handleDateChange('finalWalkThruDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date and time',
                                }}
                                // autoOk={true}
                                disabled={readOnlyMode}
                            />
                        </MuiPickersUtilsProvider>
                    ) : (
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                className={classes.dateInput}
                                margin="normal"
                                label="Final WalkThru Date"
                                value={finalWalkThruDate}
                                onChange={(d) => this.handleDateChange('finalWalkThruDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                                disabled={readOnlyMode}
                            />
                        </MuiPickersUtilsProvider>
                    )}
                </ThemeProvider>
                <br/>
                <TextField autoComplete="new"
                    name="listPrice"
                    value={listPrice}
                    onChange={(e) => this.handleFieldChange(e)}
                    label="List Price"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('listPrice')}
                    helperText={this.fieldErrorMessage('listPrice')}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    disabled={readOnlyMode}
                    required={status === 'Under Contract'}
                />
                <br/>
                <TextField autoComplete="new"
                    value={buyerLastName}
                    name="buyerLastName"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Buyer's Last Name"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('buyerLastName')}
                    helperText={this.fieldErrorMessage('buyerLastName')}
                    disabled={readOnlyMode}
                    required={status === 'Under Contract'}
                />
                <br/>
                <FormControl className={classes.dropdown} required={status === 'Under Contract'}>
                    <InputLabel htmlFor="construction-contingency-select">Construction Contingency</InputLabel>
                    <Select
                        id="construction-contingency-select"
                        name="contingency"
                        value={contingency}
                        onChange={(e) => this.handleSelectChange(e)}
                        disabled={readOnlyMode}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Sale and Close">Sale and Close</MenuItem>
                        <MenuItem value="Close Only">Close Only</MenuItem>
                        <MenuItem value="Lease">Lease</MenuItem>
                    </Select>
                </FormControl>
                {(contingency !== 'None' && contingency !== 'Lease') &&
                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/DD/YYYY"
                        className={classes.dateInput}
                        margin="normal"
                        label="Contingency Date"
                        value={contingencyDate}
                        onChange={(d) => this.handleDateChange('contingencyDate', d)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        autoOk={true}
                        disabled={readOnlyMode}
                    />
                </MuiPickersUtilsProvider>
                }
                <br/>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            name="finalClosingAmendmentCompleted"
                            checked={finalClosingAmendmentCompleted}
                            onChange={(e) => this.handleSwitchChange(e)}
                            color="primary"
                            disabled={readOnlyMode}
                        />
                    }
                    label="Final Closing Amendment Completed?"
                />
                <br/>
                <TextField autoComplete="new"
                    value={newConstructionNote}
                    name="newConstructionNote"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="Construction Note"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('newConstructionNote')}
                    helperText={this.fieldErrorMessage('newConstructionNote')}
                    multiline
                    rows={3}
                    fullWidth
                    disabled={readOnlyMode}
                />
                <Log readOnlyMode={readOnlyMode} save={(u) => this.setState({savingConstruction: true},() => this.updateConstructionNotes(u))} title="Construction Notes" log={constructionNotes} type="construction" noUpdatesText="No notes found.." />
                <br/>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            name="buildPermit"
                            checked={buildPermit}
                            onChange={(e) => this.handleSwitchChange(e)}
                            color="primary"
                            disabled={readOnlyMode}
                        />
                    }
                    label="Build Permit?"
                />
                <br/>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            name="certificateOfOccupancy"
                            checked={certificateOfOccupancy}
                            onChange={(e) => this.handleSwitchChange(e)}
                            color="primary"
                            disabled={readOnlyMode}
                        />
                    }
                    label="Certificate Of Occupancy?"
                />
                <br/>
                <FormControl className={classes.dropdown} required={status === 'Under Contract'}>
                    <InputLabel htmlFor="warranty-select">Warranty</InputLabel>
                    <Select
                        id="warranty-select"
                        name="warranty"
                        value={warranty||''}
                        onChange={(e) => this.handleSelectChange(e)}
                        disabled={readOnlyMode}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="QBW">QBW</MenuItem>
                        <MenuItem value="2-10">2-10</MenuItem>
                        <MenuItem value="StrucSure">StrucSure</MenuItem>
                        <MenuItem value="1 Year Only">1 Year Only</MenuItem>
                    </Select>
                </FormControl>

                <br/>
                {(builder === "My Home Communities" && status === 'Under Contract') &&
                <div>
                    <TextField autoComplete="new"
                       value={termiteLetterRequested}
                       name="termiteLetterRequested"
                       onChange={(e) => this.handleFieldChange(e)}
                       label="Termite Letter Requested?"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('termiteLetterRequested')}
                       helperText={this.fieldErrorMessage('termiteLetterRequested')}
                       disabled={readOnlyMode}
                       fullWidth
                    />
                    {/*<FormControlLabel*/}
                    {/*    className={classes.switch}*/}
                    {/*    control={*/}
                    {/*        <Switch*/}
                    {/*            name="termiteLetterRequested"*/}
                    {/*            checked={termiteLetterRequested}*/}
                    {/*            onChange={(e) => this.handleSwitchChange(e)}*/}
                    {/*            color="primary"*/}
                    {/*            disabled={readOnlyMode}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*    label="Termite Letter Requested?"*/}
                    {/*/>*/}
                    <br/>
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            className={classes.dateInput}
                            margin="normal"
                            label="Construction Start Date"
                            value={constructionStartDate}
                            onChange={(d) => this.handleDateChange('constructionStartDate', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                            disabled={readOnlyMode}
                        />
                    </MuiPickersUtilsProvider>
                    <br/>
                    <TextField autoComplete="new"
                       value={qbwWarranty}
                       name="qbwWarranty"
                       onChange={(e) => this.handleFieldChange(e)}
                       label="Registered Warranty"
                       className={classes.textField}
                       margin="normal"
                       error={this.fieldHasError('qbwWarranty')}
                       helperText={this.fieldErrorMessage('qbwWarranty')}
                       disabled={readOnlyMode}
                       fullWidth
                    />
                    {/*<MuiPickersUtilsProvider utils={DateMomentUtils}>*/}
                    {/*    <KeyboardDatePicker*/}
                    {/*        disableToolbar*/}
                    {/*        variant="inline"*/}
                    {/*        format="MM/DD/YYYY"*/}
                    {/*        className={classes.dateInput}*/}
                    {/*        margin="normal"*/}
                    {/*        label="QBW Warranty Order Date"*/}
                    {/*        value={qbwWarranty}*/}
                    {/*        onChange={(d) => this.handleDateChange('qbwWarranty', d)}*/}
                    {/*        KeyboardButtonProps={{*/}
                    {/*            'aria-label': 'change date',*/}
                    {/*        }}*/}
                    {/*        autoOk={true}*/}
                    {/*        disabled={readOnlyMode}*/}
                    {/*    />*/}
                    {/*</MuiPickersUtilsProvider>*/}
                </div>
                }
                <br/>
                <TextField autoComplete="new"
                    value={newSalesNote}
                    name="newSalesNote"
                    onChange={(e) => this.handleFieldChange(e)}
                    label="New Sales Note"
                    className={classes.textField}
                    margin="normal"
                    error={this.fieldHasError('newSalesNote')}
                    helperText={this.fieldErrorMessage('newSalesNote')}
                    multiline
                    rows={3}
                    fullWidth
                    disabled={readOnlyMode}
                />
                <Log readOnlyMode={readOnlyMode} save={(u) => this.setState({savingConstruction: true},() => this.updateSalesNotes(u))} title="Sales Notes" log={salesNotes} type="sales" noUpdatesText="No notes found.." />
                <br/>
                {!readOnlyMode &&
                <Button disabled={!this.isConstructionValid()} variant="contained" color="primary" onClick={() => this.setState({savingConstruction: true}, () => this.updateConstruction())} className={classes.button}>SAVE CHANGES</Button>
                }
            </div>
        );

        return (
            <div className={classes.root}>
                {loadingConstruction ? (
                    <Loading/>
                ) : (
                    <div>
                        <div>
                            <div className={classes.readOnly}>
                                <div className={classes.roTitle}>Builder</div>
                                <div className={classes.roValue}>{builder}</div>
                            </div>
                            <Divider className={classes.divider} />
                            <div className={classes.readOnly}>
                                <div className={classes.roTitle}>Property</div>
                                <div className={classes.roValue}>{property}</div>
                            </div>
                            <Divider className={classes.divider} />
                            <div className={classes.readOnly}>
                                <div className={classes.roTitle}>Community</div>
                                <div className={classes.roValue}>{community}</div>
                            </div>
                            <Divider className={classes.divider} />
                            {constructionSection}
                            <Divider className={classes.divider} />
                            <Typography variant="h6">Close Date</Typography>
                            <UpdateCloseDate ignoreValidation={(status === "Model" || status === "SPEC")} underContract={status === 'Under Contract'} stage={parseInt((stage||'1'))} builder={builder} closeDate={closeDate} save={(c) => this.setState({savingConstruction: true}, () => this.updateCloseDate(c))} readOnlyMode={readOnlyMode} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                            <Divider className={classes.divider} />
                            <Typography variant="h6">Contract</Typography>
                            <UpdateContract parentType="construction" underContract={status === 'Under Contract'} contract={contract} builder={builder} save={(c) => this.setState({savingConstruction: true}, () => this.updateContract(c))} saveMonies={(m) => this.setState({savingConstruction: true}, () => this.updateMonies(m))} readOnlyMode={readOnlyMode} showSnack={(t,e) => this.props.showSnack(t,e)}/>
                            <br/>
                            {this.state.statusModified &&
                                <Button disabled={!this.isConstructionValid()} variant="contained" color="primary" onClick={() => this.setState({savingConstruction: true}, () => this.updateConstruction())} className={classes.button}>SAVE CHANGES AND UPDATE STATUS TO UNDER CONTRACT</Button>
                            }
                        </div>
                        {savingConstruction &&
                        <Loading text="Saving..."/>
                        }
                    </div>
                )}
                <Dialog closeCallback={() => this.setState({showUnlockDialog: false})} showDialog={showUnlockDialog} title={'Unlock Editing'} bodyHtml={unlockDialogBody} />
            </div>
        );
    }
}

export default withStyles(styles)(UpdateConstruction);