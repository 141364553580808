import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {validatePrice} from '../../utils';
import _ from 'lodash';
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import Paper from '@material-ui/core/Paper';
// import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        flexGrow: 1
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        maxWidth: 140,
        minWidth: 140,
        flexGrow: 1
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(.5),
        marginBottom: theme.spacing(2),
        maxWidth: 105,
        flexGrow: 1
    },
    dateInput: {
        margin: theme.spacing(.5),
        marginBottom: theme.spacing(2),
        maxWidth: 140,
        flexGrow: 1
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: 4,
        marginTop: theme.spacing(1),
    },
    header: {
        textAlign: 'center',
        fontWeight: '500'
    },
    add: {
        marginTop: theme.spacing(2),

    },
    margin: {
        marginTop: theme.spacing(1),
        // height: 26,
        // width: 26,
    }
});

class AddInstallment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            dateDue: null,
            dateReceived: null,
            validations: []
        };
    }

    componentDidMount() {

    }

    validateAll() {
        const fieldsToValidate = [
            'amount',
            'dateDue',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'amount':
                isValid = validatePrice(value);
                invalidationReason = 'The amount is required';
                break;
            case 'dateDue':
                isValid = (value||null) !== null;
                invalidationReason = 'The Date Due is required';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        switch (field) {
            case 'amount':
                value = value.replace(/[^0-9.]/, '');

                if(value.indexOf('.') > -1){
                    value = value.substring(0,value.indexOf('.')+3);
                }
                break;
            default:
                break;
        }

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const readOnlyMode = this.props.readOnlyMode || false;
        if(readOnlyMode){
            return false;
        }

        const installments = this.props.installments || 0;
        if(installments >= 5){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    save() {
        const {
            amount,
            dateDue,
            dateReceived
        } = this.state;

        const type = this.props.type;

        const due = dateDue.format('YYYY-MM-DD');
        const received = (dateReceived||null) !== null ? dateReceived.format('YYYY-MM-DD') : null;
        // console.log(due);

        const installment = {
            type,
            amount,
            dateDue: due,
            dateReceived: received
        };

        const i = _.cloneDeep(installment)

        this.setState({
            party: '',
            amount: '',
            dateDue: null,
            dateReceived: null,
        }, () => this.props.save(i));
    }

    render() {
        const { classes } = this.props;

        const {
            amount,
            dateDue,
            dateReceived
        } = this.state;

        const readOnlyMode = this.props.readOnlyMode || false;

        return (
            <Paper className={classes.paper}>
                <div className={classes.header}>Add Installment</div>
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                        name="amount"
                        value={amount}
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Amount"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('amount')}
                        helperText={this.fieldErrorMessage('amount')}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={readOnlyMode}
                    />
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            className={classes.dateInput}
                            margin="normal"
                            label="Due"
                            value={dateDue}
                            onChange={(d) => this.handleDateChange('dateDue', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                            disabled={readOnlyMode}
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/DD/YYYY"
                            className={classes.dateInput}
                            margin="normal"
                            label="Received"
                            value={dateReceived}
                            onChange={(d) => this.handleDateChange('dateReceived', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                            disabled={readOnlyMode}
                        />
                    </MuiPickersUtilsProvider>
                    <div className={classes.add}>
                        <IconButton className={classes.margin} aria-label="add" disabled={!this.isValid()} onClick={() => this.save()} size="small" color="primary">
                            <AddIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(AddInstallment);