import React, { Component } from 'react';
import {createMuiTheme, MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue} from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import {request} from "../utils";
import Dialog from "./Dialog";
import UpdateListing from "./Forms/UpdateListing";
import CustomToolbarSelect from "./CustomToolbarSelect";
import TerminateListing from "./Forms/TerminateListing";
import CloseListing from "./Forms/CloseListing";
import DeleteListing from "./Forms/DeleteListing";
import moment from "moment";

const styles = theme => ({
    root: {
        width: '100%',
        textAlign: 'left !important',
        marginTop: theme.spacing(1),
    },
    paper: {
        width: '100%',
    },
    tableWrapper: {
        // maxHeight: 440,
        // overflow: 'auto',
        maxWidth: '100%'
    },
    fabProgress: {
        color: blue[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    fabProgressWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    margin: {
        margin: theme.spacing(1),
    },
    controls: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    grow: {
        flexGrow: 1,
    },
});

class ListingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            columns: [],
            data: [],
            status: 'Under Contract',
            type: 'Any',
            closeDateYear: '',
            years: [],
            showUpdateListing: false,
            listingId: '',
            showCloseDialog: false,
            closeDialog: {},
            showDeleteDialog: false,
            deleteDialog: {},
            confirmationCode: '',
            showTerminateDialog: false,
            terminateDialog: {},
            saving: false
        };
    }

    componentDidMount() {
        const now = moment();
        const year = now.year();
        const years = [year-1,year,year+1];
        this.setState({years, closeDateYear: year},() => this.loadListings())
    }

    loadListings() {
        const status = this.state.status || 'Under Contract';
        const type = this.state.type || null;
        const closeDateYear = this.state.closeDateYear || null;
        let action = '/Manage/getListings?status=' + status;

        if(type.length !== null && type !== 'Any'){
            action += '&type=' + type;
        }

        if(closeDateYear.length !== null){
            action += '&closeDateYear=' + closeDateYear;
        }

        request(
            'GET',
            action,
            null,
            (data) => {
                this.setState({columns: data.columns, data: data.listings, isLoading: false})
            },
            (error) => {
                this.handleError(error);
            })
    }

    handleError(error){
        this.setState({isLoading: false,loadingBuilders: false, data: [], columns: []}, () => this.props.showSnack('error',error))
    }

    getYears() {
        const status = this.state.status || 'Under Contract';
        let action = '/Manage/getListingYears?status=' + status;
        request(
            'GET',
            action,
            null,
            (data) => {
                this.setState({years: data.years, closeDateYear: data.year, isLoading: false})
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error))
            })
    }

    handleChange(e){
        const name = e.target.name;
        const value = e.target.value;

        if(name === 'status'){
            if(value === 'Closed' || value === 'Terminated'){
                this.setState({[name]:value,isLoading:true}, () => this.getYears())
            } else {
                const now = moment();
                const year = now.year();
                const years = [year-1,year,year+1];
                this.setState({[name]:value,years,closeDateYear:year})
            }
        } else{
            this.setState({[name]:value})
        }
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    }

    confirmClose(o){
        let summary = o.lot.length !== 0 ? 'Lot ' + o.lot  + ' ' : '';
        summary += o.address;

        const body = (
            <CloseListing summary={summary} listingId={o.listingId} close={(c) => this.close(c)} showSnack={(t,m) => this.props.showSnack(t,m)}/>
        );

        this.setState({
            closeDialog: {title: 'Close Sales Details', body},
            showCloseDialog: true,
        })
    }

    close(data){
        const action = '/Manage/closeListing';
        const successMessage = 'Successfully Closed Sales Details!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({saving: false, closeDialog: {}, showCloseDialog: false}, () => this.successAndReload(successMessage))
            },
            (error) => {
                this.setState({saving: false, closeDialog: {}, showCloseDialog: false}, () => this.props.showSnack('error',error))
            })
    }
    
    confirmTerminate(o){
        let summary = o.lot.length !== 0 ? 'Lot ' + o.lot  + ' ' : '';
        summary += o.address;

        const body = (
            <TerminateListing summary={summary} listingId={o.listingId} terminate={(t) => this.terminate(t)} showSnack={(t,m) => this.props.showSnack(t,m)} />
        );

        this.setState({
            terminateDialog: {title: 'Terminate Sales Details', body},
            showTerminateDialog: true
        })
    }

    confirmDelete(o){
        let summary = o.lot.length !== 0 ? 'Lot ' + o.lot  + ' ' : '';
        summary += o.address;

        const body = (
            <DeleteListing summary={summary} listingId={o.listingId} delete={(i,c) => this.delete(i,c)} showSnack={(t,m) => this.props.showSnack(t,m)} />
        );

        this.setState({
            deleteDialog: {title: 'Delete Sales Details', body},
            showDeleteDialog: true
        })
    }

    delete(id,confirmationCode){
        const action = '/Manage/deleteListing';
        const data = {listingId: id, confirmationCode: confirmationCode};
        const successMessage = 'Successfully Deleted Sales Details!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({saving: false, confirmationCode: '', deleteDialog: {}, showDeleteDialog: false}, () => this.successAndReload(successMessage))
            },
            (error) => {
                this.setState({saving: false, confirmationCode: '', deleteDialog: {}, showDeleteDialog: false}, () => this.props.showSnack('error',error))
            })
    }

    terminate(data){
        const action = '/Manage/terminateListing';
        // const data = {listingId: id};
        const successMessage = 'Successfully Terminated Sales Details!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({saving: false, terminateDialog: {}, showTerminateDialog: false}, () => this.successAndReload(successMessage))
            },
            (error) => {
                this.setState({saving: false, terminateDialog: {}, showTerminateDialog: false}, () => this.props.showSnack('error',error))
            })
    }

    successAndReload(successMessage) {
        this.props.showSnack('success',successMessage);
        this.reload();
    }

    editListing(o) {
        const listingId = o.listingId || null;

        if(listingId !== null){
            this.setState({listingId: listingId, showUpdateListing: true})
        }
    }

    reload(){
        this.setState({isLoading: true, saving: false, columns: [], data: []}, () => this.loadListings())
    }

    errorLoadingNewListing(error){
        this.setState({listingId: '', showUpdateListing: false}, () => this.props.showSnack('error',error))
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    whiteSpace: 'pre'
                }
            },
            MUIDataTableBody: {
                emptyTitle: {
                    textAlign: 'left',
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    width: 250,
                    paddingLeft: 15
                }
            }
        }
    });

    render() {
        const { classes } = this.props;
        const fileName = "tw_sales_details.csv";

        const {
            isLoading,
            columns,
            data,
            status,
            type,
            showUpdateListing,
            listingId,
            showCloseDialog,
            closeDialog,
            showDeleteDialog,
            deleteDialog,
            saving,
            showTerminateDialog,
            terminateDialog,
            years,
            closeDateYear
        } = this.state;

        const options = {
            print: false,
            selectableRowsOnClick: true,
            selectableRowsHeader: true,
            selectableRows: 'single',
            responsive: 'standard',
            downloadOptions: {filename: fileName, separator: ','},
            // onDownload: (buildHead, buildBody, columns, data) => {
            //     //return "\uFEFF" + buildHead(columns) + buildBody(data);
            // },
            // jumpToPage: true,
            textLabels: {
                body: {
                    noMatch: !isLoading ? "No Results found" : "Loading..",
                },
            },
            rowsPerPage: 100,
            rowsPerPageOptions: [10,25,50,100],
            tableBodyHeight: '440px',
            tableBodyMaxHeight: '440px',

            customToolbarSelect: (selectedRows) => (
                <CustomToolbarSelect
                    showEdit={true}
                    edit={(o) => this.editListing(o)}
                    showListingClose={(status !== 'Terminated' && status !== 'Closed')}
                    showListingTerminate={(status !== 'Terminated' && status !== 'Closed' && status !== 'Closed - Pending')}
                    showListingDelete={(status !== 'Terminated' && status !== 'Closed' && status !== 'Closed - Pending')}
                    terminate={(o) => this.confirmTerminate(o)}
                    delete={(o) => this.confirmDelete(o)}
                    close={(o) => this.confirmClose(o)}
                    selectedRows={selectedRows}
                    data={data}
                />
            ),
        };

        const closeDialogButtons = [
            {
                disable: saving,
                text: 'Cancel',
                callback: () => {
                    this.setState({
                        closeDialog: {},
                        showCloseDialog: false,
                    })
                }
            }
        ];

        const deleteDialogButtons = [
            {
                disable: saving,
                text: 'Cancel',
                callback: () => {
                    this.setState({
                        deleteDialog: {},
                        showDeleteDialog: false,
                        confirmationCode: ''
                    })
                }
            }
        ];
        
        const terminateDialogButtons = [
            {
                disable: saving,
                text: 'Cancel',
                callback: () => {
                    this.setState({
                        terminateDialog: {},
                        showTerminateDialog: false,
                    })
                }
            }
        ];

        const dialogBody = (
            <div>
                {(listingId||null) !== null &&
                <UpdateListing readOnlyMode={(status === 'Terminated' || status === 'Closed' || status === 'Closed - Pending')} listingId={listingId} showSnack={(t,m) => this.props.showSnack(t,m)} error={(e) => this.errorLoadingNewListing(e)} />
                }
            </div>
        );

        return (
            <div>
                <div className={classes.controls}>
                    <div className={classes.grow}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="status-select-label">Status</InputLabel>
                            <Select
                                name="status"
                                id="status-select"
                                value={status}
                                onChange={(e) => this.handleChange(e)}
                                disabled={isLoading}
                            >
                                <MenuItem value="Under Contract">Under Contract</MenuItem>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Closed">Closed</MenuItem>
                                <MenuItem value="Closed - Pending">Closed - Pending</MenuItem>
                                <MenuItem value="Terminated">Terminated</MenuItem>
                            </Select>
                            <FormHelperText>Filter by Status</FormHelperText>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="builder-select-label">Type</InputLabel>
                            <Select
                                name="type"
                                id="builder-select"
                                value={type}
                                onChange={(e) => this.handleChange(e)}
                                disabled={isLoading}
                            >
                                <MenuItem value="Any">Any</MenuItem>
                                <MenuItem value="New Construction">New Construction</MenuItem>
                                <MenuItem value="Resale">Resale</MenuItem>
                                <MenuItem value="Lease">Lease</MenuItem>
                            </Select>
                            <FormHelperText>Filter by Type</FormHelperText>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="year-select-label">Close Date Year</InputLabel>
                            <Select
                                name="closeDateYear"
                                id="year-select"
                                value={closeDateYear}
                                onChange={(e) => this.handleChange(e)}
                                disabled={isLoading}
                            >
                                {years.map((item, index) => (
                                    <MenuItem key={'year-select-' + item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Filter by Year</FormHelperText>
                        </FormControl>
                        <Fab
                            color="primary"
                            aria-label="load"
                            className={classes.margin}
                            disabled={isLoading}
                            onClick={() => this.setState({isLoading: true, columns: [], data: []}, () => this.loadListings())
                            }>
                            <SendIcon/>
                        </Fab>
                    </div>
                    <div className={classes.fabProgressWrapper}>
                        <Fab
                            aria-label="save"
                            onClick={() => this.reload()}
                            disabled={isLoading}
                        >
                            <RefreshIcon/>
                        </Fab>
                        {isLoading && <CircularProgress size={68} className={classes.fabProgress} />}
                    </div>
                </div>
                <div className={classes.root}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>
                </div>
                <Dialog fullScreen={true} closeCallback={() => this.setState({listingId: '', showUpdateListing: false})} showDialog={showUpdateListing} title='Edit Sales Details' bodyHtml={dialogBody}/>
                <Dialog closeCallback={() => this.setState({deleteDialog: {}, confirmationCode: '', showDeleteDialog: false})} showDialog={showDeleteDialog} title={deleteDialog.title||''} bodyHtml={deleteDialog.body||''} buttons={deleteDialogButtons} />
                <Dialog closeCallback={() => this.setState({closeDialog: {}, showCloseDialog: false})} showDialog={showCloseDialog} title={closeDialog.title||''} bodyHtml={closeDialog.body||''} buttons={closeDialogButtons} />
                <Dialog closeCallback={() => this.setState({terminateDialog: {}, showTerminateDialog: false})} showDialog={showTerminateDialog} title={terminateDialog.title||''} bodyHtml={terminateDialog.body||''} buttons={terminateDialogButtons} />
            </div>
        );
    }
}

export default withStyles(styles)(ListingTable);