import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import UpdateLink from "./Forms/UpdateLink";
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
    linkSummary: {
        position: 'relative',
        marginBottom: theme.spacing(1)
    },
    card: {
        overflow: 'unset !important'
    },
    cardContent: {
        padding: theme.spacing(1) + 'px !important'
    },
    cardLeft: {
        minWidth: 0,
        position: 'relative',
        flex: 1
    },
    cardRight: {
        textAlign: 'left'
    },
    cardFooterIcon: {
        fontSize: 12
    },
    flex: {
        display: 'flex'
    },
    grow: {
        flexGrow: 1,
    },
    label: {
        fontWeight: 500,
        fontSize: 12,
        textTransform: 'uppercase',
        display: 'block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    summary: {
        fontWeight: 400,
        fontSize: 12,
        display: 'block',
        textTransform: 'uppercase',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    url: {
        fontWeight: 300,
        fontSize: 10,
        display: 'block',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    iconButton: {
        padding: 4,
        marginLeft: theme.spacing(.5)
    },
    icon: {
        height: 16,
        width: 16,
        fontSize: 16
    },
    button: {
        marginLeft: theme.spacing(.5)
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
});

class LinkSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditor: false,
            showRemoveConfirmation: false
        };
    }

    componentDidMount() {

    }

    render() {
        const { classes, link, index, count } = this.props;
        const { title, summary, url } = link;
        const landingPageLinkId = this.props.landingPageLinkId || '';
        const editable = this.props.editable || false;

        const showEditor = this.state.showEditor;
        const showRemoveConfirmation = this.state.showRemoveConfirmation;

        return (
            <div className={classes.linkSummary}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.flex}>
                            <div className={classes.cardLeft}>
                                <span className={classes.label}>{title}</span>
                                <span className={classes.summary}>{summary}</span>
                                <span className={classes.url}>{url}</span>
                            </div>
                            {(showEditor||showRemoveConfirmation) ? (
                                <div className={classes.cardRight}></div>
                            ) : (
                                <div className={classes.cardRight}>
                                    {(index > 1 && count > 1) &&
                                    <IconButton className={classes.iconButton} size="small" color="default" aria-label="Move Up" title="Move Up" onClick={() => this.props.moveLink('up')}>
                                        <ArrowUpwardIcon className={classes.icon}/>
                                    </IconButton>
                                    }
                                    {(index < count && count > 1) &&
                                    <IconButton className={classes.iconButton} size="small" color="default" aria-label="Move Down" title="Move Down" onClick={() => this.props.moveLink('down')}>
                                        <ArrowDownwardIcon className={classes.icon}/>
                                    </IconButton>
                                    }
                                    {((landingPageLinkId||null) !== null && editable) &&
                                    <IconButton className={classes.iconButton} size="small" color="primary" aria-label="Edit Link" title="Edit Link" onClick={() => this.setState({showEditor: true})}>
                                        <EditIcon className={classes.icon}/>
                                    </IconButton>
                                    }
                                    <IconButton className={classes.iconButton} size="small" color="secondary" aria-label="Remove Link" title="Remove Link" onClick={() => this.setState({showRemoveConfirmation: true})}>
                                        <CloseIcon className={classes.icon}/>
                                    </IconButton>
                                </div>
                            )}
                        </div>
                        {showRemoveConfirmation &&
                            <div>
                                <Divider className={classes.divider} />
                                <div className={classes.flex}>
                                    <div className={classes.grow}>Are you sure you?</div>
                                    <Button variant="contained" size="small" color="primary" className={classes.button} onClick={() => this.setState({showRemoveConfirmation: false}, () => this.props.remove())}>YES</Button>
                                    <Button variant="contained" size="small" color="secondary" className={classes.button} onClick={() => this.setState({showRemoveConfirmation: false})}>NO</Button>
                                </div>
                            </div>
                        }
                        {showEditor &&
                        <div>
                            <Divider className={classes.divider} />
                            <UpdateLink link={link} close={() => this.setState({showEditor:false})} saved={(l,m) => this.setState({showEditor:false},() => this.props.edited(l,m))} showSnack={(t,m) => this.props.showSnack(t,m)}/>
                        </div>
                        }
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(LinkSummary);