import React from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";

// import Login from './Components/Login';

// import NotFound from './Components/NotFound';
import Callback from './Components/Callback'
import Auth from './Auth/Auth';
import history from './history';
import AuthRoutes from './Components/AuthRoutes'
// import {createMuiTheme} from '@material-ui/core/styles';
// import {ThemeProvider} from '@material-ui/styles';
// import blue from '@material-ui/core/colors/blue';
// import red from '@material-ui/core/colors/red';
// import Layout from "./Components/Layout";
// import Dashboard from "./Components/Dashboard";
// import Sheets from "./Components/Sheets";
// import Landing from "./Components/Landing";


const auth = new Auth();

// const RouteContent = React.forwardRef((props, ref) =>
//     <Switch>
//         <Route exact path="/" render={(props) => <Redirect to="/Landing"/>}/>
//         <Route exact path="/Admin/Dashboard" render={(props) => <Dashboard {...props} />}/>
//         <Route exact path="/Admin/Manage" render={(props) => <Sheets {...props} />}/>
//         <Route exact path="/Landing" render={(props) => <Landing {...props} />}/>
//
//         <Route path="/callback" render={(props) => {
//             return <Callback auth={auth} {...props} />
//         }}/>
//
//         <Route render={(props) =>
//             <NotFound {...props}/>
//         }/>
//     </Switch>
// );
//
// const Routes = () => {
//
//     if(!auth.isAuthenticated()){
//         auth.login();
//     }
//
//     const theme = createMuiTheme(
//         {
//             typography: {
//                 useNextVariants: true,
//             },
//             palette: {
//                 primary: blue,
//                 secondary: red,
//             }
//         }
//     );
//
//     return (
//         <Router history={history}>
//             <ThemeProvider theme={theme}>
//                 <Layout auth={auth}>
//                     <RouteContent/>
//                 </Layout>
//             </ThemeProvider>
//         </Router>
//     );
// };


const Routes = () => {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" render={(props) => <Redirect to="/Home"/>}/>
                <Route path="/admin" render={(props) => <AuthRoutes auth={auth} {...props} />}/>
                <Route path="/home" render={(props) => <AuthRoutes auth={auth} {...props} />}/>

                {/*<Route exact path="/login" render={(props) =>*/}
                {/*    <Login auth={auth} {...props} />*/}
                {/*}/>*/}
                <Route path="/callback" render={(props) => {
                    return <Callback auth={auth} {...props} />
                }}/>
            </Switch>
        </Router>
    );
};

export default Routes;