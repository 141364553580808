import React from 'react';
import { withStyles,useTheme } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import blue from "@material-ui/core/colors/blue";

const styles = theme => ({
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: '#fff'
    },
    title: {
        userSelect: 'none',
        color: '#fff',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        backgroundColor: blue[500]
    },
    content: {
        minHeight: 300
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const SomeContent = React.forwardRef((props, ref) => <div {...props} ref={ref}>Hello, World!</div>);
const DashboardDialog = React.forwardRef((props, ref) =>  {
    const {classes,closeCallback,showCloseBtn,closeBtnText,showDialog,title,body,bodyHtml,buttons} = props;
    const theme = useTheme();
    const fullScreen = (props.fullScreen || false) || useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <MuiDialog
                open={(showDialog || false)}
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={closeCallback}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                scroll="paper"
                disableBackdropClick={true}
                fullScreen={fullScreen}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255,0.5)'
                    },
                }}
            >
                <IconButton color="secondary" aria-label="close" className={classes.closeButton} onClick={closeCallback}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle className={classes.title} id="alert-dialog-slide-title">
                    {title}
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <DialogContentText id="alert-dialog-slide-description">
                        {body}
                    </DialogContentText>
                    {bodyHtml}
                </DialogContent>
                <DialogActions>
                    {(buttons || null) !== null &&
                    buttons.map((item,index) =>
                        <Button disabled={(item.disabled || false)} key={'dlg-btn-' + index} onClick={item.callback} color="primary">
                            {item.text}
                        </Button>
                    )
                    }
                    {(showCloseBtn || false) &&
                    <Button onClick={closeCallback} color="secondary">
                        {closeBtnText || 'CLOSE'}
                    </Button>
                    }
                </DialogActions>
            </MuiDialog>
        </div>
    );
});

export default withStyles(styles) (DashboardDialog);