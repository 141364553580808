import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
    request,
    validateField
} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    warning: {
        fontSize: 12,
        fontWeight: 300,
        color: '#444',
        margin: theme.spacing(1)
    }
});

class UpdateLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            summary: '',
            url: '',
            imageUrl: '',
            color: '',
            backgroundColor: '',
            landingPageLinkId: '',
            order: 0,
            updateRelated: true,
            isLoading: false,
            validations: []
        };
    }

    componentDidMount() {
        const {
            title,
            summary,
            url,
            imageUrl,
            color,
            backgroundColor,
            order,
            landingPageLinkId
        } = this.props.link;

        this.setState({title,summary,url,imageUrl,color,backgroundColor,order,landingPageLinkId})
    }

    save(){
        const {
            title,
            summary,
            url,
            imageUrl,
            color,
            backgroundColor,
            updateRelated,
            order,
            landingPageLinkId
        } = this.state;

        const action = '/Admin/updateLandingPageLink';
        const link = {title,summary,url,imageUrl,color,backgroundColor,order,landingPageLinkId}
        const data = {updateRelated, link};
        const successMessage = 'Successfully Updated Link!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({isLoading: false}, () => this.props.saved(link,successMessage))
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error))
            })
    }

    validateAll(){
        const fieldsToValidate = [
            'title',
            'summary',
            'url',
            'imageUrl',
            'color',
            'backgroundColor'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(
                fieldsToValidate[i],
                this.state[fieldsToValidate[i]] || null
            );
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value){
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'title':
                isValid = validateField(value);
                invalidationReason = 'The Title is required';
                break;
            case 'summary':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter a Summary';
                }
                break;
            case 'url':
                isValid = validateField(value);
                invalidationReason = 'The URL is required';
                break;
            case 'imageUrl':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please provide an Image URL';
                }
                break;
            case 'color':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please select a Text Color';
                }
                break;
            case 'backgroundColor':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please select a Background Color';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value === 'true'});
    };

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'UPDATE LINK';
        // const linkId = this.props.linkId || null;

        const {
            title,
            summary,
            url,
            // imageUrl,
            // color,
            // backgroundColor,
            // updateRelated,
            isLoading
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                               value={title}
                               name="title"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Link Title"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('title')}
                               helperText={this.fieldErrorMessage('title')}
                               required
                    />
                    <TextField autoComplete="new"
                               value={summary}
                               name="summary"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Link Summary"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('summary')}
                               helperText={this.fieldErrorMessage('summary')}
                    />
                    <TextField autoComplete="new"
                               value={url}
                               name="url"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Link URL"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('url')}
                               helperText={this.fieldErrorMessage('url')}
                               required
                    />
                    {/*<TextField autoComplete="new"*/}
                    {/*           value={imageUrl}*/}
                    {/*           name="imageUrl"*/}
                    {/*           onChange={(e) => this.handleFieldChange(e)}*/}
                    {/*           label="Link Image URL"*/}
                    {/*           className={classes.textField}*/}
                    {/*           margin="normal"*/}
                    {/*           error={this.fieldHasError('imageUrl')}*/}
                    {/*           helperText={this.fieldErrorMessage('imageUrl')}*/}
                    {/*/>*/}
                </div>
                {/*<br/>*/}
                {/*<FormControl component="fieldset" className={classes.formControl}>*/}
                {/*    <FormLabel component="legend">Would you like to update all usages of this link in all templates and for all Users?</FormLabel>*/}
                {/*    <br/>*/}
                {/*    <RadioGroup row aria-label="updateRelated" name="updateRelated" value={updateRelated.toString()} onChange={(e) => this.handleSelectChange(e)}>*/}
                {/*        <FormControlLabel value={'true'} control={<Radio/>} label="Yes"/>*/}
                {/*        <FormControlLabel value={'false'} control={<Radio/>} label="No"/>*/}
                {/*    </RadioGroup>*/}
                {/*</FormControl>*/}
                <div className={classes.warning}>Updating this link will update <strong>ALL</strong> instances in <strong>ALL</strong> Categories and <strong>ALL</strong> Templates.</div>
                {/*<br/>*/}
                <Button size="small" variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.setState({isLoading:true},() => this.save())} className={classes.button}>{saveBtnText}</Button>
                <Button size="small" variant="contained" color="secondary" onClick={() => this.props.close()} className={classes.button}>CANCEL</Button>
            </div>
        );
    }
}

export default withStyles(styles)(UpdateLink);