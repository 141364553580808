import React, { Component } from 'react';
import {createMuiTheme, withStyles} from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import {validateField} from '../../utils'
import _ from 'lodash';
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ThemeProvider } from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    readOnly: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // padding: theme.spacing(1),
        // border: 'solid 1px',
        // borderColor: '#e7e7e7'
    },
    roTitle: {
        fontWeight: 600
    },
    roValue: {
        fontWeight: 300
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
});

const dateTimeTheme = createMuiTheme();

dateTimeTheme.typography.h3 = {
    fontSize: '3rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.04,
    letterSpacing: '0em',
};

dateTimeTheme.typography.subtitle1 = {
    fontSize: '.85rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
};

class UpdateCloseDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readOnlyMode: true,
            // closeDateId: '',
            actualCloseDate: null,
            dateTerminated: null,

            closeDateType: '', // range,exact
            closeDateRange: '',
            constructionCloseDate: null,
            moveInDate: null,
            closeDateTime: null,
            closeDateMonth: '',
            closeDateYear: '',
            closeDateYears: [],
            isTentative: null,
            amendedCloseDate: null,
            contractCloseDate: null,
            contractCloseDateMode: 'sync',
            contractCloseDateYear: '',
            contractCloseDateMonth: '',
            validations: []
        };
    }

    componentDidMount() {
        const isLease = this.props.isLease || false;

        const underContract = this.props.underContract || false;
        const closeDate = this.props.closeDate || null;
        const builder = this.props.builder || '';

        if(isLease) {
            if(closeDate !== null) {
                this.setState({
                    moveInDate: closeDate.moveInDate,
                    closeDateType: 'Lease',
                })
            } else {
                this.setState({closeDateType: 'Lease'})
            }
        } else {
            let stage = this.props.stage || 1;
            if (isNaN(stage)) {
                stage = parseInt(stage);
            }
            const now = moment();
            const closeDateYears = [now.year()];
            const nextYear = now.year() + 1;
            closeDateYears.push(nextYear);
            closeDateYears.push(nextYear+1);

            const closeDateTypeDefault = stage === -1 || !underContract ? '' : stage < 4 ? 'TwoMonthWindow' : (builder === "Stephen Elliott" && stage < 6) || stage < 7 ? 'OneMonthWindow' : 'ExactDate';
            const closeDateRangeMonths = stage === -1 || !underContract ? -1 : stage < 4 ? 2 : (builder === "Stephen Elliott" && stage < 6) || stage < 7 ? 1 : 0;
            const closeDateRangeDefault = this.getFormattedDateRange(closeDateRangeMonths);

            if(closeDate !== null){
                const dt = closeDate.closeDateTime || null;
                if(dt !== null){
                    const year = moment(dt).year();
                    if(closeDateYears.indexOf(year) === -1){
                        closeDateYears.push(year);
                    }
                }
                const cdr = closeDate.closeDateRange || closeDateRangeDefault;
                const cdo = this.getCloseDateRangeFromString(cdr);
                const contractCdr = closeDate.contractCloseDateRange || closeDateRangeDefault;
                const defaultCdo = this.getCloseDateRangeFromString(contractCdr);

                this.setState({
                    closeDateRange: cdr,
                    constructionCloseDate: closeDate.constructionCloseDate || null,
                    closeDateType: closeDate.closeDateType || closeDateTypeDefault,
                    closeDateTime: closeDate.closeDateTime || null,
                    closeDateYears: closeDateYears,
                    closeDateMonth: cdo.month,
                    closeDateYear: cdo.year,
                    isTentative: closeDate.isTentative,
                    amendedCloseDate: closeDate.amendedCloseDate || null,
                    contractCloseDate: closeDate.contractCloseDate || null,
                    contractCloseDateMode: (closeDate.contractCloseDate || null) !== null ? closeDate.contractCloseDate === closeDate.closeDateTime ? 'sync' : 'date' : closeDate.contractCloseDateRange === closeDate.closeDateRange ? 'sync' : 'range',
                    contractCloseDateYear: defaultCdo.year,
                    contractCloseDateMonth: defaultCdo.month,
                })
            }
        }
    }

    handleError(error){
        this.props.showSnack('error',error);
    }

    validateAll() {
        const fieldsToValidate = [
            'closeDateType',
            'closeDateMonth',
            'closeDateYear',
            'constructionCloseDate',
            'moveInDate',
            'closeDateTime'
        ];

        const builder = this.props.builder || '';
        if(builder === "Stephen Elliott" ){
            fieldsToValidate.push('contractCloseDate','contractCloseDateYear','contractCloseDateMonth','amendedCloseDate');
        }

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }
    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'closeDateType':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'closeDateMonth':
                if(this.state.closeDateType === 'TwoMonthWindow' || this.state.closeDateType === 'OneMonthWindow'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter a Close Date Range.';
                }
                break;
            case 'closeDateYear':
                if(this.state.closeDateType === 'TwoMonthWindow' || this.state.closeDateType === 'OneMonthWindow'){
                    isValid = (value||null) !== null ? validateField(typeof value !== 'string' ? value.toString() : value) : false;
                    invalidationReason = 'Please enter a Close Date Range.';
                }
                break;
            case 'contractCloseDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'contractCloseDateMonth':
                if(this.state.contractCloseDateMode === 'range'){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter a Contract Close Date Range.';
                }
                break;
            case 'contractCloseDateYear':
                if(this.state.contractCloseDateMode === 'range'){
                    isValid = (value||null) !== null ? validateField(typeof value !== 'string' ? value.toString() : value) : false;
                    invalidationReason = 'Please enter a Contract Close Date Range.';
                }
                break;
            case 'constructionCloseDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'moveInDate':
                // isValid = validateField(value);
                // invalidationReason = '';
                break;
            case 'closeDateTime':
                if(this.state.closeDateType === 'ExactDate' || this.state.closeDateType === 'DateAndTime'){
                    isValid = (value||null) !== null;
                    invalidationReason = 'Please enter a Close Date.';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        } else {
            console.log(invalidationReason);
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        if(field === 'closeDateType'){
            if(value !== 'TwoMonthWindow' && value !== 'OneMonthWindow'){
                this.setState({[field]: value, closeDateRange: ''});
            } else if(value === 'OneMonthWindow') {
                this.setState({[field]: value, closeDateRange: this.getFormattedDateRange(1), closeDateMonth: '', closeDateTime: null});
            } else {
                this.setState({[field]: value, closeDateRange: this.getFormattedDateRange(2), closeDateMonth: '', closeDateTime: null});
            }
        } else if(field === 'contractCloseDateMode'){
            if(value === 'sync' || value === 'range'){
                this.setState({[field]: value, contractCloseDate: null});
            } else {
                this.setState({[field]: value, });
            }
        } else {
            this.setState({[field]: value});
        }
    }

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    handleSwitchChange(e){
        const field = e.target.name;
        const value = this.state[field];

        this.setState({[field]: !value});
    }
    
    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    getCloseDateRangeFromString(range){
        const a = range.split(' ');
        if(a.length === 2){
            return {month: a[0],year: a[1]};
        }

        return {month: '',year: moment().format('YYYY')};
    }

    getFormattedDateRange(months){
        if(months < 1){
            return '';
        }
        const now = moment();
        const start = now.clone().add({months:1});
        const end = now.clone().add({months:months});
        const startMonth = start.format('MMM').toUpperCase();
        const endMonth = end.format('MMM').toUpperCase();
        if(months === 1){
            return `${startMonth} ${start.format('YYYY')}`
        }

        return `${startMonth}/${endMonth} ${end.format('YYYY')}`
    }

    isValid() {
        const ignoreValidation = this.props.ignoreValidation || false;
        if(ignoreValidation){
            return true;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    saveCloseDate(){
        let closeDateId = this.props.closeDate.closeDateId;

        let {
            // closeDateId,
            closeDateType,
            closeDateMonth,
            closeDateYear,
            constructionCloseDate,
            moveInDate,
            closeDateTime,
            isTentative,
            amendedCloseDate,
            contractCloseDate,
            contractCloseDateMode,
            contractCloseDateYear,
            contractCloseDateMonth,
        } = this.state;

        if(closeDateType === 'OneMonthWindow' || closeDateType === 'TwoMonthWindow'){
            const m = closeDateMonth.slice(closeDateMonth.length - 3);
            const d = moment(`${closeDateYear}-01-01`);
            d.month(_.startCase(m));
            closeDateTime = d.endOf('month')
        }
        const closeDateRange = `${closeDateMonth} ${closeDateYear}`;
        let contractCloseDateRange = contractCloseDateMode === 'range' ? `${contractCloseDateMonth} ${contractCloseDateYear}` : '';

        if(contractCloseDateMode === 'sync' && closeDateType !== 'Lease'){
            if(closeDateType === 'OneMonthWindow' || closeDateType === 'TwoMonthWindow'){
                contractCloseDateRange = closeDateRange;
                contractCloseDate = null;
            } else {
                contractCloseDateRange = '';
                contractCloseDate = closeDateTime;
            }
        }

        this.props.save({
            closeDateId,
            closeDateType,
            closeDateRange,
            constructionCloseDate,
            moveInDate,
            closeDateTime,
            isTentative,
            amendedCloseDate,
            contractCloseDate,
            contractCloseDateRange
        })
    }
    
    render() {
        const { classes } = this.props;
        const builder = this.props.builder || '';
        const stage = this.props.stage || 1;
        const saveBtnText = this.props.saveBtnText || 'SAVE CLOSE DATE';
        const { closeDateMode } = this.state;
        const {
            closeDateType,
            // closeDateRange,
            constructionCloseDate,
            closeDateTime,
            closeDateMonth,
            closeDateYear,
            closeDateYears,
            dateTerminated,
            actualCloseDate,
            moveInDate,
            isTentative,
            amendedCloseDate,
            contractCloseDate,
            contractCloseDateMode,
            contractCloseDateYear,
            contractCloseDateMonth,
        } = this.state;

        const readOnlyMode = this.props.readOnlyMode || dateTerminated !== null || actualCloseDate !== null;

        const underContract = this.props.underContract || false;
        //closeDateType

        let closeDateWindows = [];
        if (underContract && builder !== 'Stephen Elliott' && builder !== 'My Home Communities') { //add && !Stephen Elliot and remove SE checks below
            if (stage < 4) {
                closeDateWindows.push('TwoMonthWindow');
            } else if (stage < 7) {
                closeDateWindows.push('OneMonthWindow');
            } else if (stage >= 7) {
                closeDateWindows.push('ExactDate');
                closeDateWindows.push('DateAndTime');
            }
        } else {
            closeDateWindows.push('TwoMonthWindow');
            closeDateWindows.push('OneMonthWindow');
            closeDateWindows.push('ExactDate');
            closeDateWindows.push('DateAndTime');
        }

        return (
            <div>
                {dateTerminated !== null &&
                <div>
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            // format="MM/DD/YYYY HH:mm"
                            className={classes.textField}
                            margin="normal"
                            label="Date Terminated"
                            value={dateTerminated}
                            // onChange={(d) => this.handleDateChange('dateTerminated', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                            disabled={true}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                }
                {actualCloseDate !== null &&
                <div>
                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            // format="MM/DD/YYYY HH:mm"
                            className={classes.textField}
                            margin="normal"
                            label="Actual Close Date"
                            value={actualCloseDate}
                            // onChange={(d) => this.handleDateChange('actualCloseDate', d)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            autoOk={true}
                            disabled={true}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                }
                <br/>
                {closeDateType === 'Lease' ? (
                    <div className={classes.formControl}>
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                className={classes.dateInput}
                                margin="normal"
                                id="moveInDate"
                                label="Move-In Date"
                                value={moveInDate}
                                onChange={(d) => this.handleDateChange('moveInDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                ) : (
                    <div>
                        <FormControl className={classes.dropdown}>
                            <InputLabel htmlFor="closeDateType-select">Close Date Window</InputLabel>
                            <Select
                                id="closeDateType-select"
                                name="closeDateType"
                                value={closeDateType}
                                onChange={(e) => this.handleSelectChange(e)}
                                disabled={readOnlyMode}
                            >
                                {(_.includes(closeDateWindows, 'TwoMonthWindow') || closeDateType === 'TwoMonthWindow') &&
                                <MenuItem value="TwoMonthWindow">Two Months</MenuItem>
                                }
                                {(_.includes(closeDateWindows, 'OneMonthWindow') || closeDateType === 'OneMonthWindow') &&
                                <MenuItem value="OneMonthWindow">One Month</MenuItem>
                                }
                                {(_.includes(closeDateWindows, 'ExactDate') || closeDateType === 'ExactDate') &&
                                <MenuItem value="ExactDate">Exact Date</MenuItem>
                                }
                                {(_.includes(closeDateWindows, 'DateAndTime') || closeDateType === 'DateAndTime') &&
                                <MenuItem value="DateAndTime">Date and Time</MenuItem>
                                }
                            </Select>
                        </FormControl>

                        {closeDateType === 'ExactDate' || closeDateType === 'DateAndTime' ? (
                            <div className={classes.formControl}>
                                {closeDateType === 'ExactDate' &&
                                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/DD/YYYY"
                                        className={classes.dateInput}
                                        margin="normal"
                                        id="closeDateTime"
                                        label={'Exact Close Date'}
                                        value={closeDateTime}
                                        onChange={(d) => this.handleDateChange('closeDateTime', d)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                        disabled={readOnlyMode}
                                    />
                                </MuiPickersUtilsProvider>
                                }
                                {closeDateType === 'DateAndTime' &&
                                <ThemeProvider theme={dateTimeTheme}>
                                    <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                        <KeyboardDateTimePicker
                                            ampm
                                            format="MM/DD/YYYY hh:mm a"
                                            onError={console.log}
                                            className={classes.dateInput}
                                            margin="normal"
                                            label="Close Date and Time"
                                            value={closeDateTime}
                                            onChange={(d) => this.handleDateChange('closeDateTime', d)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            // autoOk={true}
                                            disabled={readOnlyMode}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                                }
                            </div>
                        ) : (
                            <div>
                                <FormControl className={classes.dropdown}>
                                    <InputLabel htmlFor="closeDateMonth-select">Month(s)</InputLabel>
                                    {closeDateType === 'TwoMonthWindow' &&
                                    <Select
                                        id="closeDateMonth-select"
                                        name="closeDateMonth"
                                        value={closeDateMonth}
                                        onChange={(e) => this.handleSelectChange(e)}
                                        disabled={readOnlyMode}
                                    >
                                        <MenuItem value="JAN/FEB">JAN/FEB</MenuItem>
                                        <MenuItem value="FEB/MAR">FEB/MAR</MenuItem>
                                        <MenuItem value="MAR/APR">MAR/APR</MenuItem>
                                        <MenuItem value="APR/MAY">APR/MAY</MenuItem>
                                        <MenuItem value="MAY/JUN">MAY/JUN</MenuItem>
                                        <MenuItem value="JUN/JUL">JUN/JUL</MenuItem>
                                        <MenuItem value="JUL/AUG">JUL/AUG</MenuItem>
                                        <MenuItem value="AUG/SEP">AUG/SEP</MenuItem>
                                        <MenuItem value="SEP/OCT">SEP/OCT</MenuItem>
                                        <MenuItem value="OCT/NOV">OCT/NOV</MenuItem>
                                        <MenuItem value="NOV/DEC">NOV/DEC</MenuItem>
                                        <MenuItem value="DEC/JAN">DEC/JAN</MenuItem>
                                    </Select>
                                    }
                                    {closeDateType === 'OneMonthWindow' &&
                                    <Select
                                        id="closeDateMonth-select"
                                        name="closeDateMonth"
                                        value={closeDateMonth}
                                        onChange={(e) => this.handleSelectChange(e)}
                                        disabled={readOnlyMode}
                                    >
                                        <MenuItem value="JAN">JAN</MenuItem>
                                        <MenuItem value="FEB">FEB</MenuItem>
                                        <MenuItem value="MAR">MAR</MenuItem>
                                        <MenuItem value="APR">APR</MenuItem>
                                        <MenuItem value="MAY">MAY</MenuItem>
                                        <MenuItem value="JUN">JUN</MenuItem>
                                        <MenuItem value="JUL">JUL</MenuItem>
                                        <MenuItem value="AUG">AUG</MenuItem>
                                        <MenuItem value="SEP">SEP</MenuItem>
                                        <MenuItem value="OCT">OCT</MenuItem>
                                        <MenuItem value="NOV">NOV</MenuItem>
                                        <MenuItem value="DEC">DEC</MenuItem>
                                    </Select>
                                    }
                                </FormControl>
                                <FormControl className={classes.dropdown}>
                                    <InputLabel htmlFor="closeDateYear-select">Year</InputLabel>
                                    <Select
                                        id="closeDateYear-select"
                                        name="closeDateYear"
                                        value={closeDateYear}
                                        onChange={(e) => this.handleSelectChange(e)}
                                        disabled={readOnlyMode}
                                    >
                                        {closeDateYear === '' &&
                                        <MenuItem key={'year-select-empty'} value=""></MenuItem>
                                        }
                                        {closeDateYears.map((item, index) => (
                                            <MenuItem key={'year-select-' + index} value={item}>{item}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        <br/>
                        <MuiPickersUtilsProvider utils={DateMomentUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/DD/YYYY"
                                className={classes.dateInput}
                                margin="normal"
                                label="Anticipated Close Date"
                                value={amendedCloseDate}
                                onChange={(d) => this.handleDateChange('amendedCloseDate', d)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                autoOk={true}
                                disabled={readOnlyMode}
                            />
                        </MuiPickersUtilsProvider>
                        <br/>
                        {builder === "Stephen Elliott" &&
                        <div>
                            <FormControl component="fieldset" className={classes.formControl} disabled={readOnlyMode}>
                                <FormLabel component="legend">Contract Close Date</FormLabel>
                                <RadioGroup row aria-label="contractCloseDateMode" name="contractCloseDateMode" value={contractCloseDateMode} onChange={(e) => this.handleSelectChange(e)}>
                                    <FormControlLabel value="sync" control={<Radio/>} label="Sync with Close Date"/>
                                    <FormControlLabel value="range" control={<Radio/>} label="Date Range"/>
                                    <FormControlLabel value="date" control={<Radio/>} label="Exact Date"/>
                                </RadioGroup>
                            </FormControl>
                            <div className={classes.formControl}>
                                {contractCloseDateMode === 'range' &&
                                <div>
                                    <FormControl className={classes.dropdown}>
                                        <InputLabel htmlFor="contractCloseDateMonth-select">Month(s)</InputLabel>
                                        <Select
                                            id="contractCloseDateMonth-select"
                                            name="contractCloseDateMonth"
                                            value={contractCloseDateMonth}
                                            onChange={(e) => this.handleSelectChange(e)}
                                            disabled={readOnlyMode}
                                        >
                                            <MenuItem value="JAN">JAN</MenuItem>
                                            <MenuItem value="FEB">FEB</MenuItem>
                                            <MenuItem value="MAR">MAR</MenuItem>
                                            <MenuItem value="APR">APR</MenuItem>
                                            <MenuItem value="MAY">MAY</MenuItem>
                                            <MenuItem value="JUN">JUN</MenuItem>
                                            <MenuItem value="JUL">JUL</MenuItem>
                                            <MenuItem value="AUG">AUG</MenuItem>
                                            <MenuItem value="SEP">SEP</MenuItem>
                                            <MenuItem value="OCT">OCT</MenuItem>
                                            <MenuItem value="NOV">NOV</MenuItem>
                                            <MenuItem value="DEC">DEC</MenuItem>
                                            <MenuItem value="JAN/FEB">JAN/FEB</MenuItem>
                                            <MenuItem value="FEB/MAR">FEB/MAR</MenuItem>
                                            <MenuItem value="MAR/APR">MAR/APR</MenuItem>
                                            <MenuItem value="APR/MAY">APR/MAY</MenuItem>
                                            <MenuItem value="MAY/JUN">MAY/JUN</MenuItem>
                                            <MenuItem value="JUN/JUL">JUN/JUL</MenuItem>
                                            <MenuItem value="JUL/AUG">JUL/AUG</MenuItem>
                                            <MenuItem value="AUG/SEP">AUG/SEP</MenuItem>
                                            <MenuItem value="SEP/OCT">SEP/OCT</MenuItem>
                                            <MenuItem value="OCT/NOV">OCT/NOV</MenuItem>
                                            <MenuItem value="NOV/DEC">NOV/DEC</MenuItem>
                                            <MenuItem value="DEC/JAN">DEC/JAN</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.dropdown}>
                                        <InputLabel htmlFor="contractCloseDateYear-select">Year</InputLabel>
                                        <Select
                                            id="contractCloseDateYear-select"
                                            name="contractCloseDateYear"
                                            value={contractCloseDateYear}
                                            onChange={(e) => this.handleSelectChange(e)}
                                            disabled={readOnlyMode}
                                        >
                                            {contractCloseDateYear === '' &&
                                            <MenuItem key={'year-select-empty'} value=""></MenuItem>
                                            }
                                            {closeDateYears.map((item, index) => (
                                                <MenuItem key={'ccd-year-select-' + index} value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                }
                                {contractCloseDateMode === 'date' &&
                                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/DD/YYYY"
                                        className={classes.dateInput}
                                        margin="normal"
                                        label="Contract Close Date"
                                        value={contractCloseDate}
                                        onChange={(d) => this.handleDateChange('contractCloseDate', d)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                        disabled={readOnlyMode}
                                    />
                                </MuiPickersUtilsProvider>
                                }
                                {/*<br/>*/}
                                {/*<MuiPickersUtilsProvider utils={DateMomentUtils}>*/}
                                {/*    <KeyboardDatePicker*/}
                                {/*        disableToolbar*/}
                                {/*        variant="inline"*/}
                                {/*        format="MM/DD/YYYY"*/}
                                {/*        className={classes.dateInput}*/}
                                {/*        margin="normal"*/}
                                {/*        label="Anticipated Close Date"*/}
                                {/*        value={amendedCloseDate}*/}
                                {/*        onChange={(d) => this.handleDateChange('amendedCloseDate', d)}*/}
                                {/*        KeyboardButtonProps={{*/}
                                {/*            'aria-label': 'change date',*/}
                                {/*        }}*/}
                                {/*        autoOk={true}*/}
                                {/*        disabled={readOnlyMode}*/}
                                {/*    />*/}
                                {/*</MuiPickersUtilsProvider>*/}
                                {/*<br/>*/}
                                <MuiPickersUtilsProvider utils={DateMomentUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/DD/YYYY"
                                        className={classes.dateInput}
                                        margin="normal"
                                        label="Construction Close Date"
                                        value={constructionCloseDate}
                                        onChange={(d) => this.handleDateChange('constructionCloseDate', d)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        autoOk={true}
                                        disabled={readOnlyMode}
                                    />
                                </MuiPickersUtilsProvider>
                                <br/>
                                <FormControlLabel
                                    className={classes.switch}
                                    control={
                                        <Switch
                                            name="isTentative"
                                            checked={isTentative||false}
                                            onChange={(e) => this.handleSwitchChange(e)}
                                            color="primary"
                                            disabled={readOnlyMode}
                                        />
                                    }
                                    label="Closing Date Tentative?"
                                />
                            </div>
                        </div>
                        }
                    </div>
                )}
                {(!readOnlyMode && closeDateMode !== 'terminated' && closeDateMode !== 'closed') &&
                <Button disabled={!this.isValid()} variant="contained" color="primary" onClick={() => this.saveCloseDate()} className={classes.button}>{saveBtnText}</Button>
                }
            </div>
        );
    }
}

export default withStyles(styles)(UpdateCloseDate);