import React, { PureComponent } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

class Loading extends PureComponent {
    render() {
        const wrapper = this.props.wrapper || 'loading';
        const text = this.props.text || null;
        return (
            <div>
                {(this.props.contained || false) ? (
                    <LinearProgress />
                ) : (
                    <div className={wrapper}>
                        <div className="loading-circle">
                            <div className="loader"></div>
                        </div>
                        {text !== null &&
                        <div className="loading-text">{text}</div>
                        }
                    </div>
                )}
            </div>

        );
    }
}

export default Loading;
