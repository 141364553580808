import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField, validatePassword} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    header: {
        margin: theme.spacing(1) + 'px 0',
        color: '#555',
        fontSize: 22,
        textTransform: 'uppercase',
        fontWeight: 400
    },
});

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            name: '',
            email: '',
            password: '',
            role: '',
            roles: [],
            isLoading: true,
            validations: []
        };
    }

    componentDidMount() {
        this.getRoles();
    }

    getRoles() {
        request(
            'GET',
            '/Admin/getRoles',
            null,
            (data) => {
                this.setState({roles: data.roles, isLoading: false})
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error))
            })
    }

    validateAll(){
        const fieldsToValidate = [
            'name',
            'email',
            'password',
            'role',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(
                fieldsToValidate[i],
                this.state[fieldsToValidate[i]] || null
            );
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value){
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'name':
                isValid = validateField(value);
                invalidationReason = 'The User\'s name is required';
                break;
            case 'email':
                isValid = validateField(value);
                invalidationReason = 'The User\'s email is required';
                break;
            case 'password':
                isValid = validatePassword(value);
                invalidationReason = 'Password is required and must be at least 8 characters and contain 2+ uppercase, 2+ lowercase, 2+ numbers, and 1+ special characters (!@#$%&*)';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    handleSelectChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        this.setState({[field]: value});
    };

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    addUser() {
        const action = '/Admin/addUser';
        const {name, email, password, role} = this.state;
        const data = {name, email, password, role};
        const successMessage = 'Successfully Created User!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({isLoading: false}, () => this.props.saved(successMessage))
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error))
            })
    }

    render() {
        const { classes } = this.props;

        const {
            name,
            email,
            password,
            role,
            roles,
            isLoading
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                               value={name}
                               name="name"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Name"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('name')}
                               helperText={this.fieldErrorMessage('name')}
                    />
                    <br/>
                    <TextField autoComplete="new"
                               value={email}
                               name="email"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Email"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('email')}
                               helperText={this.fieldErrorMessage('email')}
                    />
                    <br/>
                    <TextField autoComplete="new"
                               value={password}
                               name="password"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="Password"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('password')}
                               helperText={this.fieldErrorMessage('password')}
                    />
                </div>
                <br/>
                {/*<Divider className={classes.divider}/>*/}
                {(roles||[]).length > 0 &&
                <div className={classes.flexWrapper}>
                    <FormControl className={classes.dropdown}>
                        <InputLabel htmlFor="role-select">Role</InputLabel>
                        <Select
                            id="role-select"
                            name="role"
                            value={role}
                            onChange={(e) => this.handleSelectChange(e)}
                        >
                            {roles.map((item, index) => (
                                <MenuItem key={item.roleId} value={item.name}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                }
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.setState({isLoading: true}, () => this.addUser())} className={classes.button}>ADD USER</Button>
            </div>
        );
    }
}

export default withStyles(styles)(AddUser);