import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import lightBlue from "@material-ui/core/colors/lightBlue";
import indigo from "@material-ui/core/colors/indigo";
import deepPurple from "@material-ui/core/colors/deepPurple";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import Divider from '@material-ui/core/Divider';
import ScheduleIcon from '@material-ui/icons/Schedule';

import ChartistGraph from 'react-chartist';
import '../../node_modules/chartist/dist/chartist.min.css'

const styles = theme => ({
    card: {
        marginTop: 40,
        overflow: 'visible'
    },
    cardGraphWrapper: {
        backgroundColor: '#fff',//cyan["500"],
        color: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        margin: '0 auto',
        marginTop: theme.spacing(-4),
        padding: 0,
        boxSizing: 'border-box',
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        borderRadius: 4,
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center'
    },
    red: {
        backgroundColor: red["500"]
    },
    cardGraph: {
        paddingTop: theme.spacing(3),
    },
    graphStyle: {
        '& .ct-grid': { stroke: 'rgba(0, 0, 0, 0.3)' },
        '& .ct-label': {
            fill: '#444',
            color: '#444'
        },
        '& .ct-bar': {
            stroke: 'rgba(3, 169, 244, 0.85)',
            color: 'rgba(3, 169, 244, 0.35)'
            // strokeWidth: 20
        },
        '& .ct-series-b .ct-bar': {
            stroke: 'rgba(244, 67, 54, 0.85)',
            color: 'rgba(244, 67, 54, 0.35)'
        },

        // '& .ct-line': {
        //     stroke: 'rgba(255, 255, 255, 0.85)',
        //     color: 'rgba(255, 255, 255, 0.85)'
        // },
        // '& .ct-point': {
        //     stroke: 'rgba(255, 255, 255, 0.85)',
        //     color: 'rgba(255, 255, 255, 0.85)'
        // }
    },
    icon: {
        color: '#fff',
        fontSize: '2.5rem'
    },
    cardContent: {
        paddingBottom: theme.spacing(2) + 'px !important',
    },
    cardBody: {
        margin: theme.spacing(3) + 'px 0'
    },
    cardRight: {
        marginBottom: theme.spacing(2),
    },
    cardFooter: {
        color: '#999',
        fontSize: 12,
        padding: '16px 0 0 0',
        textAlign: 'left'
    },
    cardFooterIcon: {
        fontSize: 12
    },
    flex: {
        display: 'flex'
    },
    grow: {
        flexGrow: 1,
    },

    wrapper: {
        textAlign: 'left'
    },
    label: {
        fontWeight: 300,
        fontSize: '1rem',
    },
    label2: {
        fontWeight: 200,
        fontSize: '.85rem',
    },
    notificationIcons: {
        padding: theme.spacing(1)
    },
});

class DashboardGraph extends Component {

    render() {
        const { classes, color, label, summary, period, data, options, type } = this.props;

        let style = {};

        switch(color) {
            case 'red':
                style = {backgroundColor: red[500]};
                break;
            case 'green':
                style = {backgroundColor: green[500]};
                break;
            case 'deepPurple':
                style = {backgroundColor: deepPurple[500]};
                break;
            case 'indigo':
                style = {backgroundColor: indigo[500]};
                break;
            case 'lightBlue':
                style = {backgroundColor: lightBlue[500]};
                break;
            case 'blue':
                style = {backgroundColor: blue[500]};
                break;
            case 'orange':
                style = {backgroundColor: orange[500]};
                break;
            default:
        }

        return (
            <div style={{position: 'relative'}}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.cardGraphWrapper} style={style}>
                            <div className={classes.cardGraph}>
                                <ChartistGraph className={classes.graphStyle} data={data} options={options} type={type} />
                            </div>
                        </div>
                        <div className={classes.cardBody}>
                            <div className={classes.wrapper}>
                                <span className={classes.label}>{label}</span>
                            </div>
                            <div className={classes.wrapper}>
                                <span className={classes.label2}>{summary}</span>
                            </div>
                        </div>
                        {/*<div className={classes.flex}>*/}
                        {/*<div className={classes.grow}>*/}

                        {/*</div>*/}
                        {/*</div>*/}
                        <Divider/>
                        <div className={classes.cardFooter}>
                            <ScheduleIcon className={classes.cardFooterIcon}/><span> {period}</span>
                        </div>
                    </CardContent>
                    {/*<CardActions>*/}
                    {/*</CardActions>*/}
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(DashboardGraph);