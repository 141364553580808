import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
    request,
    // validateEmail,
    validateField,
    validateLenientName
} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    dateInput: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // width: 210
    },
    switch: {
        marginTop: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
});

class Lender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            // email: '',
            phone: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            isLoading: false,
            validations: []
        };
    }

    componentDidMount() {
        const lenderId = this.props.lenderId || null;
        if(lenderId !== null){
            this.setState({isLoading:true},() => this.loadLender(lenderId))
        } else {
            const lender = this.props.lender || null;
            if(lender !== null){
                this.setState({
                    name: lender.name || '',
                    // email: lender.email,
                    phone: lender.phone || '',
                    address1: lender.address1 || '',
                    address2: lender.address2 || '',
                    city: lender.city || '',
                    state: lender.state || '',
                    zip: lender.zip || '',
                })
            }
        }
    }

    loadLender(lenderId) {
        request(
            'GET',
            '/Manage/getLender?lenderId=' + lenderId,
            null,
            (data) => {
                this.loadData(data.lender);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            name: data.name || '',
            // email: data.email || '',
            phone: data.phone || '',
            address1: data.address1 || '',
            address2: data.address2 || '',
            city: data.city || '',
            state: data.state || '',
            zip: data.zip || '',
        })
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'name',
            // 'email',
            'phone',
            'address1',
            'address2',
            'city',
            'state',
            'zip',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'name':
                isValid = validateLenientName(value);
                invalidationReason = 'The Lender\'s name is required';
                break;
            // case 'email':
            //     if(value.length > 0) {
            //         isValid = validateEmail(value);
            //         invalidationReason = 'Please enter a valid email address';
            //     }
            //     break;
            case 'phone':
                if(value !== null && value.length > 0){
                    isValid = validateField(value,10,10);
                    invalidationReason = 'Please enter a 10 digit phone number';
                }
                break;
            case 'address1':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the street # and name';
                }
                break;
            case 'address2':
                break;
            case 'city':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter the city name';
                }
                break;
            case 'state':
                if(value !== null && value.length > 0){
                    isValid = validateField(value);
                    invalidationReason = 'Please enter State';
                }
                break;
            case 'zip':
                if(value !== null && value.length > 0){
                    isValid = validateField(value,5,5);
                    invalidationReason = 'Please enter the 5 digit zip code';
                }
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;
        if (field === 'phone') {
            value = value.replace(/\D+/g, '');
            if(value.length > 10){
                value = value.substring(0,10);
            }
        }
        if (field === 'zip') {
            value = value.replace(/\D+/g, '');
            if(value.length > 5){
                value = value.substring(0,5);
            }
        }
        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    // handleSelectChange(e) {
    //     const field = e.target.name;
    //     let value = e.target.value;
    //
    //     this.setState({[field]: value});
    // };

    handleDateChange(name,date) {
        this.setState({[name]: date});
    }

    // handleSwitchChange(e){
    //     const field = e.target.name;
    //     const value = this.state[field];
    //
    //     this.setState({[field]: !value});
    // }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const readOnlyMode = this.props.readOnlyMode || false;
        if(readOnlyMode){
            return false;
        }
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;

    }

    render() {
        const { classes } = this.props;
        let lenderId = this.props.lenderId || null;
        if(lenderId === null){
            const lender = this.props.lender || null;
            if(lender !== null) {
                lenderId = lender.lenderId || null;
            }
        }
        
        const saveBtnText = this.props.saveBtnText || 'SAVE';
        const readOnlyMode = this.props.readOnlyMode || false;

        const {
            name,
            // email,
            phone,
            address1,
            address2,
            city,
            state,
            zip,
            isLoading
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                        value={name}
                        name="name"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Lender's Name"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('name')}
                        helperText={this.fieldErrorMessage('name')}
                        required
                        disabled={readOnlyMode}
                    />
                    {/*<TextField autoComplete="new"*/}
                    {/*    value={email}*/}
                    {/*    name="email"*/}
                    {/*    onChange={(e) => this.handleFieldChange(e)}*/}
                    {/*    label="Email Address"*/}
                    {/*    className={classes.textField}*/}
                    {/*    margin="normal"*/}
                    {/*    error={this.fieldHasError('email')}*/}
                    {/*    helperText={this.fieldErrorMessage('email')}*/}
                    {/*    disabled={readOnlyMode}*/}
                    {/*/>*/}
                    <TextField autoComplete="new"
                        value={phone}
                        name="phone"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Phone (10 Digits)"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('phone')}
                        helperText={this.fieldErrorMessage('phone')}
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={address1}
                        name="address1"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Address 1"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('address1')}
                        helperText={this.fieldErrorMessage('address1')}
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={address2}
                        name="address2"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Address 2"
                        className={classes.textField}
                        margin="normal"
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={city}
                        name="city"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="City"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('city')}
                        helperText={this.fieldErrorMessage('city')}
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={state}
                        name="state"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="State"
                        className={classes.textField}
                        margin="normal"
                        disabled={readOnlyMode}
                    />
                    <TextField autoComplete="new"
                        value={zip}
                        name="zip"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Zip"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('zip')}
                        helperText={this.fieldErrorMessage('zip')}
                        disabled={readOnlyMode}
                    />
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.props.save({
                    lenderId,
                    name,
                    // email,
                    phone,
                    address1,
                    address2,
                    city,
                    state,
                    zip
                })} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(Lender);