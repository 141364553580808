import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SecurityIcon from '@material-ui/icons/Security';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CardActions from '@material-ui/core/CardActions';

const styles = theme => ({
    card: {
        overflow: 'unset !important'
    },
    cardContent: {
        padding: theme.spacing(2) + 'px !important'
    },
    cardLeft: {
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        height: 40,
        minHeight: 40,
        maxHeight: 40,
        position: 'relative',
        marginRight: theme.spacing(1),
        borderRadius: '50%',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    cardRight: {
        textAlign: 'left',
        minWidth: 0,
        flex: 1
    },
    cardImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    cardLetter: {
        height: 24,
        width: 24,
        fontSize: 24,
        lineHeight: '24px',
        textAlign: 'center',
        textShadow: '1px 1px 2px rgb(60 60 60 / 50%)',
        color: '#022f3b',
        fontWeight: 600,
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    flex: {
        display: 'flex'
    },
    grow: {
        flexGrow: 1,
    },
    label: {
        fontWeight: 600,
        fontSize: 12,
        textTransform: 'uppercase',
        display: 'block',
        maxWidth: 190,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    summary: {
        fontWeight: 300,
        fontSize: 12,
        display: 'block',
        textTransform: 'uppercase',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    cardFooter: {
        color: '#999',
        fontSize: 12,
        borderTop: 'solid 1px #ebebeb'
    },
    cardFooterLeft: {
        minWidth: 0,
        flex: 1,
    },
    cardFooterRight: {

    },
    cardFooterIcon: {
        fontSize: 12
    },
    iconButton: {
        padding: 4
    },
    icon: {
        height: 16,
        width: 16,
        fontSize: 16
    },
    role: {
    }
});

class UserSummary extends Component {

    render() {
        const { classes, name, email, role, color, backgroundColor } = this.props;

        return (
            <div style={{position: 'relative'}}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.flex}>
                            <div className={classes.cardLeft} style={{backgroundColor: backgroundColor||'#3F51B5'}}>
                                <div className={classes.cardLetter} style={{color: color||'#FFF'}}>{name.charAt(0)}</div>
                            </div>
                            <div className={classes.cardRight}>
                                <span className={classes.label} title={name}>{name}</span>
                                <span className={classes.summary} title={email}>{email}</span>
                            </div>
                        </div>
                    </CardContent>
                    <CardActions className={classes.cardFooter}>
                        <div className={classes.cardFooterLeft}>
                            {(role||null) !== null &&
                            <div className={classes.role}>
                                <SecurityIcon className={classes.cardFooterIcon}/><span> {role}</span>
                            </div>
                            }
                        </div>
                        <div className={classes.cardFooterRight}>
                            <IconButton className={classes.iconButton} color="primary" aria-label="Edit User" title="Edit User" onClick={() => this.props.edit()}>
                                <EditIcon className={classes.icon} />
                            </IconButton>
                            <IconButton className={classes.iconButton} color="secondary" aria-label="Delete User" title="Delete User" onClick={() => this.props.delete()}>
                                <CloseIcon className={classes.icon} />
                            </IconButton>
                        </div>
                    </CardActions>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(UserSummary);