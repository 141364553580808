import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    strong: {
        margin: theme.spacing(1)
    }
});

class DeleteUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmation: '',
            isLoading: false,
            validations: []
        };
    }

    // componentDidMount() {
    // }

    validateAll(){
        const fieldsToValidate = [
            'confirmation'
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(
                fieldsToValidate[i],
                this.state[fieldsToValidate[i]] || null
            );
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value){
        let isValid = true;
        let invalidationReason = '';
        const email = this.props.email;

        switch(fieldName){
            case 'confirmation':
                isValid = value === email;
                invalidationReason = 'Confirmation is required';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e) {
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;
    }

    deleteUser() {
        const action = '/Admin/deleteUser';
        const userId = this.props.userId;
        const confirmation = this.state.confirmation;

        const data = {userId,confirmation};
        const successMessage = 'Successfully Deleted User!';

        request(
            'POST',
            action,
            data,
            (data) => {
                this.setState({isLoading: false}, () => this.props.saved(successMessage))
            },
            (error) => {
                this.setState({isLoading: false}, () => this.props.showSnack('error',error))
            })
    }

    render() {
        const { classes } = this.props;

        const {
            confirmation,
            isLoading
        } = this.state;

        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <strong className={classes.strong}>In order to confirm deletion of the user with the email address {this.props.email}, please enter the user's email address below.</strong>
                    <br/>
                    <TextField autoComplete="new"
                               value={confirmation}
                               name="confirmation"
                               onChange={(e) => this.handleFieldChange(e)}
                               label="User's Email Address"
                               className={classes.textField}
                               margin="normal"
                               error={this.fieldHasError('confirmation')}
                               helperText={this.fieldErrorMessage('confirmation')}
                    />
                    <br/>
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.setState({isLoading: true}, () => this.deleteUser())} className={classes.button}>DELETE USER</Button>
            </div>
        );
    }
}

export default withStyles(styles)(DeleteUser);