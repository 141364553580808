import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {request, validateField} from '../../utils'
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import Loading from "../Loading";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
    },
    dropdown: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        minWidth: 210
    },
    flexWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // minWidth: 210
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});

class LeadSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            isLoading: false,
            validations: []
        };
    }

    componentDidMount() {
        const leadSourceId = this.props.leadSourceId || null;
        if(leadSourceId !== null){
            this.setState({isLoading: true}, () => this.loadLeadSource(leadSourceId))
        } else {
            const leadSource = this.props.leadSource || null;
            if(leadSource !== null){
                this.setState({
                    name: leadSource.name || '',
                })
            }
        }
    }

    loadLeadSource(leadSourceId) {
        request(
            'GET',
            '/Manage/getLeadSource?leadSourceId=' + leadSourceId,
            null,
            (data) => {
                this.loadData(data.leadSource);
            },
            (error) => {
                this.handleError(error);
            })
    }

    loadData(data)
    {
        this.setState({
            isLoading: false,
            name: data.name
        })
    }

    handleError(error){
        this.setState({isLoading: false}, () => this.props.showSnack('error',error))
    }

    validateAll() {
        const fieldsToValidate = [
            'name',
        ];

        let validations = [];
        for(let i = 0; i < fieldsToValidate.length; i++){
            const validation = this.validateField(fieldsToValidate[i],this.state[fieldsToValidate[i]]);
            validations.push(validation);
        }

        return validations;
    }

    validateField(fieldName,value) {
        let isValid = true;
        let invalidationReason = '';

        switch(fieldName){
            case 'name':
                isValid = validateField(value);
                invalidationReason = 'The name of the LeadSource is required';
                break;
            default:
                break;
        }

        if(isValid){
            invalidationReason = '';
        }

        return {fieldName,isValid,invalidationReason}
    }

    handleFieldChange(e){
        const field = e.target.name;
        let value = e.target.value;

        let validations = this.state.validations;
        const validation = this.validateField(field,value);

        const vIndex = _.findIndex(validations, {fieldName: field});

        if(vIndex === -1) {
            validations.push(validation);
        } else {
            validations[vIndex] = validation;
        }

        this.setState({[field]: value, validations});
    }

    fieldHasError(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return !validations[vIndex].isValid;
        }
        return false;
    }

    fieldErrorMessage(field) {
        const validations = this.state.validations;
        const vIndex = _.findIndex(validations, {fieldName: field});
        if(vIndex > -1){
            return validations[vIndex].invalidationReason;
        }
        return '';
    }

    isValid() {
        const validations = this.validateAll();
        const i = _.findIndex(validations, {isValid: false});
        return i <= -1;

    }

    render() {
        const { classes } = this.props;
        const saveBtnText = this.props.saveBtnText || 'SAVE';
        const leadSourceId = this.props.leadSourceId || null;

        const { name, isLoading } = this.state;
        return (
            <div>
                {isLoading &&
                <Loading/>
                }
                <div className={classes.flexWrapper}>
                    <TextField autoComplete="new"
                        value={name}
                        name="name"
                        onChange={(e) => this.handleFieldChange(e)}
                        label="Lead Source"
                        className={classes.textField}
                        margin="normal"
                        error={this.fieldHasError('name')}
                        helperText={this.fieldErrorMessage('name')}
                        required
                    />
                </div>
                <br/>
                <Button variant="contained" color="primary" disabled={!this.isValid()} onClick={() => this.props.save({name,leadSourceId})} className={classes.button}>{saveBtnText}</Button>
            </div>
        );
    }
}

export default withStyles(styles)(LeadSource);